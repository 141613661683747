import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
import { Route, Switch} from 'react-router-dom';
// import asyncComponent from '../../../util/asyncComponent';
// import Button from '@material-ui/core/Button';
// import CardBox from 'components/CardBox';
import List from './routes/list'
import Details from './routes/details'
// import AddCourse from './AddCourse'
import { connect } from 'react-redux'
import * as CoursesActions from '../../../actions/Courses'
// import * as NewsActions from '../../../actions/News'

// import {NotificationContainer, NotificationManager} from 'react-notifications';
import {NotificationContainer} from 'react-notifications';

class News extends React.Component {

	constructor() {
		super()
		this.state = {
			addMode: false,
			editMode: false,
			editItem: null
		}
	}

	componentWillMount() {
		// this.props.dispatch(CoursesActions.getCoursesLevels())
	}

	render() {
		let match = this.props.match
		return <div className="app-wrapper">
       				<NotificationContainer/>
			        <Switch>
			            <Route path={`${match.url}/`} exact component={List}/>
			            <Route path={`${match.url}/:id`} component={Details}/>
					</Switch>
			    </div>
	}

	toggleAddMode(editMode = false) {
		this.setState({
			...this.state,
			addMode: !this.state.addMode,
			editMode
		})
	}

	addCourse(data) {
		if ( this.state.editMode ) {
			this.props.dispatch(CoursesActions.editCourse(data))
		} else {
			this.props.dispatch(CoursesActions.addCourse(data))
		}
	}

}
const mapStateToProps = (state) => {
	return {
		news: state.news
	}
}

export default connect(mapStateToProps)(News)
