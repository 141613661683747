import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
// import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import CardBox from '../../../../../components/CardBox';
import { connect } from 'react-redux'
// import ProfileBox from './ProfileBox'
// import ContactCard from './ContactCard'
import CoursesList from './CoursesList'
// import EditForm from './EditForm'
// import * as UserActions from '../../../../../actions/User'
import * as CoursesActions from '../../../../../actions/Courses'
import Widget from "../../../../../components/Widget";
import Auxiliary from "../../../../../util/Auxiliary";
import Dragndrop from '../../dragndrop'
import Tooltip from '@material-ui/core/Tooltip';
import settings from '../../../../../config/settings.js'
import {NotificationContainer, NotificationManager} from 'react-notifications';

class CourseDetails extends React.Component {

	constructor() {

    super()
    this.state = {
      courseFiles: [],
      courseFormFiles: []
    }

	}

	componentWillMount() {

		let slug = this.props.match.params.slug
    this.props.dispatch(CoursesActions.getCourseDetails(slug))
    this.props.dispatch(CoursesActions.getCourseDatesBySlug(slug))

    // this.props.dispatch(CoursesActions.getCourseStaffFiles(slug))

		// this.props.dispatch(UserActions.getUserDetails(userId))


	}

  componentDidUpdate(prevProps) {
    if ( !prevProps.courses.addedCourseStaffFiles && this.props.courses.addedCourseStaffFiles ) {
      NotificationManager.success("Pomyślnie dodano pliki do szkolenia!", "", 2000);
      this.clearCourseFormFiles()
    }
  }

	render() {
		// let match = this.props.match
		let data = this.props.courses.courseDetails.data

    return <Auxiliary>
       				<NotificationContainer/>
            <div className="jr-profile-banner" style={{
              backgroundImage: "url(" + data.image + ")",
              backgroundSize: 'cover'
              }}>
              <div className="jr-profile-container">
                <div className="jr-profile-banner-top">
                  <div className="jr-profile-banner-top-left">

                    <div className="jr-profile-banner-avatar-info">
                      <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">{data.title}</h2>
                      <p className="mb-0 jr-fs-lg">{data.category ? data.category?.name + ', ' : null}{data.level ? data.level.name : null}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="jr-profile-content">
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-7 col-12">
                  <Widget styleName="jr-card-profile">
                    <div className="mb-3">
                      <h3 className="card-title mb-2 mb-md-3">Wstęp</h3>
                      <p className="text-grey jr-fs-sm mb-0">{data.lead}</p>
                    </div>
                    <div className="mb-3">
                      <h3 className="card-title mb-2 mb-md-3">Opis</h3>
                      <p className="text-grey jr-fs-sm mb-0">{data.description}</p>
                    </div>
                  </Widget>
                </div>
                {this.renderCourseFiles()}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <CardBox styleName="col-12" cardStyle="p-0" heading={"Terminy tego szkolenia"}
                  headerOutside>
                  {this.renderCourseDatesList()}
                </CardBox>
              </div>
            </div>
          </Auxiliary>
}

  renderCourseFiles() {
		// let slug = this.props.match.params.slug

    return <div className="col-xl-5">
              <Widget styleName="jr-card-profile">
                <div className="mb-3">
                  <h3 className="card-title mb-2 mb-md-3">Materiały dla szkoleniowca</h3>
                  <div className="row">
                    <div className="col-xl-8">
                      <Dragndrop text="Przeciągnij pliki" onFileChange={this.onFileChange.bind(this)}/>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-10">
                      {Object.keys(this.props.courses.courseStaffFilesValidation).length > 0 ? <div className="text-red mb-2">Przesyłane pliki są zbyt duże.</div> : null}
                      {this.state.courseFormFiles.length > 0 ? <h3 className="card-title mb-2 mb-md-3">Pliki do przesłania:</h3> : null}
                        {this.state.courseFormFiles.map((item, key) => {
                        return <div key={key} className="mb-1 row" style={{clear: 'both'}} >
                                <div className="file-list-item col-10">
                                  {item.name} - {Math.round((item.size / (1024 * 1024)) * 100 ) / 100}MB
                                </div>
                                <Tooltip className="pull-right col-2" onClick={this.deleteCourseStaffFormFile.bind(this, key)} id="tooltip-icon" title="Usuń plik" placement="top">
                                  <Fab color="primary" className="bg-red text-white jr-fab-btn jr-btn-fab-xs" aria-label="delete">
                                    <i className="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"/>
                            </Fab>
                                </Tooltip>
                              </div>
                      })}
                    </div>
                    {this.state.courseFormFiles.length > 0 ? <div className="col-xl-2">
                      <Tooltip id="tooltip-icon" title="Wyślij" placement="top">
                <Fab onClick={this.addCourseStaffFiles.bind(this)}  color="primary" className="jr-fab-btn" aria-label="add">
                            <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg"/>
                </Fab>
                      </Tooltip>
                    </div> : null}
                  </div>
                  {this.props.courses.courseDetails.data.staffFiles.data.length > 0 ? this.renderExistingCourseFiles() : <p className="text-grey jr-fs-sm mb-0">Brak materiałow do tego szkolenia</p>}
                  {this.props.courses.courseDetails.data.staffFiles.data.length > 0 ? <a href={settings.api_endpoint+'/course/'+this.props.courses.courseDetails.data.slug+'/staffFiles/zipped'} download>
                      <Tooltip id="tooltip-icon" title="Pobierz materiały" placement="top">
                        <Fab color="primary" className="jr-fab-btn" aria-label="add">
                          <i className="zmdi zmdi-download zmdi-hc-fw zmdi-hc-lg"/>
              </Fab>
                      </Tooltip>
                    </a> : null}
                </div>
              </Widget>
            </div>
  }

  renderExistingCourseFiles() {
    return this.props.courses.courseDetails.data.staffFiles.data.map((item, key) => {
      return <div className="mb-3 row">
        <a download target="_blank" rel="noopener noreferrer" href={item.urlFile} className="mb-1 file-list-item col-10" key={key}>{item.title}</a>
              <div className="col-2">
                <Tooltip onClick={this.deleteCourseStaffFile.bind(this, item.identifier)} className="pull-right" id="tooltip-icon" title="Usuń plik" placement="top">
                        <Fab color="primary" className="bg-red text-white jr-fab-btn jr-btn-fab-xs" aria-label="delete">
                          <i className="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"/>
            </Fab>
                </Tooltip>
              </div>
             </div>
    })
  }

  deleteCourseStaffFile(fileId) {
		let courseId = this.props.match.params.slug
    this.props.dispatch(CoursesActions.deleteCourseStaffFiles(courseId, fileId))
  }

  deleteCourseStaffFormFile(id) {
    if ( this.state.courseFormFiles.length === 1 ) {
      this.props.dispatch(CoursesActions.clearCourseStaffFilesValidation())
    }
    this.setState({
      ...this.state,
      courseFormFiles: this.state.courseFormFiles.filter((item, key) => key !== id)
    })
  }

  onFileChange(e, files) {
  
    this.setState({
      ...this.state,
      courseFormFiles: files
    }, () => {
    
    })
  }

  addCourseStaffFiles() {
    let slug = this.props.match.params.slug
    this.props.dispatch(CoursesActions.addCourseStaffFiles(slug, this.state.courseFormFiles))
  }

  clearCourseFormFiles() {
    this.setState({
      ...this.state,
      courseFormFiles: []
    })
  }

  renderCourseDatesList() {
    if ( this.props.courses.courseDates.length > 0 ) {
      return <CoursesList list={this.props.courses.courseDates}/>
    } else {
      return <Widget styleName="jr-card-profile">
              <div>Brak terminów tego szkolenia.</div>
             </Widget>
    }
  }
}

const mapStateToProps = (state) => {
	return {
    courses: state.courses
	}
}

export default connect(mapStateToProps)(CourseDetails)
