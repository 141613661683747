import React, {Component} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "../assets/vendors/style"
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';
import SignIn from './SignIn';
import SignUp from './SignUp';
import MainApp from '../app/index';
import RTL from '../util/RTL';
import asyncComponent from '../util/asyncComponent';
import axios from 'axios'
import {getAuthUser, authUser} from '../actions/User';
import RecoverPassword from './RecoverPassword'
import ResetPassword from './ResetPassword'
import { LOGIN_ROUTE, RESEND_EMAIL_ROUTE } from '../constants/routes';
import ResendEmailPage from '../pages/resendEmailPage/ResendEmailPage';
// import * as UserActions from '../actions/User'

MomentUtils.prototype.getStartOfMonth=MomentUtils.prototype.startOfMonth
// const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
//   <Route
//     {...rest}
//     render={props =>
//       authUser
//         ? <Component {...props} />
//         : <Redirect
//           to={{
//             pathname: LOGIN_ROUTE,
//             state: {from: props.location}
//           }}
//         />}
//   />

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      initUrl: ''
    }
  }

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    this.setInitUrl()
  }

  componentDidMount() {
    // let reauthLaunched = false
    // const {match, location, locale, isDirectionRTL} = this.props;
        let localUser = JSON.parse(localStorage.getItem('user'))
        
        axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
        if ( localUser ) {
            axios.defaults.headers.common = {
                "Authorization": "Bearer " + localUser.access_token
            }

            this.props.dispatch(this.props.getAuthUser())
        }
    // axios.interceptors.request.use((data) => {
    // }, (err) => {
    // })
    // axios.interceptors.response.use((data) => {
    //   reauthLaunched = false
    //   return data
    // }, (err, data) => {
    //   if ( err ) {
    //     if ( err.response ) {
    //       if ( err.response.status == 401 && err.response.data.error == "Unauthenticated." && !reauthLaunched ) {
    //         // this.props.dispatch(this.props.getAuthUser())
    //         // let refresh_token = localStorage.getItem("refresh_token")
    //         let user = JSON.parse(localStorage.getItem('user'))
    //         if ( user ) {
    //           if ( user.refresh_token ) {
    //             this.props.dispatch(UserActions.refreshToken(user.refresh_token))
    //             reauthLaunched = true
    //           }
    //         }
    //       }
    //     }
    //   }
    // })
  }

  componentDidUpdate(prevProps) {
        if ( !prevProps.user.auth.authorized && this.props.user.auth.authorized ) {
            let localUser = JSON.parse(localStorage.getItem('user'))
            if ( localUser ) {
                axios.defaults.headers.common = {
                    "Authorization": "Bearer " + localUser.access_token
                }
            }
            this.props.history.push(this.state.initUrl)
            this.props.dispatch(this.props.getAuthUser())
        }
      if ( prevProps.user.auth.authorized && !this.props.user.auth.authorized ) {

          this.props.history.push(LOGIN_ROUTE)
      }
      if ( !prevProps.user.auth.refresh && this.props.user.auth.refresh ) {
        let localUser = JSON.parse(localStorage.getItem('user'))
        axios.defaults.headers.common = {
            "Authorization": "Bearer " + localUser.access_token
        }
      }
  }

  setInitUrl() {
    this.setState({
      ...this.state,
      initUrl: this.props.history.location.pathname.includes('/app') ? this.props.history.location.pathname : '/app/dashboard'
    })
  }

  render() {
    const {match, location, locale, isDirectionRTL} = this.props;

    const applyTheme = createMuiTheme(defaultTheme);

    if ( !this.props.user.auth.authorized ) {
      if (location.pathname !== LOGIN_ROUTE && location.pathname !== '/recover-password' && !location.pathname.includes('/reset-password') && location.pathname !== RESEND_EMAIL_ROUTE ) {
        return ( <Redirect to={LOGIN_ROUTE}/> );
      }
    } else {
      // if ( location.pathname === '/' || location.pathname === LOGIN_ROUTE )
      // return ( <Redirect to={this.state.initUrl}/> );
    }

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];

    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  {/* <RestrictedRoute path={`${match.url}app`} authUser={this.props.user.auth.authorized}
                                   component={MainApp}/> */}
                  <Route path={`${match.url}app`} component={MainApp}/>
                  <Route path={LOGIN_ROUTE} component={SignIn}/>
                  <Route path='/signup' component={SignUp}/>
                  <Route path='/recover-password' component={RecoverPassword}/>
                  <Route path='/reset-password/:token?' component={ResetPassword}/>
                  <Route path={RESEND_EMAIL_ROUTE} render={props => <ResendEmailPage {...props} />} />
                  <Route
                    component={asyncComponent(() => import('../components/Error404'))}/>
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const {sideNavColor, locale, isDirectionRTL} = state.settings;
  let user = state.user
  let pos = state.pos
  return {sideNavColor, locale, isDirectionRTL, user, pos, getAuthUser, authUser}
};

export default connect(mapStateToProps)(App);

