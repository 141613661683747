import React from 'react';
import TextField from '@material-ui/core/TextField';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import { connect } from 'react-redux'
// import * as CoursesActions from '../../../../actions/Courses'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import DateTimePicker from './DateTimePicker'
import moment from 'moment'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import DialogComponent from '../../../../components/Dialog/DialogComponent';

export default class AddForm extends React.Component {

    constructor(props) {
        super(props)
        let initialDate = new Date()
       
        initialDate.setDate(initialDate.getDate())
        this.state = {
            place: {
                selected: 'szczecin'
            },
            course: {
                capacity: null,
                coursePrice: 0,
                dateStart: props.addStartDate,
                dateEnd: props.addEndDate,
                timeStart: props.timeStart,
                timeEnd: props.timeEnd,
                // hours: null,
                courseID: null,
                placeName: 'szczecin',
                // description: null,
                // expertID: null
                courseDatePrice: null
            },
            errors: {
                capacity: '',
                expertID: '',
                coursePrice: '',
                dateStart: '',
                dateEnd: '',
                hours: '',
                courseID: '',
                placeName: '',
                description: ''
            }
        }

    }

    componentDidUpdate(prevProps) {
    }

    componentDidMount() {
        if ( this.props.editMode && this.props.details ) {
         
            let { capacity, dateStart, dateEnd, placeName, courseDatePrice, courseID } = this.props.details
            this.setState({
                ...this.state,
                place: {
                    selected: placeName
                },
                course: {
                    ...this.state.course,
                    capacity: capacity,
                    
                    dateStart: moment(dateStart).format('YYYY-MM-DD'),
                    dateEnd: moment(dateEnd).format('YYYY-MM-DD'),
                    timeStart: moment(dateStart).format('HH:mm'),
                    timeEnd: moment(dateEnd).format('HH:mm'),
                    // hours: null,
                    courseID: courseID,
                    placeName: placeName,
                    courseDatePrice: courseDatePrice
                    // description: null,
                }

            })
        }
    }

    render() {
     
        let { courseID, dateStart, dateEnd, } = this.props.form.errors 
        // let { capacity, timeStart, timeEnd, expertID, courseID, dateStart, dateEnd,  } = this.props.form.errors
        let capacityError = this.props.form.errors.capacity ? true : false
        let courseIDError = this.props.form.errors.courseID ? true : false
        let courseDatePriceError = this.props.form.errors.courseDatePrice ? true : false
        // let expertIDError = this.props.form.errors.expertID ? true : false
        let placeNameError = this.props.form.errors.placeName ? true : false
        let dateStartError = this.props.form.errors.dateStart ? true : false
        let dateEndError = this.props.form.errors.dateEnd ? true : false
        // let hoursError = this.props.form.errors.hours ? true : false
        // let descriptionError = this.props.form.errors.description ? true : false
        let timeStartError = this.props.form.errors.timeStart ? true : false
        let timeEndError = this.props.form.errors.timeEnd ? true : false

        // let titleError = this.props.form.errors.title ? true : false
        // let leadError = this.props.form.errors.lead ? true : false
        // let categoryIDError = this.props.form.errors.categoryID ? true : false
        // let levelIDError = this.props.form.errors.levelID ? true : false
        return (
                <form onSubmit={this.onSubmit.bind(this)}>

                <DialogComponent
                    open={this.props.open}
                    btnSave={this.props.editMode ? 'Zapisz' : 'Dodaj'}
                    handleClose={this.props.togglePopup}
                    handleSave={this.saveForm.bind(this)}
                    title={this.props.editMode ? 'Edytuj termin szkolenia' : 'Dodaj termin szkolenia'}
                >
                    <div className="col-md-6 mb-3">
                        <InputLabel shrink error={dateStartError}>{dateStartError ? dateStart : "Od dnia:"}</InputLabel>
                        <DateTimePicker initialDate={this.props.editMode ? this.state.course.dateStart : this.props.addStartDate} error={dateStartError} name="dateStart" onChange={this.onDateChange.bind(this)} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <InputLabel shrink error={dateEndError}>{dateEndError ? dateEnd : "Do dnia:"}</InputLabel>
                        <DateTimePicker initialDate={this.props.editMode ? this.state.course.dateEnd : this.props.addEndDate} error={dateEndError} name="dateEnd" onChange={this.onDateChange.bind(this)} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl className="w-100">
                            <TextField
                                id="number"
                                error={timeStartError}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={timeStartError ? this.props.form.errors.timeStart : "Od godziny:"}
                                name="timeStart"
                                value={this.state.course.timeStart}
                                onChange={this.onChange.bind(this)}
                                type="time"
                                fullWidth
                            />
                        </FormControl>
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl className="w-100">
                            <TextField
                                id="time"
                                error={timeEndError}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={timeEndError ? this.props.form.errors.timeEnd : "Do godziny:"}
                                name="timeEnd"
                                value={this.state.course.timeEnd}
                                onChange={this.onChange.bind(this)}
                                type="time"
                                fullWidth
                            />
                        </FormControl>
                    </div>

                    <div className="col-md-6 mb-3">
                        <FormControl className="w-100">
                            <TextField
                                id="capacity"
                                error={capacityError}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={capacityError ? this.props.form.errors.capacity : "Ilość wolnych miejsc"}
                                name="capacity"
                                value={this.state.course.capacity}
                                onChange={this.onChange.bind(this)}
                                type="number"
                                fullWidth
                            />
                        </FormControl>
                    </div>


                    {/* <div className="col-md-12 mb-3">
                                        <FormControl className="w-100">
                                            <TextField
                                                id="description"
                                                error={descriptionError}
                                                InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                label={hoursError ? this.props.form.errors.description : "Opis"}
                                                name="description"
                                                value={this.state.description}
                                                onChange={this.onChange.bind(this)}
                                                type="text"
                                                fullWidth
                                              />
                                        </FormControl>
                                    </div> */}
                    <div className="col-md-8 mb-3">
                        <FormControl component="fieldset" required>
                            <FormLabel component="legend">Miejsce szkolenia</FormLabel>
                            <RadioGroup
                                className="d-flex flex-row"
                                aria-label="placeName"
                                name="placeName"
                                value={this.state.place.selected}
                                onChange={this.handlePlaceChange.bind(this)}
                            >
                                <FormControlLabel value="Szczecin" control={<Radio color="primary" />} label="Szczecin" />
                                <FormControlLabel value="Warszawa" control={<Radio color="primary" />} label="Warszawa" />
                                <FormControlLabel value="other" control={<Radio color="primary" />} label="Inne" />
                                {/*<FormControlLabel value="disabled" disabled control={<Radio />} label="Disabled" />*/}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {this.state.place.selected === 'other' ? <div className="col-md-4 mt-3">
                        <FormControl className="w-100">
                            <TextField
                                error={placeNameError}
                                id="placeName"
                                name="placeName"
                                label={placeNameError ? this.props.form.errors.placeName : "Wpisz miejsce szkolenia"}
                                value={this.state.placeName}
                                onChange={this.onChange.bind(this)}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </FormControl>
                    </div> : null}
                    <div className="col-md-6 mb-3">
                        <FormControl className="w-100">
                            {/* <InputLabel htmlFor="age-simple">{categoryIDError ? this.props.form.errors.levelID : 'Szkolenie'}</InputLabel> */}
                            <InputLabel error={courseIDError}
                                shrink
                                htmlFor="courseID-label-placeholder">
                                {courseIDError ? courseID : 'Szkolenie'}
                            </InputLabel>
                            <Select
                                error={courseIDError}
                                name="courseID"
                                displayEmpty
                                onChange={this.onChange.bind(this)}
                                value={this.state.course.courseID}
                                input={<Input id="courseID" name="courseID" />} >
                                <MenuItem value="">
                                    <em>Wybierz szkolenie...</em>
                                </MenuItem>
                                {this.props.courses.map((item, key) => {
                                    return <MenuItem key={key} value={item.identifier}>{item.title}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl className="w-100">
                            <TextField
                                id="courseDatePrice"
                                error={courseDatePriceError}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={courseDatePriceError ? this.props.form.errors.courseDatePrice : "Cena szkolenia"}
                                name="courseDatePrice"
                                value={this.state.course.courseDatePrice}
                                onChange={this.onChange.bind(this)}
                                type="number"
                                fullWidth
                            />
                        </FormControl>
                    </div>
                </DialogComponent>
            
                </form>
        );
    }

    handlePlaceChange(e, place) {
    
        this.setState({
            ...this.state,
            course: {
                ...this.state.course,
                placeName: place === 'other' ? '' : place
            },
            place: {
                selected: place
            }
        })
    }

    onDateChange(name, value) {
        let course = {...this.state.course}
        // let formattedDate = moment(value).format('DD.MM.YYYY')
        let formattedDate = moment(value).format('YYYY-MM-DD')
        
        // course[name] = value
        course[name] = formattedDate
        
        this.setState({
            ...this.state,
            course: course            
        }, () => {
           
        })
    }

    onFileChange(name, value) {
        let course = {...this.state.course}
        course[name] = value
        this.setState({
            ...this.state,
            course: course            
        })
    }


    onEditorChange(name, value) {
        let course = {...this.state.course}
        course[name] = value
        this.setState({
            ...this.state,
            course: course            
        })
    }

    onChange(e) {
        let course = {...this.state.course}
        course[e.target.name] = e.target.value
        let courseDatePrice = null
        if ( e.target.name === "courseID" ) {
           
            let courses = this.props.courses.filter((item, key) => {
                return item.identifier === e.target.value
            })
            courseDatePrice = courses[0].coursePrice
            course.courseDatePrice = courseDatePrice
        }
        this.setState({
            ...this.state,
            course: course
        })
        // let course = {...this.state.course}
        // course[e.target.name] = e.target.value
        // this.setState({
        //     ...this.state,
        //     course: course            
        // })
    }

    onSubmit(e) {
    }

    saveForm() {

        this.props.onSubmit(this.state.course)

    }

}
