import React, { useEffect, useState } from 'react'
import { NavLink  } from 'react-router-dom'


const SideNavItemComponent = ({ to = '',   iconClass, title }) => {
	const [active, setActive] = useState(false)	
	useEffect(() => {
		const findTo = window.location.pathname.includes(`/app${to}`)
		setActive(findTo)
	}, [window.location.pathname])
	return (
		<li className={`menu no-arrow ${active  ? 'open' : ''}`}>
			<NavLink to={`/app${to}`} className={active ? 'active' : ''}>
				<i className={`zmdi ${iconClass}`} />
				<span className="nav-text">{title}</span>
			</NavLink>
		</li>
	)
}

export default SideNavItemComponent;