import React from "react"


const InfoRowComponent = ({ children, title, iconName}) => {
	return (
		<div className="media align-items-start flex-nowrap jr-pro-contact-list">
			<div className="mr-3">
				<i className={`zmdi jr-fs-xxl text-orange ${iconName}`} />
			</div>
			<div className="media-body">
				<span className="mb-0 text-grey jr-fs-sm">{title}</span>
				<div className="mb-0">{children}</div>
			</div>
		</div>
	);
};


export default React.memo(InfoRowComponent);