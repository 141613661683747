const INIT_STATE = {
    list: [],
    loading: false,
    tableLoading: false,
    nextPageLoading: false,
    pagination: {},
    error: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'GET_NOTIFICATIONS_APP_PENDING':
            return {
                ...state,
                error: false,
                loading: true,
                tableLoading: state.pagination && !state.pagination.current_page,
                nextPageLoading: state.pagination && (state.pagination.current_page >= 1),
            }

        case 'GET_NOTIFICATIONS_APP_REJECTED':
            return {
                ...state,
                error: 'Nie udało się załadować nowe dane',
                loading: false,
                tableLoading: false,
                nextPageLoading: false,
            }

        case 'GET_NOTIFICATIONS_APP_FULFILLED':
            return {
                ...state,
                list: action.payload.data.data ? action.payload.data.data : [],
                loading: false,
                tableLoading: false,
                nextPageLoading: false,
                pagination: action.payload.data.meta ? action.payload.data.meta.pagination : {}
            }
        case 'ADD_NOTIFICATIONS_APP_PENDING':
            return {
                ...state,
                error: false,
                loading: true
            }

        case 'ADD_NOTIFICATIONS_APP_REJECTED':
            return {
                ...state,
                error: 'Nie udało się dodać notyfikacje. Pamiętaj że nazwa nie może powtarzać się',
                loading: false
            }

        case 'ADD_NOTIFICATIONS_APP_FULFILLED':
            return {
                ...state,
                error: false,
                loading: false,
                list: [action.payload.data.data, ...state.list],
            }
        case 'SEND_PUSH_NOTIFICATIONS_APP_PENDING':
            return {
                ...state,
                error: false,
                loading: true
            }

        case 'SEND_PUSH_NOTIFICATIONS_APP_REJECTED':
            return {
                ...state,
                error: 'Nie udało się z edytować notyfikacje',
                loading: false
            }

        case 'SEND_PUSH_NOTIFICATIONS_APP_FULFILLED':
            return {
                ...state,
                error: false,
                loading: false,
                list: state.list.map((notif) => {
                    if (notif.identifier === action.payload.data.data.identifier) {
                        return {
                            ...notif, 
                            is_send: true,
                        }
                    }
                    else
                        return {
                            ...notif
                        }
                }),
            }
        case 'UPDATE_NOTIFICATIONS_APP_PENDING':
            return {
                ...state,
                error: false,
                loading: true
            }

        case 'UPDATE_NOTIFICATIONS_APP_REJECTED':
            return {
                ...state,
                error: 'Nie udało się z edytować notyfikacje',
                loading: false
            }

        case 'UPDATE_NOTIFICATIONS_APP_FULFILLED':
            return {
                ...state,
                error: false,
                loading: false,
                list: state.list.map((notif) => {
                    if (notif.identifier === action.payload.data.data.identifier) {
                        return {
                            ...action.payload.data.data
                        }
                    }
                    else
                        return {
                            ...notif
                        }
                }),
            }
        case 'DELETE_NOTIFICATIONS_APP_PENDING':
            return {
                ...state,
                error: false,
                loading: true
            }

        case 'DELETE_NOTIFICATIONS_APP_REJECTED':
            return {
                ...state,
                error: 'Nie udało się usunąć notyfikacje',
                loading: false
            }

        case 'DELETE_NOTIFICATIONS_APP_FULFILLED':
            return {
                ...state,
                error: false,
                loading: false,
                list: state.list.filter((notif) => notif.identifier !== action.payload.data.data.identifier),
            }
        default:
            return state;
    }
}
