import React from 'react';
import DialogImgComponent from '../../../components/Dialog/DialogImgComponent';
import { Typography } from '@material-ui/core';

const InspirationDialogRowComponent = ({description, image}) => {
  return (
   <>
    <div className="col-md-12 mt-2">
      Opis
    </div>
    <div className="col-md-12">
      <Typography dangerouslySetInnerHTML={{ __html: description }} />
    </div>
    <div className="col-md-12 mt-2">
      Zdjęcie
    </div>
    <DialogImgComponent src={image} />
   </>
  );
};

export default React.memo(InspirationDialogRowComponent);
