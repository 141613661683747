import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function CircularLoader() {
  return (
    <div className="manage-margin">
      <CircularProgress size={45}/>
    </div>
  );
}

export default CircularLoader;