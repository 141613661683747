const INIT_STATE = {
	data: {},
	loading: false,
	barChartLoading: false,
	error: false
};


export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case 'GET_APP_STATISTIC_PENDING':
			return {
				...state,
				error: false,
				loading: true
			}

		case 'GET_APP_STATISTIC_REJECTED':
			return {
				...state,
				error: 'Nie udało się załadować nowe dane',
				loading: false,
			}

		case 'GET_APP_STATISTIC_FULFILLED':
			const data = action.payload.length
				? action.payload.map((response) => ({
					...response.data,
				}))
				: []

			return {
				...state,
				data: {
					...state.data,
					...Object.assign({}, ...data)
				},
				loading: false,
			}
		case 'GET_APP_STATISTIC_PAGINATION_PENDING':
			return {
				...state,
				error: false,
				// nextPageLoading: state.pagination && (state.pagination.current_page >= 1),
			}

		case 'GET_APP_STATISTIC_PAGINATION_REJECTED':
			return {
				...state,
				error: 'Nie udało się załadować nowe dane',
				loading: false,
				nextPageLoading: false
			}

		case 'GET_APP_STATISTIC_PAGINATION_FULFILLED':
			return {
				...state,
				data: {
					...state.data,
					...action.payload.data
				},
				loading: false,
			}
		case 'GET_APP_STATISTIC_AGENT_POS_PENDING':
			return {
				...state,
				error: false,
				barChartLoading: true,
				// nextPageLoading: state.pagination && (state.pagination.current_page >= 1),
			}

		case 'GET_APP_STATISTIC_AGENT_POS_REJECTED':
			return {
				...state,
				error: 'Nie udało się załadować nowe dane',
				loading: false,
				barChartLoading: false
			}

		case 'GET_APP_STATISTIC_AGENT_POS_FULFILLED':
			return {
				...state,
				data: {
					...state.data,
					agentSalons: action.payload.data.agentSalons
				},
				loading: false,
				barChartLoading: false,
			}
		default:
			return state;
	}
}
