import React from 'react';
import FilesList from './FilesList';
import CardBox from '../../../../../components/CardBox/index';
import AddFile from '../../AddFile'
import { connect } from 'react-redux'
// import * as CoursesActions from '../../../../../actions/Courses'
import * as FilesActions from '../../../../../actions/Files'
import { NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert'
import Auth from '../../../../../util/Auth'

class Files extends React.Component {

    constructor() {
        super()
        this.state = {
            addMode: false,
            editMode: false,
            progress: 0,
            editItem: null,
            deleteWarning: false,
            selectedDeletionSlug: '',
            editSlug: ''
        }
    }

    componentDidMount() {
        this.props.dispatch(FilesActions.getFilesList())
    }

    componentDidUpdate(prevProps) {
        if (prevProps.files.addFilesForm.status !== 'error' && this.props.files.addFilesForm.status === 'error') {
            NotificationManager.warning("Błąd serwera. Spróbuj ponownie lub skontaktuj się z administracją.", "", 4000);
        }
        if (!prevProps.files.addFilesForm.added && this.props.files.addFilesForm.added) {
            NotificationManager.success("Pomyślnie dodano nowy plik!", "", 4000);
            this.toggleAddMode()
        }
        if (!prevProps.files.deletedFile && this.props.files.deletedFile) {
            NotificationManager.success("Usunięto paczkę!", "", 4000);
        }
        if (!prevProps.files.addFilesForm.edited && this.props.files.addFilesForm.edited) {
            NotificationManager.success("Pomyślnie zedytowano paczkę!", "", 4000);
            this.toggleAddMode()
            this.props.dispatch(FilesActions.getFilesList())
        }
    }

    render() {
        return (<div className="animated slideInUpTiny animation-duration-3">
            <Auth roles={['super_admin', 'admin']}>
                <AddFile
                    editMode={this.state.editMode}
                    progress={this.state.progress}
                    levels={this.props.courses.levels}
                    categories={this.props.courses.categories}
                    loading={this.props.files.fileDetails.loading}
                    form={this.props.files.addFilesForm}
                    addCourse={this.addCourse.bind(this)}
                    editCourse={this.editCourse.bind(this)}
                    open={this.state.addMode}
                    editSlug={this.state.editSlug}
                    togglePopup={this.toggleAddMode.bind(this)}
                    editItem={this.props.files.fileDetails.data} />
            </Auth>
            <div className="row mb-md-4">
                <CardBox
                    styleName="col-12"
                    cardStyle="p-0"
                    headerOutside
                    heading='Lista plików'
                    addTooltip='Dodaj plik'
                    addBtn='zmdi-plus'
                    addClick={this.toggleAddMode.bind(this, false)}
                >
                    <FilesList
                        toggleAddMode={this.toggleAddMode.bind(this)}
                        onDelete={this.onDelete.bind(this)}
                        onPageChange={this.getUsersPage.bind(this)}
                        meta={this.props.files.filesList.meta}
                        data={this.props.files.filesList.list}
                        loading={this.props.files.loading}
                        nextPageLoading={this.props.files.nextPageLoading}
                    />
                </CardBox>
            </div>
            <Auth roles={['super_admin', 'admin']}>
                <SweetAlert show={this.state.deleteWarning}
                    warning
                    showCancel
                    confirmBtnText="Tak, usuń"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    cancelBtnText="Anuluj"
                    title="Jesteś pewien?"
                    onConfirm={this.onConfirmDelete.bind(this)}
                    onCancel={this.onCancelDelete.bind(this)} >
                    Plik zostanie bezpowrotnie usunięty!
                </SweetAlert>
            </Auth>
        </div>);
    }

    getUsersPage(url) {

        this.props.dispatch(FilesActions.getFilesList({
            per_page: 15
        }, url))
    }

    toggleAddMode(editMode = false, id) {
        this.props.dispatch(FilesActions.clearFormData())
        if (editMode) {
            this.props.dispatch(FilesActions.fetchFile(id))
        }
        this.setState({
            ...this.state,
            addMode: !this.state.addMode,
            editMode: editMode,
            editId: id
        })
    }

    onConfirmDelete() {

        this.props.dispatch(FilesActions.deleteFile(this.state.selectedDeletionSlug))
        this.setState({
            ...this.state,
            deleteWarning: false
        })
    }

    onCancelDelete() {
        this.setState({
            ...this.state,
            deleteWarning: false,
            selectedDeletionSlug: ''
        })
    }

    onDelete(slug) {
        this.setState({
            ...this.state,
            deleteWarning: !this.state.deleteWarning,
            selectedDeletionSlug: slug
        })
    }

    clearFormData() {
        this.props.dispatch(FilesActions.clearFormData())
    }

    addCourse(data) {
        // this.props.dispatch(CoursesActions.addCourse(data))
        this.props.dispatch(FilesActions.addFile(data, progressEvent => {
            var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
            if (percent >= 100) {
                this.setState({
                    ...this.state,
                    progress: 0
                })
            } else {
                this.setState({
                    ...this.state,
                    progress: percent
                })
            }
        }))

    }

    editCourse(slug, data) {
        this.props.dispatch(FilesActions.editFile(slug, data))
    }
}


const mapStateToProps = state => ({
    courses: state.courses,
    files: state.files
})


export default connect(mapStateToProps)(Files)
