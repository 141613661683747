import axios from 'axios'
import settings from '../config/settings.js'

export const getAgentsSignups = (params) => {

    return {
        type: 'GET_AGENTS_SIGNUPS',
        payload: axios.request({
            url: '/statistics/agentsSignups',
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: params
        })
    }
}

export const getCourseDatesStats = (params) => {
    return {
        type: 'GET_COURSE_DATES_STATS',
        payload: axios.request({
            url: '/statistics/courseDates',
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: params
        })
    }
}

export const getSignupsStats = (params) => {
    return {
        type: 'GET_SIGNUPS_STATS',
        payload: axios.request({
            url: '/statistics/signups',
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: params
        })
    }
}

export const getSignupsDone = (params) => {
    return {
        type: 'GET_SIGNUPS_DONE',
        payload: axios.request({
            url: '/statistics/signupsDone',
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: params
        })
    }
}

export const getPosLogged = (params) => {
    return {
        type: 'GET_POS_LOGGED',
        payload: axios.request({
            url: '/statistics/posLogged',
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: params
        })
    }
}
