import React from 'react';
import {  Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../components/Header/index';
import Sidebar from '../containers/SideNav/index';
// import Footer from 'components/Footer';
import {
       ABOVE_THE_HEADER,
       BELOW_THE_HEADER,
       COLLAPSED_DRAWER,
       FIXED_DRAWER,
       HORIZONTAL_NAVIGATION,
} from '../constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from '../components/TopNav';
import User from './routes/user/index';
import POS from './routes/POS/index';
import Dashboard from './routes/dashboard/index';
import Courses from './routes/courses/index';
import CourseSignups from './routes/courseSignups/index';
import CourseDates from './routes/course-dates/index';
import News from './routes/news/index';
import Files from './routes/files/index';
import Statistics from './routes/statistics/index';
import Auth from '../util/Auth'
// ORDER_STATISTIC_ROUTE
import { NOTIFICATIONS_ROUTE, ORDERS_ROUTE, SLIDERS_ROUTE, ORDERS_HISTORY_ROUTE, BLOG_ROUTE, PROMO_CODES_ROUTE, APP_STATISTIC_ROUTE, INSPIRATIONS_ROUTE } from '../constants/routes';
import NotificationsPage from '../pages/notificationsPage/NotificationsPage';
import SlidersPage from '../pages/slidersPage/SlidersPage';
import OrdersPage from '../pages/ordersPage/OrdersPage';
import OrdersHistoryPage from '../pages/ordersHistoryPage/OrdersHistoryPage';
import PageViewCheckerComponent from './components/PageViewCheckerComponent';
import BlogPage from '../pages/blogPage/BlogPage';
import PromoCodesPage from '../pages/promoCodesPage/PromoCodesPage';
import AppStatisticPage from '../pages/appStatisticPage/AppStatisticPage';
import InspirationsPage from '../pages/inspirationsPage/InspirationsPage';
// import OrderStatisticPage from '../pages/orderStatisticPage/OrderStatisticPage';
// const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
//   <Route
//     {...rest}
//     render={props =>
//       authUser
//         ? <Component {...props} />
//         : <Redirect
//           to={{
//             pathname: LOGIN_ROUTE,
//             state: {from: props.location}
//           }}
//         />}
//   />

class App extends React.Component {


       render() {

              const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
              const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';
              const url = match && match.url ?  match.url : '';
              if (isIOS && isMobile) {
                     document.body.classList.add('ios-mobile-view-height')
              }
              else if (document.body.classList.contains('ios-mobile-view-height')) {
                     document.body.classList.remove('ios-mobile-view-height')
              }

              return (
                     <div className={`app-container ${drawerStyle}`}>
                            {/* <Tour/> */}
                            <Sidebar />
                            <div className="app-main-container">
                                   <div
                                          className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                                          {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                                                 <TopNav styleName="app-top-header" />}
                                          <PageViewCheckerComponent />
                                          <Header />
                                          {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                                                 <TopNav />}
                                   </div>
                                   <main className="app-main-content-wrapper">
                                          <div className="app-main-content">
                                                        <Switch>
                                                               <Route path={`${url}/dashboard`}
                                                                      component={Dashboard} />
                                                               <Route path={`${url}/users`}
                                                                      component={User} />
                                                               <Route path={`${url}/saloons`}
                                                                      component={POS} />
                                                               <Route path={`${url}/courses`}
                                                                      component={Courses} />
                                                               <Route path={`${url}/course-dates`}
                                                                      component={CourseDates} />
                                                               <Route path={`${url}/keune-news`}
                                                                      component={News} />
                                                               <Route path={`${url}/course-signups`}
                                                                      component={CourseSignups} />
                                                               <Route path={`${url}/files`}
                                                                      component={Files} />
                                                               <Route path={`${url}${NOTIFICATIONS_ROUTE}`}
                                                                      component={NotificationsPage} />
                                                               <Route path={`${url}${SLIDERS_ROUTE}`}
                                                                      component={SlidersPage} />
                                                               <Route path={`${url}${ORDERS_ROUTE}`}
                                                                      component={OrdersPage} />
                                                               <Route path={`${url}${ORDERS_HISTORY_ROUTE}`}
                                                               component={OrdersHistoryPage} />
                                                               <Route path={`${url}${APP_STATISTIC_ROUTE}`}
                                                               component={AppStatisticPage} />
                                                               <Route path={`${url}${PROMO_CODES_ROUTE}`}
                                                               component={PromoCodesPage} />
                                                               <Route path={`${url}${BLOG_ROUTE}`}
                                                                      component={BlogPage} />
                                                               <Route path={`${url}${INSPIRATIONS_ROUTE}`}
                                                                      component={InspirationsPage} />

                                                               {/* <Route path={`${url}${ORDER_STATISTIC_ROUTE}`}
                         component={OrderStatisticPage}/> */}

                                                               <Auth roles={['super_admin', 'admin']}>


                                                                      <Route path={`${url}/statistics`}
                                                                             component={Statistics} />

                                                               </Auth>

                                                               <Route path={`${url}/sample-page`}
                                                                      component={asyncComponent(() => import('./routes/SamplePage'))} />
                                                               <Route component={asyncComponent(() => import('../components/Error404'))} />
                                                        </Switch>
                                          </div>
                                          {/* <Footer/> */}
                                   </main>
                            </div>
                     </div>
              );
       }
}


const mapStateToProps = ({ settings, user }) => {
       const { drawerType, navigationStyle, horizontalNavPosition } = settings;
       return { drawerType, navigationStyle, horizontalNavPosition, user }
};
export default withRouter(connect(mapStateToProps)(App));
