import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
// import asyncComponent from '../../../util/asyncComponent';
// import Button from '@material-ui/core/Button';
import CardBox from '../../../components/CardBox';
import { connect } from 'react-redux'
import * as StatisticsActions from '../../../actions/Statistics'
// import {NotificationContainer, NotificationManager} from 'react-notifications';
// import ReportBox from 'components/ReportBox/index';
// import Tooltip from '@material-ui/core/Tooltip';
// import { Link } from 'react-router-dom'
// import Auth from '../../../util/Auth'
// import Condition from '../../../util/Condition'
// import SignupsChart from './SignupsChart'
// import SignupsPieChart from './SignupsPieChart'
// import DatePicker from './DatePicker'
import moment from 'moment'
import 'moment/locale/pl'
import DataCard from './DataCard'
import VerticalComposedChart from './VerticalComposedChart'
import DayPicker, { DateUtils } from 'react-day-picker'
import { Badge } from 'reactstrap';
import 'react-day-picker/lib/style.css';
import StatisticTableComponent from '../../../pages/appStatisticPage/components/StatisticTableComponent';

moment.locale('pl')

class Dashboard extends React.Component {

	constructor() {
		super()
		this.state = {
			date_from: new moment().subtract(30, 'days').format('DD-MM-YYYY'),
			date_to: new moment().format('DD-MM-YYYY'),
			isLoading: false,
			range: {
				from: new Date(new moment().subtract(30, 'days')),
				to: new Date()
			}
		}
	}

	componentDidMount() {
		this.props.dispatch(StatisticsActions.getCourseDatesStats())
		this.props.dispatch(StatisticsActions.getSignupsStats())
		this.props.dispatch(StatisticsActions.getSignupsDone())
		this.props.dispatch(StatisticsActions.getAgentsSignups(this.createQueryParams()))
		this.props.dispatch(StatisticsActions.getPosLogged(this.createQueryParams()))

	}

	onDateChange(name, value) {
		let newState = { ...this.state }
		newState[name] = new moment(value).format('DD-MM-YYYY')
		this.setState({
			...newState
		}, () => {
			this.props.dispatch(StatisticsActions.getAgentsSignups(this.createQueryParams()))
			this.props.dispatch(StatisticsActions.getCourseDatesStats(this.createQueryParams()))
			this.props.dispatch(StatisticsActions.getSignupsStats(this.createQueryParams()))
			this.props.dispatch(StatisticsActions.getSignupsDone(this.createQueryParams()))
			this.props.dispatch(StatisticsActions.getPosLogged(this.createQueryParams()))
		})
	}

	createQueryParams() {
		return {
			date_from: new moment(this.state.range.from).format('DD-MM-YYYY'),
			date_to: new moment(this.state.range.to).format('DD-MM-YYYY')
		}
	}

	handleDayClick(day) {
		const range = DateUtils.addDayToRange(day, this.state.range);
		this.setState({
			...this.state,
			range: range
		}, () => {
			this.props.dispatch(StatisticsActions.getAgentsSignups(this.createQueryParams()))
			this.props.dispatch(StatisticsActions.getCourseDatesStats(this.createQueryParams()))
			this.props.dispatch(StatisticsActions.getSignupsStats(this.createQueryParams()))
			this.props.dispatch(StatisticsActions.getSignupsDone(this.createQueryParams()))
			this.props.dispatch(StatisticsActions.getPosLogged(this.createQueryParams()))
		});
	}
	handleChange(e, page) {
		const params = this.createQueryParams();
		this.props.dispatch(StatisticsActions.getPosLogged({ ...params, page: page + 1}))
	}
	render() {


		// let isLoading = this.props.statistics.courseDates.loading
		// let match = this.props.match
		// let { statistics } = this.props

		let signupsSum = 0

		let preparedSignupsData = this.props.statistics.agentsSignups.data.map((item, key) => {
			signupsSum += item.countSignups
			return {
				zapisy: item.countSignups,
					// eslint-disable-next-line
				['Ukonczone Szkolenia']: item.countSignups,
				name: item.last_name
			}
		}).sort((a, b) => {
			if (b.zapisy > a.zapisy) return 1
			if (a.zapisy > b.zapisy) return -1
			return 0
		})

		let from = this.state.range.from
		let to = this.state.range.to
		const modifiers = { start: from, end: to }

		let fromTxt = new moment(this.state.range.from).format('DD MMM.')
		let toTxt = new moment(this.state.range.to).format('DD MMM. YYYY')

		return <div className="app-wrapper-mobile-response">
			{/* <CardBox styleName="col-12" heading="Zapisy na szkolenia poszczególnych handlowców">
                        <SignupsChart data={statistics.agentsSignups.list} />
					</CardBox> */}
			<CardBox styleName="col-12" heading="Filtry">
				<div className="row">
					<div className="col-md-12">
						<DayPicker
							selectedDays={[from, { from, to }]}
							className="Selectable"
							numberOfMonths={3}
							modifiers={modifiers}
							initialMonth={new Date(new moment().subtract(1, 'month'))}
							firstDayOfWeek={1}
							onDayClick={this.handleDayClick.bind(this)} />
					</div>
					{/* <div classname="col-md-4 mr-30">
								Od:
								<DatePicker initialDate={new moment().subtract(30, 'days')} name="date_from" onChange={this.onDateChange.bind(this)}/>
							</div>
							<div classname="col-md-4">
								Do:
								<DatePicker initialDate={new moment()} name="date_to" onChange={this.onDateChange.bind(this)}/>
							</div> */}
				</div>
			</CardBox>
			<CardBox styleName="col-12" heading="Statystyki ogólne">
				<div className="row p-0">
					<div className="col-md-4 p-1">
						<DataCard title="ILOŚĆ TERMINÓW SZKOLEŃ"
							desc={"Statystyki dla " + fromTxt + " - " + toTxt}
							color="indigo"
							data={this.props.statistics.courseDates.data} />
					</div>
					<div className="col-md-4 p-1">
						<DataCard title="ILOŚĆ ZAPISÓW NA SZKOLENIA"
							desc={"Statystyki dla " + fromTxt + " - " + toTxt}
							color="pink"
							data={this.props.statistics.signups.data} />
					</div>
					<div className="col-md-4 p-1">
						<DataCard title="ILOŚĆ OSÓB KTÓRE UKOŃCZYŁY SZKOLENIE"
							desc={"Statystyki dla " + fromTxt + " - " + toTxt}
							color="info"
							data={this.props.statistics.signupsDone.data} />
					</div>
				</div>
			</CardBox>

			<CardBox styleName="col-12" heading="Ilość osób które ukończyły szkolenie z podziałem na handlowców">
				<div>

					{/* <h3>
								Zapisów łącznie: {signupsSum}
							</h3> */}
					<h4>
						{"Statystyki dla " + fromTxt + " - " + toTxt}
					</h4>
					<Badge pill color="primary">
						Ukończone szkolenia łącznie: {signupsSum}
					</Badge>
					{<VerticalComposedChart data={preparedSignupsData} />}
					{/* <SignupsPieChart data={preparedSignupsData}/> */}
				</div>
			</CardBox>
			<StatisticTableComponent
				heading='Liczba logowań właścicieli salonów'
				titles={['Nazwa salonu', 'Liczba logowań']}
				range={{ from, to}}
				loading={this.props.statistics.posLogged ? this.props.statistics.posLogged.loading : null}
				data={this.props.statistics.posLogged}
				onChange={this.handleChange.bind(this)}
				meta={this.props.statistics.posLogged ? this.props.statistics.posLogged.meta : {}}
				keyArr='posLogged'
			/>
		</div>
	}

}
const mapStateToProps = (state) => {
	return {
		user: state.user,
		statistics: state.statistics
	}
}

export default connect(mapStateToProps)(Dashboard)
