const INIT_STATE = {
	list: [],
	loading: false,
	tableLoading: false,
	nextPageLoading: false,
	pagination: {},
	error: false
};


export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case 'GET_BLOG_POSTS_PENDING':
			return {
				...state,
				error: false,
				loading: state.pagination && !state.pagination.current_page,
				nextPageLoading: state.pagination && (state.pagination.current_page >= 1),
			}

		case 'GET_BLOG_POSTS_REJECTED':
			return {
				...state,
				error: 'Nie udało się załadować nowe dane',
				loading: false,
				nextPageLoading: false
			}

		case 'GET_BLOG_POSTS_FULFILLED':
			return {
				...state,
				list: action.payload.data.data ? action.payload.data.data : [],
				loading: false,
				nextPageLoading: false,
				pagination: action.payload.data.meta ? action.payload.data.meta.pagination : {}
			}
		case 'ADD_BLOG_POST_PENDING':
			return {
				...state,
				error: false,
				loading: true
			}

		case 'ADD_BLOG_POST_REJECTED':
			return {
				...state,
				error: 'Nie udało się dodać post. Pamiętaj że nazwa nie może powtarzać się',
				loading: false
			}

		case 'ADD_BLOG_POST_FULFILLED':
			return {
				...state,
				error: false,
				loading: false,
				list: [action.payload.data.data, ...state.list],
			}
		case 'UPDATE_BLOG_POST_PENDING':
			return {
				...state,
				error: false,
				loading: true
			}

		case 'UPDATE_BLOG_POST_REJECTED':
			return {
				...state,
				error: 'Nie udało się z edytować post',
				loading: false
			}

		case 'UPDATE_BLOG_POST_FULFILLED':
			return {
				...state,
				error: false,
				loading: false,
				list: state.list.map((post) => {
					if (post.identifier === action.payload.data.data.identifier) {
						return {
							...action.payload.data.data
						}
					}
					else
						return {
							...post
						}
				}),
			}
		case 'DELETE_BLOG_POST_PENDING':
			return {
				...state,
				error: false,
				loading: true
			}

		case 'DELETE_BLOG_POST_REJECTED':
			return {
				...state,
				error: 'Nie udało się usunąć post',
				loading: false
			}

		case 'DELETE_BLOG_POST_FULFILLED':
			return {
				...state,
				error: false,
				loading: false,
				list: state.list.filter((post) => post.identifier !== action.payload.data.data.identifier),
			}
		default:
			return state;
	}
}
