import React from 'react'
import { makeStyles } from '@material-ui/styles';

import DialogLoaderComponent from './DialogLoaderComponent';

const useStyles = makeStyles((theme) => ({
	cont: {
		width: '100%',
		height: '50vh',
		position: 'relative',
	},
}))

const TableLoaderComponent = ({ loading, children }) => {
	const classes = useStyles();

	return (
		<>
			{loading
				?
				<section >
					<section className={classes.cont}>
						<DialogLoaderComponent />
					</section>
				</section>
				: children

			}
		</>
	)
}

export default React.memo(TableLoaderComponent);