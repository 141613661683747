import React from 'react';
import Table from '@material-ui/core/Table'; import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableHead from '@material-ui/core/TableHead'; import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip';
import { Badge } from 'reactstrap';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Auth from '../../../../../util/Auth'
import Condition from '../../../../../util/Condition'
import TextField from '@material-ui/core/TextField';
import TableLoaderComponent from '../../../../../components/Loaders/TableLoaderComponent';
// import * as UserActions from '../../../../../actions/User'
// import * as CoursesActions from '../../../../../actions/Courses'


export default class SignupsList extends React.Component {

    constructor(props) {
        super()
        this.state = {
            addPoints: {
                enabled: false,
                signupPoints: null,
                selectedUser: null
            }
        }
    }

    componentDidUpdate(prevProps) {

        if (!prevProps.addedPoints && this.props.addedPoints) {
            this.disableAddPointsMode()
        }

    }

    render() {
        return (
            <div className="table-responsive-material">
                <TableLoaderComponent loading={this.props.loading}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Imię i nazwisko</TableCell>
                                <TableCell>Przedstawiciel</TableCell>
                                <TableCell>Salon</TableCell>
                                <Auth roles={['super_admin', 'admin', 'ph']}>
                                    <TableCell>Bilans punktów</TableCell>
                                </Auth>
                                {/* <TableCell>E-mail</TableCell> */}
                                <Condition condition={!this.props.reserveList}>
                                    <Auth roles={['super_admin', 'admin', 'training_staff', 'ph']}>
                                        <TableCell>Obecność</TableCell>
                                    </Auth>
                                </Condition>
                                <Auth roles={['super_admin', 'admin', 'ph']}>
                                    <TableCell>Status zapisu</TableCell>
                                    <TableCell>Akcje</TableCell>
                                </Auth>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.list.map((n, keyList) => {
                                let pendingStatus = {
                                    status: 'pending',
                                    name: 'Do potwierdzenia',
                                    color: 'warning'
                                }
                                let acceptedStatus = {
                                    status: 'accepted',
                                    name: 'Zaakceptowany',
                                    color: 'success'
                                }
                                let unjustifiedStatus = {
                                    status: 'unjustified',
                                    name: 'Nieusprawiedliwiony',
                                    color: 'danger'
                                }
                                let cancelledStatus = {
                                    status: 'cancelled',
                                    name: 'Anulowany',
                                    color: 'danger'
                                }
                                let availableStatuses = []
                                // if ( this.props.authUserHasRole(['super_admin', 'admin']) ) {
                                availableStatuses.push(acceptedStatus)
                                // }
                                let participantID = ''
                                let signupPoints = n.signupPoints
                                let agentFirstName = ''
                                let agentLastName = ''
                                let agentId = ''
                                let loggedUserId = this.props.loggedUser.identifier
                                let isAgentsRow = false
                                if (n.pos_agent) {
                                    agentFirstName = n.pos_agent.data.firstName
                                    agentLastName = n.pos_agent.data.lastName
                                    agentId = n.pos_agent.data.identifier
                                }
                                if (this.props.authUserHasRole(['ph'])) {
                                    if (n.post_agent) {
                                        if (n.pos_agent.data.identifier === this.props.loggedUser.identifier) {
                                            isAgentsRow = true

                                        }
                                    }
                                } else {
                                    isAgentsRow = true
                                }
                                if (n.participant) {
                                    participantID = n.participant.data.identifier
                                    // signupPoints = n.participant.data.signupPoints
                                    // if ( n.participant.data.pos ) {
                                    //     if ( n.participant.data.pos.data.agent ) {
                                    //         agentFirstName = n.participant.data.pos.data.agent.data.firstName
                                    //         agentLastName = n.participant.data.pos.data.agent.data.lastName
                                    //     }
                                    // }
                                }
                                availableStatuses.push(unjustifiedStatus)
                                availableStatuses.push(cancelledStatus)
                                availableStatuses.push(pendingStatus)
                                return (
                                    <TableRow key={keyList} >
                                        <TableCell className={n.participant ? null : 'row-inactive'}>
                                            <Auth roles={['super_admin', 'admin']}>
                                                <Link to={"/app/users/" + participantID} className="keune-link">
                                                    {n.firstName + ' ' + n.lastName} {n.participant ? null : '(nieaktywny użytkownik)'}
                                                </Link>
                                            </Auth>
                                            <Auth roles={['ph']}>
                                                {loggedUserId === agentId
                                                    ? <Link to={"/app/users/" + participantID} className="keune-link">
                                                        {n.firstName + ' ' + n.lastName} {n.participant ? null : '(nieaktywny użytkownik)'}
                                                    </Link>
                                                    : `${n.firstName}  ${n.lastName} ${n.participant ? '' : '(nieaktywny użytkownik)'}`
                                                }
                                            </Auth>
                                            <Auth roles={['training_staff']}>
                                                {n.firstName + ' ' + n.lastName} {n.participant ? null : '(nieaktywny użytkownik)'}
                                            </Auth>
                                        </TableCell>
                                        <TableCell className={n.participant ? null : 'row-inactive'}>
                                            {agentFirstName ? agentFirstName + ' ' + agentLastName : 'Brak'}
                                        </TableCell>
                                        <TableCell className={n.participant ? null : 'row-inactive'}>
                                            {n.participant && n.participant.data.pos ? n.participant.data.pos.data.ownerName : ''}
                                        </TableCell>
                                        <Auth roles={['super_admin', 'admin']}>
                                            <TableCell>
                                                <div className="row">
                                                    <div className="mt-1 mr-2" style={{ width: '60px' }}>
                                                        {signupPoints}
                                                    </div>
                                                    {/* loggedUserId */}
                                                    {this.renderAddPoints(n.identifier)}
                                                </div>
                                            </TableCell>
                                        </Auth>
                                        <Auth roles={['ph']}>
                                            <TableCell className={n.participant ? null : 'row-inactive'}>
                                                <div className="row">
                                                    <div className="mt-1 mr-2" style={{ width: '60px' }}>
                                                        {signupPoints}
                                                    </div>
                                                    {loggedUserId === n.identifier
                                                        ? this.renderAddPoints(n.identifier)
                                                        : ''
                                                    }
                                                </div>
                                            </TableCell>
                                        </Auth>

                                        {/* <TableCell>{n.email}</TableCell> */}
                                        <Condition condition={!this.props.reserveList}>
                                            <Auth roles={['super_admin', 'admin', 'training_staff', 'ph']}>
                                                <TableCell className={n.participant ? null : 'row-inactive'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox color="primary"
                                                                checked={n.present}
                                                                onChange={this.changePresentStatus.bind(this, !n.present, n.identifier)}
                                                                value={n.present.toString()} />
                                                        } />
                                                </TableCell>
                                            </Auth>
                                        </Condition>
                                        <Auth roles={['super_admin', 'admin', 'ph']}>
                                            <TableCell>
                                                <FormControl className={`${!isAgentsRow ? 'gray-out' : ''} w-100`}>
                                                    <Select
                                                        name="roleID"
                                                        onChange={this.changeSignupStatus.bind(this, n)}
                                                        value={n.statusName}
                                                        disabled={!isAgentsRow}
                                                        input={<Input id="roleID" />} >
                                                        {availableStatuses.map((item, key) => {
                                                            return <MenuItem key={key} value={item.status} disabled={!this.props.authUserHasRole(['super_admin', 'admin']) && key === 0 ? true : false}>
                                                                <Badge pill color={item.color}>{item.name}</Badge>
                                                            </MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <Auth roles={['ph']}>
                                                    {loggedUserId === n.identifier
                                                        ? <Tooltip id="tooltip-icon" title="Usuń zapis" placement="top" onClick={this.onDelete.bind(this, n.identifier)}>
                                                            <Fab className="jr-fab-btn bg-danger text-white jr-btn-fab-xs">
                                                                <i className="zmdi zmdi-delete" />
                                                            </Fab>
                                                        </Tooltip>
                                                        : ''
                                                    }
                                                </Auth>
                                                <Auth roles={['super_admin', 'admin']}>
                                                    <Tooltip id="tooltip-icon" title="Usuń zapis" placement="top" onClick={this.onDelete.bind(this, n.identifier)}>
                                                        <Fab className="jr-fab-btn bg-danger text-white jr-btn-fab-xs">
                                                            <i className="zmdi zmdi-delete" />
                                                        </Fab>
                                                    </Tooltip>
                                                </Auth>
                                            </TableCell>
                                        </Auth>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableLoaderComponent>
            </div>
        );
    }

    onDelete(id) {

        // this.props.onDelete(id)
        this.props.showDeleteWarningSignup(id)
    }

    changePresentStatus(value, signupID, e) {

        this.props.changePresentStatus(value, signupID)
    }

    changeSignupStatus(item, e) {

        this.props.changeSignupStatus(item.courseDate.data.identifier, item.identifier, {
            statusName: e.target.value
        })
    }

    renderAcceptBtn(n) {
        return <Tooltip id="tooltip-icon" title="Zaakceptuj zapis" placement="top">
            <Fab onClick={this.changeSignupStatus.bind(this, n, 'accepted')} className="jr-fab-btn bg-success text-white jr-btn-fab-sm mb-3">
                <i className="zmdi zmdi-badge-check" />
            </Fab>
        </Tooltip>
    }

    renderCancelBtn(n) {
        return <Tooltip id="tooltip-icon" title="Anuluj zapis" placement="top">
            <Fab onClick={this.changeSignupStatus.bind(this, n, 'cancelled')} className="jr-fab-btn bg-warning text-white jr-btn-fab-sm mb-3">
                <i className="zmdi zmdi-stop" />
            </Fab>
        </Tooltip>
    }

    renderAddPoints(id) {

        return <div className="">
            <Tooltip onClick={this.toggleAddPointsMode.bind(this, id)} id="tooltip-icon" title="Dodaj punkty" placement="left">
                <Fab color="primary" className="mr-2 bg-orange text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                    <i className="zmdi zmdi-money zmdi-hc-fw zmdi-hc-md" />
                </Fab>
            </Tooltip>
            {this.state.addPoints.enabled && this.state.addPoints.selectedUser === id ?
                <div className="row">
                    <div className="col-6">
                        <TextField
                            error={false}
                            autoFocus
                            placeholder="0"
                            onChange={this.onPointsChange.bind(this)}
                            margin="dense"
                            id="points"
                            type="number"
                            value={this.state.addPoints.signupPoints}
                            name="signupPoints"
                            fullWidth />
                    </div>
                    <div className="mt-1">
                        <Tooltip onClick={this.addPoints.bind(this, id)} id="tooltip-icon" title="Zapisz" placement="top">
                            <Fab color="primary" className="bg-green text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                                <i className="zmdi zmdi-save zmdi-hc-fw zmdi-hc-lg" />
                            </Fab>
                        </Tooltip>
                    </div>
                </div>
                : null}
        </div>

    }

    addPoints(signupId) {
        this.props.addPoints(signupId, this.state.addPoints.signupPoints)
    }

    onPointsChange(e) {

        this.setState({
            ...this.state,
            addPoints: {
                ...this.state.addPoints,
                signupPoints: e.target.value
            }
        })
    }

    toggleAddPointsMode(id = null) {
        this.setState({
            ...this.state,
            addPoints: {
                ...this.state.addPoints,
                enabled: !this.state.addPoints.enabled,
                points: null,
                selectedUser: id
            }
        })
    }

    disableAddPointsMode() {
        this.setState({
            ...this.state,
            addPoints: {
                ...this.state.addPoints,
                enabled: false,
                points: null,
                selectedUser: null
            }
        })
    }

}
