import {
    GET_USER_LIST,
    GET_USER_DETAILS,
    // SEARCH_USERS,
    AUTH_USER,
} from '../constants/ActionTypes';
import axios from 'axios'
import settings from '../config/settings.js'

// export const getUserList = ( filters, url = settings.api_endpoint + "/users") => {
//     let params = {}
//     if ( filters ) {
//         params = filters
//     }
//     return {
//         type: GET_USER_LIST,
//         payload: axios.get(url, {
//             params
//         })
//     }
// }

export const logOut = () => {
    return {
        type: 'LOG_OUT'
    }
}

export const getTeachers = ( params, url = settings.api_endpoint + "/users") => {
    return {
        type: 'GET_TEACHERS',
        payload: axios.get(url, {
            params: params
        })
    }
}

export const getAvailableUsersForCourseDate = (params, courseDateID) => {
    return {
        type: "GET_AVAILABLE_USERS_FOR_COURSE_DATE",
        payload: axios.request({
            url: "/courseDate/"+courseDateID+"/usersAvailable",
            method: "get",
            baseURL: settings.api_endpoint,
            params: params
        })
    }
}

export const getUserList = ( params, url = settings.api_endpoint + "/users") => {
    return {
        type: GET_USER_LIST,
        payload: axios.get(url, {
            params: params
        })
    }
}

export const getFreeWorkers = ( params, url = settings.api_endpoint + "/worker") => {
    return {
        type: 'GET_FREE_WORKERS',
        payload: axios.get(url, {
            params: {
                'filter[noPos]': true
            }
        })
    }
}

export const getFreeAgents = ( params, url = settings.api_endpoint + "/agents") => {
    return {
        type: 'GET_FREE_AGENTS',
        payload: axios.request({
            url: '/agents',
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: {
                per_page: 0
            }
        })
    }
}

export function getUserDetails(params, id) {
    return {
        type: GET_USER_DETAILS,
        payload: axios.request({
            url: '/users/'+id,
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: params
        })
    }
}

export function searchUsers(term) {
    return {
        type: 'SEARCH_USERS',
        payload: term
    }
}

export const authUser = (user) => {
    // let authHeader = user ? {
    //     "username": user.email,
    //     "password": user.password
    // } : null
   
    return {
        type: AUTH_USER,
        payload: axios.request({
            url: "/auth/login",
            method: "post",
            baseURL: settings.api_endpoint,
            data: {
                "username": user.email,
                "password": user.password,
                // "grant_type": "password",
                // "client_id": settings.client_id,
                // "client_secret": settings.client_secret
            }
        })
    }
}

// export const refreshToken = (token) => {
//     return {
//         type: 'REFRESH_TOKEN',
//         payload: axios.request({
//             url: "/oauth/token/refresh",
//             method: "post",
//             baseURL: settings.api_endpoint,
//             data: {
//                 refresh_token: token,
//             }
//         })
//     }
// }

export const refreshToken = (token) => {
    return {
        type: 'REFRESH_TOKEN',
        payload: axios.request({
            url: "/auth/login",
            method: "post",
            baseURL: settings.api_endpoint,
            data: {
                // grant_type: 'refresh_token',
                refresh_token: token,
                // client_id: settings.client_id,
                // client_secret: settings.client_secret
            }
        })
    }
}

export const getAuthUser = () => {
    return {
        type: 'GET_AUTH_USER',
        payload: axios.request({
            url: '/user',
            method: 'GET',
            baseURL: settings.api_endpoint
        })
    }
}

export const editUser = (id, data) => {
    return {
        type: 'EDIT_USER',
        payload: axios.request({
            url: '/users/' + id,
            method: 'PUT',
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}

export const addUser = (data) => {
    
    return {
        type: "ADD_USER",
        payload: axios.request({
            url: "/users",
            method: "post",
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}

export const deleteUser = (slug) => {
    return {
        type: "DELETE_USER",
        payload: axios.request({
            url: "/users/" + slug,
            method: "delete",
            baseURL: settings.api_endpoint
        })
    }
}

export const getAvailableSaloons = () => {
    return {
        type: "GET_AVAILABLE_SALOONS",
        payload: axios.request({
            url: "/posNoAgent",
            method: "get",
            baseURL: settings.api_endpoint
        })
    }
}

export const getAgentSaloons = (id, params) => {
    return {
        type: "GET_AGENT_SALOONS",
        payload: axios.request({
            url: "/agents/" + id + "/pos",
            method: "get",
            baseURL: settings.api_endpoint,
            params: params
        })
    }
}

export const addWorkerToSaloon = (posId, userId) => {
    return {
        type: "ADD_WORKER_TO_SALOON",
        payload: axios.request({
            url: "/pos/" + posId + "/workers",
            method: "post",
            baseURL: settings.api_endpoint,
            data: {
                userID: userId
            }
        })
    }
}

export const addSaloonToWorker = (posId, userId) => {
    return {
        type: "ADD_SALOON_TO_WORKER",
        payload: axios.request({
            url: "/pos/" + posId + "/workers",
            method: "post",
            baseURL: settings.api_endpoint,
            data: {
                userID: userId
            }
        })
    }
}

export const addAgentSaloon = (posId, agentId) => {
    return {
        type: "ADD_AGENT_SALOON",
        payload: axios.request({
            url: "/pos/" + posId + "/agent/" + agentId,
            method: "put",
            baseURL: settings.api_endpoint
        })
    }
}

export const deleteAgentSaloon = (posId, agentId) => {
    return {
        type: "ADD_AGENT_SALOON",
        payload: axios.request({
            url: "/pos/" + posId + "/agent/" + agentId,
            method: "delete",
            baseURL: settings.api_endpoint
        })
    }
}


export const getUserCourses = (userId) => {
    return {
        type: "GET_USER_COURSES",
        payload: axios.request({
            url: "/usersCourses/" + userId,
            method: "GET",
            baseURL: settings.api_endpoint,
            params: {
                per_page: 0
            }
        })
    }
}

export const changeSignupCourseDate = (signupID, courseDateID) => {
    return {
        type: "CHANGE_SIGNUP_COURSE_DATE",
        payload: axios.request({
            url: "/courseSignup/" + signupID + "/courseDate/" + courseDateID,
            method: "PUT",
            baseURL: settings.api_endpoint
        })
    }
}

export const resend = (data) => {
    return {
        type: "RESEND",
        payload: axios.request({
            url: "/user/resend",
            method: "post",
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}

export const changePassword = (data, token) => {
    return {
        type: "CHANGE_PASSWORD",
        payload: axios.request({
            url: "/user/change-password/" + token,
            method: "post",
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}


export const addUserFiles = (id, data) => {
    let formData = new FormData()
    
    // let filesArray = []
    data.forEach((item, key) => {
        // filesArray.push(item)
        formData.append('files[]', item)
    })

    formData.append('files', formData)

    return {
        type: 'ADD_USER_FILES',
        payload: axios.request({
            url: '/user/' + id + '/files',
            method: 'POST',
            baseURL: settings.api_endpoint,
            data: formData,
            params: {
                include: 'files'
            }
        })
    }
}

export const addPoints = (userId, data) => {
    return {
        type: 'ADD_USER_POINTS',
        payload: axios.request({
            url: '/users/' + userId + '/points',
            method: 'POST',
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}

export const clearUserForm = () => {
    return {
        type: 'CLEAR_USER_FORM_DATA'
    }
}

export const getExistingUsers = ( params, url = settings.api_endpoint + "/users") => {
    return {
        type: 'GET_EXISTING_USERS',
        payload: axios.get(url, {
            params: params
        })
    }
}
