import React from 'react';
import { connect } from 'react-redux'

class Auth extends React.Component {

	render() {
		return this.authUserHasRole() ? this.props.children : null
			    
	}

	authUserHasRole() {
		if ( this.props.roles.includes(this.getRole().slug) ) {
			return true
		} else {
			return false
		}
	}

    getRole() {
        return this.props.user&&this.props.user.auth&&this.props.user.auth.user ? this.props.user.auth.user.roles : {}
    }

}
const mapStateToProps = (state) => {
	return {
		user: state.user
	}
}

export default connect(mapStateToProps)(Auth)
