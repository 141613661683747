import React, { useCallback, useState } from 'react'
import { useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { getPromoCodesApp, addPromoCodeApp, updatePromoCodeApp } from '../../actions';
import TableComponent from '../../components/Table/TableComponent';
import { DialogTitle } from '@material-ui/core';
import { DATE_TIME_FORMAT, INITIAL_PAGINATION, PERCENT_SALES_TYPE, SALES_TYPE } from '../../constants';
import DateTimePickerComponent from '../../components/Form/DateTimePickerComponent';
import DialogComponent from '../../components/Dialog/DialogComponent';
import TextFieldComponent from '../../components/Form/TextFieldComponent';
import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import validations from '../../functions/validations'
import findItem from '../../functions/findItem';
import RadioGroupComponent from '../../components/Form/RadioGroupComponent';
import { changePerPage, handleAdd, handleCloseAdd, handleCloseShow, handleShow, onChangePage } from '../../functions/tableFunctions';
import DialogRowComponent from '../../components/Dialog/DialogRowComponent';
import CheckboxComponent from '../../components/Form/CheckboxComponent';


const codeSchema = (t) => Yup.object().shape({
    name: Yup.string()
        .required(validations('required'))
        .min(3, validations('min', 3))
        .max(80, validations('max', 80)),
    code: Yup.string()
        .required(validations('required'))
        .min(3, validations('min', 3))
        .max(250, validations('max', 20)),
    value: Yup.string()
        .required(validations('required'))
        .test('len', validations('biggest', 0), val => val > 0),
    type: Yup.string()
        .required(validations('required'))
});

const initialData = {
    name: '',
    message: '',
    code: '',
    firstPurchase: false,
    value: '',
    startDate: new Date(),
    finishDate: moment().add(1, 'days'),
    type: PERCENT_SALES_TYPE,
};


const PromoCodesPage = ({
    getPromoCodesApp = () => { },
    addPromoCodeApp = () => { },
    updatePromoCodeApp = () => { },
    promoCodesApp }) => {
    const [editPromoCode, setEditPromoCode] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [show, setShow] = useState(false);
    const [activePromoCode, setActivePromoCode] = useState({});
    const [filters, setFilters] = useState(INITIAL_PAGINATION)
    const [data, setData] = useState({ ...initialData });

    const formik = useFormik({
        validationSchema: codeSchema(),
        enableReinitialize: true,
        initialValues: data,
        onSubmit: values => {
            let bodyFormData = new FormData();
            bodyFormData.append('name', values.name)
            bodyFormData.append('code', values.code)
            bodyFormData.append('value', values.value)
            bodyFormData.append('firstPurchase', values.firstPurchase)
            bodyFormData.append('startDate', moment(values.startDate).format(DATE_TIME_FORMAT))
            bodyFormData.append('finishDate', moment(values.finishDate).format(DATE_TIME_FORMAT))
            bodyFormData.append('message', values.message)
            bodyFormData.append('type', values.type)
            if (editPromoCode) {
                bodyFormData.append('_method', 'put')
                updatePromoCodeApp({ id: values.id, params: bodyFormData });
            }
            else
                addPromoCodeApp(bodyFormData,);
        },
    });

    useEffect(() => {
        if (promoCodesApp.error) {
            NotificationManager.error(promoCodesApp.error, "", 2000);
        }
    }, [promoCodesApp])

    useEffect(() => {
        getPromoCodesApp(filters);
    }, [filters])

    const handleEdit = useCallback((id) => {
        const code = findItem(promoCodesApp, id);
        if (code) {
            setData({
                id: code.identifier,
                name: code.name,
                message: code.message,
                type: code.type,
                code: code.code,
                value: code.value,
                startDate: code.startDate,
                finishDate: code.finishDate,
            })
            setEditPromoCode(true);
            setOpenAdd(true)
        }
    }, [promoCodesApp])

    useEffect(() => {
        if (!promoCodesApp.loading && !promoCodesApp.error) {
            setOpenAdd(false)
            setEditPromoCode(false);
            formik.resetForm();
            setData({ ...initialData });
            setActivePromoCode(false);

        } else if (promoCodesApp.error) {
            NotificationManager.error(promoCodesApp.error, "", 2000);
        }
    }, [promoCodesApp])

    return (
        <div className="app-wrapper animated slideInUpTiny animation-duration-3">
            <NotificationContainer />
            <div className="row mb-md-4">
                <TableComponent
                    headerOutside
                    loading={promoCodesApp.tableLoading}
                    nextPageLoading={promoCodesApp.nextPageLoading}
                    heading='Kody promocyjne'
                    onChangePage={(e, value) => onChangePage(value, filters, setFilters)}
                    changePerPage={(e, value) => changePerPage(value, filters, setFilters)}
                    addClick={() => handleAdd(setOpenAdd)}
                    handleEdit={handleEdit}
                    handleShow={(id) => handleShow(promoCodesApp, id, setActivePromoCode, setShow)}
                    addBtn='zmdi-plus'
                    meta={promoCodesApp.pagination}
                    hiddenDelete
                    titles={['Nazwa', 'Kod', 'Data rozpoczęcia', 'Data zakończenia']}
                    data={promoCodesApp && promoCodesApp.list && promoCodesApp.list.length
                        ? promoCodesApp.list.map((promoCode) => ({
                            id: promoCode.identifier,
                            data: {
                                name: promoCode.name,
                                code: promoCode.code,
                                startDate: moment(promoCode.startDate).format(DATE_TIME_FORMAT),
                                finishDate: moment(promoCode.finishDate).format(DATE_TIME_FORMAT),
                            }
                        }))
                        : []}
                />
            </div>
            <DialogComponent
                open={openAdd}
                loading={promoCodesApp.loading}
                handleClose={() => handleCloseAdd(setData, initialData, setOpenAdd, setEditPromoCode)}
                title={editPromoCode ? "Edytuj promo kod" : 'Dodaj promo kod'}
                handleSave={formik.handleSubmit}>
                <form onSubmit={formik.handleSubmit} className="col-md-12">
                    <TextFieldComponent
                        fullWidth
                        id='name'
                        formikProps={formik}
                        label='Nazwa'
                    />
                    <TextFieldComponent
                        fullWidth
                        id='message'
                        formikProps={formik}
                        label='Wiadomość'
                    />
                    <TextFieldComponent
                        fullWidth
                        id='code'
                        formikProps={formik}
                        label='Kod'
                    />
                    <RadioGroupComponent
                        id='type'
                        formikProps={formik}
                        label='Typ rabatu'
                        group={SALES_TYPE}
                    />
                    <CheckboxComponent
                        id='firstPurchase'
                        formikProps={formik}
                        label='Tylko dla pierwszego zakupu?'
                    />
                    <TextFieldComponent
                        fullWidth
                        id='value'
                        type='number'
                        formikProps={formik}
                        label='Wartość'
                    />
                    <DateTimePickerComponent
                        id='startDate'
                        label='Data rozpoczęcia działania'
                        formikProps={formik}
                        fullWidth
                        maxDate={formik.values.finishDate}
                        type={DATE_TIME_FORMAT}
                    />
                    <DateTimePickerComponent
                        id='finishDate'
                        label='Data zakończenia działania'
                        formikProps={formik}
                        fullWidth
                        minDate={formik.values.startDate}
                        type={DATE_TIME_FORMAT}
                    />
                </form>
            </DialogComponent>
            <DialogComponent
                open={show}
                hiddenClose
                handleSave={() => handleCloseShow(setShow)}
            >
                {activePromoCode
                    ? <>
                        <div className={`col-md-6`}>
                            <DialogTitle className='pl-0'>{activePromoCode ? activePromoCode.name : ''}</DialogTitle>
                        </div>
                        <div className={`col-md-6`}>
                            <DialogTitle align='right'>{activePromoCode.code}</DialogTitle>
                        </div>
                        <DialogRowComponent
                            label={'Wiadomość'}
                            text={activePromoCode.message ? activePromoCode.message : ''}
                        />
                        <DialogRowComponent
                            label={'Data rozpoczęcia działania'}
                            text={activePromoCode.startDate ? moment(activePromoCode.startDate).format(DATE_TIME_FORMAT) : ''}
                        />
                        <DialogRowComponent
                            label={'Data zakończenia działania'}
                            text={activePromoCode.finishDate ? moment(activePromoCode.finishDate).format(DATE_TIME_FORMAT) : ''}
                        />
                        <DialogRowComponent
                            label={'Typ rabatu'}
                            text={activePromoCode.type ? SALES_TYPE.find((type) => type.value === activePromoCode.type).label : null}
                        />
                        <DialogRowComponent
                            label={'Wartość'}
                            text={activePromoCode.value}
                        />
                    </>
                    : null
                }
            </DialogComponent>
        </div>
    )
}


export default connect((state) => ({
    promoCodesApp: state.promoCodesApp
}), {
    getPromoCodesApp,
    addPromoCodeApp,
    updatePromoCodeApp,
})(PromoCodesPage);