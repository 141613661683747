import axios from 'axios'
import settings from '../config/settings.js'


export const getDashboardData = ( params, url = settings.api_endpoint + "/dashboardData") => {
    return {
        type: 'GET_DASHBOARD_DATA',
        payload: axios.get(url, {
            params: params
        })
    }
}

export const getOrderStatistic = (params, url = settings.api_endpoint + "/mobile_order/statistics") => {
    return {
        type: 'GET_ORDER_STATISTIC',
        payload: axios.get(url, {
            params: params
        })
    }
}
