import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import User from './User';
import Pos from './Pos';
import Auth from './Auth';
import Courses from './Courses';
import Dashboard from './Dashboard';
import News from './News';
import Files from './Files';
import Statistics from './Statistics';
import Notifications from './Notifications';
import NotificationsAppReducer from './NotificationsAppReducer';
import SlidersAppReducer from './SlidersAppReducer';
import OrdersAppReducer from './OrdersAppReducer';
import FiltersReducer from './FiltersReducer';
import OrderHistoryReducer from './OrderHistoryReducer';
import BlogReducer from './BlogReducer';
import InspirationsReducer from './InspirationsReducer';
import PromoCodesAppReducer from './PromoCodesAppReducer';
import MobileProductsReducer from './MobileProductsReducer';
import AppStatisticReducer from './AppStatisticReducer';
// import OrderStatisticReducer from './OrderStatisticReducer';



export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  user: User,
  pos: Pos,
  auth: Auth,
  courses: Courses,
  dashboard: Dashboard,
  news: News,
  files: Files,
  statistics: Statistics,
  notifications: Notifications,
  notificationsApp: NotificationsAppReducer,
  slidersApp: SlidersAppReducer,
  ordersApp: OrdersAppReducer,
  ordersHistory: OrderHistoryReducer,
  filters: FiltersReducer,
  blogPosts: BlogReducer,
  inspirations: InspirationsReducer,
  promoCodesApp: PromoCodesAppReducer,
  appStatistic: AppStatisticReducer,
  mobileProducts: MobileProductsReducer,
  // orderStatistic: OrderStatisticReducer,
})
