import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {connect} from 'react-redux'
import * as UserActions from '../../actions/User'
import { Link } from 'react-router-dom'

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  logOut() {
    this.handleRequestClose()
    this.props.dispatch(UserActions.logOut())
  }

  render() {
    let { firstName, lastName, avatar, roles } = this.props.user.auth.user
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt='...'
          src={avatar}
          className="user-avatar "
        />
        <div className="user-detail" onClick={this.handleClick.bind(this)}>
          <h4 className="user-name">{firstName+" "+lastName}
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
          </h4>
          <p style={{fontSize: 11}}>{roles ? roles.name : null}</p>
        </div>
        <Menu className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  minWidth: 120,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}>
          <MenuItem>
            <Link to={"/app/users/"+this.props.user.auth.user.identifier} onClick={this.handleRequestClose.bind(this)}>
              <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
              Profil
            </Link>
          </MenuItem>
          <MenuItem onClick={this.logOut.bind(this)}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
            Wyloguj
          </MenuItem>
        </Menu>
      </div>
    );
  }


}

const mapStateToProps = (state) => ({
  user: state.user,
});


export default connect(mapStateToProps)(UserInfo)


