import React from 'react';
import Auth from '../../util/Auth';
import { Tooltip, Fab } from '@material-ui/core';
import { Badge } from 'reactstrap';

const CardBox = ({ subtitle, badge, heading, children, addTooltip, addRoles = ['super_admin', 'admin'], addClick = () => { }, addBtn, styleName, cardStyle, childrenStyle, headerOutside, headingStyle }) => {

  return (
    <div className={`${styleName}`}>
      {headerOutside || addBtn ? <div className='d-flex align-items-center jr-entry-header'>
        {addBtn
          ?
          <Auth roles={addRoles}>
            <Tooltip title={addTooltip || 'Dodaj'} placement="top">
              <Fab onClick={addClick} color="primary" className="jr-fab-btn " aria-label="add" style={{ minWidth: 50 }}>
                <i className={`zmdi zmdi-hc-fw zmdi-hc-lg ${addBtn}`} />
              </Fab>
            </Tooltip>
          </Auth>
          : null
        }
        {headerOutside &&
          <div className="">
            <h3 className="entry-heading">{heading}</h3>
            {children.length > 1 && <div className="entry-description">{children[0]}</div>}
          </div>
        }
      </div>
        : null
      }
      <div className={`jr-card ${cardStyle}`}>

        {!headerOutside &&
          (heading &&
            <div className={`jr-card-header ${headingStyle}`}>
              <h3 className="card-heading">{heading}</h3>
              {subtitle ? <Badge pill color="primary" className='ml-0 mt-2 mb-2'>{subtitle}</Badge> : null}
              {badge
                ? <Badge pill color="secondary" className='ml-0 mt-2 mb-2'>
                  {badge}
                </Badge>
                : null}
              {children.length > 1 && <div className="sub-heading">{children[0]}</div>}
            </div>
          )}
        <div className={`jr-card-body ${childrenStyle}`}>
          {children.length > 1 ? children[1] : children}
        </div>
      </div>
    </div>
  )
};

export default CardBox;

CardBox.defaultProps = {
  cardStyle: '',
  headingStyle: '',
  childrenStyle: '',
  styleName: 'col-lg-6 col-sm-12'
};