import React, { useMemo } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

const CheckboxComponent = ({
	label,
	route,
	currentValue,
	currentTouched,
	currentError,
	formikProps,
	id,
	color = 'default',
	onBlur = () => { },
	onChange = () => { },
	customHelperText,
}) => {
	const value = useMemo(() => currentValue || formikProps.values[id], [formikProps, id, currentValue]);
	const error = useMemo(() => currentValue ? currentError : formikProps.errors[id], [currentValue, currentError, formikProps, id]);
	const touched = useMemo(() => currentValue ? currentTouched : formikProps.touched[id], [currentValue, currentTouched, formikProps, id]);
	const isError = useMemo(() => error && Boolean(touched) ? true : false, [error, touched])
	const handleBlur = (event) => {
		onBlur(event);
		formikProps.handleBlur(event)
	}
	const handleChange = (event) => {
		onChange(event);
		formikProps.handleChange(event)
	}
	return (
		<FormControl error={customHelperText ? Boolean(customHelperText) : isError}>
			<FormControlLabel
				control={
					<Checkbox
						checked={Boolean(value)}
						onChange={handleChange}
						onBlur={handleBlur}
						name={id}
						id={id}
						color={color}

					/>
				}
				label={<Typography  dangerouslySetInnerHTML={{ __html: label }} />}
			/>
			<FormHelperText>{customHelperText ? customHelperText : isError ? error : ' '}</FormHelperText>
		</FormControl>
	)
}


export default React.memo(CheckboxComponent);