import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import ReactSelect from 'react-select'
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Condition from '../../../../../util/Condition'
// import * as UserActions from '../../../../../actions/User'
import * as CoursesActions from '../../../../../actions/Courses'
import { connect } from 'react-redux'
import moment from 'moment'
import { Fab } from '@material-ui/core';

class CourseTile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      editMode: false,
      addMode: false,
      selectedAddModeDate: null,
      selectedDate: null
    }
  }

  toggleEditMode() {
    this.setState({
      ...this.state,
      editMode: !this.state.editMode
    })
  }

  toggleAddMode() {
    this.setState({
      ...this.state,
      editMode: !this.state.editMode
    })
  }

  onEditModeCourseDateChange(value) {
    this.setState({
      ...this.state,
      selectedDate: value
    })
  }
  onCourseDateChangeSubmit() {
    // let signupID = null
    let courseDateID = this.state.selectedDate.value
    if (this.props.data.courseDate) {
      // signupID = this.props.data.courseDate.data.courseSignup.data.identifier
      courseDateID = this.state.selectedDate.value
    }

    // let courseID = this.props.data.identifier
    let creatorID = this.props.user.auth.user.identifier
    let participantID = this.props.user.userDetails.data.identifier

    this.toggleEditMode()
    // this.props.dispatch(UserActions.changeSignupCourseDate(signupID, courseDateID))
    // this.props.dispatch(UserActions.changeSignupCourseDate(signupID, courseDateID))
    this.props.dispatch(CoursesActions.signUpForCourse(courseDateID, {
      creatorID,
      participantID
    }))
  }
  render() {
    let badgeColor = ''
    let badgeText = ''

    let courseDateId = ''
    let isActive = false

    let availableCourseDates = this.props.courseDates.list.filter((item, key) => {
      return item.courseSlug === this.props.data.slug
    })
    availableCourseDates = availableCourseDates.map((item, key) => {
      return {
        label: moment(item.dateStart).format('DD-MM-YYYY') + ', ' + item.placeName,
        value: item.identifier
      }
    })
    let courseDateStatus = null
    let courseSignupStatus = null
    let courseSignupPoints = null
    let courseSlug = this.props.data.slug
    if (this.props.data.courseDate) {
      courseDateId = this.props.data.courseDate.data.identifier
      // courseDateStatus = this.props.data.courseDate.data.status
      courseDateStatus = this.props.data.courseDate.data.status
      courseSignupStatus = this.props.data.courseDate.data.courseSignup.data.status
      courseSignupPoints = this.props.data.courseDate.data.courseSignup.data.signupPoints
      // if ( courseDateStatus == "pending" ) {
      //   badgeColor = "primary"
      //   badgeText = "Nadchodzące"
      // }
      // if ( courseDateStatus == "active" ) {
      //   badgeColor = "warning"
      //   badgeText = "W trakcie"
      // }
      // if ( courseDateStatus == "done" ) {
      //   badgeColor = "success"
      //   badgeText = "Zakończone"
      // }
      if (courseDateStatus !== "done" && courseSignupStatus !== "cancelled") {
        badgeColor = "warning"
        badgeText = "Zgłoszono na szkolenie"
        isActive = true
      }
      if (courseDateStatus === "done" && courseSignupStatus === "accepted") {
        badgeColor = "success"
        badgeText = "Ukończone szkolenie"
        isActive = true
      }
    }
    let activeClass = isActive ? 'active-tile' : 'inactive-tile'
    let canAdd = this.props.data.beforeCourse;
    if (this.props.data.group) {
      canAdd = canAdd || this.props.data.group.id === 5;
    }
    return <div className={"jr-card user-detail-card " + activeClass}>
      <div className="user-img-container" style={{ backgroundColor: this.props.data.colorHEX }}>
        <img className="user-img" style={{ objectFit: 'cover' }} alt="userAvatar" src={this.props.data.image} />
      </div>

      <div className="jr-card-body d-flex flex-column justify-content-center">
        <Link to={courseDateId ? "/app/course-dates/details/" + courseDateId : "/app/courses/" + courseSlug}>
          <h4 className="mb-1">{this.props.data.title}</h4>
          {isActive ? <span className="d-block jr-fs-15">
            <Badge pill color={badgeColor}>{badgeText}</Badge>
          </span> : null}
          {isActive
            ? <span className="d-block jr-fs-15">
              <Badge pill color={courseSignupPoints < 0 ? 'danger' : 'success'}>Do zapłaty: {(-1) * courseSignupPoints}</Badge>
            </span>
            : null}
        </Link>
        {/* {isActive ? <span className="d-block jr-fs-13">
            Status zapisu: <Badge pill color={signupBadgeColor}>{signupBadgeText}</Badge>
        </span> : null} */}
        <Condition condition={courseDateStatus !== 'accepted'}>
          <Condition condition={!isActive}>
            <Tooltip onClick={this.toggleAddMode.bind(this)} id="tooltip-icon" className="ml-3" title="Zmień termin szkolenia" placement="top">
              <Fab color="primary" className={`bg-primary text-white jr-fab-btn jr-btn-fab-xs ${canAdd ? '' : 'd-none'}`} aria-label="edit">
                <i className="zmdi zmdi-plus zmdi-hc-fw zmdi-hc-lg" />
              </Fab>
            </Tooltip>
          </Condition>
          <Condition condition={this.state.editMode}>
            <ReactSelect
              className="mt-2"
              options={availableCourseDates}
              value={this.state.selectedDate}
              onChange={this.onEditModeCourseDateChange.bind(this)}
              placeholder="Wybierz termin szkolenia"
            />
            <Button variant="contained" onClick={this.onCourseDateChangeSubmit.bind(this)} color="primary" className="jr-btn mt-2">
              <i className="zmdi zmdi-assignment-check zmdi-hc-fw zmdi-hc-lg" />
                Zapisz
              </Button>
            {/* <Tooltip onClick={this.onCourseDateChangeSubmit.bind(this)} id="tooltip-icon" className="mt-1" title="Zapisz" placement="top">
                  <Button variant="fab" color="primary" className="bg-primary text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                    <i className="zmdi zmdi-assignment-check zmdi-hc-fw zmdi-hc-lg"/>
                  </Button>
                </Tooltip> */}
          </Condition>
        </Condition>

        {/* <span className="d-block jr-fs-sm text-grey">EOM </span> */}
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(CourseTile)
