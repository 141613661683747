import { FormLabel } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react'
import RadioComponent from './RadioComponent';

const useStyles = makeStyles((theme) => ({
	section: {
		marginTop: 16,
		marginBottom: 8,
	}
}));

const RadioGroupComponent = ({ group, formikProps, label = '', id}) => {
	const classes = useStyles();
	return (
		<section className={classes.section}>
			<FormLabel component="legend" margin="normal">{label}</FormLabel>
			{group && group.length 
				? group.map(({ label, value}, key) =>
					<RadioComponent
						key={key}
						id={id}
						formikProps={formikProps}
						radioValue={value}
						label={label}
					/>
					)
			: null}
		</section>
	)
}

export default React.memo(RadioGroupComponent);