import React from 'react';
import UsersList from './UsersList';
import CardBox from '../../../../../components/CardBox/index';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddUser from '../../AddUser'
import { connect } from 'react-redux'
import * as UserActions from '../../../../../actions/User'
import * as PosActions from '../../../../../actions/Pos'
import SweetAlert from 'react-bootstrap-sweetalert'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Auth from '../../../../../util/Auth'

class Users extends React.Component {

    constructor() {
        super()
        this.state = {
            addMode: false,
            editMode: false,
            editedUserKey: null,
            tabsValue: '',
            activeFilter: '',
            searchQuery: '',
            deleteWarning: false,
            selectedDeletionSlug: '',
            per_page: 15,
            sort: {
                name: '',
                direction: true
            }
        }

    }

    componentDidUpdate(prevProps) {
        if (!prevProps.user.addUserForm.added && this.props.user.addUserForm.added) {
            NotificationManager.success("Pomyślnie dodano użytkownika", "", 2000);
            this.disableAddMode()
            this.props.dispatch(PosActions.getPosList({
                per_page: 0
            }))
        }
        if (!prevProps.user.editedUser && this.props.user.editedUser) {
            NotificationManager.success("Pomyślnie zaktualizowano użytkownika!", "", 2000);
            this.disableAddMode()
        }
    }

    componentDidMount() {
        this.dispatchOnMount()
    }

    dispatchOnMount() {
        this.props.dispatch(UserActions.getUserList(this.createQueryParams()))
        this.props.dispatch(UserActions.getFreeAgents())
        this.props.dispatch(PosActions.getPosList({
            per_page: 0
        }))
    }

    render() {

        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <NotificationContainer />
                <Auth roles={['super_admin', 'admin']}>
                    <AddUser editMode={this.state.editMode}
                        existingUsers={this.props.user.existingUsers}
                        onCheck={this.checkUsers.bind(this)}
                        addUserForm={this.props.user.addUserForm}
                        addUser={this.addUser.bind(this)}
                        editUser={this.editUser.bind(this)}
                        open={this.state.addMode}
                        data={this.props.user.list}
                        agents={this.props.user.freeAgents}
                        posList={this.props.pos.list}
                        togglePopup={this.toggleAddMode.bind(this)}
                        editedUserKey={this.state.editedUserKey} />
                </Auth>
                <div className="row mb-4">
                    <div className="col-lg-6">
                        <div className="search-bar right-side-icon bg-transparent d-none d-sm-block">
                            <div className="form-group">
                                <input onChange={this.changeSearchQuery.bind(this)} className="form-control border-0" type="search" placeholder="Wyszukaj użytkownika..." />
                                <button className="search-icon"><i className="zmdi zmdi-search zmdi-hc-lg" /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-md-4">
                    <Auth roles={['super_admin', 'admin']}>
                        <CardBox
                            styleName="col-12"
                            cardStyle="p-0"
                            heading='Lista użytkowników'
                            headerOutside
                            addTooltip='Dodaj użytkownika'
                            addClick={this.toggleAddMode.bind(this)}
                            addBtn='zmdi-account-add'
                        >
                            <Tabs value={this.state.tabsValue} onChange={this.setActiveFilter.bind(this)} variant="scrollable">
                                <Tab className="jr-tabs-label" value="" label="Wszyscy" />
                                <Tab className="jr-tabs-label" value="ph" label="Handlowiec" />
                                <Tab className="jr-tabs-label" value="training_staff" label="Szkoleniowcy" />
                                <Tab className="jr-tabs-label" value="pos_admin" label="Szef salonu" />
                                <Tab className="jr-tabs-label" value="worker" label="Pracownik" />
                                <Tab className="jr-tabs-label" value="minusPoint" label="Nierozliczone szkolenia" />
                            </Tabs>
                        </CardBox>
                    </Auth>
                    <Auth roles={['ph']}>
                        <CardBox styleName="col-12" cardStyle="p-0" heading='Lista użytkowników' headerOutside>
                            <Tabs value={this.state.tabsValue} onChange={this.setActiveFilter.bind(this)} variant="scrollable">
                                <Tab className="jr-tabs-label" value="" label="Wszyscy" />
                                <Tab className="jr-tabs-label" value="pos_admin" label="Szef salonu" />
                                <Tab className="jr-tabs-label" value="worker" label="Pracownik" />
                            </Tabs>
                        </CardBox>
                    </Auth>
                    {/* <CardBox styleName="col-12 mb-1">
                    <h2 className="keune-title">Lista użytkowników</h2>
                </CardBox> */} 
                        <CardBox styleName="col-12" cardStyle="p-0">
                            <UsersList changePerPage={this.changePerPage.bind(this)}
                                toggleEditMode={this.toggleEditMode.bind(this)}
                                user={this.props.user}
                                onDelete={this.onDelete.bind(this)}
                                sort={this.state.sort}
                                loading={this.props.user.loading}
                                nextPageLoading={this.props.user.nextPageLoading}
                                activeFilter={this.state.tabsValue}
                                changeSorting={this.changeSorting.bind(this)}
                                onPageChange={this.getUsersPage.bind(this)}
                                meta={this.props.user.userListMeta}
                                data={this.props.user.list} />
                        </CardBox>
                </div>
                <SweetAlert show={this.state.deleteWarning}
                    warning
                    showCancel
                    confirmBtnText="Tak, usuń"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    cancelBtnText="Anuluj"
                    title="Jesteś pewien?"
                    onConfirm={this.onConfirmDelete.bind(this)}
                    onCancel={this.onCancelDelete.bind(this)} >
                    Użytkownik zostanie bezpowrotnie usunięty!
                </SweetAlert>
            </div>
        );
    }
    setActiveWorker(value) {
        if (value) {
            this.props.dispatch(UserActions.getUserList({
                per_page: this.state.per_page,
                'filter[points]': value,
            }))
        }
    }
    setActiveFilter(e, value) {
        this.setState({
            ...this.state,
            tabsValue: value,
            activeFilter: value === 'minusPoint'
                ? {
                    'filter[points]': 1
                }
                : {
                    'filter[role]': value
                },
        }, () => {
            // if (value !== 'minusPoint') {
            this.props.dispatch(UserActions.getUserList(this.createQueryParams()))
            // }
        })

    }

    checkUsers(query) {
        this.props.dispatch(UserActions.getExistingUsers({
            'filter[q]': query
        }))
    }


    changePerPage(e, value) {
    }

    changeSearchQuery(e) {
        this.setState({
            ...this.state,
            searchQuery: e.target.value
        }, () => {
            this.props.dispatch(UserActions.getUserList(this.createQueryParams()))

        })
    }

    authUserHasRole(roles) {
        if (roles.includes(this.props.user.auth.user.roles.slug)) {
            return true
        } else {
            return false
        }
    }

    getUsersPage(url) {
        this.props.dispatch(UserActions.getUserList(null, url))
    }

    changeSorting(name) {
        this.setState({
            ...this.state,
            sort: {
                name: name,
                direction: !this.state.sort.direction
            }
        }, () => {
            this.props.dispatch(UserActions.getUserList(this.createQueryParams()))
        })
    }

    disableAddMode() {
        this.setState({
            ...this.state,
            addMode: false,
            editMode: false,
            editedUserKey: null
        })
    }

    toggleAddMode() {
        this.setState({
            ...this.state,
            addMode: !this.state.addMode,
            editMode: false,
            editedUserKey: null
        })
    }

    toggleEditMode(key) {
        this.setState({
            ...this.state,
            addMode: !this.state.addMode,
            editMode: !this.state.editMode,
            editedUserKey: key
        })
    }

    createQueryParams() {
        let direction = ''
        if (!this.state.sort.direction) {
            direction = '-'
        }
        return {
            per_page: this.state.per_page,
            ...this.state.activeFilter,
            'filter[q]': this.state.searchQuery,
            'include': 'pos,pos.agent',
            'sort': direction + this.state.sort.name
        }
    }

    addUser(data) {
        this.props.dispatch(UserActions.addUser(data))
    }

    editUser(data) {
        this.props.dispatch(UserActions.editUser(this.props.user.list[this.state.editedUserKey].identifier, data))
    }

    onConfirmDelete() {
        this.props.dispatch(UserActions.deleteUser(this.state.selectedDeletionSlug))
        this.setState({
            ...this.state,
            deleteWarning: false
        })
    }

    onCancelDelete() {
        this.setState({
            ...this.state,
            deleteWarning: false,
            selectedDeletionSlug: ''
        })
    }

    onDelete(slug) {
        this.setState({
            ...this.state,
            deleteWarning: !this.state.deleteWarning,
            selectedDeletionSlug: slug
        })
    }

}


const mapStateToProps = state => ({
    user: state.user,
    pos: state.pos
});


export default connect(mapStateToProps)(Users)
