import React from 'react';
// import Avatar from '@material-ui/core/Avatar'
import { Link } from 'react-router-dom'
import NotifContentComponent from './NotifContentComponent';


const NotificationItem = ({ notification, onClick }) => {
  const { created, description, identifier, logName, readed, subjectID } = notification;

  function click(id) {
    onClick(id)
  }
  let unread = readed ? '' : 'unread'

  let url = '/course-dates/details/'

  if (logName === 'course-date-signup') {
    url = '/course-dates/details/'
  }
  if (logName === 'course-date-created') {
    url = '/course-dates/details/'
  }
  if (logName === 'panel-news-created') {
    url = '/keune-news/'
  }

  return <li className={"media " + unread}>
    {subjectID
      ? <Link to={"/app" + url + subjectID} onClick={click.bind(click, identifier)}>
        <NotifContentComponent created={created} description={description} />
      </Link>
      : <NotifContentComponent created={created} description={description} />
    }
  </li>
}


export default NotificationItem;
