export const NOTIFICATIONS_ROUTE = '/notifications'
export const SLIDERS_ROUTE = '/sliders'
export const ORDERS_ROUTE = '/orders'
export const ORDER_STATISTIC_ROUTE = '/order_statistic'
export const ORDERS_HISTORY_ROUTE = '/history_orders'
export const APP_STATISTIC_ROUTE = '/app_statistic'
export const PROMO_CODES_ROUTE = '/promo_codes'
export const BLOG_ROUTE = '/blog'
export const RESEND_EMAIL_ROUTE = '/resend_email'
export const LOGIN_ROUTE = '/signin'
export const INSPIRATIONS_ROUTE = '/inspirations'
