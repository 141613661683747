import axios from 'axios'
import settings from '../config/settings.js'



export const getSalonsFilterData = (filterValue, url = settings.api_endpoint + "/mobile_order/salons") => {
    return {
        type: 'GET_SALONS_FILTER',
        payload:  axios.get(`${url}?filter[name]=${filterValue}`)
    }
}
export const getSalesFilterData = (filterValue, url = settings.api_endpoint + "/mobile_order/sales") => {
    return {
        type: 'GET_SALES_FILTER',
        payload:  axios.get(`${url}?filter[name]=${filterValue}`)
    }
}
