import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableHead from '@material-ui/core/TableHead'; import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom'
import TableLoaderComponent from '../../../../../components/Loaders/TableLoaderComponent';




function WorkersList({ list, loading}) {

    return (
        <div className="table-responsive-material">
            <TableLoaderComponent loading={loading}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Imię i nazwisko</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Telefon</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((n, key) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        <Link className="keune-link" to={'/app/users/' + n.identifier}>
                                            {n.firstName + ' ' + n.lastName}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <a className="keune-link" href={"mailto:" + n.email}>{n.email}</a>
                                    </TableCell>
                                    <TableCell>
                                        <a className="keune-link" href={"tel:" + n.phoneNumber}>{n.phoneNumber}</a>
                                    </TableCell>
                                    <TableCell>
                                        <Link className="keune-link" to={'/app/users/' + n.identifier}>
                                            <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm mb-3">
                                                <i className="zmdi zmdi-eye" />
                                            </Fab>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableLoaderComponent>
        </div>
    );
}


export default WorkersList;