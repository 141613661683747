import axios from 'axios'
import settings from '../config/settings.js'



export const getBlogPosts = (params, url = settings.api_endpoint + "/blog") => {
// export const getBlogPosts = (params, url = settings.api_endpoint + "/course") => {
	return {
		type: 'GET_BLOG_POSTS',
		payload: axios.get(url, {
			params: params
		})
	}
}


export const addBlogPost = (params, url = settings.api_endpoint + "/blog") => {
	return {
		type: 'ADD_BLOG_POST',
		payload: axios.post(url, params)
	}
}

export const updateBlogPost = (data, url = settings.api_endpoint + "/blog") => {
	return {
		type: 'UPDATE_BLOG_POST',
		payload: axios.post(`${url}/${data.slug}`, data.params)
	}
}
export const deleteBlogPost = (id, url = settings.api_endpoint + "/blog") => {
	return {
		type: 'DELETE_BLOG_POST',
		payload: axios.delete(`${url}/${id}`)
	}
}
