import { Typography } from '@material-ui/core';
import React from 'react'

const DialogRowComponent = ({label, text}) => {

	return (
		<>
			<div className="col-md-12 mt-2">
				{label}
			</div>
			<div className="col-md-12">
				<Typography dangerouslySetInnerHTML={{ __html: text }} />
			</div>
		</>
	)
}

export default React.memo(DialogRowComponent);