import React from 'react';
import { Link, withRouter} from 'react-router-dom'
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
// import IntlMessages from 'util/IntlMessages';
// import {
//     hideMessage,
//     showAuthLoader,
//     userFacebookSignIn,
//     userGithubSignIn,
//     userGoogleSignIn,
//     userSignIn,
//     userTwitterSignIn
// } from 'actions/Auth';
import * as userActions from '../actions/User'
import { RESEND_EMAIL_ROUTE } from '../constants/routes';
import DialogLoaderComponent from '../components/Loaders/DialogLoaderComponent';

class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: ''
        }
    }

    handleInputChange(e) {
        let newState = {...this.state}
        newState[e.target.name] = e.target.value
        this.setState(newState)
    }

    authUser() {
        this.props.dispatch(userActions.authUser(this.state))
    }

    componentDidUpdate(prevProps) {
        if ( prevProps.user.auth.status !== this.props.user.auth.status ) {
            if ( this.props.user.auth.status === 'invalid_credentials' ) {
                NotificationManager.error(this.props.user.auth.message)
            } else if (this.props.user.auth.status === 402) {
                this.props.history.push(`${RESEND_EMAIL_ROUTE}?email=${this.state.email}`);
            }else if (this.props.user.auth.message){
                NotificationManager.error('Nie udało się zalogować ciebie, spróbuj ponownie')
            }
        }
        // if (this.props.showMessage) {
        //     setTimeout(() => {
        //         this.props.hideMessage();
        //     }, 100);
        // }
        // if (this.props.authUser !== null) {
        //     this.props.history.push('/');
        // }
    }

    render() {
        const {
            email,
            password
        } = this.state;
        const {showMessage, loader, alertMessage} = this.props;
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    {/* <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img src="" alt="jambo" title="jambo"/>
                        </Link>
                    </div> */}

                    <div className="app-login-content position-relative">
                        <div className="app-login-header mb-4">
                            <h1>Witamy w KEUNE Plus.<br></br>Zaloguj się!</h1>
                        </div>

                        <div className="app-login-form">
                            <form>
                                <fieldset>
                                    <TextField
                                        label={"E-mail"}
                                        fullWidth
                                        name="email"
                                        onChange={this.handleInputChange.bind(this)}
                                        defaultValue={email}
                                        margin="normal"
                                        autoComplete='email'
                                        className="mt-1 my-sm-3"
                                    />
                                    <TextField
                                        type="password"
                                        label={"Hasło"}
                                        fullWidth
                                        name="password"
                                        autoComplete='current-password'
                                        onChange={this.handleInputChange.bind(this)}
                                        defaultValue={password}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                    />

                                    <div className="mt-3 mb-3 d-flex align-items-center justify-content-between">
                                        <Button onClick={this.authUser.bind(this)} color="primary" className="jr-btn text-white keune-btn">
                                            Zaloguj się
                                        </Button>
                                        <Link to="/recover-password" className="keune-link">
                                            Zapomniałeś hasła?
                                        </Link>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <DialogLoaderComponent loaded={!loader} />
                    </div>
                </div>
                {showMessage && NotificationManager.error(alertMessage)}
                <NotificationContainer/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // const {loader, alertMessage, showMessage, authUser} = auth;
    // return {loader, alertMessage, showMessage, authUser}
    return {
        user: state.user
    }
};

export default connect(mapStateToProps)(withRouter(SignIn));
