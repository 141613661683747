import React from 'react';
// import Table from '@material-ui/core/Table'; 
// import TableBody from '@material-ui/core/TableBody'; 
// import TableCell from '@material-ui/core/TableCell';
//  import TableHead from '@material-ui/core/TableHead'; 
//  import TableRow from '@material-ui/core/TableRow';
// import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
// import TablePagination from '@material-ui/core/TablePagination';
// import Auth from '../../../../../util/Auth'
import moment from 'moment'

export default class NewsGrid extends React.Component {

    constructor(props) {
        super()
        this.state = {}
    }

    render() {
        return <div className="card shadow border-0 p-4">
            <h3 className="text-uppercase letter-spacing-base mb-4">KEUNE News!</h3>

            <ul className="articles-section list-unstyled row">
                {this.props.list.map((item, key) => {
                    return <li className="articles col-md-3 col-xs-12">
                        <Link to={"/app/keune-news/" + item.identifier}>
                            <span className="article-image">
                                <img src={item.image ? item.image.data.urlFile : ''} alt={item.title} />
                            </span>
                        </Link>
                        <div className="article-description">
                            <Link to={"/app/keune-news/" + item.identifier}>
                                <p>
                                    <span className="jr-link">{item.title}</span>
                                </p>
                            </Link>
                            <div className="meta-wrapper">
                                <span className="meta-date"><i
                                    className="zmdi zmdi-calendar-note zmdi-hc-lg" />{moment(item.creationDate).format('Do MMMM YYYY')}</span>
                            </div>
                        </div>
                    </li>
                })}
            </ul>
        </div>
    }

}


