import { makeStyles } from '@material-ui/styles';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    text: {
        fontWeight: 'bold',
    },
    div: {
        marginTop: 20,
    },
    titleDiv: {
        marginTop: 20,
        '@media (max-width: 500px)': {
            marginTop: 40,
        },
    }
}))
const RowListComponent = ({ label, text, bold, textClass = '' }) => {
    const classes = useStyles();
    return (
        <>
            <div className={`col-sm-4 col-xs-12 ${classes.div} ${classes.titleDiv}`}>
                <span className={`${classes.text} keune-link`}>
                    {label}:
                  </span>
            </div>
            <div className={`col-sm-8 col-xs-12 ${classes.div} ${textClass}`}>
                <span >
                    {text}
                </span>
            </div>
        </>
    )
}


export default RowListComponent;
