import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
// import asyncComponent from '../../../util/asyncComponent';
import Fab from '@material-ui/core/Fab';
import CardBox from '../../../components/CardBox';
import { connect } from 'react-redux'
import * as DashboardActions from '../../../actions/Dashboard'
// import * as UserActions from '../../../actions/User'
import * as NewsActions from '../../../actions/News'
// import {NotificationContainer, NotificationManager} from 'react-notifications';
import {NotificationContainer} from 'react-notifications';
import ReportBox from '../../../components/ReportBox/index';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom'
import Auth from '../../../util/Auth'
import AgentSaloons from './AgentSaloons'
import UpcomingCourseDates from './UpcomingCourseDates'
import NewsBox from './NewsBox'
import TrainingStaffCourse from './TrainingStaffCourse'
import Condition from '../../../util/Condition'
import OrderStatisticComponent from '../../../components/orderStatisticComponents/OrderStatisticComponent';


class Dashboard extends React.Component {

	componentDidMount() {
        this.props.dispatch(DashboardActions.getDashboardData())
        this.props.dispatch(NewsActions.getNews({
            per_page: 3
        }))
	}

	render() {
        // let match = this.props.match
        return <div className="app-wrapper-mobile-response">
       				<NotificationContainer/>
                    {/* <Auth roles={['super_admin', 'admin']}>
                        {this.renderAdminDashboard()}
                    </Auth> */}
                    <Auth roles={['ph']}>
                        {this.renderPHDashboard()}
                    </Auth>
                    <Auth roles={['training_staff']}>
                        {this.renderTrainingStaffDashboard()}
                    </Auth>
                    <Condition condition={this.props.news.news.loaded}>
                        <NewsBox list={this.props.news.news.list} />
                    </Condition>
                    <OrderStatisticComponent />
			    </div>
    }

    renderPHDashboard() {
        return <div className="row">
            <div className="col-sm-6 col-12">
                        <CardBox styleName="col-12" cardStyle="mb-2">
                            <h2 className="keune-title">Twoje salony</h2>
                        </CardBox>
                        <CardBox styleName="col-12" cardStyle="p-0">
                            <AgentSaloons data={this.props.dashboard.dashboardData.agentPosList.data} />
                        </CardBox>
                    </div>
            <div className="col-sm-6 col-12">
                        <CardBox styleName="col-12" cardStyle="mb-2">
                            <h2 className="keune-title">Nadchodzące szkolenia</h2>
                        </CardBox>
                        <CardBox styleName="col-12" cardStyle="p-0">
                            <UpcomingCourseDates data={this.props.dashboard.dashboardData.upcomingCourseDate.data} />
                        </CardBox>
                    </div>
               </div>
    }

    renderTrainingStaffDashboard() {
        return <div className="row">
                    <div className="col-6">
                        <CardBox styleName="col-12" cardStyle="mb-2">
                            <h2 className="keune-title">Twoje Szkolenia</h2>
                        </CardBox>
                        <CardBox styleName="col-12" cardStyle="p-0">
                            <TrainingStaffCourse data={this.props.dashboard.dashboardData.upcomingExpertCourses.data} />
                        </CardBox>
                    </div>
               </div>
    }

    renderAdminDashboard() {
        // let { users, saloons, courseDates } = this.props.dashboard.dashboardData.data
        let { users, courseDates } = this.props.dashboard.dashboardData.data
        return <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                        <ReportBox
                        styleName="bg-primary text-white p-3"
                        price={users ? users.total : 0}
                        icon="accounts"
                        detail="Użytkownicy"
                        subHeadingColor="text-white" >
                            <Link to="/app/users">
                                <Tooltip id="tooltip-icon" title="Użytkownicy" placement="top">
                                    <Fab color="white" className="jr-fab-btn" aria-label="add">
                                        <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg"/>
                            </Fab>
                                </Tooltip>
                            </Link>
                        </ReportBox>
                    </div>
                    {/* <div className="col-lg-3 col-sm-6 col-12">
                        <ReportBox
                        styleName="bg-cyan text-white p-3"
                        price={saloons ? saloons.total : 0}
                        icon="store-24"
                        detail="Salony"
                        subHeadingColor="text-white"
                        >
                            <Link to="/app/saloons">
                                <Tooltip id="tooltip-icon" title="Salony" placement="top">
                                    <Fab color="white" className="jr-fab-btn" aria-label="add">
                                        <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg"/>
                                    </Fab>
                                </Tooltip>
                            </Link>
                        </ReportBox>
                    </div> */}
                    <div className="col-lg-3 col-sm-6 col-12">
                        <ReportBox
                        styleName="bg-pink text-white p-3"
                        price={courseDates ? courseDates.upcoming : 0}
                        icon="local-library"
                        detail="Nadchodzące szkolenia"
                        subHeadingColor="text-white"
                        >
                            <Link to="/app/course-dates/calendar">
                                <Tooltip id="tooltip-icon" title="Nadchodzących szkoleń" placement="top">
                                    <Fab color="white" className="jr-fab-btn" aria-label="add">
                                        <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg"/>
                            </Fab>
                                </Tooltip>
                            </Link>
                        </ReportBox>
                    </div>
                </div>
    }


}
const mapStateToProps = (state) => {
	return {
        dashboard: state.dashboard,
        user: state.user,
        news: state.news
	}
}

export default connect(mapStateToProps)(Dashboard)
