import { FormControl, FormControlLabel, FormHelperText, Radio } from '@material-ui/core';
import React, { useMemo } from 'react'

const RadioComponent = ({
    formikProps,
    id,
    radioValue,
    onBlur = () => { },
    onChange = () => { },
    customHelperText,
    className,
    label,
    color
}) => {
    const value = useMemo(() => formikProps.values[id], [id, formikProps]);
    const error = useMemo(() => formikProps.errors[id], [id, formikProps]);
    const touched = useMemo(() => formikProps.touched[id], [id, formikProps]);
    const isError = () => {
        return error && Boolean(touched) ? true : false
    }
    const handleBlur = (event) => {
        onBlur(event);
        formikProps.handleBlur(event)
    }
    const handleChange = (event) => {
        onChange(event);
        formikProps.handleChange(event)
    }
    return (
        <FormControl
            error={customHelperText ? Boolean(customHelperText) : isError()}
            className={className}
        >
            <FormControlLabel
                control={
                    <Radio
                        color={color}
                        // eslint-disable-next-line
                        checked={value == radioValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={radioValue}
                        name={id}
                        // id={id}
                        inputProps={{ 'aria-label': radioValue }}
                    />}
                label={label}
            />
            {customHelperText
                ? <FormHelperText>{customHelperText}</FormHelperText>
                : isError()
                    ? <FormHelperText>{error}</FormHelperText>
                    : ''
            }

        </FormControl>
    )
}


export default React.memo(RadioComponent);