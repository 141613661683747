import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
import { Route, Switch} from 'react-router-dom';
// import asyncComponent from '../../../util/asyncComponent';
// import Button from '@material-ui/core/Button';
// import CardBox from 'components/CardBox';
import List from './routes/list'
import Details from './routes/details'
import { connect } from 'react-redux'
import * as CoursesActions from '../../../actions/Courses'
import {NotificationContainer, NotificationManager} from 'react-notifications';

class Courses extends React.Component {

	constructor() {
		super()
		this.state = {
			addMode: false,
			editMode: false,
			editItem: null
		}
	}

	componentWillMount() {
		this.props.dispatch(CoursesActions.getCoursesCategories())
		this.props.dispatch(CoursesActions.getCoursesLevels())
	}

	componentDidUpdate(prevProps) {

		if ( prevProps.files.systemError.length !== this.props.files.systemError.length ) {
            NotificationManager.warning(this.props.files.systemError[this.props.files.systemError.length - 1], "", 4000);   
		}

	}

	render() {
		let match = this.props.match
		return <div className="app-wrapper">
       				<NotificationContainer/>
			        <Switch>
			            <Route path={`${match.url}/`} exact component={List}/>
			            <Route path={`${match.url}/:slug`} component={Details}/>
					</Switch>
			    </div>
	}

	toggleAddMode(editMode = false) {
		this.setState({
			...this.state,
			addMode: !this.state.addMode,
			editMode
		})
	}

	addCourse(data) {
		if ( this.state.editMode ) {
			this.props.dispatch(CoursesActions.editCourse(data))
		} else {
			this.props.dispatch(CoursesActions.addCourse(data))
		}
	}

}
const mapStateToProps = (state) => {
	return {
		courses: state.courses,
		files: state.files
	}
}

export default connect(mapStateToProps)(Courses)
