import axios from 'axios';
import React, {  useCallback, useMemo, useState } from 'react';
import Select from 'react-select/async';
import settings from '../../../config/settings.js'
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  select: {
    marginTop: 24,
    position: 'relative',
    zIndex: 10,
  },
}));

const ProductAutocompleterComponent = ({formikProps, id}) => {
  const classes = useStyles();
  const value = useMemo(() => formikProps.values[id], [id, formikProps]);

  const loadOptions = (inputValue, callback = () => {}) => {
    axios.get(`${settings.api_endpoint}/mobile_product`, {
			params: {
        'filter[name]': inputValue
      }
		}).then((response) => {
      const data = response?.data?.data;
      const options = data?.length 
        ? data.map((product) => ({
          value: product.identifier,
          title: product.title
        }))
        : []
      callback(options)
    })
  };

  const handleChange = useCallback((newValue) => {
    formikProps.setFieldValue(id, newValue);
  }, [formikProps, id]);
  
  return (
      <Select
        className={classes.select}
        id={id}
        value={value}
        onChange={handleChange}
        getOptionLabel={(option) => option.title}
        isMulti
        loadOptions={loadOptions}
      />
  );
};

export default ProductAutocompleterComponent;

