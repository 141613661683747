import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles';
import { DateTimePicker } from 'material-ui-pickers';

const useStyles = makeStyles((theme) => ({
	section: {
		marginTop: 16,
		marginBottom: 8,
	}
}))

const DateTimePickerComponent = ({ label, minDate, maxDate, onChange = () => { }, formikProps, fullWidth, id, type = 'DD-MM-YYYY'}) => {
 const classes = useStyles();
	const value = useMemo(() => formikProps.values[id], [id, formikProps]);
	const error = useMemo(() => formikProps.errors[id], [id, formikProps]);
	const touched = useMemo(() => formikProps.touched[id], [id, formikProps]);
	const isError = useMemo(() => error && Boolean(touched) ? true : false, [error, touched])
	const handleChange = useCallback((event) => {
		formikProps.setFieldValue(id, event)
		onChange(event);
		// formikProps.handleChange(event)
	}, [onChange, formikProps, id])
	return (
		<section className={classes.section}>
			<DateTimePicker
				error={isError}
				fullWidth={fullWidth}
				hinttext="fr locale"
				locale='fr'
				label={label}
				
				helperText={isError ? error : ' '}
				minDate={minDate}
				maxDate={maxDate}
				value={value}
				showTabs={false}
				onChange={handleChange}
				leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
				rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
			/>
		</section>
	)
}

export default React.memo(DateTimePickerComponent);