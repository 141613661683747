import axios from 'axios'
import settings from '../config/settings.js'



export const getNotificationsApp = (params, url = settings.api_endpoint + "/mobile_notification") => {
    return {
        type: 'GET_NOTIFICATIONS_APP',
        payload: axios.get(url, {
            params: params
        })
    }
}

export const addNotificationsApp = (params, url = settings.api_endpoint + "/mobile_notification") => {
    return {
        type: 'ADD_NOTIFICATIONS_APP',
        payload: axios.post(url, params)
    }
}

export const updateNotificationsApp = (data, url = settings.api_endpoint + "/mobile_notification") => {
    return {
        type: 'UPDATE_NOTIFICATIONS_APP',
        payload: axios.post(`${url}/${data.id}`, data.params)
    }
}

export const sendPushNotificationApp = (id, url = settings.api_endpoint + "/mobile_notification/send") => {
    return {
        type: 'SEND_PUSH_NOTIFICATIONS_APP',
        payload: axios.get(`${url}/${id}`)
    }
}
export const deleteNotificationsApp = (id, url = settings.api_endpoint + "/mobile_notification") => {
    return {
        type: 'DELETE_NOTIFICATIONS_APP',
        payload: axios.delete(`${url}/${id}`)
    }
}
