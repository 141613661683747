import {  Tooltip, Fab } from '@material-ui/core';
import React from 'react'
import { STATUS_COMPLETED } from '../../../constants';
import Auth from '../../../util/Auth';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

const DeliveryNumbComponent = ({ onChangePage, status, handleShow, deliveryNumb, delivered }) => {
    const matchesXs = useMediaQuery('(max-width:600px)');
    return (
        <>
            <Tooltip id="tooltip-icon" title="Podgląd" placement="top">
                <Fab onClick={handleShow} className={`${matchesXs ? 'mb-1' : ''} jr-fab-btn bg-light-blue text-white jr-btn-fab-sm  mr-1`}>
                    <i className="zmdi zmdi-eye" />
                </Fab>
            </Tooltip>
            <Auth roles={['super_admin', 'admin']}>
                <Tooltip id="tooltip-icon" title="Numer przewozowy" placement="top">
                    <Fab
                        onClick={onChangePage}
                        disabled={status !== STATUS_COMPLETED}
                        className={`${matchesXs ? 'mb-1' : ''} ${status === STATUS_COMPLETED && !deliveryNumb && !delivered ? 'bg-danger' : ''} ${deliveryNumb && !delivered ? 'bg-amber' : ''} ${delivered ? 'bg-success' : ''} jr-fab-btn  text-white jr-btn-fab-sm mr-1`}
                    >
                        <i className={`zmdi  zmdi-truck`} />
                    </Fab>
                </Tooltip>
            </Auth>
        </>
        // <div className={classes.cont}>
        //     <IconButton onClick={onChangePage} disabled={status !== STATUS_COMPLETED} color='primary' >
        //         <i className={`zmdi zmdi-truck`} />
        //     </IconButton>
        // </div>
    )
}


export default DeliveryNumbComponent;

