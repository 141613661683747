import React from 'react';
import Table from '@material-ui/core/Table'; import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableHead from '@material-ui/core/TableHead'; import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap';
import TableLoaderComponent from '../../../../../components/Loaders/TableLoaderComponent';
import Widget from "../../../../../components/Widget";

function CoursesList({ list, loading }) {
    return <div className="table-responsive-material">
        <TableLoaderComponent loading={loading}>
            {list && list.length
                ? <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Termin szkolenia</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Miejsce</TableCell>
                            <TableCell>Wolnych miejsc</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((n, key) => {
                            let badgeColor = ''
                            let badgeText = ''
                            if (n.status === 'pending') {
                                badgeColor = 'primary'
                                badgeText = 'Nadchodzące'
                            }
                            if (n.status === 'done') {
                                badgeColor = 'success'
                                badgeText = 'Zakończone'
                            }
                            return (
                                <TableRow key={key}>
                                    <TableCell>{n.data}</TableCell>
                                    <TableCell>
                                        <Badge pill color={badgeColor}>{badgeText}</Badge>
                                    </TableCell>
                                    <TableCell>{n.placeName}</TableCell>
                                    <TableCell>{n.freeSpots} z ({n.capacity})</TableCell>
                                    <TableCell>
                                        <Link to={'/app/course-dates/details/' + n.identifier}>
                                            <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm mb-3">
                                                <i className="zmdi zmdi-eye" />
                                            </Fab>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                : <Widget styleName="jr-card-profile">
                    <div>Brak terminów tego szkolenia.</div>
                </Widget>
            }
        </TableLoaderComponent>
    </div>
}


export default CoursesList;
