import React from 'react';
import CoursesList from './CoursesList';
import CardBox from '../../../../../components/CardBox/index';
import AddCourse from '../../AddCourse'
import { connect } from 'react-redux'
import * as NewsActions from '../../../../../actions/News'
import { NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert'
import Auth from '../../../../../util/Auth'
import NewsGrid from './NewsGrid'

class Courses extends React.Component {

    constructor() {
        super()
        this.state = {
            addMode: false,
            editMode: false,
            editItem: null,
            deleteWarning: false,
            selectedDeletionSlug: '',
            editSlug: ''
        }

    }

    componentDidMount() {
        // this.props.dispatch(CoursesActions.getCoursesList({
        //     per_page: 30
        // }))
        this.props.dispatch(NewsActions.getNews())
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.news.addForm.added && this.props.news.addForm.added) {
            NotificationManager.success("Pomyślnie dodano KEUNE Newsa!", "", 4000);
            this.toggleAddMode()
            this.props.dispatch(NewsActions.getNews())
        }
        if (!prevProps.news.addForm.edited && this.props.news.addForm.edited) {
            NotificationManager.success("Pomyślnie edytowano KEUNE Newsa!", "", 4000);
            this.toggleAddMode()
            this.props.dispatch(NewsActions.getNews())
        }
    }

    render() {
        return (<div className="animated slideInUpTiny animation-duration-3">
            <Auth roles={['super_admin', 'admin']}>
                <AddCourse
                    coursesList={this.props.courses.list}
                    editMode={this.state.editMode}
                    levels={this.props.courses.levels}
                    categories={this.props.courses.categories}
                    addForm={this.props.news.addForm}
                    onSubmit={this.addNews.bind(this)}
                    loaded={this.props.news.newsDetails.loaded}
                    editCourse={this.editCourse.bind(this)}
                    open={this.state.addMode}
                    editSlug={this.state.editSlug}
                    togglePopup={this.toggleAddMode.bind(this)}
                    newsDetails={this.props.news.newsDetails.data} />
            </Auth>
            {/* <div className="row mb-md-4">
                        <div className="col-lg-6">
                            <div className="search-bar right-side-icon bg-transparent d-none d-sm-block">
                                <div className="form-group">
                                    <input className="form-control border-0" type="search" placeholder="Wyszukaj szkolenia..." />
                                    <button className="search-icon"><i className="zmdi zmdi-search zmdi-hc-lg"/></button>
                                </div>
                            </div>
                        </div>
                    </div> */}
            <Auth roles={['super_admin', 'admin']}>
                <div className="row mb-md-4">
                    <CardBox styleName="col-12" cardStyle="p-0" heading="Zarządzanie KEUNE News!"
                        headerOutside addClick={this.toggleAddMode.bind(this, false)} addBtn='zmdi-plus ' >
                        <CoursesList
                            toggleAddMode={this.toggleAddMode.bind(this)}
                            onDelete={this.onDelete.bind(this)}
                            onPageChange={this.getUsersPage.bind(this)}
                            meta={this.props.news.news.meta}
                            data={this.props.news.news.list}
                            loading={this.props.news.loading}
                            nextPageLoading={this.props.news.nextPageLoading}
                        />
                    </CardBox>
                </div>
            </Auth>
            <Auth roles={['ph', 'training_staff', 'pos_owner']}>
                <NewsGrid list={this.props.news.news.list} />
            </Auth>
            <Auth roles={['super_admin', 'admin']}>
                <SweetAlert show={this.state.deleteWarning}
                    warning
                    showCancel
                    confirmBtnText="Tak, usuń"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    cancelBtnText="Anuluj"
                    title="Jesteś pewien?"
                    onConfirm={this.onConfirmDelete.bind(this)}
                    onCancel={this.onCancelDelete.bind(this)} >
                    Wybrany KEUNE News zostanie bezpowrotnie usunięty!
                </SweetAlert>
            </Auth>
        </div>);
    }

    getUsersPage(url) {
        this.props.dispatch(NewsActions.getNews({
            per_page: 15
        }, url))
    }

    toggleAddMode(editMode = false, id) {
        this.props.dispatch(NewsActions.getEmptyNewsDetails())
        if (editMode) {
            this.props.dispatch(NewsActions.getNewsDetails(id))
        }
        this.setState({
            ...this.state,
            addMode: !this.state.addMode,
            editMode: editMode,
            editSlug: id
        })
    }

    // toggleAddMode() {
    //     this.setState({
    //         ...this.state,
    //         addMode: !this.state.addMode
    //     })
    // }   

    onConfirmDelete() {
        this.props.dispatch(NewsActions.deleteNews(this.state.selectedDeletionSlug))
        this.setState({
            ...this.state,
            deleteWarning: false
        })
    }

    onCancelDelete() {
        this.setState({
            ...this.state,
            deleteWarning: false,
            selectedDeletionSlug: ''
        })
    }

    onDelete(slug) {
        this.setState({
            ...this.state,
            deleteWarning: !this.state.deleteWarning,
            selectedDeletionSlug: slug
        })
    }

    addNews(data) {
        this.props.dispatch(NewsActions.addNews(data))
    }

    editCourse(slug, data) {
        this.props.dispatch(NewsActions.editNews(slug, data))
    }
}


const mapStateToProps = state => ({
    courses: state.courses,
    news: state.news
})


export default connect(mapStateToProps)(Courses)
