import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert'

const RemoveDialogComponent = ({
  name, 
  show, 
  onDelete = () => {},
  onClose = () => {},
}) => {
  
  return (
    <SweetAlert show={show}
    warning
    showCancel
    confirmBtnText="Tak, usuń"
    confirmBtnBsStyle="danger"
    cancelBtnBsStyle="default"
    cancelBtnText="Anuluj"
    title="Jesteś pewien?"
    onConfirm={onDelete}
    onCancel={onClose} >
    {name} zostanie bezpowrotnie usunięty!
  </SweetAlert>
  );
};

export default React.memo(RemoveDialogComponent);
