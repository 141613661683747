import React from 'react';
import NewsBoxItem from './NewsBoxItem'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';

const NewsBox = ({list}) => {
  
    return <div className="col-lg-12 col-sm-12 col-12">
             <div className="jr-card">
                <div className="jr-card-header d-flex align-items-center">
                    <div className="mr-auto">
                    <h3 className="card-heading">KEUNE News!</h3>
                    <p className="sub-heading">Bądź na bieżąco z newsami od administracji KEUNE.</p>
                    </div>
                </div>
                <div className="jr-card-body">
                    <div className="row row-eq-height">
                        {list.map((item, key) =>
                        <NewsBoxItem key={key} data={item}/>
                        )}
                    </div>
                </div>
                <Link to="/app/keune-news">
                    <Button variant="contained" color="primary" className="">Zobacz wszystkie</Button>
                </Link>
                </div>
            </div>

}

export default NewsBox;



