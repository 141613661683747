import React, { useCallback, useMemo, useState } from 'react';
import EditComponent from '../../../components/Form/EditComponent';
import DropComponent from '../../../components/Form/DropComponent';
import { makeStyles } from "@material-ui/styles";
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 12,
    paddingBottom: 24,
  },
  line: {
    marginBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    border: '1px solid #e0e0e0',
  },
}));

const InspirationRowFormComponent = ({formik, withIndex, index, showLine}) => {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);
  const values = useMemo(() => formik.values, [formik]);
  const {descValue, imageValue} = useMemo(() => {
    if(withIndex){
      return {
        descValue: values.rows[index].desc,
        imageValue: values.rows[index].img
      }
    }
    return {
      descValue: values.description,
      imageValue: values.img
    }
  }, [values, withIndex, index])
  const descId = useMemo(() => withIndex ? `rows[${index}].desc` : 'description', [withIndex, index]);

  const handleUpdateImage = useCallback((newImg) => {
    const id = withIndex ? `rows[${index}].img` : 'img';
    formik.setFieldValue(id, newImg);
  }, [formik, index, withIndex]);
 
  const handleRemove = useCallback(() => {
    formik.setFieldValue('rows', values.rows.filter((_, i) => i !== index));
  }, [formik, index]);
  return (
    <div className={`${classes.container} ${showLine ? classes.line : ''}`}>
			<div>
				<EditComponent
					value={descValue}
					formikProps={formik}
					id={descId}
				/>
			</div>
      <div>
				<DropComponent
					showError={showError}
					setShowError={setShowError}
					img={imageValue}
					setImg={handleUpdateImage}
				/>
			</div>
      {showLine && <Button onClick={handleRemove} color="secondary">
        Usuń sekcje
      </Button>}
    </div>
  );
};

export default React.memo(InspirationRowFormComponent);
