import { makeStyles } from '@material-ui/styles'
import React from 'react'
import DataCard from '../../app/routes/statistics/DataCard'

const useStyles = makeStyles((theme) => ({
    cardCont: {
        '& .jr-fillchart-content': {
            position: 'relative',
        },
        '& .jr-fillchart': {
            minHeight: 'auto',
        }
    }

}))

const OrderDataCardComponent = ({ hideDesc, orders, title, color }) => {
    const classes = useStyles();
    return (
        <div className={`col-md-4 p-1 ${classes.cardCont}`}>
            <DataCard title={title}
                color={color}
                data={`${orders && orders.money ? orders.money : 0} zł`}
                desc={
                    hideDesc
                        ? null
                        : <span>Całkowita ilość zamówień: {orders && orders.quantity ? orders.quantity : 0} <br /> Całkowita kwota: {orders && orders.total ? orders.total : 0} zł </span>
                }
            />
        </div>
    )
}


export default OrderDataCardComponent;