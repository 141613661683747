import React from "react";
// import {Area, AreaChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";
// import UserImages from "./UserImages";
// import RecentActivity from "../CRM/RecentActivity";
// import CurrentPlan from "./CurrentPlan";
// import DealsClosedCard from "./DealsClosedCard";
// import PropertiesCard from "./PropertiesCard";
// import Widget from "components/Widget/index";
// import ContainerHeader from "components/ContainerHeader/index";
// import IntlMessages from "util/IntlMessages";

// import {increamentData} from "./mdata";
// import {recentActivity} from "../CRM/data";

export default class Dashboard extends React.Component {



    render() {
     
        let { title, data, color, desc } = this.props
        return <ChartCard data={{
            title: title,
            prize: data,
            icon: 'stats',
            bgColor: color,
            styleName: 'up',
            desc: desc,
            // percent: '03%',
        }}
        />
    }

}

// const Listing = ({match, data, title, desc, color}) => {
  
// }

// export default Listing;

// const Somsiad = (props) => {
//     return (
//         <div>
//             <div>Ile somsiad zajebal kasy</div>
//             <div>W ciągu ostatnich 30 dni</div>
//             <span>{props.data}</span>
//         </div>
//     )
// }

// export default Somsiad;
