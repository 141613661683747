import React from 'react';
import Table from '@material-ui/core/Table'; import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableHead from '@material-ui/core/TableHead'; import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip';
import Auth from '../../../../../util/Auth'
import Condition from '../../../../../util/Condition'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardBox from '../../../../../components/CardBox';
import ReactSelect from 'react-select'
import { Fab } from '@material-ui/core';


export default class SaloonsList extends React.Component {
    constructor(props) {
        super()
    }

    render() {

        return (
            <div>
                <Condition condition={this.props.list.length > 0}>
                    <Auth roles={['super_admin', 'admin']}>
                        <CardBox styleName="col-12" cardStyle="">
                            <div className="row">
                                <div className="mt-2">
                                    Zaznaczone salony: {this.props.selectedListItems.length}
                                </div>
                                {this.props.listSelectMode
                                    ?
                                    <div className="col-4 row">
                                        <ReactSelect
                                            className="col-10"
                                            options={this.props.agentsList}
                                            onChange={this.props.onSelectChange}
                                            value={this.props.selectedAgent}
                                            placeholder="Wyszukaj przedstawiciela" />
                                        <div className="mt-1">
                                            <Tooltip onClick={this.props.onSave} id="tooltip-icon" title="Zapisz" placement="top">
                                                <Fab color="primary" className="bg-green text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                                                    <i className="zmdi zmdi-save zmdi-hc-fw zmdi-hc-lg" />
                                                </Fab>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    :
                                    null}
                            </div>
                        </CardBox>
                    </Auth>
                </Condition>
                <div className="table-responsive-material">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <Condition condition={this.props.list.length > 0}>
                                    <Auth roles={['super_admin', 'admin']}>
                                        <TableCell>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color="primary"
                                                        checked={this.props.checkAll}
                                                        value={true}
                                                        onChange={this.props.onCheckAll}
                                                        name='check_all'
                                                    />
                                                }
                                                label="" />
                                        </TableCell>
                                    </Auth>
                                </Condition>
                                <TableCell>Właściciel salonu</TableCell>
                                <TableCell>Wojewodztwo</TableCell>
                                <TableCell>Adres</TableCell>
                                <Auth roles={['super_admin', 'admin']}>
                                    <TableCell>Akcje</TableCell>
                                </Auth>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.list.map(n => {
                                return (
                                    <TableRow key={n.id}>
                                        <Auth roles={['super_admin', 'admin']}>
                                            <TableCell>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox color="primary"
                                                            checked={this.props.selectedListItems.indexOf(n.identifier) > -1 ? true : false}
                                                            onChange={this.onCheck.bind(this, n.identifier)}
                                                            value={true}
                                                            name='test'
                                                        />
                                                    }
                                                    label="" />
                                            </TableCell>
                                        </Auth>
                                        <TableCell>
                                            <Link to={"/app/saloons/" + n.identifier} className="keune-link">
                                                {n.ownerName}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{n.regionName ? n.regionName.name : ''}</TableCell>
                                        <TableCell>{n.nameAddress}</TableCell>
                                        <Auth roles={['super_admin', 'admin']}>
                                            <TableCell>
                                                <Link to={"/app/saloons/" + n.identifier}>
                                                    <Tooltip id="tooltip-icon" title="Szczegóły salonu" placement="top">
                                                        <Fab onClick={this.onDelete.bind(this, n.identifier)} className="jr-fab-btn bg-cyan text-white jr-btn-fab-sm">
                                                            <i className="zmdi zmdi-eye" />
                                                        </Fab>
                                                    </Tooltip>
                                                </Link>
                                            </TableCell>
                                        </Auth>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </div>
        )
    }

    onCheck(item) {

        this.props.onCheck(item)

    }

    onDelete(id) {
        this.props.onDelete(id)
    }

    renderAcceptBtn(n) {
        return <Tooltip id="tooltip-icon" title="Zaakceptuj zapis" placement="top">
            <Fab onClick={this.changeSignupStatus.bind(this, n, 'accepted')} className="jr-fab-btn bg-success text-white jr-btn-fab-sm mb-3">
                <i className="zmdi zmdi-badge-check" />
            </Fab>
        </Tooltip>
    }

    renderCancelBtn(n) {
        return <Tooltip id="tooltip-icon" title="Anuluj zapis" placement="top">
            <Fab onClick={this.changeSignupStatus.bind(this, n, 'cancelled')} className="jr-fab-btn bg-warning text-white jr-btn-fab-sm mb-3">
                <i className="zmdi zmdi-stop" />
            </Fab>
        </Tooltip>
    }

    changeSignupStatus(item, status) {
        this.props.changeSignupStatus(item.courseDate.identifier, item.identifier, {
            statusName: status
        })
    }

}
