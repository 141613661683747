import React from 'react';
// import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import moment from 'moment'

class EventBox extends React.Component {

  constructor(props) {
    super()
  }

  render() {
    let statusClass = ''
    let today = moment()
    let eventStart = moment(this.props.event.dateStart)
    if ( eventStart.isBefore(today) ) {
      statusClass = 'in-past'
    }
    return (
      <div className={"event-box " + statusClass} style={{backgroundColor: this.props.event.colorHEX}}>
        <Link to={"/app/course-dates/details/"+this.props.event.identifier} className="test" title={this.props.event.title+", "+this.props.event.freeSpots+" wolnych z "+this.props.event.capacity}>
          <div className="row">
            <div className="col-md-8">
              <h6 className="text-white" style={{marginBottom: 0}}>{this.props.event.title}, <span style={{fontSize: '10px'}}>{this.props.event.placeName}</span> ({this.props.event.freeSpots})</h6>
            </div>
          </div>
        </Link>
      </div>
    )
  }

}

export default EventBox;