import React from 'react';
import TextField from '@material-ui/core/TextField';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import { connect } from 'react-redux'
// import * as CoursesActions from '../../../actions/Courses'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Editor from './editor'
import Dragndrop from './dragndrop'
import { CirclePicker } from 'react-color'
import { Checkbox, FormControlLabel } from '@material-ui/core';
import DialogComponent from '../../../components/Dialog/DialogComponent';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';

export default class AddCourse extends React.Component {

    constructor(props) {
        super()
        this.state = {
            selectedCourseTypeRadio: null,
            course: {
                title: '',
                lead: '',
                description: '',
                toolboxList: '',
                levelID: '',
                categoryID: '',
                groupID: '',
                coursePrice: null,
                image: null,
                thumbnail: null,
                light: 1,
                publish: 0,
                colorHEX: '#f44336'
            },
            errors: {
                title: '',
                lead: '',
                description: '',
                levelID: '',
                categoryID: '',
                colorHEX: ''
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loaded !== this.props.loaded && this.props.editMode) {
            let { title, coursePrice, publish, lead, description, toolboxList, level, category, thumbnail, image, group, colorHEX } = this.props.courseDetails
            this.setState({
                ...this.state,
                course: {
                    ...this.state.course,
                    title: title,
                    lead: lead,
                    publish: publish,
                    description: description,
                    toolboxList: toolboxList,
                    levelID: level?.id,
                    groupID: group ? group.id : null,
                    categoryID: category?.id,
                    image: image,
                    coursePrice: coursePrice,
                    thumbnail: thumbnail,
                    colorHEX: colorHEX
                }
            })
        }
    }


    render() {
        // let { title, lead, description, toolboxList, password, coursePrice, groupID, levelID, image, thumbnail, colorHEX } = this.props.addCourseForm.errors
        let { lead, description, toolboxList, groupID, } = this.props.addCourseForm.errors
        let titleError = this.props.addCourseForm.errors.title ? true : false
        let leadError = this.props.addCourseForm.errors.lead ? true : false
        let groupIDError = this.props.addCourseForm.errors.groupID ? true : false
        let descriptionError = this.props.addCourseForm.errors.description ? true : false
        let toolboxListError = this.props.addCourseForm.errors.toolboxList ? true : false
        let categoryIDError = this.props.addCourseForm.errors.categoryID ? true : false
        let levelIDError = this.props.addCourseForm.errors.levelID ? true : false
        let coursePriceError = this.props.addCourseForm.errors.coursePrice ? true : false
        let imageError = this.props.addCourseForm.errors.image ? true : false
        let thumbnailError = this.props.addCourseForm.errors.thumbnail ? true : false
        let colorHEXError = this.props.addCourseForm.errors.colorHEX ? true : false
        // let firstLevelCourses = this.props.coursesList.filter((item, key) => {
        //     return item.level.value == 1
        // })
        let firstLevelCourses = [{
            id: 1,
            title: 'Koloryzacja'
        }, {
            id: 2,
            title: 'Stylizacja'
        }, {
            id: 3,
            title: 'Strzyżenia'
        }, {
            id: 4,
            title: 'Dodatkowe'
        }, {
            id: 5,
            title: 'Poboczne'
        }]

        return <form onSubmit={this.onSubmit.bind(this)}>
            <DialogComponent
                open={this.props.open}
                btnSave={this.props.editMode ? 'Zapisz' : 'Dodaj'}
                handleClose={this.closePopup.bind(this)}
                loading={!this.props.loaded}
                handleSave={this.saveForm.bind(this)}
                title={this.props.editMode ? 'Edytuj szkolenie' : 'Dodaj szkolenie'}
            >
                        <div className="col-md-6 mb-3">
                            <TextField
                                error={titleError}
                                autoFocus
                                onChange={this.onChange.bind(this)}
                                margin="dense"
                                id="title"
                                label={this.props.addCourseForm.errors.title ? this.props.addCourseForm.errors.title : 'Tytuł'}
                                type="text"
                                value={this.state.course.title}
                                name="title"
                                fullWidth
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextField
                                error={leadError}
                                onChange={this.onChange.bind(this)}
                                margin="dense"
                                id="lead"
                                label={lead ? lead : 'Lead'}
                                type="text"
                                value={this.state.course.lead}
                                name="lead"
                                fullWidth
                            />
                        </div>
                        <div className="col-md-12 mb-3">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(this.state.course.publish)}
                                        onChange={this.onPublishChange.bind(this)}
                                        value={true}
                                        name='publish'
                                        color="primary"
                                    />
                                }

                                label="Publikuj"
                            />
                        </div>
                        <div className="col-md-12">
                            <InputLabel shrink error={descriptionError}>{descriptionError ? description : 'Opis szkolenia'}</InputLabel>
                            <Editor onEditorChange={this.onEditorChange.bind(this)}
                                name="description"
                                initialValue={this.state.course.description}
                                value={this.state.course.description}
                                onChange={this.onChange.bind(this)} />
                        </div>
                        <div className="col-md-12">
                            <InputLabel shrink error={toolboxListError}>{toolboxListError ? toolboxList : 'Narzędziownik'}</InputLabel>
                            <Editor onEditorChange={this.onEditorChange.bind(this)}
                                name="toolboxList"
                                initialValue={this.state.course.toolboxList}
                                value={this.state.course.toolboxList}
                                onChange={this.onChange.bind(this)} />
                        </div>
                        {/* <div className="col-md-12 mb-3">
                                        <FormControl component="fieldset" required>
                                            <FormLabel component="legend">Określ poziom szkolenia</FormLabel>
                                            <RadioGroup
                                            className="d-flex flex-row"
                                            aria-label="placeName"
                                            name="placeName"
                                            value={this.state.selectedCourseTypeRadio}
                                            onChange={this.onChangeSelectedCourseType.bind(this)} >
                                                <FormControlLabel value={"0"} control={<Radio color="primary"/>} label="Poziom 1"/>
                                                <FormControlLabel value={"1"} control={<Radio color="primary"/>} label="Kontynuacja innego szkolenia"/>
                                            </RadioGroup>
                                        </FormControl>
                                    </div> */}
                        <div className="col-md-4 mb-3">
                            <FormControl className="w-100">
                                <InputLabel shrink htmlFor="groupID" error={groupIDError}>{groupIDError ? groupID : 'Wybierz grupę'}</InputLabel>
                                <Select
                                    error={groupIDError}
                                    name="groupID"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.course.groupID}
                                    input={<Input id="groupID" />} >
                                    <MenuItem value="">
                                        <em>Wybierz grupę...</em>
                                    </MenuItem>
                                    {firstLevelCourses.map((item, key) => {
                                        return <MenuItem key={key} value={item.id}>{item.title}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        {/* {this.state.selectedCourseTypeRadio == "1" ? <div className="col-md-6 mb-3">
                                        <FormControl className="w-100">
                                            <InputLabel htmlFor="groupID">Wybierz szkolenie</InputLabel>
                                            <Select
                                                error={groupIDError}
                                                name="groupID"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.course.groupID}
                                                input={<Input id="groupID"/>} >
                                                <MenuItem value="">
                                                    <em>Wybierz szkolenie...</em>
                                                </MenuItem>
                                                {firstLevelCourses.map((item, key) => {
                                                    return <MenuItem key={key} value={item.identifier}>{item.title}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div> : null} */}
                        <div className="col-md-4 mb-3">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="levelID" shrink error={levelIDError}>{levelIDError ? this.props.addCourseForm.errors.levelID : 'Wybierz poziom'}</InputLabel>
                                <Select
                                    error={levelIDError}
                                    name="levelID"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.course.levelID}
                                    input={<Input id="levelID" />} >
                                    <MenuItem value="">
                                        <em>Wybierz Poziom...</em>
                                    </MenuItem>
                                    {this.props.levels.map((item, key) => {
                                        return <MenuItem key={key} value={item.identifier}>{item.title}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        {/* {this.state.selectedCourseTypeRadio == "1" ? <div className="col-md-6 mb-3">
                                        <FormControl className="w-100">
                                            <InputLabel htmlFor="levelID">{levelIDError ? this.props.addCourseForm.errors.levelID : 'Wybierz poziom'}</InputLabel>
                                            <Select
                                                error={levelIDError}
                                                name="levelID"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.course.levelID}
                                                input={<Input id="levelID"/>} >
                                                <MenuItem value="">
                                                    <em>Wybierz Poziom...</em>
                                                </MenuItem>
                                                {this.props.levels.map((item, key) => {
                                                    return <MenuItem key={key} value={item.identifier}>{item.title}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div> : null} */}
                        <div className="col-md-4 mb-3">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="age-simple" shrink error={categoryIDError}>{categoryIDError ? this.props.addCourseForm.errors.categoryID : 'Kategoria'}</InputLabel>
                                <Select
                                    error={categoryIDError}
                                    name="categoryID"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.course.categoryID}
                                    input={<Input id="age-simple" />} >
                                    <MenuItem value="">
                                        <em>Wybierz kategorię...</em>
                                    </MenuItem>
                                    {this.props.categories.map((item, key) => {
                                        return <MenuItem key={key} value={item.identifier}>{item.title}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 mb-3">
                            {/* <h2>Miniatura</h2> */}
                            <InputLabel className="mb-3" htmlFor="thumbnail" error={thumbnailError}>{thumbnailError ? this.props.addCourseForm.errors.thumbnail : 'Miniatura'}</InputLabel>
                            <Dragndrop existingImage={this.state.course.thumbnail}
                                name="thumbnail"
                                onFileChange={this.onFileChange.bind(this)} />
                        </div>
                        <div className="col-md-6 mb-3">
                            {/* <h2>Banner</h2> */}
                            <InputLabel className="mb-3" htmlFor="image" error={imageError}>{imageError ? this.props.addCourseForm.errors.image : 'Banner'}</InputLabel>
                            <Dragndrop existingImage={this.state.course.image}
                                name="image"
                                onFileChange={this.onFileChange.bind(this)} />
                        </div>
                        <div className="col-md-6 mb-3">
                            {/* <h2>Kolor przewodni szkolenia</h2> */}
                            <InputLabel className="mb-3" htmlFor="colorHEX" error={colorHEXError}>{colorHEXError ? this.props.addCourseForm.errors.colorHEX : 'Kolor przewodni szkolenia'}</InputLabel>
                            <CirclePicker color={this.state.course.colorHEX} onChange={this.onColorChange.bind(this)} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextField
                                error={coursePriceError}
                                autoFocus
                                onChange={this.onChange.bind(this)}
                                margin="dense"
                                id="coursePrice"
                                label={this.props.addCourseForm.errors.coursePrice ? this.props.addCourseForm.errors.coursePrice : 'Cena szkolenia'}
                                type="number"
                                value={this.state.course.coursePrice}
                                name="coursePrice"
                                fullWidth
                            />
                        </div>

            </DialogComponent>
        </form>
    }

    clearForm() {
        this.setState({
            ...this.state,
            selectedCourseTypeRadio: null,
            course: {
                title: '',
                lead: '',
                description: '',
                toolboxList: '',
                levelID: '',
                categoryID: '',
                groupID: '',
                image: null,
                thumbnail: null,
                light: 1,
                publish: 0,
                colorHEX: '#f44336'
            },
            errors: {
                title: '',
                lead: '',
                description: '',
                levelID: '',
                categoryID: '',
                colorHEX: ''
            }
        })
    }

    closePopup() {
        this.clearForm()
        this.props.togglePopup()
    }

    onChangeSelectedCourseType(e, value) {
        if (value === "0") {
            this.setState({
                ...this.state,
                selectedCourseTypeRadio: value,
                course: {
                    ...this.state.course,
                    groupID: '',
                    levelID: 1
                }
            })
        } else {
            this.setState({
                ...this.state,
                selectedCourseTypeRadio: value,
                course: {
                    ...this.state.course,
                    groupID: value,
                    levelID: ''
                }
            })
        }
    }

    onFileChange(name, value) {
        let course = { ...this.state.course }
        course[name] = value[0]
        this.setState({
            ...this.state,
            course: course
        })
    }

    onEditorChange(name, value) {
        let course = { ...this.state.course }
        course[name] = value
        this.setState({
            ...this.state,
            course: course
        })
    }

    onColorChange(e, e2) {
        this.setState({
            ...this.state,
            course: {
                ...this.state.course,
                colorHEX: e.hex
            }
        })
    }

    onChange(e) {
        let course = { ...this.state.course }
        course[e.target.name] = e.target.value
        this.setState({
            ...this.state,
            course: course
        })
    }
    onPublishChange(e) {
        let course = { ...this.state.course }
        course.publish = !course.publish
        this.setState({
            ...this.state,
            course: course
        })
    }

    onSubmit(e) {
        // let data = new FormData(e.target)
    }

    saveForm() {
        let slug = this.props.editSlug
        if (this.props.editMode) {
            this.props.editCourse(slug, { ...this.state.course, publish: this.state.course.publish ? 1 : 0})
        } else {
            this.props.addCourse({ ...this.state.course, publish: this.state.course.publish ? 1 : 0 })
        }
    }

}
