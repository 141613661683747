import React from 'react';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { connect } from 'react-redux'
import {findDOMNode} from 'react-dom';
// import * as UserActions from '../../../actions/User'
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import moment from 'moment'
import ReactSelect from 'react-select'
import Popover from '@material-ui/core/Popover';
import voids from '../../../util/Voids'
import CardBox from '../../../components/CardBox/index';
import Tooltip from '@material-ui/core/Tooltip';


export default class AddUser extends React.Component {

    constructor(props) {
        super()
        this.state = {
            create_pos: false,
            assignPosMode: false,
            user: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                roleID: '',
                birthdayDate: '',
                pos_region: '',
                pos_agent: '',
                assign_pos: ''
            },
            errors: {
                role: '',
                firstName: '',
                lastname: '',
                email: '',
                password: '',
                phoneNumber: '',
                roleID: ''
            },
            selectedVoid: null,
            selectedAgent: null,
            selectedPos: null,
            openPopover: false,
            anchorEl: null
        }
        this.button = null
    }

  

    componentDidUpdate(prevProps) {
        if ( prevProps.open && !this.props.open ) {
            this.clearForm()
        }
        if ( !prevProps.editMode && this.props.editMode ) {
            let user = this.props.data[this.props.editedUserKey]
            this.setState({
                ...this.state,
                user: {
                    ...this.state.user,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    roleID: user.roles.id,
                    phoneNumber: user.phoneNumber,
                    birthdayDate: user.birthdayDate ? moment(user.birthdayDate, 'DD.MM.YYYY').format('YYYY-MM-DD') : ''
                }
            }, () => {

            })
        }
    }

    createEditData() {
        let user = this.state.user
        // let originalUser = this.props.data[this.props.editedUserKey]
        return {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            roleID: user.roleID,
            phoneNumber: user.phoneNumber,
            birthdayDate: user.birthdayDate ? moment(user.birthdayDate, "YYYY-MM-DD").format("DD.MM.YYYY") : '',
            create_pos: this.state.create_pos,
            pos_region: this.state.user.pos_region,
            pos_agent: this.state.user.pos_agent,
            assign_pos: this.state.user.assign_pos
        }
    }

    render() {
        let {  lastName, email, phoneNumber, birthdayDate, roleID } = this.props.addUserForm.errors
        let firstNameError = this.props.addUserForm.errors.firstName ? true : false
        let lastNameError = this.props.addUserForm.errors.lastName ? true : false
        let emailError = this.props.addUserForm.errors.email ? true : false
        let phoneNumberError = this.props.addUserForm.errors.phoneNumber ? true : false
        let birthdayDateError = this.props.addUserForm.errors.birthdayDate ? true : false
        let roleIDError = this.props.addUserForm.errors.roleID ? true : false
        
        let preparedAgentsList = this.props.agents.list.map((item, key) => {
            return {
              label: item.firstName + ' ' + item.lastName,
              value: item.identifier
            }
          })

          let preparedPosList = this.props.posList.map((item, key) => {
            return {
                label: item.ownerName,
              value: item.identifier
            }
          })
          return (
                    <form onSubmit={this.onSubmit.bind(this)}>
                    <Dialog open={this.props.open} onClose={this.handleRequestClose}>
                        <DialogTitle>{this.props.editMode ? 'Edytuj użytkownika' : 'Dodaj użytkownika'}</DialogTitle>
                        <DialogContent>
                            <div className="container">
                                <div className="row">
                                    {this.state.user.firstName.length > 0 || this.state.user.lastName.length > 0
                                    ? <div className="col-md-12">
                                        <Tooltip id="tooltip-icon" title="Sprawdź czy istnieje taki użytkownik" placement="top">
                                              <Fab ref={node => { this.button = node; }} onClick={this.checkUsers.bind(this)} className="jr-fab-btn bg-cyan text-white jr-btn-fab-sm">
                                                <i className="zmdi zmdi-spellcheck" />
                                              </Fab>
                                        </Tooltip>
                                        {this.props.existingUsers.loaded && this.props.existingUsers.list.length === 0
                                        ? <span className="ml-3">Nie ma takiego użytkownika.</span>
                                        : null}
                                        <Popover
                                                open={this.state.openPopover && this.props.existingUsers.list.length > 0}
                                                anchorEl={this.state.anchorEl}
                                                anchorReference={'anchorEl'}
                                                anchorPosition={{top: 0, left: 0}}
                                                // onClose={this.handleRequestClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                >
                                                <CardBox>
                                                    <div>
                                                        {this.props.existingUsers.list.length > 0 
                                                        ? <div className="similar-users">
                                                            <Button onClick={this.disablePopover.bind(this)} className="pull-right bg-primary text-white">
                                                                    Zamknij
                                                                </Button>
                                                            <div className="similar-users__heading">
                                                                Podobni użytkownicy:
                                                            </div>
                                                            <div className="similar-users__list">
                                                                {this.props.existingUsers.list.map((item, key) => {
                                                                    return <div className="similar-users__item">
                                                                        <a href={"/app/users/" + item.identifier} target="_blank" rel="noopener noreferrer" >
                                                                                    {item.firstName+' '+item.lastName}
                                                                                </a>
                                                                            </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                        : null}
                                                        
                                                    </div>
                                                </CardBox>
                                            </Popover>
                                    </div>
                                    : null}
                                    <div className="col-md-6">
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={firstNameError}
                                            autoFocus
                                            value={this.state.user.firstName}
                                            onChange={this.onChange.bind(this)}
                                            margin="dense"
                                            id="firstName"
                                            label={this.props.addUserForm.errors.firstName ? this.props.addUserForm.errors.firstName : 'Imię'}
                                            type="text"
                                            name="firstName"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={lastNameError}
                                            onChange={this.onChange.bind(this)}
                                            value={this.state.user.lastName}
                                            margin="dense"
                                            id="lastName"
                                            label={lastName ? lastName : 'Nazwisko'}
                                            type="text"
                                            name="lastName"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={birthdayDateError}
                                            value={this.state.user.birthdayDate}
                                            // value={new Date()}
                                            onChange={this.onChange.bind(this)}
                                            margin="dense"
                                            id="birthdayDate"
                                            label={birthdayDate ? birthdayDate : 'Data urodzenia'}
                                            type="date"
                                            name="birthdayDate"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={emailError}
                                            value={this.state.user.email}
                                            onChange={this.onChange.bind(this)}
                                            margin="dense"
                                            id="name"
                                            label={email ? email : 'Adres e-mail'}
                                            type="email"
                                            name="email"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={this.onChange.bind(this)}
                                            value={this.state.user.phoneNumber}
                                            error={phoneNumberError}
                                            margin="dense"
                                            id="phoneNumber"
                                            label={phoneNumber ? phoneNumber : 'Numer telefonu'}
                                            type="text"
                                            name="phoneNumber"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <FormControl className="w-100">
                                            <InputLabel error={roleIDError} htmlFor="age-simple">{roleIDError ? roleID : 'Rola użytkownika'}</InputLabel>
                                            <Select
                                                name="roleID"
                                                error={roleIDError}
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.user.roleID}
                                                input={<Input id="roleID"/>} >
                                                <MenuItem value="">
                                                    <em>Wybierz rolę...</em>
                                                </MenuItem>
                                                <MenuItem value={2}>Administrator</MenuItem>
                                                <MenuItem value={3}>Przedstawiciel handlowy</MenuItem>
                                                <MenuItem value={4}>Właściciel salonu</MenuItem>
                                                <MenuItem value={5}>Pracownik salonu</MenuItem>
                                                <MenuItem value={6}>Kadra szkoleniowa</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {this.state.user.roleID === 4 && !this.props.editMode ? <div className="col-md-6">
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary"
                                                        checked={this.state.create_pos}
                                                        onChange={this.onCreatePosChange.bind(this)}
                                                        value={this.state.create_pos}
                                                        name='create_pos'
                                                />
                                            }
                                            label="Dodaj również jako salon"
                                            />
                                    </div> : null}
                                    {this.state.user.roleID === 5 && !this.props.editMode ? <div className="col-md-6">
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary"
                                                        checked={this.state.assignPosMode}
                                                        onChange={this.onAssignPosChange.bind(this)}
                                                        value={this.state.assignPosMode}
                                                        name='assignPosMode'
                                                />
                                            }
                                            label="Przypisz do salonu"
                                            />
                                    </div> : null}
                                    {this.state.user.roleID === 5 && this.state.assignPosMode && !this.props.editMode ? <div className="col-md-6 mb-2">
                                        <InputLabel error={false}
                                                    shrink>
                                            Wybierz salon
                                        </InputLabel>
                                        <ReactSelect
                                            options={preparedPosList}
                                            onChange={this.onPosChange.bind(this)}
                                            value={this.state.selectedPos}
                                            placeholder="Wyszukaj..." />
                                    </div> : null}
                                    {this.state.user.roleID === 4 && this.state.create_pos && !this.props.editMode ? <div className="col-md-6 mb-2">
                                        <InputLabel error={false}
                                                    shrink>
                                            Województwo
                                        </InputLabel>
                                        <ReactSelect
                                            options={voids}
                                            onChange={this.onVoidChange.bind(this)}
                                            value={this.state.selectedVoid}
                                            placeholder="Wybierz województwo" />
                                    </div> : null}
                                    {this.state.user.roleID === 4 && this.state.create_pos && !this.props.editMode ? <div className="col-md-6 mb-2">
                                        <InputLabel error={false}
                                                    shrink>
                                            Wybierz przedstawiciela handlowego
                                        </InputLabel>
                                        <ReactSelect
                                            options={preparedAgentsList}
                                            onChange={this.onAgentChange.bind(this)}
                                            value={this.state.selectedAgent}
                                            placeholder="Wyszukaj..." />
                                    </div> : null}
                                    
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closePopup.bind(this)} color="secondary" >
                                Anuluj
                            </Button>
                            <Button onClick={this.saveForm.bind(this)} className="bg-primary text-white">
                                {this.props.editMode ? 'Zapisz' : 'Dodaj'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
        );
    }

    clearForm() {
        this.setState({
            ...this.state,
            create_pos: false,
            assignPosMode: false,
            selectedAgent: false,
            selectedPos: false,
            selectedVoid: false,
            user: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                roleID: '',
                birthdayDate: '',
                pos_region: '',
                pos_agent: '',
                assign_pos: ''
            },
            errors: {
                role: '',
                firstName: '',
                lastname: '',
                email: '',
                password: '',
                phoneNumber: '',
                roleID: ''
            }
        })
    }

    closePopup() {
        this.clearForm()
        this.props.togglePopup()
    }

    checkUsers() {
        let queryParam = this.state.user.firstName+' '+this.state.user.lastName
        this.props.onCheck(queryParam)
        this.setState({
            ...this.state,
            openPopover: !this.state.openPopover,
            anchorEl: findDOMNode(this.button)
        })
    }

    disablePopover() {
        this.setState({
            ...this.state,
            openPopover: false
        })
    }

    onChange(e, value) {
        let user = {...this.state.user}
        if (e.target.value || e.target.value === '' ) {
            user[e.target.name] = e.target.value
            this.setState({
                ...this.state,
                user: user            
            })
        }
        
    }

    onVoidChange(item) {
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                pos_region: item.value
            },
            selectedVoid: item
        })
    }

    onAgentChange(item) {
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                pos_agent: item.value
            },
            selectedAgent: item
        })
    }

    onPosChange(item) {
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                assign_pos: item.value
            },
            selectedPos: item
        })
    }

    onCreatePosChange() {
        this.setState({
            ...this.state,
            create_pos: !this.state.create_pos
        })
    }

    onAssignPosChange() {
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                assign_pos: ''
            },
            assignPosMode: !this.state.assignPosMode,
            selectedPos: null
        })
    }

    onSubmit(e) {
        // let data = new FormData(e.target)
        
    }

    saveForm() {
        if ( this.props.editMode ) {
            this.props.editUser(this.createEditData())
        } else {
            this.props.addUser(this.createEditData())
        }

    }

}

// const mapStateToProps = (state) => {
//     return {
//         user: state.user.userDetails.data,
//         userForm: state.user.userForm
//     }
// }

// export default connect(mapStateToProps)(AddUser);
