import React, { Component } from 'react'
import Auth from '../../../../../util/Auth'
import CourseTile from './CourseTile'

export default class CoursesGrid extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        let courseGroups = {}
        this.props.data.forEach((item, key) => {
            if (item.group) {
                if (!courseGroups[item.group.id]) {
                    courseGroups[item.group.id] = {
                        group: item.group,
                        list: []
                    }
                }
                courseGroups[item.group.id].list.push(item)
            }
        })
        return <div className="row">
            {Object.keys(courseGroups).map((i, k) => {
                return <div className="col-md-6 col-lg-3" key={k}>

                    <h3 className="mb-3 jr-mb-sm-3 jr-fs-l jr-font-weight-light">
                        {courseGroups[i].group.name}
                    </h3>
                    <Auth roles={['super_admin', 'admin']}>
                        {courseGroups[i].list.map((item, key) => {
                            return <CourseTile key={key} courseDates={this.props.courseDates} data={{ ...item, beforeCourse: true }} />
                        })}
                    </Auth>
                    <Auth roles={['ph']}>
                        {courseGroups[i].list.map((item, key) => {
                            let beforeCourse = key > 0 ? courseGroups[i].list[key - 1] : 'first';
                            if (beforeCourse !== 'first') {
                                beforeCourse = beforeCourse.courseDate && beforeCourse.courseDate.data.status === 'done'
                            }

                            return <CourseTile key={key} courseDates={this.props.courseDates} data={{ ...item, beforeCourse }} />
                        })}
                    </Auth>
                </div>
            })}
        </div>
    }

}
