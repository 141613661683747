import React from 'react';
// import { Redirect, Route, Switch } from 'react-router-dom';
// import Avatar from '@material-ui/core/Avatar';
import CardBox from '../../../../../components/CardBox';
import { connect } from 'react-redux'
// import ProfileBox from './ProfileBox'
// import ContactCard from './ContactCard'
import SignupsList from './SignupsList'
// import EditForm from './EditForm'
import * as UserActions from '../../../../../actions/User'
import * as CoursesActions from '../../../../../actions/Courses'
import Widget from "../../../../../components/Widget";
import Auxiliary from "../../../../../util/Auxiliary";
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom'
import Auth from '../../../../../util/Auth'
// import Condition from '../../../../../util/Condition'
import ReactSelect from 'react-select'
import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import Input from '@material-ui/core/Input';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Badge } from 'reactstrap';
import moment from 'moment';
import settings from '../../../../../config/settings.js';
import AddForm from '../AddForm'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Fab } from '@material-ui/core';
import DialogLoaderComponent from '../../../../../components/Loaders/DialogLoaderComponent';
class CourseDetails extends React.Component {

  constructor() {

    super()
    this.state = {
      progressCourseStaffFiles: 0,
      changeTeacherMode: false,
      selectedTeacher: null,
      selectedUser: null,
      addFormMode: false,
      showDeleteWarning: false,
      showDeleteWarningSignup: false,
      deleteSignupID: null,
      courseFormFiles: [],
    }

  }

  componentDidMount() {

    let id = this.props.match.params.id
    this.props.dispatch(CoursesActions.getCourseDateDetails({
      include: 'trainingStaff,files'
    }, id))
    this.props.dispatch(CoursesActions.getCourseDateSignups(id))
    this.props.dispatch(UserActions.getAvailableUsersForCourseDate({
      'filter[role]': 'worker,pos_admin',
      per_page: 0
    }, id))
    this.props.dispatch(CoursesActions.getCoursesList({
      per_page: 0
    }))
    this.props.dispatch(UserActions.getTeachers({
      per_page: 0,
      'filter[role]': 'training_staff'
    }))
  }
  componentDidUpdate(prevProps) {
    let id = this.props.match.params.id


    if (!prevProps.courses.addedCourseDateFiles && this.props.courses.addedCourseDateFiles) {
      NotificationManager.success("Pomyślnie dodano pliki do szkolenia!", "", 2000);
      this.clearCourseDateFormFiles()
    }
    // if (prevProps.courses.courseDateDetails.canSignup !== this.props.courses.courseDateDetails.canSignup && this.props.courses.courseDateDetails.canSignup) {
    //   this.props.dispatch(UserActions.getAvailableUsersForCourseDate({
    //     'filter[role]': 'worker,pos_admin',
    //     per_page: 0
    //   }, id))
    // }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.dispatch(CoursesActions.getCourseDateDetails({
        include: 'trainingStaff,files'
      }, id))
      this.props.dispatch(CoursesActions.getCourseDateSignups(id))
      this.props.dispatch(UserActions.getAvailableUsersForCourseDate({
        'filter[role]': 'worker,pos_admin',
        per_page: 0
      }, id))
      this.props.dispatch(CoursesActions.getCoursesList({
        per_page: 0
      }))
      this.props.dispatch(UserActions.getTeachers({
        per_page: 0,
        'filter[role]': 'training_staff'
      }))
    }

    if (!prevProps.courses.updatedCourseSignup.success && this.props.courses.updatedCourseSignup.success) {
      this.props.dispatch(CoursesActions.getCourseDateSignups(id))
      this.props.dispatch(CoursesActions.getCourseDateDetails({
        include: 'files,trainingStaff'
      }, id))
    }
    if (!prevProps.courses.updatedCourseDateExpert && this.props.courses.updatedCourseDateExpert) {
      NotificationManager.success("Pomyślnie zmieniono szkoleniowca!", "", 4000)
      this.toggleChangeTeacherMode()
    }
    if (!prevProps.courses.deletedCourseDate && this.props.courses.deletedCourseDate) {
      NotificationManager.success("Pomyślnie usunięto termin szkolenia!", "", 4000)
      this.props.history.push('/app/course-dates')
    }
    if (!prevProps.courses.deletedCourseSignup && this.props.courses.deletedCourseSignup) {
      NotificationManager.success("Pomyślnie usunięto zapis na szkolenie!", "", 4000)
      this.props.dispatch(CoursesActions.getCourseDateSignups(id))
    }
    if (!prevProps.courses.updatedCourseSignupPresent && this.props.courses.updatedCourseSignupPresent) {
      NotificationManager.success("Pomyślnie zaktualizowano listę obecności!", "", 4000)
    }
    if (!prevProps.courses.error && this.props.courses.error) {
      NotificationManager.error(this.props.courses.error, "", 4000)
    }
    if (!prevProps.courses.signedUpForCourse && this.props.courses.signedUpForCourse) {
      NotificationManager.success("Pomyślnie zapisano użytkownika na szkolenie!", "", 4000)
      this.props.dispatch(CoursesActions.getCourseDateSignups(id))
      this.props.dispatch(CoursesActions.getCourseDateDetails({
        include: 'files,trainingStaff'
      }, id))
      this.props.dispatch(UserActions.getAvailableUsersForCourseDate({
        'filter[role]': 'worker,pos_admin',
        per_page: 0
      }, id))
      this.clearSelectedUser()
    }
    if (!prevProps.courses.updatedCourseSignup.error && this.props.courses.updatedCourseSignup.error) {
      NotificationManager.warning("Brak wolnych miejsc na szkoleniu!", "", 4000)
      this.props.dispatch(CoursesActions.getCourseDateSignups(id))
      this.props.dispatch(CoursesActions.getCourseDateDetails({
        include: 'files,trainingStaff'
      }, id))
    }
    if (!prevProps.courses.addCourseDateForm.added && this.props.courses.addCourseDateForm.added) {
      NotificationManager.success("Pomyślnie zedytowano termin szkolenia!", "", 4000)
      this.toggleEditMode()
    }
    if (!prevProps.courses.addSignupPoints.added && this.props.courses.addSignupPoints.added) {
      NotificationManager.success("Pomyślnie dodano punkty!", "", 4000)
      this.props.dispatch(CoursesActions.getCourseDateSignups(id))
    }
  }

  render() {

    let userData = this.props.courses.courseDateDetails
    let teachersList = this.props.user.teachers.map((item, key) => {
      return {
        label: item.firstName + " " + item.lastName,
        value: item.identifier
      }
    })

    if (userData.trainingStaff) {
      teachersList = teachersList.filter((item, key) => {
        return item.value !== userData.trainingStaff.data.identifier
      })
    }


    let courseDateStatus = ''
    let courseDateStatusColor = ''

    if (userData.status === 'pending') {
      courseDateStatus = 'Nadchodzące szkolenie'
      courseDateStatusColor = 'primary'
    }
    if (userData.status === 'active') {
      courseDateStatus = 'Szkolenie w trakcie'
      courseDateStatusColor = 'warning'
    }
    if (userData.status === 'done') {
      courseDateStatus = 'Ukończone szkolenie'
      courseDateStatusColor = 'success'
    }

    let startDate = moment(userData.dateStart).format("DD-MM-YYYY")
    let endDate = moment(userData.dateEnd).format("DD-MM-YYYY")

    let finalDate = endDate > startDate ? startDate + ' - ' + endDate : startDate

    let startTime = moment(userData.dateStart).format("HH:mm")
    let endTime = moment(userData.dateEnd).format("HH:mm")

    let finalHours = startTime + ' - ' + endTime

    let basicList = this.props.courses.courseDateSignups.filter((item, key) => {
      return item.statusName === 'accepted' || item.statusName === 'unjustified'
    })

    let reserveList = this.props.courses.courseDateSignups.filter((item, key) => {
      return item.statusName === 'pending' || item.statusName === 'cancelled'
    })

    return <Auxiliary>
      <NotificationContainer />
      {this.state.addFormMode ? <AddForm open={this.state.addFormMode}
        form={this.props.courses.addCourseDateForm}
        onSubmit={this.onAddFormSubmit.bind(this)}
        teachers={this.props.user.teachers}
        courses={this.props.courses.list}
        addStartDate={this.state.addStartDate}
        addEndDate={this.state.addEndDate}
        editMode={true}
        details={this.props.courses.courseDateDetails}
        togglePopup={this.toggleEditMode.bind(this)} /> : null}
      <div className="jr-profile-banner" style={{
        backgroundImage: "url(" + userData.image + ")",
        backgroundSize: 'cover'
      }}>
        <div className="jr-profile-container">
          <div className="jr-profile-banner-top">
            <div className="jr-profile-banner-top-left">
              <div className="jr-profile-banner-avatar">
              </div>
              <div className="jr-profile-banner-avatar-info">
                <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                  <Link to={"/app/courses/" + userData.courseSlug} className="keune-link">
                    {userData.title}
                  </Link>
                </h2>
                <p className="mb-0 jr-fs-lg">{userData.category?.name + ", " + userData.level.name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="jr-profile-content">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-xs-12">
            <Widget title="Informacje o terminie szkolenia" styleName="jr-card-profile-sm">
              <Auth roles={['super_admin', 'admin']}>
                <div className="mb-3">
                  <Tooltip onClick={this.toggleEditMode.bind(this)} id="tooltip-icon" className="mr-3" title="Edytuj termin szkolenia" placement="top">
                    <Fab color="primary" className="bg-orange text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                      <i className="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg" />
                    </Fab>
                  </Tooltip>
                  <Tooltip onClick={this.showDeleteWarning.bind(this)} id="tooltip-icon" title="Usuń termin szkolenia" placement="top">
                    <Fab color="primary" className="bg-red text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                      <i className="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg" />
                    </Fab>
                  </Tooltip>
                </div>
              </Auth>
              <div className="media align-items-start flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                  <i className="zmdi zmdi-pin jr-fs-xxl text-orange" />
                </div>
                <div className="media-body">
                  <span className="mb-0 text-grey jr-fs-sm">Miejsce</span>
                  <p className="mb-0">{userData.placeName}</p>
                </div>
              </div>
              <div className="media align-items-start flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                  <i className="zmdi zmdi-calendar jr-fs-xxl text-orange" />
                </div>
                <div className="media-body">
                  <span className="mb-0 text-grey jr-fs-sm">Data</span>
                  <p className="mb-0">{finalDate}</p>
                </div>
              </div>
              <div className="media align-items-start flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                  <i className="zmdi zmdi-money jr-fs-xxl text-orange" />
                </div>
                <div className="media-body">
                  <span className="mb-0 text-grey jr-fs-sm">Cena szkolenia</span>
                  <p className="mb-0">{userData.courseDatePrice}</p>
                </div>
              </div>
              <div className="media align-items-start flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                  <i className="zmdi zmdi-time jr-fs-xxl text-orange" />
                </div>
                <div className="media-body">
                  <span className="mb-0 text-grey jr-fs-sm">Godziny</span>
                  <p className="mb-0">{finalHours}</p>
                </div>
              </div>
              <div className="media align-items-start flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                  <i className="zmdi zmdi-accounts jr-fs-xxl text-orange" />
                </div>
                <div className="media-body">
                  <span className="mb-0 text-grey jr-fs-sm">Wolne miejsca</span>
                  <p className="mb-0">{userData.freeSpots} z ({userData.capacity})</p>
                </div>
              </div>
              <div className="media align-items-start flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                  <i className="zmdi zmdi-badge-check jr-fs-xxl text-orange" />
                </div>
                <div className="media-body">
                  <span className="mb-0 text-grey jr-fs-sm">Szkoleniowiec</span>
                  <p className="mb-0">
                    {userData.trainingStaff && userData.trainingStaff.data.length > 0 ? userData.trainingStaff.data.map((item, key) => {
                      return <Link to={"/app/users/" + item.identifier} key={key}>
                        {item.firstName + " " + item.lastName}{key >= userData.trainingStaff.data.length - 1 ? '' : ', '}
                      </Link>
                    }) : <span>Brak przypisanego szkoleniowca</span>}
                    <Auth roles={['super_admin', 'admin']}>
                      <Tooltip onClick={this.toggleChangeTeacherMode.bind(this)} id="tooltip-icon" className="ml-3" title="Zmień szkoleniowca" placement="top">
                        <Fab color="primary" className="bg-orange text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                          <i className="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg" />
                        </Fab>
                      </Tooltip>
                      {this.state.changeTeacherMode ? <div className="row mt-2">
                        <ReactSelect
                          className="col-10"
                          options={teachersList}
                          isMulti={true}
                          onChange={this.onChangeTeacher.bind(this)}
                          value={this.state.selectedTeacher}
                          placeholder="Wyszukaj szkoleniowca"
                        />
                        <div className="mt-1">
                          <Tooltip onClick={this.updateCourseDate.bind(this)} id="tooltip-icon" title="Zapisz" placement="top">
                            <Fab color="primary" className="bg-green text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                              <i className="zmdi zmdi-save zmdi-hc-fw zmdi-hc-lg" />
                            </Fab>
                          </Tooltip>
                        </div>
                      </div> : null}
                    </Auth>
                  </p>
                </div>
              </div>
              <div className="media align-items-start flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                  <i className="zmdi zmdi-assignment-o jr-fs-xxl text-orange" />
                </div>
                <div className="media-body">
                  <span className="mb-0 text-grey jr-fs-sm">Status</span>
                  <p className="mb-0">
                    <Badge pill color={courseDateStatusColor}>
                      {courseDateStatus}
                    </Badge>
                  </p>
                </div>
              </div>
              {this.props.courses
                && this.props.courses.courseDateDetails
                && this.props.courses.courseDateDetails.loading
                ? <DialogLoaderComponent />
                : null
              }
            </Widget>
          </div>
          <Auth roles={['super_admin', 'admin', 'ph']}>
            <div className="col-md-8 col-xs-12">
              <Widget title="Zapisz uczestnika na szkolenie" styleName="jr-card-profile-sm">
                <div className="row">
                  {/* {userData.canSignup ? */}
                  <>
                    {this.renderAvailableUsersSelect()}
                    {this.state.selectedUser ? <div className="col-md-2">
                      <Tooltip onClick={this.createSignup.bind(this)} id="tooltip-icon" title="Zapisz" placement="top">
                        <Fab color="primary" className="jr-fab-btn" aria-label="add">
                          <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg" />
                        </Fab>
                      </Tooltip>
                    </div> : null}
                  </>
                  {/* : <span>Rejestracja jest możliwa nie wcześniej niż dwa miesiące przed startem szkolenia</span>} */}
                </div>
              </Widget>
              {/* <Widget title="Materiały dla szkoleniowca" styleName="jr-card-profile-sm">
                <div className="mb-3">
                  <Auth roles={['super_admin', 'admin']}>
                    <div className="row">
                      <div className="col-xl-8">
                        <Dragndrop text="Przeciągnij pliki" onFileChange={this.onFileChange.bind(this)} />
                      </div>
                      <div className="col-xl-4">
                        {this.state.progressCourseStaffFiles !== 0 ? <Line percent={this.state.progressCourseStaffFiles} strokeWidth='3' strokeColor='#3f51b5' strokeLinecap='square' /> : null}
                      </div>
                    </div>
                  </Auth>
                  <div className="row mb-3">
                    <div className="col-10">
                      {Object.keys(this.props.courses.addCourseDateForm.errors).length > 0 ? <div className="text-red mb-2">Przesyłane pliki są zbyt duże.</div> : null}
                      {this.state.courseFormFiles.length > 0 ? <h3 className="card-title mb-2 mb-md-3">Pliki do przesłania:</h3> : null}
                      {this.state.courseFormFiles.length
                        ? this.state.courseFormFiles.map((item, key) => {
                          return <div key={key} className="mb-1 row" style={{ clear: 'both' }} >
                            <div className="file-list-item col-10">
                              {item.name} - {Math.round((item.size / (1024 * 1024)) * 100) / 100}MB
                                </div>
                            <Tooltip className="pull-right col-2" onClick={this.deleteCourseDateFormFile.bind(this, key)} id="tooltip-icon" title="Usuń plik" placement="top">
                              <Fab color="primary" className="bg-red text-white jr-fab-btn jr-btn-fab-xs" aria-label="delete">
                                <i className="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg" />
                              </Fab>
                            </Tooltip>
                          </div>
                        })
                        : ''
                      }
                    </div>
                    {this.state.courseFormFiles.length > 0 ? <div className="col-xl-2">
                      <Tooltip id="tooltip-icon" title="Wyślij" placement="top">
                        <Fab onClick={this.addCourseDateFiles.bind(this)} color="primary" className="jr-fab-btn" aria-label="add">
                          <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg" />
                        </Fab>
                      </Tooltip>
                    </div> : null}
                  </div>
                  {userData && userData.files.data && userData.files.data.length ? this.renderExistingCourseFiles() : <p className="text-grey jr-fs-sm mb-0">Brak materiałów do tego szkolenia</p>}
                  {userData && userData.files.data && userData.files.data.length ? <a href={settings.api_endpoint + '/courseDate/' + this.props.courses.courseDateDetails.identifier + '/files/zipped'} download rel="noopener noreferrer" target="_blank">
                    <Tooltip id="tooltip-icon" title="Pobierz materiały" placement="top">
                      <Fab color="primary" className="jr-fab-btn" aria-label="add">
                        <i className="zmdi zmdi-download zmdi-hc-fw zmdi-hc-lg" />
                      </Fab>
                    </Tooltip>
                  </a> : null}
                </div>
              </Widget> */}
            </div>
          </Auth>
        </div>
      </div>
      <div className="row">
        <CardBox styleName="col-12" cardStyle="p-0" heading={"Zapisy na szkolenie"}
          headerOutside>
          <SignupsList authUserHasRole={this.authUserHasRole.bind(this)}
            loggedUser={this.props.user.auth.user}
            loading={this.props.courses && this.props.courses.courseDateDetails && this.props.courses.courseDateDetails.loading}
            changeSignupStatus={this.changeSignupStatus.bind(this)}
            changePresentStatus={this.changePresentStatus.bind(this)}
            courseDateStatus={userData.status}
            addedPoints={this.props.courses.addSignupPoints.added}
            addPoints={this.addPoints.bind(this)}
            showDeleteWarningSignup={this.showDeleteWarningSignup.bind(this)}
            list={basicList}

          />

        </CardBox>
        <Auth roles={['super_admin', 'admin', 'ph']}>
          <CardBox styleName="col-12" cardStyle="p-0" heading={"Lista rezerwowa"}
            headerOutside>
            <SignupsList authUserHasRole={this.authUserHasRole.bind(this)}
              loggedUser={this.props.user.auth.user}
              loading={this.props.courses && this.props.courses.courseDateDetails && this.props.courses.courseDateDetails.loading}
              changeSignupStatus={this.changeSignupStatus.bind(this)}
              changePresentStatus={this.changePresentStatus.bind(this)}
              courseDateStatus={userData.status}
              reserveList={true}
              addedPoints={this.props.user.addPoints.added}
              addPoints={this.addPoints.bind(this)}
              showDeleteWarningSignup={this.showDeleteWarningSignup.bind(this)}
              list={reserveList} />
          </CardBox>
        </Auth>
      </div>
      <SweetAlert show={this.state.showDeleteWarning}
        warning
        showCancel
        confirmBtnText="Tak, usuń"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        cancelBtnText="Anuluj"
        title="Jesteś pewien?"
        onConfirm={this.onConfirmDelete.bind(this)}
        onCancel={this.onCancelDelete.bind(this)}
      >
        Wybrany termin szkolenia zostanie bezpowrotnie usunięty!
      </SweetAlert>
      <SweetAlert show={this.state.showDeleteWarningSignup}
        warning
        showCancel
        confirmBtnText="Tak, usuń"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        cancelBtnText="Anuluj"
        title="Jesteś pewien?"
        onConfirm={this.onConfirmDeleteSignup.bind(this)}
        onCancel={this.onCancelDeleteSignup.bind(this)} >
        Wybrany zapis na szkolenie zostanie usunięty!
      </SweetAlert>
    </Auxiliary>
  }
  renderExistingCourseFiles() {
    let userData = this.props.courses.courseDateDetails;
    return userData.files.data.map((item, key) => (
      <div className="mb-3 row" key={key}>
        <a download target="_blank" href={item.urlFile} className="mb-1 file-list-item col-10" rel="noopener noreferrer" >{item.title}</a>
        <Auth roles={['super_admin', 'admin']}>
          <div className="col-2">
            <Tooltip onClick={this.deleteCourseDateFile.bind(this, item.identifier)} className="pull-right" id="tooltip-icon" title="Usuń plik" placement="top">
              <Fab color="primary" className="bg-red text-white jr-fab-btn jr-btn-fab-xs" aria-label="delete">
                <i className="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg" />
              </Fab>
            </Tooltip>
          </div>
        </Auth>
      </div>
    ))
  }
  clearCourseDateFormFiles() {
    this.setState({
      ...this.state,
      courseFormFiles: []
    })
  }

  deleteCourseDateFile(fileId) {
    let courseId = this.props.match.params.id
    this.props.dispatch(CoursesActions.deleteCourseDateFile(courseId, fileId))
  }
  deleteCourseDateFormFile(id) {
    if (this.state.courseFormFiles.length === 1) {
      this.props.dispatch(CoursesActions.clearCourseStaffFilesValidation())
    }
    this.setState({
      ...this.state,
      courseFormFiles: this.state.courseFormFiles.filter((item, key) => key !== id)
    })
  }
  addCourseDateFiles() {
    let slug = this.props.match.params.id
    this.props.dispatch(CoursesActions.addCourseDateFiles(slug, this.state.courseFormFiles, progressEvent => {
      var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
      if (percent >= 100) {
        this.setState({
          ...this.state,
          progressCourseStaffFiles: 0
        })
      } else {
        this.setState({
          ...this.state,
          progressCourseStaffFiles: percent
        })
      }
    }))
  }
  onConfirmDeleteSignup() {
    this.props.dispatch(CoursesActions.deleteCourseSignup(this.state.deleteSignupID))
    this.setState({
      ...this.state,
      showDeleteWarningSignup: false,
      deleteSignupID: null
    })
  }

  onCancelDeleteSignup() {
    this.setState({
      ...this.state,
      showDeleteWarningSignup: false
    })
  }

  showDeleteWarningSignup(id) {
    this.setState({
      ...this.state,
      showDeleteWarningSignup: true,
      deleteSignupID: id
    })
  }

  onConfirmDelete() {
    this.props.dispatch(CoursesActions.deleteCourseDate(this.props.courses.courseDateDetails.identifier))
    this.setState({
      ...this.state,
      showDeleteWarning: false
    })
  }

  onCancelDelete() {
    this.setState({
      ...this.state,
      showDeleteWarning: false
    })
  }

  showDeleteWarning() {
    this.setState({
      ...this.state,
      showDeleteWarning: true
    })
  }

  renderAvailableUsersSelect() {

    let availableUsersList = this.props.user.availableUsersForCourseDate.map((item, key) => {
      return {
        label: item.firstName + " " + item.lastName,
        value: item.identifier
      }
    })
    return <div className="col-md-7">
      <FormControl className="w-100">
        <InputLabel shrink htmlFor="courseID">Wybierz termin szkolenia</InputLabel>
        <ReactSelect
          onChange={this.onChangeUser.bind(this)}
          value={this.state.selectedUser}
          options={availableUsersList}
          placeholder="Wyszukaj użytkownika..."
        />
      </FormControl>
    </div>
  }

  renderCourseFiles() {

    return <div className="col-xl-5">
      <Widget styleName="jr-card-profile">
        <div>
          <h3 className="card-title mb-2 mb-md-3">Materiały dla szkoleniowca</h3>
          <a href={settings.api_endpoint + '/course/' + this.props.courses.courseDateDetails.courseSlug + '/staffFiles/zipped'} download>
            <Tooltip id="tooltip-icon" title="Pobierz materiały" placement="top">
              <Fab color="primary" className="jr-fab-btn" aria-label="add">
                <i className="zmdi zmdi-download zmdi-hc-fw zmdi-hc-lg" />
              </Fab>
            </Tooltip>
          </a>
        </div>
      </Widget>
    </div>
  }
  onFileChange(e, files) {
    this.setState({
      ...this.state,
      courseFormFiles: files
    })
  }

  authUserHasRole(roles) {
    if (roles.includes(this.props.user.auth.user.roles.slug)) {
      return true
    } else {
      return false
    }
  }

  updateCourseDate() {
    let id = this.props.match.params.id
    // let data = {
    //   expertID: this.state.selectedTeacher.value
    // }
    let data = {
      expertIDs: this.state.selectedTeacher.map((item, key) => {
        return item.value
      })
    }
    this.props.dispatch(CoursesActions.updateCourseDateExpert(id, data.expertIDs))
  }

  changePresentStatus(value, signupId) {

    this.props.dispatch(CoursesActions.updateCourseSignupPresent(value, signupId))

  }

  changeSignupStatus(courseId, signupId, data) {

    this.props.dispatch(CoursesActions.changeSignupStatus(courseId, signupId, data))

  }

  onChangeTeacher(value) {
    this.setState({
      ...this.state,
      selectedTeacher: value
    }, () => {
    })
  }

  onChangeUser(value) {
    this.setState({
      ...this.state,
      selectedUser: value
    }, () => {

    })
  }

  clearSelectedUser() {

    this.setState({
      ...this.state,
      selectedUser: null
    }, () => {

    })
  }

  createSignup() {

    let courseId = this.props.match.params.id

    this.props.dispatch(CoursesActions.signUpForCourse(courseId, {
      creatorID: this.props.user.auth.user.identifier,
      participantID: this.state.selectedUser.value
    }))
  }

  toggleChangeTeacherMode() {
    this.setState({
      ...this.state,
      changeTeacherMode: !this.state.changeTeacherMode,
      selectedTeacher: this.props.courses.courseDateDetails.trainingStaff.data.map((item, key) => {
        return {
          label: item.firstName + ' ' + item.lastName,
          value: item.identifier
        }
      })
    })
  }

  toggleEditMode() {
    this.setState({
      ...this.state,
      addFormMode: !this.state.addFormMode
    })
  }

  onAddFormSubmit(data) {
    if (this.state.addFormMode) {
      let courseDateId = this.props.courses.courseDateDetails.identifier
      this.props.dispatch(CoursesActions.updateCourseDate(courseDateId, data))
    } else {
      this.props.dispatch(CoursesActions.addCourseDate(data))
    }
  }

  addPoints(signupId, signupPoints) {
    this.props.dispatch(CoursesActions.addSignupPoints(signupId, {
      signupPoints: signupPoints
    }))
    // this.props.dispatch(UserActions.addPoints(userId, {
    //   userPoints: userPoints
    // }))
  }

}

const mapStateToProps = (state) => {
  return {
    courses: state.courses,
    user: state.user
  }
}

export default connect(mapStateToProps)(CourseDetails)
