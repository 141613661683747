import React from 'react'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	showImg: {
		width: '100%'
	},
	showDialogTitle: {
		marginTop: 20,
		marginBottom: 40,
	},
}))

const DialogImgComponent = ({ src, alt }) => {
	const classes = useStyles();

	return (
		<>
			{src
				? <div className={`col-md-12 ${classes.showDialogTitle}`}>
					<img className={classes.showImg} src={src} alt={alt} />
				</div>
				: null
			}
		</>
	)
}

export default React.memo(DialogImgComponent);
