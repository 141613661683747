import React, { useCallback, useMemo, useRef, useState } from 'react'

import moment from 'moment'
import 'moment/locale/pl'
// import VerticalComposedChart from './VerticalComposedChart'
import { Button } from 'reactstrap';
import CardBox from '../../components/CardBox';
import { Line } from 'react-chartjs-2'
// eslint-disable-next-line
import * as zoom from 'chartjs-plugin-zoom';
import { connect } from 'react-redux';
import { getOrderStatistic } from '../../actions'
import CircularLoader from '../../app/routes/components/routes/progressbar/circular/CircularLoader';
import { makeStyles } from '@material-ui/styles';
import ReactSelect from 'react-select'
import Auth from '../../util/Auth';
import OrderCardsComponent from './OrderCardsComponent';


const useStyles = makeStyles((theme) => ({
    chartContainer: {
        position: 'relative',
        minHeight: 800,
        '& canvas': {
            height: '100%',
        },
        '@media (max-width: 500px)': {
            minHeight: 400,
        },
    },
    btn: {
        textTransform: 'uppercase !important',
    },
    chartLoaderCont: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        background: '#FFFFFF99',
    },
    salons: {
        zIndex: 2,
    }
}))
const OrderStatisticComponent = ({ getOrderStatistic = () => { }, loading, orderData }) => {
    const classes = useStyles();
    const lineReference = useRef();
    const [salon, setSalon] = useState({
        label: 'Wszystkie',
        value: 0
    });
    const { data, options } = useMemo(() => {
        let labels = [];
        let moneyData = [];
        let totalData = [];
        let quantityData = [];
        let unit = 'month';
        if (orderData && orderData.chart) {
            const chart = orderData.chart;
            labels = chart.dates && chart.dates.length ? chart.dates : [];
            moneyData = chart.money && chart.money.length ? chart.money : [];
            totalData = chart.total && chart.total.length ? chart.total : [];
            quantityData = chart.quantity && chart.quantity.length ? chart.quantity : [];
            unit = chart.type || 'month'
            if (lineReference.current && lineReference.current.chartInstance)
                lineReference.current.chartInstance.resetZoom()
        }
        return {
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "Całkowita kwota (PLN)",
                        fill: true,
                        backgroundColor: `#e91e6340`,
                        borderColor: '#e91e63',
                        data: totalData,
                        // borderWidth: WIDTH_BORDER,
                    },
                    {
                        label: "Kwota salonu (PLN)",
                        fill: true,
                        backgroundColor: `#3f51b540`,
                        borderColor: '#3f51b5',
                        data: moneyData,
                        // borderWidth: WIDTH_BORDER,
                    },
                    {
                        label: 'Ilość zamówień',
                        fill: true,
                        backgroundColor: `#00bcd440`,
                        borderColor: '#00bcd4',
                        data: quantityData,
                        // borderWidth: WIDTH_BORDER,
                    }
                ]
            },
            options: {
                legend: { position: 'bottom' },
                // responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit,
                            parser: 'YYYY.MM.DD',
                            displayFormats: {
                                quarter: 'YYYY.MM.DD'
                            },

                        },
                        scaleLabel: {
                            display: true,
                        },
                        ticks: {
                            maxRotation: 0
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: ''
                        }
                    }]
                },
                plugins: {
                    zoom: {
                        zoom: {
                            enabled: true,
                            onZoom: function ({ chart }) {
                                if (unit !== 'day' && chart.scales && chart.scales['x-axis-0']) {
                                    const ticks = chart.scales['x-axis-0'].ticks;
                                    if (ticks) {
                                        const start = moment(ticks[0], 'MMM YYYY');
                                        const finish = moment(ticks[ticks.length - 1], 'MMM YYYY');
                                        if (moment(start).isSame(finish, 'month')) {
                                            getOrderStatistic(
                                                {
                                                    salonId: salon.value || 0,
                                                    start: moment(start).startOf('month').format('YYYY-MM-DD'),
                                                    finish: moment(finish).endOf('month').format('YYYY-MM-DD')
                                                }
                                            )
                                        }
                                    }

                                }
                            },
                            drag: {
                                animationDuration: 1000
                            },
                            mode: 'x',
                            speed: 0.05
                        }
                    }
                }
            }
        }
    }, [orderData])
    const handleReset = useCallback(() => {
        // if (options.current.scales.xAxes[0].time.unit === 'day') {
        getOrderStatistic({
            salonId: salon.value
        });
        // }
    }, [salon])
    const handleChangeSalon = useCallback((salon) => {
        setSalon(salon)
        getOrderStatistic({
            salonId: salon.value
        })
    }, [])
    return (
        <div >
            <Auth roles={['super_admin', 'admin', 'ph']}>
                <CardBox styleName={`col-12 ${classes.salons}`} heading="Salony w aplikacji">
                    <section className="container-fluid ">
                        <div className="row ">
                            <div className="col-md-4 p-1">
                                <ReactSelect
                                    className="w-100"
                                    onChange={handleChangeSalon}
                                    value={salon}
                                    options={[
                                        {
                                            label: 'Wszystkie',
                                            value: 0
                                        },
                                        ...(orderData && orderData.salons && orderData.salons.length
                                            ? orderData.salons.map(({ name, id }) => ({
                                                label: name,
                                                value: id,
                                            }))
                                            : [])
                                    ]
                                    }
                                    placeholder="Wpisz nazwę salonu..."
                                />
                            </div>
                        </div>
                    </section>
                </CardBox>
            </Auth>
            <OrderCardsComponent orderData={orderData} styleName='col-12' />
            <CardBox styleName="col-12" heading="Analiza zamówień">
                <Button variant="contained" color="primary" onClick={handleReset} className={`mt-1 ${classes.btn}`}>Resetuj</Button>
                <div className={classes.chartContainer}>
                    <Line data={data} options={options} ref={lineReference} />
                    <div className={classes.chartLoaderCont} hidden={!loading}>
                        <CircularLoader />
                    </div>
                </div>
            </CardBox>

        </div>
    )
}


export default connect((state) => ({
    orderData: state.dashboard.dashboardData && state.dashboard.dashboardData.statistic ? state.dashboard.dashboardData.statistic : {},
    loading: state.dashboard.loading
}), {
    getOrderStatistic,
})(OrderStatisticComponent);
