const INIT_STATE = {
	list: [],
	loading: false,
	error: false
};


export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case 'GET_MOBILE_PRODUCTS_PENDING':
			return {
				...state,
				error: false,
				loading: true,
			}

		case 'GET_MOBILE_PRODUCTS_REJECTED':
			return {
				...state,
				error: 'Nie udało się załadować nowe dane',
				loading: false,
			}

		case 'GET_MOBILE_PRODUCTS_FULFILLED':
			return {
				...state,
				list: action.payload.data.data || [],
				loading: false,
			}
		default:
			return state;
	}
}
