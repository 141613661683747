import {
    // GET_USER_LIST,
    // GET_USER_DETAILS,
    SEARCH_USERS,
    // AUTH_USER,
} from '../constants/ActionTypes';
import axios from 'axios'
import settings from '../config/settings.js'

// | PUT|PATCH | courseDate/{courseDate}/signup/{signup}/changeStatus 'finish', 'statusName'

export const changeSignupStatus = (courseId, signupId, data) => {
    return {
        type: 'CHANGE_SIGNUP_STATUS',
        payload: axios.request({
            url: "/courseDate/" + courseId + "/signup/" + signupId,
            method: "put",
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}

export const deleteCourseSignup = (id) => {
    return {
        type: 'DELETE_COURSE_SIGNUP',
        payload: axios.request({
            url: "/courseSignup/" + id,
            method: "DELETE",
            baseURL: settings.api_endpoint
        })
    }
}

export const getAllSaloons = (userId) => {
    return {
        type: 'GET_ALL_SALOONS'
        // payload: axios.request({
        //     url: "/users/" + userId + "/courseSignup",
        //     method: "get",
        //     baseURL: settings.api_endpoint
        // })
    }
}


export const getUserCourses = (userId) => {
    return {
        type: 'GET_USER_COURSES_TEST',
        payload: axios.request({
            url: "/users/" + userId + "/courseSignup",
            method: "get",
            baseURL: settings.api_endpoint
        })
    }
}


export const signUpForCourse = (courseId, data) => {
    return {
        type: 'SIGN_UP_FOR_COURSE',
        payload: axios.request({
            url: "/courseDate/" + courseId + "/signup",
            method: "post",
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}

export const createCourseDate = (data) => {
    return {
        type: 'CREATE_COURSE_DATE',
        payload: data
    }
}

export const addCourseDate = (data) => {
    return {
        type: 'ADD_COURSE_DATE',
        payload: axios.request({
            url: "/courseDate",
            method: "post",
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}

export const getCourseDateDetails = (params, id) => {
    return {
        type: 'GET_COURSE_DATE_DETAILS',
        payload: axios.request({
            url: '/courseDate/' + id,
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: params
        })
    }
}

export const getCourseDateSignups = (id) => {
    return {
        type: 'GET_COURSE_DATE_SIGNUPS',
        payload: axios.request({
            url: '/courseDate/' + id + '/signup',
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: {
                // include: 'participant.pos.agent'
                include: 'pos_agent,participant.pos',
                per_page: 0
            }
        })
    }
}

export const getCourseDates = (params = {}) => {
    return {
        type: 'GET_COURSE_DATES',
        payload: axios.request({
            url: '/courseDate',
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: params
        })
    }
}

export const getCourseDatesBySlug = (slug) => {
    return {
        type: 'GET_COURSE_DATES_BY_SLUG',
        payload: axios.request({
            url: '/course/' + slug + '/date',
            method: 'GET',
            baseURL: settings.api_endpoint
        })
    }
}

// export const getCoursesList = (url = "http://api.keuneakademia.sofine.pl/api/v1/course") => {
//     return {
//         type: 'GET_COURSES_LIST',
//         payload: axios.get(url)
//     }
// }

export const getCoursesList = ( params = {}, url = settings.api_endpoint + "/course") => {
    return {
        type: 'GET_COURSES_LIST',
        payload: axios.get(url, {
            params: params
        })
    }
}

export const getCoursesCategories = (url = settings.api_endpoint + "/courseCategory") => {
    return {
        type: 'GET_COURSES_CATEGORIES',
        payload: axios.get(url)
    }
}

export const getCoursesLevels = (url = settings.api_endpoint + "/courseLevel") => {
    return {
        type: 'GET_COURSES_LEVELS',
        payload: axios.get(url)
    }
}

export function getCourseDetails(slug) {
    return {
        type: 'GET_COURSE_DETAILS',
        payload: axios.request({
            url: '/course/'+slug,
            method: 'GET',
            baseURL: settings.api_endpoint,
            params: {
                include: 'staffFiles,agentFiles'
            }
        })
    }
}

export function searchUsers(term) {
    return {
        type: SEARCH_USERS,
        payload: term
    }
}

export const updateCourseSignupPresent = (value, signupID) => {
    return {
        type: 'UPDATE_COURSE_SIGNUP_PRESENT',
        payload: axios.request({
            url: '/courseSignup/' + signupID + '/present',
            method: 'PUT',
            baseURL: settings.api_endpoint,
            data: {
                present: value
            },
            params: {
                include: 'pos_agent,participant.pos'
            }
        })
    }
}

export const updateCourseDateExpert = (id, expertIDs) => {
    return {
        type: 'UPDATE_COURSE_DATE_EXPERT',
        payload: axios.request({
            // url: '/courseDate/' + id + '/expert/' + expertID,
            url: '/courseDate/' + id + '/trainingStaff',
            method: 'PUT',
            baseURL: settings.api_endpoint,
            data: {
                training_staff: expertIDs
            },
            params: {
                include: 'staffFiles,trainingStaff,agentFiles'
            }
        })
    }
}

export const updateCourseDate = (id, data, ) => {
    return {
        type: 'UPDATE_COURSE_DATE',
        payload: axios.request({
            url: '/courseDate/' + id,
            method: 'PUT',
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}

export const deleteCourseDate = (courseDateId) => {
    return {
        type: 'DELETE_COURSE_DATE',
        payload: axios.request({
            url: '/courseDate/' + courseDateId,
            method: 'DELETE',
            baseURL: settings.api_endpoint
        })
    }
}

export const editCourse = (id, data) => {
    let formData = new FormData()
    Object.keys(data).forEach((item, key) => {
        formData.append(item, data[item])
    })

    formData.append('_method', 'put')

    return {
        type: 'EDIT_COURSE',
        payload: axios.request({
            url: '/course/' + id,
            method: 'POST',
            baseURL: settings.api_endpoint,
            data: formData
        })
    }
}

export const getSignups = () => {
    return {
        type: 'GET_SIGNUPS',
        payload: axios.request({
            url: '/courseSignupAll',
            method: 'GET',
            baseURL: settings.api_endpoint
        })
    }
}

export const getCourse = (slug) => {
    return {
        type: 'GET_COURSE',
        payload: axios.request({
            url: '/course/' + slug,
            method: 'GET',
            baseURL: settings.api_endpoint
        })
    }
}

export const addCourse = (data) => {
    let formData = new FormData()
    Object.keys(data).forEach((item, key) => {
        formData.append(item, data[item])
    })
    formData.append('thumbnail', data.thumbnail)
    formData.append('image', data.image)
    return {
        type: "ADD_COURSE",
        payload: axios.request({
            url: "/course",
            method: "post",
            baseURL: settings.api_endpoint,
            data: formData
        })
        // payload: axios.post({
        //  url: 'http://api.keuneakademia.sofine.pl/api/v1/course',
        //  data: formData
        // })
    }
}

export const deleteCourse = (slug) => {
    return {
        type: "DELETE_COURSE",
        payload: axios.request({
            url: "/course/" + slug,
            method: "delete",
            baseURL: settings.api_endpoint
        })
    }
}

export const deleteCourseAgentFiles = (courseId, fileId) => {

    return {
        type: 'DELETE_COURSE_AGENT_FILES',
        payload: axios.request({
            url: '/course/' + courseId + '/agentFiles/' + fileId,
            method: 'DELETE',
            baseURL: settings.api_endpoint,
            params: {
                include: 'staffFiles,agentFiles'
            }
        })
    }
}

export const clearCourseAgentFilesValidation = () => {
    return {
        type: 'CLEAR_COURSE_AGENT_FILES_VALIDATION'
    }
}

export const deleteCourseStaffFiles = (courseId, fileId) => {

    return {
        type: 'DELETE_COURSE_STAFF_FILES',
        payload: axios.request({
            url: '/course/' + courseId + '/staffFiles/' + fileId,
            method: 'DELETE',
            baseURL: settings.api_endpoint,
            params: {
                include: 'staffFiles,agentFiles'
            }
        })
    }
}
export const deleteCourseDateFile = (courseId, fileId) => {

    return {
        type: 'DELETE_COURSE_DATE_FILES',
        payload: axios.request({
            url: '/courseDate/' + courseId + '/files/' + fileId,
            method: 'DELETE',
            baseURL: settings.api_endpoint,
            params: {
                include: 'files'
            }
        })
    }
}


export const clearCourseStaffFilesValidation = () => {
    return {
        type: 'CLEAR_COURSE_STAFF_FILES_VALIDATION'
    }
}

export const getCourseAgentFiles = (id) => {
    return {
        type: 'GET_COURSE_AGENT_FILES',
        payload: axios.request({
            url: '/course/' + id + '/agentFiles',
            method: 'GET',
            baseURL: settings.api_endpoint
        })
    }
}

export const addCourseAgentFiles = (id, data, progress) => {
    let formData = new FormData()
  
    // let filesArray = []
    data.forEach((item, key) => {
        // filesArray.push(item)
        formData.append('files[]', item)
    })
  

    formData.append('files', formData)

    return {
        type: 'ADD_COURSE_AGENT_FILES',
        payload: axios.request({
            url: '/course/' + id + '/agentFiles',
            method: 'POST',
            baseURL: settings.api_endpoint,
            data: formData,
			timeout: 10000000000000000,
			maxContentLength: 10000000000000000,
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: progress,
            params: {
                include: 'agentFiles,staffFiles'
            }
        })
    }
}

export const getCourseStaffFiles = (id) => {
    return {
        type: 'GET_COURSE_STAFF_FILES',
        payload: axios.request({
            url: '/course/' + id + '/staffFiles',
            method: 'GET',
            baseURL: settings.api_endpoint
        })
    }
}


export const addCourseStaffFiles = (id, data, progress) => {
    let formData = new FormData()
    
    // let filesArray = []
    data.forEach((item, key) => {
        // filesArray.push(item)
        formData.append('files[]', item)
    })
    formData.append('files', formData)
    return {
        type: 'ADD_COURSE_STAFF_FILES',
        payload: axios.request({
            url: '/course/' + id + '/staffFiles',
            method: 'POST',
            baseURL: settings.api_endpoint,
            data: formData,
			timeout: 10000000000000000,
			maxContentLength: 10000000000000000,
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: progress,
            params: {
                include: 'staffFiles,agentFiles'
            }
        })
    }
}
export const addCourseDateFiles = (id, data, progress) => {
    let formData = new FormData()
   
    // let filesArray = []
    data.forEach((item, key) => {
        // filesArray.push(item)
        formData.append('files[]', item)
    })
    

    formData.append('files', formData)
    return {
        type: 'UPDATE_COURSE_DATE_FILES',
        payload: axios.request({
            url: '/courseDate/' + id + '/files',
            method: 'POST',
            baseURL: settings.api_endpoint,
            data: formData,
			timeout: 10000000000000000,
			maxContentLength: 10000000000000000,
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: progress,
            params: {
                include: 'files'
            }
        })
    }
}

export const addSignupPoints = (signupId, data) => {
    return {
        type: 'ADD_SIGNUP_POINTS',
        payload: axios.request({
            url: '/courseSignup/' + signupId + '/points',
            method: 'POST',
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}
