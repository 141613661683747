import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
// import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import CardBox from '../../../../../components/CardBox';
import { connect } from 'react-redux'
// import ProfileBox from './ProfileBox'
// import ContactCard from './ContactCard'
import CoursesList from './CoursesList'
// import EditForm from './EditForm'
// import * as UserActions from '../../../../../actions/User'
import * as CoursesActions from '../../../../../actions/Courses'
import Widget from "../../../../../components/Widget";
import Auxiliary from "../../../../../util/Auxiliary";
import Dragndrop from '../../dragndrop'
import Tooltip from '@material-ui/core/Tooltip';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Auth from '../../../../../util/Auth'
import settings from '../../../../../config/settings.js'
// import CircularLoader from '../../../components/routes/progressbar/circular/CircularLoader'
import { Line } from 'rc-progress';
import DialogLoaderComponent from '../../../../../components/Loaders/DialogLoaderComponent';

class CourseDetails extends React.Component {

  constructor() {

    super()
    this.state = {
      progressCourseStaffFiles: 0,
      progressCourseAgentFiles: 0,
      courseFiles: [],
      courseFormFiles: [],
      courseAgentFiles: [],
      courseAgentFormFiles: []
    }

  }

  componentWillMount() {

    let slug = this.props.match.params.slug
    this.props.dispatch(CoursesActions.getCourseDetails(slug))
    this.props.dispatch(CoursesActions.getCourseDatesBySlug(slug))

    // this.props.dispatch(CoursesActions.getCourseStaffFiles(slug))

    // this.props.dispatch(UserActions.getUserDetails(userId))

  }

  componentDidUpdate(prevProps) {
    if (!prevProps.courses.addedCourseStaffFiles && this.props.courses.addedCourseStaffFiles) {
      NotificationManager.success("Pomyślnie dodano pliki do szkolenia!", "", 2000);
      this.clearCourseFormFiles()
    }
    if (!prevProps.courses.addedCourseAgentFiles && this.props.courses.addedCourseAgentFiles) {
      NotificationManager.success("Pomyślnie dodano pliki do szkolenia!", "", 2000);
      this.clearAgentCourseFormFiles()
    }
  }

  render() {
    // let match = this.props.match
    let data = this.props.courses.courseDetails.data

    return <Auxiliary>
      <NotificationContainer />
      <div className="jr-profile-banner" style={{
        backgroundImage: "url(" + data.image + ")",
        backgroundSize: 'cover'
      }}>
        <div className="jr-profile-container">
          <div className="jr-profile-banner-top">
            <div className="jr-profile-banner-top-left">

              <div className="jr-profile-banner-avatar-info">
                <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">{data.title}</h2>
                <p className="mb-0 jr-fs-lg">{data.category ? data.category?.name + ', ' : null}{data.level ? data.level.name : null}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="jr-profile-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <Widget styleName="jr-card-profile">

              <div className="mb-3">
                <h3 className="card-title mb-2 mb-md-3">Wstęp</h3>
                <p className="text-grey jr-fs-sm mb-0">{data.lead}</p>
              </div>
              <div className="mb-3">
                <h3 className="card-title mb-2 mb-md-3">Opis</h3>
                <div className="text-grey jr-fs-sm mb-0" dangerouslySetInnerHTML={{ __html: data.description ? data.description : 'Brak opisu' }}>
                </div>
              </div>
              <div className="mb-3">
                <h3 className="card-title mb-2 mb-md-3">Narzędziownik</h3>
                <div className="text-grey jr-fs-sm mb-0" dangerouslySetInnerHTML={{ __html: data.toolboxList ? data.toolboxList : 'Brak opisu' }}>
                </div>
              </div>
              {this.props.courses.courseDetails.loading ? <DialogLoaderComponent /> : null}
            </Widget>
          </div>
          <Auth roles={['super_admin', 'admin', 'training_staff']}>
            {this.renderCourseFiles()}
          </Auth>
          <Auth roles={['super_admin', 'admin', 'ph']}>
            {this.renderAgentCourseFiles()}
          </Auth>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <CardBox styleName="col-12" cardStyle="p-0" heading={"Terminy tego szkolenia"}
            headerOutside>
            <CoursesList
              list={this.props.courses.courseDates.list}
              loading={this.props.courses.courseDates.loading}

            />
          </CardBox>
        </div>
      </div>
    </Auxiliary>
  }

  renderCourseFiles() {
    // let slug = this.props.match.params.slug
    return <div className="col-xl-6">
      <Widget styleName="jr-card-profile">
        <div className="mb-3">
          <h3 className="card-title mb-2 mb-md-3">Materiały dla szkoleniowca</h3>
          <Auth roles={['super_admin', 'admin']}>
            <div className="row">
              <div className="col-xl-8">
                <Dragndrop text="Przeciągnij pliki" onFileChange={this.onFileChange.bind(this)} />
              </div>
              <div className="col-xl-4">
                {this.state.progressCourseStaffFiles !== 0 ? <Line percent={this.state.progressCourseStaffFiles} strokeWidth='3' strokeColor='#3f51b5' strokeLinecap='square' /> : null}
              </div>
            </div>
          </Auth>
          <div className="row mb-3">
            <div className="col-10">
              {Object.keys(this.props.courses.courseStaffFilesValidation).length > 0 ? <div className="text-red mb-2">Przesyłane pliki są zbyt duże.</div> : null}
              {this.state.courseFormFiles.length > 0 ? <h3 className="card-title mb-2 mb-md-3">Pliki do przesłania:</h3> : null}
              {this.state.courseFormFiles.map((item, key) => {
                return <div key={key} className="mb-1 row" style={{ clear: 'both' }} >
                  <div className="file-list-item col-10">
                    {item.name} - {Math.round((item.size / (1024 * 1024)) * 100) / 100}MB
                  </div>
                  <Tooltip className="pull-right col-2" onClick={this.deleteCourseStaffFormFile.bind(this, key)} id="tooltip-icon" title="Usuń plik" placement="top">
                    <Fab color="primary" className="bg-red text-white jr-fab-btn jr-btn-fab-xs" aria-label="delete">
                      <i className="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg" />
                    </Fab>
                  </Tooltip>
                </div>
              })}
            </div>
            {this.state.courseFormFiles.length > 0 ? <div className="col-xl-2">
              <Tooltip id="tooltip-icon" title="Wyślij" placement="top">
                <Fab onClick={this.addCourseStaffFiles.bind(this)} color="primary" className="jr-fab-btn" aria-label="add">
                  <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg" />
                </Fab>
              </Tooltip>
            </div> : null}
          </div>
          {this.props.courses.courseDetails.data.staffFiles ? this.renderExistingCourseFiles() : <p className="text-grey jr-fs-sm mb-0">Brak materiałów do tego szkolenia</p>}
          {this.props.courses.courseDetails.data.staffFiles ? <a href={settings.api_endpoint + '/course/' + this.props.courses.courseDetails.data.slug + '/staffFiles/zipped'} download>
            <Tooltip id="tooltip-icon" title="Pobierz materiały" placement="top">
              <Fab color="primary" className="jr-fab-btn" aria-label="add">
                <i className="zmdi zmdi-download zmdi-hc-fw zmdi-hc-lg" />
              </Fab>
            </Tooltip>
          </a> : null}
        </div>
        {this.props.courses.courseDetails.loading ? <DialogLoaderComponent /> : null}
      </Widget>
    </div>
  }

  renderAgentCourseFiles() {
    // let slug = this.props.match.params.slug
    return <div className="col-xl-6">
      <Widget styleName="jr-card-profile">
        <div className="mb-3">
          <h3 className="card-title mb-2 mb-md-3">Materiały dla przedstawiciela</h3>
          <Auth roles={['super_admin', 'admin']}>
            <div className="row">
              <div className="col-xl-8">
                <Dragndrop text="Przeciągnij pliki" onFileChange={this.onPHFileChange.bind(this)} />
              </div>
              <div className="col-xl-4">
                {this.state.progressCourseAgentFiles !== 0 ? <Line percent={this.state.progressCourseAgentFiles} strokeWidth='3' strokeColor='#3f51b5' strokeLinecap='square' /> : null}
              </div>
            </div>
          </Auth>
          <div className="row mb-3">
            <div className="col-10">
              {Object.keys(this.props.courses.courseAgentFilesValidation).length > 0 ? <div className="text-red mb-2">Przesyłane pliki są zbyt duże.</div> : null}
              {this.state.courseAgentFormFiles.length > 0 ? <h3 className="card-title mb-2 mb-md-3">Pliki do przesłania:</h3> : null}
              {this.state.courseAgentFormFiles.map((item, key) => {
                return <div key={key} className="mb-1 row" style={{ clear: 'both' }} >
                  <div className="file-list-item col-10">
                    {item.name} - {Math.round((item.size / (1024 * 1024)) * 100) / 100}MB
                  </div>
                  <Auth roles={['super_admin', 'admin']}>
                    <Tooltip className="pull-right col-2" onClick={this.deleteCourseAgentFormFile.bind(this, key)} id="tooltip-icon" title="Usuń plik" placement="top">
                      <Fab color="primary" className="bg-red text-white jr-fab-btn jr-btn-fab-xs" aria-label="delete">
                        <i className="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg" />
                      </Fab>
                    </Tooltip>
                  </Auth>
                </div>
              })}
            </div>
            {this.state.courseAgentFormFiles.length > 0 ? <div className="col-xl-2">
              <Tooltip id="tooltip-icon" title="Wyślij" placement="top">
                <Fab onClick={this.addCourseAgentFiles.bind(this)} color="primary" className="jr-fab-btn" aria-label="add">
                  <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg" />
                </Fab>
              </Tooltip>
            </div> : null}
          </div>
          {this.props.courses.courseDetails.data.agentFiles ? this.renderExistingAgentCourseFiles() : <p className="text-grey jr-fs-sm mb-0">Brak materiałow do tego szkolenia</p>}
          {this.props.courses.courseDetails.data.agentFiles ? <a href={settings.api_endpoint + '/course/' + this.props.courses.courseDetails.data.slug + '/agentFiles/zipped'} download>
            <Tooltip id="tooltip-icon" title="Pobierz materiały" placement="top">
              <Fab color="primary" className="jr-fab-btn" aria-label="add">
                <i className="zmdi zmdi-download zmdi-hc-fw zmdi-hc-lg" />
              </Fab>
            </Tooltip>
          </a> : null}
        </div>
        {this.props.courses.courseDetails.loading ? <DialogLoaderComponent /> : null}
      </Widget>
    </div>
  }

  renderExistingCourseFiles() {
    return this.props.courses.courseDetails.data.staffFiles.data.map((item, key) => {
      return <div className="mb-3 row">
        <a download rel="noopener noreferrer" target="_blank" href={item.urlFile} className="mb-1 file-list-item col-10" key={key}>{item.title}</a>
        <div className="col-2">
          <Tooltip onClick={this.deleteCourseStaffFile.bind(this, item.identifier)} className="pull-right" id="tooltip-icon" title="Usuń plik" placement="top">
            <Fab color="primary" className="bg-red text-white jr-fab-btn jr-btn-fab-xs" aria-label="delete">
              <i className="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg" />
            </Fab>
          </Tooltip>
        </div>
      </div>
    })
  }

  renderExistingAgentCourseFiles() {
    return this.props.courses.courseDetails.data.agentFiles.data.map((item, key) => {
      return <div className="mb-3 row">
        <a download rel="noopener noreferrer" target="_blank" href={item.urlFile} className="mb-1 file-list-item col-10" key={key}>{item.title}</a>
        <Auth roles={['super_admin', 'admin']}>
          <div className="col-2">
            <Tooltip onClick={this.deleteCourseAgentFile.bind(this, item.identifier)} className="pull-right" id="tooltip-icon" title="Usuń plik" placement="top">
              <Fab color="primary" className="bg-red text-white jr-fab-btn jr-btn-fab-xs" aria-label="delete">
                <i className="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg" />
              </Fab>
            </Tooltip>
          </div>
        </Auth>
      </div>
    })
  }

  deleteCourseStaffFile(fileId) {
    let courseId = this.props.match.params.slug
    this.props.dispatch(CoursesActions.deleteCourseStaffFiles(courseId, fileId))
  }

  deleteCourseAgentFile(fileId) {
    let courseId = this.props.match.params.slug
    this.props.dispatch(CoursesActions.deleteCourseAgentFiles(courseId, fileId))
  }

  deleteCourseStaffFormFile(id) {
    if (this.state.courseFormFiles.length === 1) {
      this.props.dispatch(CoursesActions.clearCourseStaffFilesValidation())
    }
    this.setState({
      ...this.state,
      courseFormFiles: this.state.courseFormFiles.filter((item, key) => key !== id)
    })
  }

  deleteCourseAgentFormFile(id) {
    if (this.state.courseFormFiles.length === 1) {
      this.props.dispatch(CoursesActions.clearCourseAgentFilesValidation())
    }
    this.setState({
      ...this.state,
      courseAgentFormFiles: this.state.courseAgentFormFiles.filter((item, key) => key !== id)
    })
  }

  onFileChange(e, files) {

    this.setState({
      ...this.state,
      courseFormFiles: files
    })
  }

  onPHFileChange(e, files) {

    this.setState({
      ...this.state,
      courseAgentFormFiles: files
    })
  }

  addCourseStaffFiles() {
    let slug = this.props.match.params.slug
    this.props.dispatch(CoursesActions.addCourseStaffFiles(slug, this.state.courseFormFiles, progressEvent => {
      var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
      if (percent >= 100) {
        this.setState({
          ...this.state,
          progressCourseStaffFiles: 0
        })
      } else {
        this.setState({
          ...this.state,
          progressCourseStaffFiles: percent
        })
      }
    }))
  }

  addCourseAgentFiles() {
    let slug = this.props.match.params.slug
    this.props.dispatch(CoursesActions.addCourseAgentFiles(slug, this.state.courseAgentFormFiles, progressEvent => {
      var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
      if (percent >= 100) {
        this.setState({
          ...this.state,
          progressCourseAgentFiles: 0
        })
      } else {
        this.setState({
          ...this.state,
          progressCourseAgentFiles: percent
        })
      }
    }))
  }

  clearCourseFormFiles() {
    this.setState({
      ...this.state,
      courseFormFiles: []
    })
  }

  clearAgentCourseFormFiles() {
    this.setState({
      ...this.state,
      courseAgentFormFiles: []
    })
  }
}

const mapStateToProps = (state) => {
  return {
    courses: state.courses
  }
}

export default connect(mapStateToProps)(CourseDetails)
