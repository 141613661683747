import React, { useMemo } from 'react'
import { TextField } from '@material-ui/core';
import { useCallback } from 'react';

const TextFieldComponent = (
    {
        multiline,
        customHelperText,
        label,
        className = '',
        placeholder,
        fullWidth = true,
        type = 'text',
        formikProps,
        rows = 1,
        id,
        onBlur = () => { },
        onChange = () => { },
        variant,
    }
) => {
    const value = useMemo(() => formikProps.values[id], [id, formikProps]);
    const error = useMemo(() => formikProps.errors[id], [id, formikProps]);
    const touched = useMemo(() => formikProps.touched[id], [id, formikProps]);
    const isError = () => {
        return error && Boolean(touched) ? true : false
    }
    const handleBlur = useCallback((event) => {
        onBlur(event);
        formikProps.handleBlur(event)
    }, [formikProps, onBlur])
    const handleChange = useCallback((event) => {
        onChange(event);
        formikProps.handleChange(event)
    }, [onChange, formikProps])
    return (
        <TextField
            error={customHelperText ? Boolean(customHelperText) : isError()}
            label={label}
            margin="normal"
            fullWidth={fullWidth}
            multiline={multiline}
            type={type}
            placeholder={placeholder}
            name={id}
            rows={rows}
            className={className}
            onChange={handleChange}
            onBlur={handleBlur}
            variant={variant}
            value={value}
            helperText={customHelperText ? customHelperText : isError() ? error : ' '}
        />
    )
}


export default TextFieldComponent;