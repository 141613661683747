import moment from 'moment'
import React, { useMemo } from 'react'
import TableComponent from '../../../components/Table/TableComponent'
import Auth from '../../../util/Auth'


const StatisticTableComponent = ({
	roles = [],
	badgeTitle = '',
	badge,
	hiddenSubtitle,
	cardBoxStyleName = 'col-md-6 col-12',
	range = { from: '', to: '' },
	loading,
	heading,
	onChange = () => { },
	titles = [],
	keyArr,
	data = {},
}) => {
	const { from, to } = useMemo(() => range, [range])
	const meta = useMemo(() => data && data[keyArr] && data[keyArr].meta
		? data[keyArr].meta
		: {}
		, [data, keyArr])
	const tranformedData = useMemo(() =>
		data && data[keyArr] && data[keyArr].list && data[keyArr].list.length
			? data[keyArr].list.map((item) => ({
				id: item.id,
				data: {
					...item,
				}
			}))
			: [], [data, keyArr])

	return (
		<Auth roles={['super_admin', 'admin', ...roles]}>
			<TableComponent
				hiddenDelete
				hiddenEdit
				hiddenShow
				meta={meta}
				rowsPerPage={[]}
				loading={loading}
				cardBoxStyleName={cardBoxStyleName}
				badge={badge ? `Całkowita liczba ${badgeTitle} : ${data && data[keyArr] && data[keyArr].totalQuantity ? data[keyArr].totalQuantity : 0}` : badge}
				onChangePage={onChange}
				headingStyle='pl-4 pr-4 pt-4 pb-0 mb-0'
				subtitle={!hiddenSubtitle ? `${moment(from).format('DD-MM-YYYY')} - ${moment(to).format('DD-MM-YYYY')}` : null}
				heading={heading}
				titles={titles}
				data={tranformedData}
			/>
		</Auth>
	)
}

export default StatisticTableComponent