// import {
//     GET_POS_LIST,
//     GET_POS_DETAILS
// } from '../constants/ActionTypes';

const INIT_STATE = {
    list: [],
    loading: false,
    nextPageLoading: false,
    posListMeta: {
        total: 0,
        current_page: 0,
        per_page: 15,
        count: 0
    },
    noOwnerPosList: {
        list: [],
        loaded: false
    },
    deletedPos: false,
    posDetails: {
        error: false,
        loaded: false,
        loading: false,
        data: {
            name: '',
            slug: '',
            salesman: '',
            void: '',
            town: '',
            email: '',
            phone: ''
        },
    },
    addPosForm: {
        errors: {},
        added: false
    },
    posAgent: {},
    updatedPosAgent: false,
    posWorkers: {
        loaded: false,
        loading: false,
        status: '',
        list: []
    },
    changedPosListForAgent: false,
    addWorkerToPosLoaded: false,
    updatedWorkerPos: false,
    updatedOwnerPos: false,
    posOwner: {}
};



export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'GET_POS_DETAILS_PENDING':
            return {
                ...state,
                posDetails: {
                    ...state.posDetails,
                    loading: true,
                    loaded: false
                }
            }
        case 'GET_POS_DETAILS_FULFILLED':
            return {
                ...state,
                posDetails: {
                    ...state.posDetails,
                    loading: false,
                    loaded: true,
                    data: action.payload.data.data
                }
            }
        case 'GET_POS_DETAILS_REJECTED':
            return state

        case 'GET_POS_OWNER_PENDING':
            return state
        case 'GET_POS_OWNER_FULFILLED':
            return {
                ...state,
                posOwner: action.payload.data.data
            }
        case 'GET_POS_OWNER_REJECTED':
            return {
                ...state,
                posOwner: {},
            }

        case 'GET_POS_WORKERS_PENDING':
            return {
                ...state,
                posWorkers: {
                    ...state.posWorkers,
                    loaded: false,
                    loading: true,
                }
            }
        case 'GET_POS_WORKERS_FULFILLED':
            return {
                ...state,
                posWorkers: {
                    ...state.posWorkers,
                    status: 'success',
                    loaded: true,
                    loading: false,
                    list: action.payload && action.payload.data ? action.payload.data.data : []
                }
            }
        case 'GET_POS_WORKERS_REJECTED':
            return {
                ...state,
                posWorkers: {
                    ...state.posWorkers,
                    list: [],
                    loaded: true,
                    loading: false,
                    status: 'error'
                }
            }

        case 'ADD_POS_PENDING':
            return {
                ...state,
                addPosForm: {
                    ...state.addPosForm,
                    added: false,
                    status: 'pending'
                }
            }
        case 'ADD_POS_REJECTED':
            return {
                ...state,
                addPosForm: {
                    errors: action.payload.response.data.error,
                    added: false,
                    status: 'error'
                }
            }
        case 'ADD_POS_FULFILLED':
            return {
                ...state,
                addPosForm: {
                    ...state.addPosForm,
                    added: true,
                    status: 'success'
                }
            }

        case 'EDIT_POS_PENDING':
            return {
                ...state,
                addPosForm: {
                    ...state.addPosForm,
                    added: false,
                    status: 'pending'
                }
            }
        case 'EDIT_POS_REJECTED':
            return {
                ...state,
                addPosForm: {
                    errors: action.payload.response.data.error,
                    added: false,
                    status: 'error'
                }
            }
        case 'EDIT_POS_FULFILLED':
            return {
                ...state,
                addPosForm: {
                    errors: {
                        ...state.addPosForm.errors
                    },
                    added: true,
                    status: 'success'
                }
            }


        case 'DELETE_POS_PENDING':
            return {
                ...state,
                deletedPos: false
            }
        case 'DELETE_POS_REJECTED':
            return {
                ...state,
                deletedPos: false
            }
        case 'DELETE_POS_FULFILLED':
            return {
                ...state,
                deletedPos: true,
                list: state.list.filter((item, key) => action.payload.data.data.identifier !== item.identifier)
            }

        case 'GET_POS_LIST_PENDING':
            return {
                ...state,
                loading: state.posListMeta && (state.posListMeta.current_page < 1),
                nextPageLoading: state.posListMeta && (state.posListMeta.current_page >= 1),
            }
        case 'GET_POS_LIST_REJECTED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
            }
        case 'GET_POS_LIST_FULFILLED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
                list: action.payload ? action.payload.data.data : [],
                posListMeta: action.payload && action.payload.data && action.payload.data.meta ? action.payload.data.meta.pagination : {}
            }

        case 'GET_NO_OWNER_POS_LIST_PENDING':
            return {
                ...state,
                noOwnerPosList: {
                    ...state.noOwnerPosList,
                    loaded: false,
                    status: ''
                }
            }
        case 'GET_NO_OWNER_POS_LIST_REJECTED':
            return {
                ...state,
                noOwnerPosList: {
                    ...state.noOwnerPosList,
                    loaded: false,
                    status: 'error'
                }
            }
        case 'GET_NO_OWNER_POS_LIST_FULFILLED':
            return {
                ...state,
                noOwnerPosList: {
                    list: action.payload.data.data,
                    loaded: true
                }
            }

        case 'GET_AGENT_POS_LIST_PENDING':
            return {
                ...state,
                loading: state.posListMeta && (state.posListMeta.current_page < 1),
                nextPageLoading: state.posListMeta && (state.posListMeta.current_page >= 1),
            }
        case 'GET_AGENT_POS_LIST_REJECTED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
            }
        case 'GET_AGENT_POS_LIST_FULFILLED':
            return {
                ...state,
                nextPageLoading: false,
                loading: false, 
                list: action.payload.data.data,
                posListMeta: action.payload.data.meta ? action.payload.data.meta.pagination : {}
            }

        case 'UPDATE_POS_AGENT_PENDING':
            return {
                ...state,
                updatedPosAgent: false
            }
        case 'UPDATE_POS_AGENT_REJECTED':
            return {
                ...state,
                updatedPosAgent: false
            }
        case 'UPDATE_POS_AGENT_FULFILLED':
            return {
                ...state,
                updatedPosAgent: true,
                posAgent: action.payload.data.data
            }

        case 'SET_POS_PAYMENT_PENDING':
            return {
                ...state,
                posDetails: {
                    ...state.posDetails,
                    error: false,
                    loading: true,
                }
            }
        case 'SET_POS_PAYMENT_REJECTED':
            return {
                ...state,
                posDetails: {
                    ...state.posDetails,
                    loading: false,
                    error: true,
                }
            }
        case 'SET_POS_PAYMENT_FULFILLED':
            return {
                ...state,
                posDetails: {
                    ...state.posDetails,
                    error: false,
                    loading: false,
                    data: {
                        ...action.payload.data.data
                    }
                }
            }

        case 'ADD_WORKER_TO_POS_PENDING':
            return {
                ...state,
                addWorkerToPosLoaded: false
            }
        case 'ADD_WORKER_TO_POS_REJECTED':
            return {
                ...state,
                addWorkerToPosLoaded: false
            }
        case 'ADD_WORKER_TO_POS_FULFILLED':
            return {
                ...state,
                posWorkers: {
                    ...state.posWorkers,
                    list: action.payload.data.data
                },
                addWorkerToPosLoaded: true
            }

        case 'UPDATE_WORKER_POS_PENDING':
            return {
                ...state,
                updatedWorkerPos: false
            }
        case 'UPDATE_WORKER_POS_REJECTED':
            return {
                ...state,
                updatedWorkerPos: false
            }
        case 'UPDATE_WORKER_POS_FULFILLED':
            return {
                ...state,
                // posWorkers: {
                //     ...state.posWorkers,
                //     list: action.payload.data.data
                // },
                updatedWorkerPos: true
            }

        case 'UPDATE_OWNER_POS_PENDING':
            return {
                ...state,
                updatedOwnerPos: false
            }
        case 'UPDATE_OWNER_POS_REJECTED':
            return {
                ...state,
                updatedOwnerPos: false
            }
        case 'UPDATE_OWNER_POS_FULFILLED':
            return {
                ...state,
                updatedOwnerPos: true
            }

        case 'GET_POS_AGENT_PENDING':
            return {
                ...state,
                posAgent: {
                    ...INIT_STATE.posAgent
                }
            }
        case 'GET_POS_AGENT_REJECTED':
            return {
                ...state
            }
        case 'GET_POS_AGENT_FULFILLED':
            return {
                ...state,
                posAgent: action.payload.data.data
            }

        case 'REWRITE_POS_TO_AGENT_PENDING':
            return {
                ...state,
                changedPosListForAgent: false
            }
        case 'REWRITE_POS_TO_AGENT_REJECTED':
            return {
                ...state,
                changedPosListForAgent: false
            }
        case 'REWRITE_POS_TO_AGENT_FULFILLED':
            return {
                ...state,
                changedPosListForAgent: true
            }

        case 'CLEAR_POS_FORM':
            return {
                ...state,
                addPosForm: {
                    errors: {},
                    added: false
                }
            }

        default:
            return state;
    }
}
