import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom'




function WorkersList(props) {

    return (
        <div className="table-responsive-material">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Termin szkolenia</TableCell>
                        <TableCell>Miejsce</TableCell>
                        <TableCell>Wolnych miejsc</TableCell>
                        <TableCell>Akcje</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.list.map(n => {
                        return (
                            <TableRow key={n.id}>
                                <TableCell>{n.data}</TableCell>
                                <TableCell>{n.placeName}</TableCell>
                                <TableCell>{n.freeSpots} z ({n.capacity})</TableCell>
                                <TableCell>
                                    <Link to={'/app/course-dates/details/' + n.identifier}>
                                        <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm mb-3">
                                            <i className="zmdi zmdi-eye" />
                                        </Fab>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}


export default WorkersList;