import React, {Component} from 'react';
import {DatePicker} from 'material-ui-pickers';

export default class DateAndTimePickers extends Component {

  constructor(props) {
    super(props)


    this.state = {
      selectedDate: props.initialDate,
    };
  }


  handleDateChange = (date) => {
    this.setState({selectedDate: date}, () => {
      let formattedDate = this.state.selectedDate.format('YYYY-MM-DD HH:mm:ss')

      this.props.onChange(this.props.name, formattedDate)
    });
  };

  render() {
    const {selectedDate} = this.state;
    return (
      <div key="datetime_default" className="picker">
        <DatePicker
          error={this.props.error}
          fullWidth
          hinttext="fr locale"
          locale='fr'
          // minDate={new Date()}
          value={selectedDate}
          showTabs={false}
          onChange={this.handleDateChange}
          leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
          rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
        />
      </div>)
  }
}
