import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
import { Route, Switch} from 'react-router-dom';
// import asyncComponent from '../../../util/asyncComponent';
// import Button from '@material-ui/core/Button';
// import CardBox from 'components/CardBox';
import List from './routes/list'
import Details from './routes/details'
// import AddUser from './AddUser'
import { connect } from 'react-redux'
import * as UserActions from '../../../actions/User'
import {NotificationContainer, NotificationManager} from 'react-notifications';

class User extends React.Component {

	constructor() {
		super()
		this.state = {
			addMode: false
		}
	}

	componentDidUpdate(prevProps) {

		if ( !prevProps.user.deletedUser && this.props.user.deletedUser ) {
			NotificationManager.success("Użytkownik został usunięty", "", 2000);	
		}
	}

	render() {
		let match = this.props.match
		return <div className="app-wrapper">
       				<NotificationContainer/>
			        <Switch>
			            <Route path={`${match.url}/`} exact component={List}/>
			            <Route path={`${match.url}/:slug`} component={Details}/>
					</Switch>
			    </div>

	}

	disableAddMode() {
		this.setState({
			...this.state,
			addMode: false
		})
	}

	toggleAddMode() {
		this.setState({
			...this.state,
			addMode: !this.state.addMode
		})
	}

	addUser(data) {
		this.props.dispatch(UserActions.addUser(data))
	}

}
const mapStateToProps = (state) => {
	return {
		user: state.user
	}
}

export default connect(mapStateToProps)(User)
