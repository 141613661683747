export const STATUS_PENDING = 'PENDING';
export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_CANCELLED = 'CANCELLED';

export const DATE_TIME_FORMAT = 'HH:mm DD-MM-YYYY'

export const STATUSES = {
    [STATUS_PENDING]: 'W toku',
    [STATUS_COMPLETED]: 'Opłacone',
    [STATUS_CANCELLED]: 'Anulowane',

}


export const INITIAL_PAGINATION = {
    per_page: 10,
    page: 0,
}

export const PERCENT_SALES_TYPE = 1;
export const PRICE_SALES_TYPE = 2;


export const SALES_TYPE = [
    {
        value: PERCENT_SALES_TYPE,
        label: `Rabat procentowy`
    },
    {
        value: PRICE_SALES_TYPE,
        label: `Rabat kwotowy`
    },
];