import React from 'react'
import OrderDataCardComponent from './OrderDataCardComponent';
import CardBox from '../CardBox';
import DialogLoaderComponent from '../Loaders/DialogLoaderComponent';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    cardCont: {
        width: '100%',
        margin: 'auto',
    }
}))

const OrderCardsComponent = ({ loading, hideDesc, heading = "Statystyki sprzędaży Aplikacji KEUNE", orderData = {}, styleName }) => {
    const classes = useStyles();
    
    return (
        <CardBox styleName={styleName} heading={heading}>
            {loading ? <DialogLoaderComponent /> : null}
            <div className={`row p-0 mt-4 ${classes.cardCont}`}>
                <OrderDataCardComponent
                    title="OD POCZĄTKU"
                    color="indigo"
                    hideDesc={hideDesc}
                    orders={orderData.allOrders}
                />
                <OrderDataCardComponent
                    title="W TYM ROKU"
                    color="pink"
                    hideDesc={hideDesc}
                    orders={orderData.yearOrders}
                />
                <OrderDataCardComponent
                    title="W TYM MIESIĄCU"
                    color="info"
                    hideDesc={hideDesc}
                    orders={orderData.monthOrders}
                />
            </div>
        </CardBox>
    )
}


export default OrderCardsComponent;
