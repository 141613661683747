import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from '../util/IntlMessages';
// import {
//     hideMessage,
//     showAuthLoader,
//     userFacebookSignIn,
//     userGithubSignIn,
//     userGoogleSignIn,
//     userSignIn,
//     userTwitterSignIn
// } from 'actions/Auth';
import * as UserActions from '../actions/User'
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DialogLoaderComponent from '../components/Loaders/DialogLoaderComponent';

class RecoverPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            email: ''
        }
    }

 
    handleInputChange(e) {
        let newState = {...this.state}
        newState[e.target.name] = e.target.value
        this.setState(newState)
    }

    resend() {
        this.props.dispatch(UserActions.resend(this.state))
    }

    componentDidUpdate(prevProps) {
        if ( prevProps.user.auth.status !== this.props.user.auth.status ) {
            if ( this.props.user.auth.status === 'invalid_credentials' ) {
                NotificationManager.error(this.props.user.auth.message)
            }
        }
    }

    render() {
        const {
            email,
            // password
        } = this.state;
        const {showMessage, loader, alertMessage} = this.props;
        let message = this.props.user.resendPassword.message
        let emailError = this.props.user.resendPassword.error.email ? this.props.user.resendPassword.error.email : ''
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    {/* <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img src="" alt="jambo" title="jambo"/>
                        </Link>
                    </div> */}

                    <div className="app-login-content position-relative">
                        <div className="app-login-header mb-4">
                            <h1>Przypominanie hasła!</h1>
                        </div>

                        <div className="app-login-form">
                                    <FormControl className="w-100">
                                        <TextField
                                            error={emailError}
                                            fullWidth
                                            name="email"
                                            label={emailError ? emailError : 'E-mail'}
                                            onChange={this.handleInputChange.bind(this)}
                                            defaultValue={email}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                        />
                                        {message ? <FormLabel component="legend">
                                                        <IntlMessages id={"response."+message}/>
                                                   </FormLabel> : null}
                                        <div className="mt-3 mb-3 d-flex align-items-center justify-content-between">
                                            <Button onClick={this.resend.bind(this)} color="primary" className="jr-btn text-white keune-btn">
                                                Wyślij
                                            </Button>
                                            <div></div>
                                            <Link to="/signin" className="keune-link">
                                                Logowanie
                                            </Link>
                                        </div>
                                    </FormControl>
                        </div>
                        <DialogLoaderComponent loaded={!loader} />
                    </div>
                </div>
                {showMessage && NotificationManager.error(alertMessage)}
                <NotificationContainer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // const {loader, alertMessage, showMessage, authUser} = auth;
    // return {loader, alertMessage, showMessage, authUser}
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(RecoverPassword);
