import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
import { Route, Switch} from 'react-router-dom';
// import asyncComponent from '../../../util/asyncComponent';
// import Button from '@material-ui/core/Button';
// import CardBox from 'components/CardBox';
// import List from './routes/list'
import Calendar from './routes/calendar'
import Details from './routes/details'
// import AddCourse from './AddCourse'
import { connect } from 'react-redux'
// import * as CoursesActions from '../../../actions/Courses'
// import {NotificationContainer, NotificationManager} from 'react-notifications';
import {NotificationContainer} from 'react-notifications';

class Courses extends React.Component {

	constructor() {
		super()
		this.state = {

		}
	}

	render() {
		let match = this.props.match
		return <div className="app-wrapper">
       				<NotificationContainer/>
       				<Switch>
			            <Route path={`${match.url}/calendar/:date?`} component={Calendar}/>
			            <Route path={`${match.url}/details/:id`} exact component={Details}/>
					</Switch>
			    </div>


	}

	

}
const mapStateToProps = (state) => {
	return {
		courses: state.courses
	}
}

export default connect(mapStateToProps)(Courses)
