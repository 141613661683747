import {
    // GET_USER_LIST,
    // GET_USER_DETAILS,
    SEARCH_USERS,
    // AUTH_USER,
} from '../constants/ActionTypes';

const INIT_STATE = {
    loading: false,
    nextPageLoading: false,
    list: [],
    freeWorkers: {
        list: [],
        loaded: false
    },
    freeAgents: {
        list: [],
        loaded: false
    },
    existingUsers: {
        list: [],
        loaded: false
    },
    deletedUser: false,
    userListMeta: {
        total: 0,
        current_page: 0,
        per_page: 15,
        count: 0
    },
    availableUsersForCourseDate: [],
    teachers: [],
    auth: {
        authorized: false,
        refresh: false,
        user: {
            roles: {}
        },
        status: '',
        message: ''
    },
    userCourses: {
        list: []
    },
    userDetails: {
        data: {
            firstName: '',
            lastName: '',
            email: '',
            roles: {}
        }
    },
    addUserFiles: {
        errors: {}
    },
    userFiles: {
        files: [],
        added: false,
        deleted: false,
        status: null
    },
    addUserForm: {
        errors: {},
        added: false
    },
    userForm: {

    },
    agentSaloons: {
        list: []
    },
    allSaloons: {
        list: [],
        loaded: false
    },
    editUserError: false,
    editedUser: false,
    addedAgentSaloon: false,
    resendPassword: {
        status: '',
        message: '',
        error: {}
    },
    changePassword: {
        status: '',
        message: '',
        error: {},
        done: false
    },
    addPoints: {
        status: '',
        added: false
    }
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'LOG_OUT':
            localStorage.removeItem("user")
            return {
                ...state,
                auth: INIT_STATE.auth
            }

        case 'AUTH_USER_PENDING':
            return {
                ...state,
                auth: {
                    ...state.auth,
                    message: '',
                    status: ''
                }
            }
        case 'AUTH_USER_REJECTED':
            const status = action.payload.response 
            ? action.payload.response.data.error 
                    ? action.payload.response.data.error
                    : action.payload.response.data.status
            : ''; 
            return {
                ...state,
                auth: {
                    ...state.auth,
                    status,
                    message: action.payload.response ? action.payload.response.data.message : ''
                }
            }
        case 'AUTH_USER_FULFILLED':
            if ( action.payload ) {
                localStorage.setItem("user", JSON.stringify(action.payload.data))
                return {
                    ...state, 
                    auth: {
                        ...state.auth,
                        authorized: true
                    }
                }
            } else {
                return {
                    ...state
                }
            }
            
        

        case 'REFRESH_TOKEN_PENDING':
            return {
                ...state,
                auth: {
                    ...state.auth,
                    message: '',
                    status: '',
                    refresh: false
                }
            }
        case 'REFRESH_TOKEN_FULFILLED':
            localStorage.setItem("user", JSON.stringify(action.payload.data))
            return {
                ...state, 
                auth: {
                    ...state.auth,
                    authorized: true,
                    refresh: true
                }
            }
        case 'REFRESH_TOKEN_REJECTED':
            return {
                ...state,
                auth: {
                    ...state.auth,
                    refresh: false
                }
            }

        case 'GET_AUTH_USER_PENDING':
            return state
        case 'GET_AUTH_USER_FULFILLED':
            return {
                ...state,
                auth: {
                    ...state.auth,
                    authorized: true,
                    user: action.payload&&action.payload.data ? action.payload.data.data : {}
                }
            }
        case 'GET_AUTH_USER_REJECTED':
            return state
            
        case 'GET_USER_DETAILS_PENDING':
            return {
                ...state,
                userDetails: {
                    ...INIT_STATE.userDetails,
                    loading: true
                }
            }
        case 'GET_USER_DETAILS_FULFILLED':
            return {
                ...state,
                userDetails: {
                    loaded: true,
                    loading: false,
                    data: action.payload&&action.payload.data ? action.payload.data.data : {}
                }
            }
        case 'GET_USER_DETAILS_REJECTED':
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    loading: false,
                    loaded: false
                }
            }

        case 'GET_TEACHERS_PENDING':
            return state
        case 'GET_TEACHERS_REJECTED':
            return state
        case 'GET_TEACHERS_FULFILLED':
            return {
                ...state,
                teachers: action.payload&&action.payload.data ? action.payload.data.data : []
            }

        case 'GET_USER_LIST_PENDING':
       
            return {
                ...state,
                loading: state.userListMeta && ( state.userListMeta.current_page < 1),
                nextPageLoading: state.userListMeta && (state.userListMeta.current_page >= 1),
            } 
        case 'GET_USER_LIST_REJECTED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
            }
        case 'GET_USER_LIST_FULFILLED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
                list: action.payload&&action.payload.data ? action.payload.data.data : [],
                userListMeta: action.payload&&action.payload.data&&action.payload.data.meta ? action.payload.data.meta.pagination : {}
            }

        case 'GET_EXISTING_USERS_PENDING':
            return {
                ...state,
                existingUsers: {
                    list: [],
                    loaded: false
                }
            }
        case 'GET_EXISTING_USERS_REJECTED':
            return {
                ...state,
                existingUsers: {
                    list: [],
                    loaded: false
                }
            }
        case 'GET_EXISTING_USERS_FULFILLED':
            return {
                ...state,
                existingUsers: {
                    list: action.payload.data.data,
                    loaded: true
                }
            }

        case 'GET_FREE_AGENTS_PENDING':
            return {
                ...state,
                freeAgents: {
                    ...state.freeAgents,
                    loaded: false
                }
            }
        case 'GET_FREE_AGENTS_REJECTED':
            return {
                ...state,
                freeAgents: {
                    ...state.freeAgents,
                    loaded: false
                }
            }
        case 'GET_FREE_AGENTS_FULFILLED':
            return {
                ...state,
                freeAgents: {
                    ...state.freeAgents,
                    list: action.payload ? action.payload.data.data : [],
                    loaded: false
                }
            }

        case 'GET_FREE_WORKERS_PENDING':
            return {
                ...state,
                freeWorkers: {
                    ...state.freeWorkers,
                    loaded: false
                }
            }
        case 'GET_FREE_WORKERS_REJECTED':
            return {
                ...state,
                freeWorkers: {
                    ...state.freeWorkers,
                    loaded: false
                }
            }
        case 'GET_FREE_WORKERS_FULFILLED':
            return {
                ...state,
                freeWorkers: {
                    ...state.freeWorkers,
                    list: action.payload.data.data,
                    loaded: false
                }
            }

        case 'DELETE_USER_PENDING':
            return {
                ...state,
                deletedUser: false
            }
        case 'DELETE_USER_REJECTED':
            return {
                ...state,
                deletedUser: false
            }
        case 'DELETE_USER_FULFILLED':
            return {
                ...state,
                deletedUser: true,
                list: state.list.filter((item, key) => action.payload.data.data.identifier !== item.identifier)
            }

        case 'EDIT_USER_PENDING':
            return {
                ...state,
                editedUser: false,
                editUserError: false
            }
        case 'EDIT_USER_REJECTED':
            return {
                ...state,
                editUserError: true,
                editedUser: false
            }
        case 'EDIT_USER_FULFILLED':
            return {
                ...state,
                editUserError: false,
                editedUser: true,
                list: state.list.map((item, key) => {
                    if ( item.identifier === action.payload.data.data.identifier ) {
                        item = action.payload.data.data
                    }
                    return item
                })
            }

        case 'GET_AGENT_SALOONS_PENDING':
            return {
                ...state
            }
        case 'GET_AGENT_SALOONS_REJECTED':
            return {
                ...state
            }
        case 'GET_AGENT_SALOONS_FULFILLED':
            return {
                ...state,
                agentSaloons: {
                    ...state.agentSaloons,
                    list: action.payload&&action.payload.data ? action.payload.data.data : []
                }
            }

        case 'ADD_USER_PENDING':
            return {
                ...state,
                addUserForm: {
                    ...state.addUserForm,
                    added: false
                }
            }
        case 'ADD_USER_REJECTED':
            return {
                ...state,
                addUserForm: {
                    ...state.addUserForm,
                    errors: action.payload.response.data.error
                }
            }
        case 'ADD_USER_FULFILLED':
            return {
                ...state,
                list: [action.payload.data.data, ...state.list],
                addUserForm: {
                    ...state.addUserForm,
                    errors: {},
                    added: true
                }
            }

        case 'GET_AVAILABLE_SALOONS_PENDING':
            return {
                ...state
            }
        case 'GET_AVAILABLE_SALOONS_REJECTED':
            return {
                ...state
            }
        case 'GET_AVAILABLE_SALOONS_FULFILLED':
            return {
                ...state,
                allSaloons: {
                    list: action.payload&&action.payload.data ? action.payload.data.data : [],
                    loaded: true
                }
            }

        

        case 'ADD_AGENT_SALOON_PENDING':
            return {
                ...state,
                addedAgentSaloon: false
            }
        case 'ADD_AGENT_SALOON_REJECTED':
            return {
                ...state,
                addedAgentSaloon: false
            }
        case 'ADD_AGENT_SALOON_FULFILLED':
            return {
                ...state,
                addedAgentSaloon: true
            }

        case 'DELETE_AGENT_SALOON_PENDING':
            return {
                ...state,
                addedAgentSaloon: false
            }
        case 'DELETE_AGENT_SALOON_REJECTED':
            return {
                ...state,
                addedAgentSaloon: false
            }
        case 'DELETE_AGENT_SALOON_FULFILLED':
            return {
                ...state,
                addedAgentSaloon: true
            }

        case 'GET_AVAILABLE_USERS_FOR_COURSE_DATE_PENDING':
            return {
                ...state
            }
        case 'GET_AVAILABLE_USERS_FOR_COURSE_DATE_REJECTED':
            return {
                ...state
            }
        case 'GET_AVAILABLE_USERS_FOR_COURSE_DATE_FULFILLED':
            return {
                ...state,
                availableUsersForCourseDate: action.payload.data.data
            }

        case 'GET_USER_COURSES_PENDING':
            return {
                ...state,
                   userCourses: {
                    ...INIT_STATE.userCourses,
                }
            }
        case 'GET_USER_COURSES_REJECTED':
            return {
                ...state
            }
        case 'GET_USER_COURSES_FULFILLED':
            return {
                ...state,
                userCourses: {
                    ...state.userCourses,
                    list: action.payload.data.data
                }
            }

        case 'CHANGE_SIGNUP_COURSE_DATE_PENDING':
            return {
                ...state
            }
        case 'CHANGE_SIGNUP_COURSE_DATE_REJECTED':
            return {
                ...state
            }
        case 'CHANGE_SIGNUP_COURSE_DATE_FULFILLED':
            return {
                ...state
            }

        case 'RESEND_PENDING':
            return {
                ...state,
                resendPassword: {
                    ...INIT_STATE.resendPassword
                }
            }
        case 'RESEND_REJECTED':
            return {
                ...state,
                resendPassword: {
                    ...state.resendPassword,
                    status: 'error',
                    error: action.payload.response.data.error,
                    message: action.payload.response.data.message
                }
            }
        case 'RESEND_FULFILLED':
            return {
                ...state,
                resendPassword: {
                    ...state.resendPassword,
                    status: 'success',
                    message: action.payload.data.message
                }
            }

        case 'CHANGE_PASSWORD_PENDING':
            return {
                ...state,
                changePassword: {
                    ...INIT_STATE.changePassword
                }
            }
        case 'CHANGE_PASSWORD_REJECTED':
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    status: 'error',
                    error: action.payload.response.data.error,
                    message: action.payload.response.data.message
                }
            }
        case 'CHANGE_PASSWORD_FULFILLED':
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    status: 'success',
                    message: action.payload.data.message
                }
            }

        case 'ADD_USER_POINTS_PENDING':
            return {
                ...state,
                addPoints: {
                    added: false,
                    status: ''
                }
            }
        case 'ADD_USER_POINTS_REJECTED':
            return {
                ...state,
                addPoints: {
                    added: false,
                    status: 'error'
                }
            }
        case 'ADD_USER_POINTS_FULFILLED':
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    data: {
                        ...state.userDetails.data,
                        ...action.payload.data.data
                    }
                },
                addPoints: {
                    added: true,
                    status: 'success'
                }
            }
        
        case 'CLEAR_USER_FORM_DATA':
            return {
                ...state,
                addUserForm: {
                    errors: {},
                    added: false
                }
            }

        case SEARCH_USERS:
            return {
                ...state
            }
        default:
            return state;
    }
}
