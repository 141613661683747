import { FormLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import {  useCallback } from 'react';
import Dropzone from 'react-dropzone';


const useStyles = makeStyles((theme) => ({
    error: {
        marginTop: 10,
    },
    dropzoneStyle: {
        width: "100%",
        height: "auto",
        border: "1px dashed #aaa"
    },
    label: {
        marginTop: 20,
        marginBottom: 10,
    }
}))

const DropComponent = ({ showError, error, img, setImg, setShowError, label = 'Obraz' }) => {
    const classes = useStyles();

    const onDrop = useCallback((accepted, rejected) => {
        setShowError(false);
        setImg(accepted[0])
        // setImg(files)
    }, [])
    return (
        <>
            <FormLabel className={classes.label}>{label}</FormLabel>
            <Dropzone
                className={`jr-wall-dropzone ${classes.dropzoneStyle}`} onDrop={onDrop}>
                {img ?
                    img.preview
                        ? <img src={img.preview} alt={'Preview'} />
                        : <img src={img} alt={'Preview'} />
                    :
                    <>
                        <i className="zmdi zmdi-collection-image" /> Przeciągnij zdjęcie
                </ >
                }
            </Dropzone>
            {showError ? <Typography color='error' className={classes.error}>{error}</Typography> : null}
        </>
    )
}


export default DropComponent;