import findItem from "./findItem";

export const handleCloseDelete = (setDelete = () => { }, setActive = () => { }) => {
	setDelete(false);
	setActive(false)
}

export const handleCloseAdd = (setData = () => { }, initialData, setOpenAdd = () => { }, setEdit = () => { }) => {
	setData(initialData);
	setOpenAdd(false);
	setEdit(false);
}

export const handleDelete = (activeItem, deleteDispatch = () => { }, setDeletePromoCode = () => {}) => {
	if (activeItem) {
		deleteDispatch(activeItem.identifier)
		setDeletePromoCode(false);
	}
}

export const handleShowDelete = (item, id, setActive = () => { }, setDelete = () => { },) => {
	setActive(findItem(item, id))
	setDelete(true);
}

export const handleClose = (setShow = () => { }) => {
	setShow(false)
}
export const handleAdd = (setOpen = () => { }) => {
	setOpen(true)
}

export const handleCloseShow = (setShow = () => { }) => {
	setShow(false)
}
export const handleShow = (items, id, setActive = () => { }, setShow = () => { }) => {
	if (items && items.list && items.list.length) {
		const item = findItem(items, id);
		setActive(item)
		setShow(true)
	}
}

export const onChangePage = (value, filters, setFilters = () => {}) => {
	setFilters({
		...filters,
		page: value + 1,
	})
}

export const changePerPage = (value, filters, setFilters = () => {}) => {
	setFilters({
		...filters,
		per_page: value.key,
	})
}
