import React from 'react';
import Dropzone from 'react-dropzone';

class Accept extends React.Component {
  constructor() {
    super();
    this.state = {
      accepted: [],
      rejected: []
    }
  }

  render() {
    const dropzoneStyle = {
      width  : "100%",
      height : "auto",
      border : "1px dashed #aaa"
  };
    return (
      <div className="dropzone-card">
        <div className="dropzone">
          <Dropzone
            accept=""
            onDrop={this.onDrop.bind(this)}
            style={dropzoneStyle}
          >
            {this.renderExistingImage()}
            {/* {
              this.state.accepted.map(f => <img width="100" src={f.preview} />)
            } */}
            {this.props.text ? this.props.text : 'Przeciągnij zdjęcia'}
          </Dropzone>
        </div>
      </div>
    );
  }

  

  renderExistingImage() {
    if ( this.props.existingImage ) {
      return this.props.existingImage.preview ? <img src={this.props.existingImage.preview} alt={'Preview'} /> : <img src={this.props.existingImage} alt={'Preview'} />
    } else {
      return null
    }
  }

  onDrop(accepted, rejected) {
    this.setState({accepted, rejected});
    this.props.onFileChange(this.props.name, this.state.accepted)
  }

}

export default Accept;