

let settings = {}

// http://localhost:8888/api/v1
// VceR7XbE08V9KKaxuMMNeBRCitXAQnYCIdC2IYCz
// 4
if ( process.env.NODE_ENV === "development" ) {
    settings = {
        client_secret: 'gUZNyPd5Ia7vroctPkxqmMoV0yKZOGVt6JCaRWzp',
        client_id: 2,
        api_endpoint: '',
    }
}

if ( process.env.NODE_ENV === "production" ) {
    settings = {
        client_secret: 'gUZNyPd5Ia7vroctPkxqmMoV0yKZOGVt6JCaRWzp',
        client_id: 2,
        api_endpoint: 'https://api.keune-akademia.pl'
    }
}

export default settings
