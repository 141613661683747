import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react'


const PublishCheckboxComponent = ({ formik = {}}) => {

	return (
		<FormControlLabel
			control={
				<Checkbox color="primary"
					onChange={formik.handleChange}
					checked={Boolean(formik.values.published)}
					name={'published'}
				/>
			}
			label="Opublikuj"
		/>
	)
}

export default React.memo(PublishCheckboxComponent);