import React from 'react';
import BasicTable from './Components/BasicTable';
import CardBox from '../../../../../components/CardBox/index';
// import IntlMessages from 'util/IntlMessages';
import { connect } from 'react-redux'
import * as PosActions from '../../../../../actions/Pos'
import * as UserActions from '../../../../../actions/User'
import Auth from '../../../../../util/Auth'
import AddPos from '../../AddPos'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert'

class BasicTables extends React.Component {

    constructor(props) {
        super()
        this.state = {
            searchQuery: '',
            addMode: false,
            editMode: false,
            editedPosKey: null,
            deleteWarning: false,
            selectedDeletionSlug: '',
            sort: {
                name: '',
                direction: true
            }
        }
    }

    componentWillMount() {
        let userID = this.props.user.auth.user.identifier
        this.props.dispatch(UserActions.getFreeAgents())

        if (this.props.user.auth.user.roles.slug === 'ph') {
            this.props.dispatch(PosActions.getAgentPosList({
                per_page: 15
            }, userID))
        } else {
            this.props.dispatch(PosActions.getPosList(this.createQueryParams()))
        }
    }

    componentDidUpdate(prevProps) {

        if (!prevProps.pos.deletedPos && this.props.pos.deletedPos) {
            NotificationManager.success("Salon został usunięty", "", 2000);
        }
        if (!prevProps.pos.addPosForm.added && this.props.pos.addPosForm.added) {
            NotificationManager.success("Pomyślnie dodano salon!", "", 2000);
            this.props.dispatch(PosActions.getPosList({
                per_page: 15,
                include: 'agent'
            }))
            this.toggleEditMode()
        }
    }

    disableAddMode() {
        this.props.dispatch(PosActions.clearPosForm())
        this.setState({
            ...this.state,
            addMode: false,
            editMode: false,
            editedPosKey: null
        })
    }

    toggleAddMode() {
        this.props.dispatch(PosActions.clearPosForm())
        this.setState({
            ...this.state,
            addMode: !this.state.addMode,
            editMode: false,
            editedPosKey: null
        })
    }

    toggleEditMode(key) {
        this.props.dispatch(PosActions.clearPosForm())
       
        this.setState({
            ...this.state,
            addMode: !this.state.addMode,
            editMode: !this.state.editMode,
            editedPosKey: key
        })
    }

    addPos(data) {
        this.props.dispatch(PosActions.addPos(data))
    }

    editPos(data) {
        this.props.dispatch(PosActions.editPos(this.props.pos.list[this.state.editedPosKey].identifier, data))
    }

    render() {
        console.log('this.props.pos.loading', this.props.pos)
        return <div className="animated slideInUpTiny animation-duration-3">
            <NotificationContainer />
            <Auth roles={['super_admin', 'admin']}>
                <AddPos editMode={this.state.editMode}
                    addPosForm={this.props.pos.addPosForm}
                    addPos={this.addPos.bind(this)}
                    agents={this.props.user.freeAgents}
                    editPos={this.editPos.bind(this)}
                    open={this.state.addMode}
                    data={this.props.pos.list}
                    togglePopup={this.toggleAddMode.bind(this)}
                    editedPosKey={this.state.editedPosKey} />
            </Auth>
            <div className="row mb-md-4">
                <div className="col-lg-6">
                    <div className="search-bar right-side-icon bg-transparent d-none d-sm-block">
                        <div className="form-group">
                            <input onChange={this.changeSearchQuery.bind(this)} className="form-control border-0" type="search" placeholder="Wyszukaj salonu..." />
                            <button className="search-icon"><i className="zmdi zmdi-search zmdi-hc-lg" /></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="row mb-md-4">
                        <CardBox addTooltip="Dodaj salon" styleName="col-12" cardStyle="p-0" heading={this.props.user.auth.user.roles.slug === "ph" ? "Twoje salony" : "Lista Salonów"}
                            headerOutside
                            addClick={this.toggleAddMode.bind(this)}
                            addBtn='zmdi-account-add'
                        >
                            <BasicTable data={this.props.pos.list}
                                pos={this.props.pos}
                                onDelete={this.onDelete.bind(this)}
                                toggleEditMode={this.toggleEditMode.bind(this)}
                                sort={this.state.sort}
                                changeSorting={this.changeSorting.bind(this)}
                                meta={this.props.pos.posListMeta}
                                loading={this.props.pos.loading}
                                nextPageLoading={this.props.pos.nextPageLoading}
                                onPageChange={this.getPosPage.bind(this)} />
                        </CardBox>
                    </div>
                </div>
            </div>
            <SweetAlert show={this.state.deleteWarning}
                warning
                showCancel
                confirmBtnText="Tak, usuń"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                cancelBtnText="Anuluj"
                title="Jesteś pewien?"
                onConfirm={this.onConfirmDelete.bind(this)}
                onCancel={this.onCancelDelete.bind(this)}
            >
                Salon zostanie bezpowrotnie usunięty!
                    </SweetAlert>
        </div>
    }

    changeSearchQuery(e) {
        this.setState({
            ...this.state,
            searchQuery: e.target.value
        }, () => {
            this.props.dispatch(PosActions.getPosList(this.createQueryParams()))
        })
    }

    getPosPage(url) {
        this.props.dispatch(PosActions.getPosList(null, url))
    }

    createQueryParams() {
        let direction = ''
        if (!this.state.sort.direction) {
            direction = '-'
        }
        return {
            'filter[name]': this.state.searchQuery,
            'sort': direction + this.state.sort.name,
            per_page: 15,
            include: 'agent',
            'filter[region]': ''
        }
    }

    changeSorting(name) {
        this.setState({
            ...this.state,
            sort: {
                name: name,
                direction: !this.state.sort.direction
            }
        }, () => {
           
            this.props.dispatch(PosActions.getPosList(this.createQueryParams()))
        })
    }

    onConfirmDelete() {
       
        this.props.dispatch(PosActions.deletePos(this.state.selectedDeletionSlug))
        this.setState({
            ...this.state,
            deleteWarning: false
        })
    }

    onCancelDelete() {
   
        this.setState({
            ...this.state,
            deleteWarning: false,
            selectedDeletionSlug: ''
        })
    }

    onDelete(slug) {
     
        this.setState({
            ...this.state,
            deleteWarning: !this.state.deleteWarning,
            selectedDeletionSlug: slug
        })
    }

}


const mapStateToProps = state => ({
    pos: state.pos,
    user: state.user
})


export default connect(mapStateToProps)(BasicTables)
