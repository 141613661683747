import { green, grey, orange, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import { STATUSES, STATUS_CANCELLED, STATUS_COMPLETED, STATUS_PENDING } from '../../../constants';


const getStatusColor = (status) => {
    switch (true) {
        case status === STATUS_COMPLETED:
            return green[500]
        case status === STATUS_PENDING:
            return orange[500]
        case status === STATUS_CANCELLED:
            return red[500]
        default:
            return grey[500]
    }
}
const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex',
        alignItems: 'center',
    },
    status: {
        width: 6,
        height: 6,
        marginRight: 3,
        borderRadius: '100%',
    }
}))

const OrderStatusComponent = ({ status }) => {
    const classes = useStyles();

    return (
        <section className={classes.cont}>
            <div className={classes.status} style={{ background: getStatusColor(status)}}></div>
            <span>
                {STATUSES[status]}
            </span>
        </section>
    )
}


export default OrderStatusComponent;