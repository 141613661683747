import {
    // GET_POS_LIST,
    // GET_POS_DETAILS,
    // GET_POS_WORKERS
} from '../constants/ActionTypes';
import axios from 'axios'
import settings from '../config/settings.js'


export const getFilesList = (params = {}, url = settings.api_endpoint + "/downloads") => {
    return {
        type: 'GET_FILES_LIST',
        payload: axios.get(url, {
            params: params
        })
    }
}

export const fetchFile = (id) => {
    return {
        type: 'FETCH_FILE',
        payload: axios.get(settings.api_endpoint + "/downloads/"+id)
    }
}

export const clearFormData = () => {
    return {
        type: 'CLEAR_FORM_DATA'
    }
}

export const addFile = (data, progress) => {
    let formData = new FormData()
   
    Object.keys(data).forEach((item, key) => {
        formData.append(item, data[item])
    })
    if ( data.files ) {
        if ( data.files.length > 0 ) {
			formData.append('file', data.files[0])
            // data.files.forEach((item, key) => {
            //     formData.append('file', item)
            // })
        }
    }
    return {
        type: "ADD_FILE",
        payload: axios.request({
            url: "/downloads",
            method: "post",
            baseURL: settings.api_endpoint,
            data: formData,
			timeout: 10000000000000000,
			maxContentLength: 10000000000000000,
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: progress
        })
    }
}

export const editFile = (id, data) => {
    let formData = new FormData()
    Object.keys(data).forEach((item, key) => {
        formData.append(item, data[item])
    })
    formData.append('_method', 'put')
    return {
        type: "EDIT_FILE",
        payload: axios.request({
            url: "/downloads/" + id,
            method: "post",
            baseURL: settings.api_endpoint,
            data: formData
        })
    }
}

export const deleteFile = (id) => {
    return {
        type: "DELETE_FILE",
        payload: axios.request({
            url: "/downloads/" + id,
            method: "delete",
            baseURL: settings.api_endpoint
        })
    }
}
