import React, {Component} from 'react';
import {convertToRaw, EditorState, ContentState, convertFromHTML} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class WYSISWYG extends Component {

  constructor(props) {
    super(props)
    this.loaded = false
    this.state = {
      // editorState: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.initialValue ? this.props.initialValue : "a")
        )
      )
    }
  }

  componentWillMount() {
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
 

    if ( prevProps.initialValue !== this.props.initialValue && !this.loaded ) {
      this.loaded = true
 

      this.setState({
        ...this.state,
        editorState: !convertFromHTML(this.props.initialValue).contentBlocks ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML("a")
          )
        ) : EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(this.props.initialValue)
          )
        )
      })
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  
    this.props.onEditorChange(this.props.name, value)
  };



  render() {
    const {editorState} = this.state;
    return (
      <div>
          <Editor editorStyle={{
            width: '100%',
            minHeight: 100,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'lightgray'
          }}
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  onEditorStateChange={this.onEditorStateChange}
          />
      </div>
    );
  }
}

export default WYSISWYG;
