import React from 'react';
import TextField from '@material-ui/core/TextField';
import ReactSelect from 'react-select'
// import DialogContentText from '@material-ui/core/DialogContentText';
// import { connect } from 'react-redux'
import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
import moment from 'moment'
import voids from '../../../util/Voids'
import DialogComponent from '../../../components/Dialog/DialogComponent';

export default class AddUser extends React.Component {

    constructor(props) {
        super()
        this.state = {
            pos: {
                title: '',
                nameAddress: '',
                phoneNumber: '',
                regionName: '',
                cityName: '',
                zipCode: '',
                agentID: null
            },
            errors: {
                title: '',
                nameAddress: '',
                phoneNumber: '',
                regionName: '',
                zipCode: '',
                cityName: ''
            },
            selectedAgent: null,
            selectedVoid: null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open && !this.props.open) {
            this.clearForm()
        }
        if (!prevProps.editMode && this.props.editMode && this.props.editedPosKey !== undefined && this.props.editedPosKey !== null) {
            let pos = this.props.data[this.props.editedPosKey]
            this.setState({
                ...this.state,
                pos: {
                    ...this.state.pos,
                    title: pos.title,
                    nameAddress: pos.nameAddress,
                    phoneNumber: pos.phoneNumber,
                    regionName: pos.regionName.id,
                    cityName: pos.cityName,
                    zipCode: pos.zipCode,
                    agentID: pos.agent ? pos.agent.data.identifier : null
                },
                selectedVoid: {
                    label: pos.regionName.name,
                    value: pos.regionName.id
                },
                selectedAgent: {
                    value: pos.agent ? pos.agent.data.identifier : null,
                    label: pos.agent ? pos.agent.data.firstName + ' ' + pos.agent.data.lastName : null
                }
            })
        }
    }

    createEditData() {
        let user = this.state.user
        // let originalUser = this.props.data[this.props.editedUserKey]

        return {
            title: user.title,
            lastName: user.lastName,
            email: user.email,
            roleID: user.roleID,
            phoneNumber: user.phoneNumber,
            birthdayDate: moment(user.birthdayDate, "YYYY-MM-DD").format("DD.MM.YYYY")
        }
    }

    render() {
        // let { title, nameAddress, regionName, cityName, phoneNumber } = this.props.addPosForm.errors
        let { regionName } = this.props.addPosForm.errors
        let titleError = this.props.addPosForm.errors.title ? true : false
        let nameAddressError = this.props.addPosForm.errors.nameAddress ? true : false
        let regionNameError = this.props.addPosForm.errors.regionName ? true : false
        let cityNameError = this.props.addPosForm.errors.cityName ? true : false
        let phoneNumberError = this.props.addPosForm.errors.phoneNumber ? true : false
        let zipError = this.props.addPosForm.errors.zip ? true : false

        let preparedAgentsList = this.props.agents.list.map((item, key) => {
            return {
                label: item.firstName + ' ' + item.lastName,
                value: item.identifier
            }
        })

        return (
            <form>
                <DialogComponent 
                    open={this.props.open}
                    btnSave={this.props.editMode ? 'Zapisz' : 'Dodaj'}
                    handleClose={this.closePopup.bind(this)}
                    handleSave={this.saveForm.bind(this)}
                    title={this.props.editMode ? 'Edytuj salon' : 'Dodaj salon'}
                >
                    <div className="col-md-6 mb-2">
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={titleError}
                            autoFocus
                            value={this.state.pos.title}
                            onChange={this.onChange.bind(this)}
                            margin="dense"
                            id="title"
                            label={this.props.addPosForm.errors.title ? this.props.addPosForm.errors.title : 'Nazwa salonu'}
                            type="text"
                            name="title"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6 mb-2">
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={nameAddressError}
                            autoFocus
                            value={this.state.pos.nameAddress}
                            onChange={this.onChange.bind(this)}
                            margin="dense"
                            id="nameAddress"
                            label={this.props.addPosForm.errors.nameAddress ? this.props.addPosForm.errors.nameAddress : 'Adres'}
                            type="text"
                            name="nameAddress"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6 mb-2">
                        <InputLabel error={regionNameError}
                            shrink>
                            {regionNameError ? regionName : 'Województwo'}
                        </InputLabel>
                        <ReactSelect
                            options={voids}
                            onChange={this.onVoidChange.bind(this)}
                            value={this.state.selectedVoid}
                            placeholder="Wybierz województwo" />
                    </div>
                    <div className="col-md-6 mb-2">
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={cityNameError}
                            autoFocus
                            value={this.state.pos.cityName}
                            onChange={this.onChange.bind(this)}
                            margin="dense"
                            id="cityName"
                            label={this.props.addPosForm.errors.cityName ? this.props.addPosForm.errors.cityName : 'Miasto'}
                            type="text"
                            name="cityName"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6 mb-2">
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={zipError}
                            autoFocus
                            value={this.state.pos.zipCode}
                            onChange={this.onChange.bind(this)}
                            margin="dense"
                            id="zip"
                            label={this.props.addPosForm.errors.zip ? this.props.addPosForm.errors.zip : 'Kod pocztowy'}
                            type="text"
                            name="zipCode"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6 mb-2">
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={phoneNumberError}
                            autoFocus
                            value={this.state.pos.phoneNumber}
                            onChange={this.onChange.bind(this)}
                            margin="dense"
                            id="phoneNumber"
                            label={this.props.addPosForm.errors.phoneNumber ? this.props.addPosForm.errors.phoneNumber : 'Numer telefonu'}
                            type="text"
                            name="phoneNumber"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6 mb-2">
                        <InputLabel error={false}
                            shrink>
                            Wybierz przedstawiciela handlowego
                        </InputLabel>
                        <ReactSelect
                            options={preparedAgentsList}
                            onChange={this.onAgentChange.bind(this)}
                            value={this.state.selectedAgent}
                            placeholder="Wyszukaj..." />
                    </div>
                </DialogComponent>
            </form>
        );
    }

    clearForm() {
        this.setState({
            ...this.state,
            pos: {
                title: '',
                nameAddress: '',
                phoneNumber: '',
                regionName: '',
                cityName: '',
                agentID: null
            },
            errors: {
                title: '',
                nameAddress: '',
                phoneNumber: '',
                regionName: '',
                cityName: ''
            },
            selectedVoid: null,
            selectedAgent: null
        })
    }

    onAgentChange(item) {
        this.setState({
            ...this.state,
            pos: {
                ...this.state.pos,
                agentID: item.value
            },
            selectedAgent: item
        })
    }

    closePopup() {
        this.props.togglePopup()
    }

    onVoidChange(item) {
        this.setState({
            ...this.state,
            pos: {
                ...this.state.pos,
                regionName: item.value
            },
            selectedVoid: item
        })
    }

    onChange(e, value) {
        let pos = { ...this.state.pos }
        if (e.target.value) {
            pos[e.target.name] = e.target.value
            this.setState({
                ...this.state,
                pos: pos
            })
        }

    }

    saveForm() {
        if (this.props.editMode) {
            this.props.editPos(this.state.pos)
        } else {
            this.props.addPos(this.state.pos)
        }
    }

}
