import axios from 'axios'
import settings from '../config/settings.js'



export const getOrderHistory = (params, url = settings.api_endpoint + "/mobile_order/archive") => {
    return {
        type: 'GET_ORDER_HISTORY',
        payload: axios.get(url, {
            params: params
        })
    }
}

