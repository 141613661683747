// import {
//     GET_USER_LIST,
//     GET_USER_DETAILS,
//     SEARCH_USERS,
//     AUTH_USER,
// } from '../constants/ActionTypes';

const INIT_STATE = {
    list: [],
    loading: false,
    nextPageLoading: false,
    coursesListMeta: {
        total: 0,
        current_page: 0,
        per_page: 15,
        count: 0
    },
    error: false,

    courseStaffFiles: [],
    addedCourseStaffFiles: false,
    courseStaffFilesValidation: {},

    courseAgentFiles: [],
    addedCourseAgentFiles: false,
    courseAgentFilesValidation: {},

    categories: [],
    levels: [],

    courseDateFilesValidation: {}, 
    addedCourseDateFiles: false,
    courseDateDetails: {
        loading: false,
        category: {},
        level: {},
        staffFiles: {
            data: []
        },
        files: {
            data: []
        },
        trainingStaff: {
            data: []
        }
    },
    courseDetails: {
        data: {
            description: 's',
            lead: '',
            title: '',
            staffFiles: {
                data: []
            }
        },
        loaded: true,
        loading: false,
    },
    addCourseDateForm: {
        errors: {},
        added: false,
        failed: false
    },
    addCourseForm: {
        errors: {},
        added: false,
        edited: false
    },
    courseForm: {

    },
    courseDates: {
        loading: false,
        list: [],
        meta: {}
    },
    courseDateSignups: [],
    signedUpForCourse: false,
    deletedCourseDate: false,
    deletedCourseSignup: false,
    updatedCourseSignup: false,
    updatedCourseSignupPresent: false,
    updatedCourseDateExpert: false,
    signups: {
        loading: false,
        list: []
    },
    addSignupPoints: {
        status: '',
        added: false
    }
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {


        case 'GET_COURSE_DETAILS_PENDING':
            return {
                ...state,
                courseDetails: {
                    ...state.courseDetails,
                    loading: true,
                    loaded: false
                }
            }
        case 'GET_COURSE_DETAILS_FULFILLED':
            return {
                ...state,
                courseDetails: {
                    loaded: true,
                    loading: false,
                    data: action.payload.data.data
                }
            }
        case 'GET_COURSE_DETAILS_REJECTED':
            return {
                ...state,
                courseDetails: {
                    ...state.courseDetails,
                    loading: false,
                    loaded: true,
                }
            }

        case 'SIGN_UP_FOR_COURSE_PENDING':
            return {
                ...state,
                signedUpForCourse: false
            }
        case 'SIGN_UP_FOR_COURSE_REJECTED':
            return {
                ...state,
                error: action.payload.response && action.payload.response.data && action.payload.response.data.error ? action.payload.response.data.error : 'Nie udało się dodać',
                signedUpForCourse: false
            }
        case 'SIGN_UP_FOR_COURSE_FULFILLED':
            return {
                ...state,
                signedUpForCourse: true
            }

        case 'GET_COURSES_LIST_PENDING':
            return {
                ...state,
                loading: state.coursesListMeta && (state.coursesListMeta.current_page < 1),
                nextPageLoading: state.coursesListMeta && (state.coursesListMeta.current_page >= 1),
            }
        case 'GET_COURSES_LIST_REJECTED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
            }
        case 'GET_COURSES_LIST_FULFILLED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
                list: action.payload&&action.payload.data ? action.payload.data.data : [],
                coursesListMeta: action.payload&&action.payload.data&&action.payload.data.meta ? action.payload.data.meta.pagination : {}
            }

        case 'GET_COURSES_CATEGORIES_PENDING':
            return state
        case 'GET_COURSES_CATEGORIES_REJECTED':
            return state
        case 'GET_COURSES_CATEGORIES_FULFILLED':
            return {
                ...state,
                categories: action.payload&&action.payload.data ? action.payload.data.data : []
            }

        case 'GET_COURSE_PENDING':
            return state
        case 'GET_COURSE_REJECTED':
            return state
        case 'GET_COURSE_FULFILLED':
            return {
                ...state
            }

        case 'GET_COURSES_LEVELS_PENDING':
            return state
        case 'GET_COURSES_LEVELS_REJECTED':
            return state
        case 'GET_COURSES_LEVELS_FULFILLED':
            return {
                ...state,
                levels: action.payload&&action.payload.data ? action.payload.data.data : {}
            }

        case 'DELETE_COURSE_PENDING':
            return {
                ...state
            }
        case 'DELETE_COURSE_REJECTED':
            return {
                ...state
            }
        case 'DELETE_COURSE_FULFILLED':
            return {
                ...state,
                list: state.list.filter((item, key) => action.payload.data.data.identifier !== item.identifier)
            }

        case 'ADD_COURSE_DATE_PENDING':
            return {
                ...state,
                addCourseDateForm: {
                    ...state.addCourseDateForm,
                    added: false,
                    errors: [],
                    failed: false
                }
            }
        case 'ADD_COURSE_DATE_REJECTED':
            return {
                ...state,
                addCourseDateForm: {
                    ...state.addCourseDateForm,
                    errors: action.payload.response.data.error ? action.payload.response.data.error : {...state.addCourseDateForm.errors},
                    added: false,
                    failed: action.payload.response.data.error ? false : true
                }
            }
        case 'ADD_COURSE_DATE_FULFILLED':
            return {
                ...state,
                courseDates: {
                    ...state.courseDates,
                    list: [...state.courseDates.list, action.payload.data.data]
                },
                addCourseDateForm: {
                    ...state.addCourseDateForm,
                    errors: [],
                    added: true,
                    failed: false
                }
            }

        case 'ADD_COURSE_PENDING':
            return {
                ...state,
                addCourseForm: {
                    ...state.addCourseForm,
                    added: false
                }
            }

        case 'ADD_COURSE_FULFILLED':
            return {
                ...state,
                list: [action.payload.data.data, ...state.list],
                addCourseForm: {
                    ...state.addCourseForm,
                    added: true
                }
            }
        case 'ADD_COURSE_REJECTED':
            return {
                ...state,
                addCourseForm: {
                    ...state.addCourseForm,
                    errors: action.payload.response.data ? action.payload.response.data.error : {...state.addCourseForm.errors}
                }
            }

        case 'EDIT_COURSE_PENDING':
            return {
                ...state,
                addCourseForm: {
                    ...state.addCourseForm,
                    edited: false
                }
            }
        case 'EDIT_COURSE_REJECTED':
            return {
                ...state,
                addCourseForm: {
                    ...state.addCourseForm,
                    errors: action.payload.response.data ? action.payload.response.data.error : {...state.addCourseForm.errors},
                    edited: false
                }
            }
        case 'EDIT_COURSE_FULFILLED':
            return {
                ...state,
               addCourseForm: {
                   ...state.addCourseForm,
                   errors: {},
                   edited: true
               }
            }

        case 'CREATE_COURSE_DATE':
            return {
                ...state,
                courseDates: {
                    ...state.courseDates,
                    list: [...state.courseDates.list, action.payload]
                }
            }

        case 'CHANGE_SIGNUP_STATUS_PENDING':
            return {
                ...state,
                updatedCourseSignup: {
                    error: false,
                    success: false,
                    message: '',
                }
            }
        case 'CHANGE_SIGNUP_STATUS_REJECTED':
            return {
                ...state,
                updatedCourseSignup: {
                    ...state.updatedCourseSignup,
                    error: true,
                    message: action.payload.response.data.error
                }
            }
        case 'CHANGE_SIGNUP_STATUS_FULFILLED':
            return {
                ...state,
                updatedCourseSignup: {
                    error: false,
                    success: true,
                    message: ''
                }
            }


        case 'GET_COURSE_DATE_SIGNUPS_PENDING':
            return {
                ...state
            }
        case 'GET_COURSE_DATE_SIGNUPS_REJECTED':
            return {
                ...state
            }
        case 'GET_COURSE_DATE_SIGNUPS_FULFILLED':
            return {
                ...state,
                courseDateSignups: action.payload.data.data
            }

        case 'GET_COURSE_DATE_DETAILS_PENDING':
            return {
                ...state,
                courseDateDetails: {
                    ...state.courseDateDetails,
                    loading: true,
                }
            }
        case 'GET_COURSE_DATE_DETAILS_REJECTED':
            return {
                ...state,
                 courseDateDetails: {
                     ...state.courseDateDetails,
                     loading: false,
                }
            }
        case 'GET_COURSE_DATE_DETAILS_FULFILLED':
            return {
                ...state,
                courseDateDetails: {
                    loading: false,
                    ...action.payload.data.data
                }
            }

        case 'UPDATE_COURSE_DATE_PENDING':
            return {
                ...state,
                addCourseDateForm: {
                    ...state.addCourseDateForm,
                    errors: [],
                    added: false,
                    failed: false
                }
            }
        case 'UPDATE_COURSE_DATE_REJECTED':
            return {
                ...state,
                addCourseDateForm: {
                    ...state.addCourseDateForm,
                    errors: action.payload.response.data.error ? action.payload.response.data.error : {...state.addCourseDateForm.errors},
                    added: false,
                    failed: true
                }
            }
        case 'UPDATE_COURSE_DATE_FULFILLED':
            return {
                ...state,
                courseDateDetails: {
                    ...state.courseDateDetails,
                    ...action.payload.data.data
                },
                addCourseDateForm: {
                    ...state.addCourseDateForm,
                    errors: [],
                    added: true,
                    failed: false
                }
            }
        case 'UPDATE_COURSE_DATE_FILES_PENDING':
            return {
                ...state,
                addedCourseDateFiles: false,
                courseDateFilesValidation: {}
            }
        case 'UPDATE_COURSE_DATE_FILES_REJECTED':
            return {
                ...state,
                addedCourseDateFiles: false,
                courseDateFilesValidation: action.payload.response ? action.payload.response.data.error : { ...state.courseDateFilesValidation },
            }
        case 'UPDATE_COURSE_DATE_FILES_FULFILLED':
            return {
                ...state,
                addedCourseDateFiles: true,
                courseDateFilesValidation: {},
                courseDateDetails: {
                    ...state.courseDateDetails,
                    ...action.payload.data.data
                },
            }

        case 'UPDATE_COURSE_DATE_EXPERT_PENDING':
            return {
                ...state,
                updatedCourseDateExpert: false
            }
        case 'UPDATE_COURSE_DATE_EXPERT_REJECTED':
            return {
                ...state,
                updatedCourseDateExpert: false
            }
        case 'UPDATE_COURSE_DATE_EXPERT_FULFILLED':
            return {
                ...state,
                courseDateDetails: action.payload.data.data,
                updatedCourseDateExpert: true
            }

        case 'UPDATE_COURSE_SIGNUP_PRESENT_PENDING':
            return {
                ...state,
                updatedCourseSignupPresent: false
            }
        case 'UPDATE_COURSE_SIGNUP_PRESENT_REJECTED':
            return {
                ...state,
                updatedCourseSignupPresent: false
            }
        case 'UPDATE_COURSE_SIGNUP_PRESENT_FULFILLED':
            return {
                ...state,
                updatedCourseSignupPresent: true,
                courseDateSignups: state.courseDateSignups.map((item, key) => {
                    if ( item.identifier === action.payload.data.data.identifier ) {
                        return action.payload.data.data
                    } else {
                        return item
                    }
                })
            }

        case 'GET_COURSE_DATES_BY_SLUG_PENDING':
            return {
                ...state,
                courseDates: {
                    loading: true,
                    list: [],
                    meta: {}
                }
            }
        case 'GET_COURSE_DATES_BY_SLUG_REJECTED':
            return {
                ...state,
                courseDates: {
                    ...state.courseDates,
                    loading: false,
                }
            }
        case 'GET_COURSE_DATES_BY_SLUG_FULFILLED':
            return {
                ...state,
                courseDates: {
                    loading: false,
                    list: action.payload.data.data,
                    meta: action.payload.data.meta.pagination
                }
            }

        case 'GET_COURSE_DATES_PENDING':
            return {
                ...state,
                courseDates: {
                    loading: true,
                    list: []
                }
            }
        case 'GET_COURSE_DATES_REJECTED':
            return {
                ...state,
                courseDates: {
                    ...state.courseDates,
                    loading: false,
                }
            }
        case 'GET_COURSE_DATES_FULFILLED':
            return {
                ...state,
                courseDates: {
                    loading: false,
                    list: action.payload.data.data
                }
            }

        case 'ADD_COURSE_STAFF_FILES_PENDING':
            return {
                ...state,
                addedCourseStaffFiles: false,
                courseStaffFilesValidation: {}

            }
        case 'ADD_COURSE_STAFF_FILES_REJECTED':
            return {
                ...state,
                addedCourseStaffFiles: false,
                courseStaffFilesValidation: action.payload.response.data.error
            }
        case 'ADD_COURSE_STAFF_FILES_FULFILLED':
            return {
                ...state,
                addedCourseStaffFiles: true,
                courseDetails: {
                    ...state.courseDetails,
                    data: action.payload.data.data
                },
                courseStaffFilesValidation: {}
            }
        case 'ADD_COURSE_AGENT_FILES_PENDING':
            return {
                ...state,
                addedCourseAgentFiles: false,
                courseAgentFilesValidation: {}

            }
        case 'ADD_COURSE_AGENT_FILES_REJECTED':
            return {
                ...state,
                addedCourseAgentFiles: false,
                courseAgentFilesValidation: action.payload.response.data.error
            }
        case 'ADD_COURSE_AGENT_FILES_FULFILLED':
            return {
                ...state,
                addedCourseAgentFiles: true,
                courseDetails: {
                    ...state.courseDetails,
                    data: action.payload.data.data
                },
                courseAgentFilesValidation: {}
            }

        case 'CLEAR_COURSE_AGENT_FILES_VALIDATION':
            return {
                ...state,
                courseAgentFilesValidation: {}
            }

        case 'DELETE_COURSE_DATE_PENDING':
            return {
                ...state,
                deletedCourseDate: false
            }
        case 'DELETE_COURSE_DATE_REJECTED':
            return {
                ...state,
                deletedCourseDate: false
            }
        case 'DELETE_COURSE_DATE_FULFILLED':
            return {
                ...state,
                deletedCourseDate: true
            }

        case 'DELETE_COURSE_SIGNUP_PENDING':
            return {
                ...state,
                deletedCourseSignup: false
            }
        case 'DELETE_COURSE_SIGNUP_REJECTED':
            return {
                ...state,
                deletedCourseSignup: false
            }
        case 'DELETE_COURSE_SIGNUP_FULFILLED':
            return {
                ...state,
                deletedCourseSignup: true
            }

        case 'DELETE_COURSE_STAFF_FILES_PENDING':
            return {
                ...state
            }
        case 'DELETE_COURSE_STAFF_FILES_REJECTED':
            return {
                ...state
            }
        case 'DELETE_COURSE_STAFF_FILES_FULFILLED':
            return {
                ...state,
                courseDetails: {
                    ...state.courseDetails,
                    data: action.payload.data.data
                }
            }
        case 'DELETE_COURSE_DATE_FILES_PENDING':
            return {
                ...state
            }
        case 'DELETE_COURSE_DATE_FILES_REJECTED':
            return {
                ...state
            }
        case 'DELETE_COURSE_DATE_FILES_FULFILLED':
            return {
                ...state,
                courseDateDetails: {
                    ...state.courseDateDetails,
                    ...action.payload.data.data
                },
            }

        case 'DELETE_COURSE_AGENT_FILES_PENDING':
            return {
                ...state
            }
        case 'DELETE_COURSE_AGENT_FILES_REJECTED':
            return {
                ...state
            }
        case 'DELETE_COURSE_AGENT_FILES_FULFILLED':
            return {
                ...state,
                courseDetails: {
                    ...state.courseDetails,
                    data: action.payload.data.data
                }
            }

        case 'GET_COURSE_STAFF_FILES_PENDING':
            return {
                ...state
            }
        case 'GET_COURSE_STAFF_FILES_REJECTED':
            return {
                ...state
            }
        case 'GET_COURSE_STAFF_FILES_FULFILLED':
            return {
                ...state,
                courseStaffFiles: action.payload.data.data
            }

        case 'GET_SIGNUPS_PENDING':
            return {
                ...state,
                signups: {
                    ...state.signups,
                    loaded: false
                }
            }
        case 'GET_SIGNUPS_REJECTED':
            return {
                ...state,
                signups: {
                    ...state.signups,
                    loaded: false
                }
            }
        case 'GET_SIGNUPS_FULFILLED':
            return {
                ...state,
                signups: {
                    list: action.payload.data.data,
                    loaded: true
                }
            }

        case 'ADD_SIGNUP_POINTS_PENDING':
            return {
                ...state,
                addSignupPoints: {
                    added: false,
                    status: ''
                }
            }
        case 'ADD_SIGNUP_POINTS_REJECTED':
            return {
                ...state,
                addSignupPoints: {
                    added: false,
                    status: 'error'
                }
            }
        case 'ADD_SIGNUP_POINTS_FULFILLED':
            return {
                ...state,
                addSignupPoints: {
                    added: true,
                    status: 'success'
                }
            }

        default:
            return state;
    }
}
