// import MailNotification from '../MailNotification/index';
import AppNotification from '../AppNotification/index';
import CardHeader from '../dashboard/Common/CardHeader/index';
import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
// import asyncComponent from '../../../util/asyncComponent';
// import Button from '@material-ui/core/Button';
// import CardBox from 'components/CardBox';
import { connect } from 'react-redux'
import * as NotificationActions from '../../actions/Notifications'
// import * as NotificationsActions from '../actions/Notifications'
// import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';

class Notifications extends React.Component {

  constructor() {
    super()
    this.state = {
      appNotification: false
    }
  }
  onGetNotif = (params) => {
    this.props.dispatch(NotificationActions.getNotifications(params))
  }
  componentDidMount() {
   
    this.onGetNotif({ per_page: 5 });

  }

  onNotificationClick(id = 1) {
   
    this.props.dispatch(NotificationActions.setNotificationAsRead(id))

  }

  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    })
  };

  render() {
    let notifications = this.props.notifications.notifications
    let isBouncing = notifications.unread ? 'wobble' : ''
    let isUnread = notifications.unread ? 'icon-alert' : ''
    let currentPage = notifications.pagination.current_page;
    let totalPages = notifications.pagination.total_pages;
    let preparedNotifications = notifications.list.filter((item) => {
      return item.subjectID
    })

    return <ul className="header-notifications list-inline ml-auto">
      <li className="list-inline-item app-tour">
        <Dropdown
          className="quick-menu"
          isOpen={this.state.appNotification}
          toggle={this.onAppNotificationSelect.bind(this)}>
          <DropdownToggle
            className="d-inline-block"
            tag="span"
            data-toggle="dropdown">
            <IconButton className="icon-btn">
              <i className={"zmdi zmdi-notifications-none animated infinite " + isBouncing + ' ' + isUnread} />
            </IconButton>
          </DropdownToggle>
          <DropdownMenu right>
            <CardHeader styleName="align-items-center"
              heading="Powiadomienia" />
            <AppNotification onClick={this.onNotificationClick.bind(this)} list={preparedNotifications} totalPages={totalPages} currentPage={currentPage} onLoadMore={this.onGetNotif.bind(this)} />
          </DropdownMenu>
        </Dropdown>
      </li>
    </ul>
  }
}
const mapStateToProps = (state) => {
  return {
    notifications: state.notifications
  }
}

export default connect(mapStateToProps)(Notifications)
