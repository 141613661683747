const INIT_STATE = {
    courseDates: {
        data: 0,
        loading: false,
        status: ''
    },
    signups: {
        data: 0,
        loading: false,
        status: ''
    },
    agentsSignups: {
        data: [],
        loading: false,
        status: ''
    },
    signupsDone: {
        data: 0,
        loading: false,
        status: ''
    },
    posLogged: {
        data: 0,
        loading: false,
        meta: {}
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'GET_AGENTS_SIGNUPS_PENDING':
            return {
                ...state,
                agentsSignups: {
                    ...state.agentsSignups,
                    loading: true,
                    status: ''
                }
            }

        case 'GET_AGENTS_SIGNUPS_REJECTED':
            return {
                ...state,
                agentsSignups: {
                    ...state.agentsSignups,
                    loading: false,
                    status: 'error'
                }
            }

        case 'GET_AGENTS_SIGNUPS_FULFILLED':
            return {
                ...state,
                agentsSignups: {
                    ...state.agentsSignups,
                    data: action.payload && action.payload.data
                        ? action.payload.data.data
                        : [],
                    loading: false,
                    status: 'success'
                }
            }

        case 'GET_COURSE_DATES_STATS_PENDING':
            return {
                ...state,
                courseDates: {
                    ...state.courseDates,
                    loading: true
                }
            }

        case 'GET_COURSE_DATES_STATS_REJECTED':
            return {
                ...state,
                courseDates: {
                    ...state.courseDates,
                    loading: false,
                    status: 'error'
                }
            }

        case 'GET_COURSE_DATES_STATS_FULFILLED':
            return {
                ...state,
                courseDates: {
                    ...state.courseDates,
                    loading: false,
                    data: action.payload && action.payload.data
                        ? action.payload.data.data
                        : null,
                    status: 'success'
                }
            }

        case 'GET_SIGNUPS_STATS_PENDING':
            return {
                ...state,
                signups: {
                    ...state.signups,
                    loading: true
                }
            }
        case 'GET_SIGNUPS_STATS_REJECTED':
            return {
                ...state,
                signups: {
                    ...state.signups,
                    loading: false,
                    status: 'error'
                }
            }

        case 'GET_SIGNUPS_STATS_FULFILLED':
            return {
                ...state,
                signups: {
                    ...state.signups,
                    loading: false,
                    data: action.payload && action.payload.data
                        ? action.payload.data.data
                        : null,
                    status: 'success'
                }
            }

        case 'GET_SIGNUPS_DONE_PENDING':
            return {
                ...state,
                signupsDone: {
                    ...state.signupsDone,
                    loading: true
                }

            }
        case 'GET_SIGNUPS_DONE_REJECTED':
            return {
                ...state,
                signupsDone: {
                    ...state.signupsDone,
                    loading: false,
                    status: 'error'
                }
            }

        case 'GET_SIGNUPS_DONE_FULFILLED':
            return {
                ...state,
                signupsDone: {
                    ...state.signupsDone,
                    loading: false,
                    data: action.payload && action.payload.data
                        ? action.payload.data.data
                        : null,
                    status: 'succes'
                }
            }
        case 'GET_POS_LOGGED_PENDING':
            return {
                ...state,
                posLogged: {
                    ...state.posLogged,
                    loading: true
                }

            }
        case 'GET_POS_LOGGED_REJECTED':
            return {
                ...state,
                posLogged: {
                    ...state.posLogged,
                    loading: false,
                    status: 'error'
                }
            }

        case 'GET_POS_LOGGED_FULFILLED':
            const newPostLogged = action.payload && action.payload.data
                ? action.payload.data
                : {};
            console.log("action.payload", action.payload.data.posLogged)
            return {
                ...state,
                posLogged: {
                    ...state.posLogged,
                    loading: false,
                    status: 'succes',
                    ...newPostLogged
                }
            }

        default:
            return state;
    }
}
