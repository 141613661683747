import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom'
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Auth from '../../../../../util/Auth'
import { Fab, Paper } from '@material-ui/core';
import DialogLoaderComponent from '../../../../../components/Loaders/DialogLoaderComponent';
import TableLoaderComponent from '../../../../../components/Loaders/TableLoaderComponent';

// let id = 0;

export default class UsersList extends React.Component {

    constructor(props) {
        super()
        this.state = {}
    }

    render() {
        const { meta } = this.props;
        return <div className="table-responsive-material">
            <TableLoaderComponent
                loading={this.props.loading}
            >
                <Paper >
                    <section className='position-relative' >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Tooltip
                                            title="Sortuj"
                                            placement="bottom-end"
                                            enterDelay={300} >
                                            <div>
                                                <TableSortLabel
                                                    active={this.props.sort.name === "first_name" ? true : false}
                                                    direction={!this.props.sort.direction ? "asc" : "desc"}
                                                    onClick={this.changeSorting.bind(this, "first_name")} >
                                                    Imię
                                                </TableSortLabel>
                                                <TableSortLabel
                                                    active={this.props.sort.name === "last_name" ? true : false}
                                                    direction={!this.props.sort.direction ? "asc" : "desc"}
                                                    onClick={this.changeSorting.bind(this, "last_name")} >
                                                    Nazwisko
                                                </TableSortLabel>
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                    {this.props.user.auth.user.roles.slug === 'admin' ? <TableCell className="d-none d-md-table-cell">
                                        <TableSortLabel
                                            active={this.props.sort.name === "agent" ? true : false}
                                            direction={!this.props.sort.direction ? "asc" : "desc"}
                                            onClick={this.changeSorting.bind(this, "agent")} >
                                            Przedstawiciel
                                        </TableSortLabel>
                                    </TableCell> : null}
                                    <TableCell className="d-none d-md-table-cell">
                                        <TableSortLabel
                                            active={this.props.sort.name === "ownerName" ? true : false}
                                            direction={!this.props.sort.direction ? "asc" : "desc"}
                                            onClick={this.changeSorting.bind(this, "ownerName")} >
                                            Właściciel salonu
                                        </TableSortLabel>
                                    </TableCell>
                                    {/* <TableCell className="d-none d-md-table-cell">E-mail</TableCell> */}
                                    <TableCell className="d-none d-md-table-cell">
                                        <TableSortLabel
                                            active={this.props.sort.name === "roles" ? true : false}
                                            direction={!this.props.sort.direction ? "asc" : "desc"}
                                            onClick={this.changeSorting.bind(this, "roles")} >
                                            Rola
                                        </TableSortLabel>
                                    </TableCell>
                                    {this.props.activeFilter === 'minusPoint' || this.props.user.auth.user.roles.slug === 'ph' ? <TableCell className="d-none d-md-table-cell">
                                        <TableSortLabel
                                            active={this.props.sort.name === "points" ? true : false}
                                            direction={!this.props.sort.direction ? "asc" : "desc"}
                                            onClick={this.changeSorting.bind(this, "points")} >
                                            Punkty
                                        </TableSortLabel>
                                    </TableCell> : null}
                                    <TableCell>Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.props.data && this.props.data.length ?
                                <TableBody>
                                    {this.props.data.map((n, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <TableCell>
                                                    <Link to={'/app/users/' + n.identifier} className="keune-link">
                                                        {n.firstName + ' ' + n.lastName}
                                                    </Link>
                                                </TableCell>
                                                {this.props.user.auth.user.roles.slug === 'admin' ? <TableCell className="d-none d-md-table-cell">{n.pos && n.pos.data && n.pos.data.agent && n.pos.data.agent.data ? `${n.pos.data.agent.data.firstName || ''} ${n.pos.data.agent.data.lastName || ''}` : '-'}</TableCell> : null}
                                                <TableCell className="d-none d-md-table-cell">{n.pos && n.pos.data ? n.pos.data.ownerName : '-'}</TableCell>
                                                {/* <TableCell className="d-none d-md-table-cell">{n.email}</TableCell> */}
                                                <TableCell className="d-none d-md-table-cell">{n.roles.name}</TableCell>
                                                {this.props.activeFilter === 'minusPoint' || this.props.user.auth.user.roles.slug === 'ph' ? <TableCell className="d-none d-md-table-cell">{n.pointBalance}</TableCell> : null}
                                                <TableCell>
                                                    <Tooltip id="tooltip-icon" title="Podgląd użytkownika" placement="top">
                                                        <Link to={'/app/users/' + n.identifier} className="mr-1">
                                                            <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm">
                                                                <i className="zmdi zmdi-eye" />
                                                            </Fab>
                                                        </Link>
                                                    </Tooltip>
                                                    <Auth roles={['super_admin', 'admin']}>
                                                        <Tooltip onClick={this.toggleEditMode.bind(this, key)} id="tooltip-icon" title="Edytuj użytkownika" placement="top">
                                                            <Fab className="jr-fab-btn bg-amber text-white jr-btn-fab-sm mr-1">
                                                                <i className="zmdi zmdi-edit" />
                                                            </Fab>
                                                        </Tooltip>
                                                    </Auth>
                                                    <Auth roles={['super_admin', 'admin']}>
                                                        <Tooltip id="tooltip-icon" title="Usuń użytkownika" placement="top">
                                                            <Fab onClick={this.onDelete.bind(this, n.identifier)} className="jr-fab-btn bg-red text-white jr-btn-fab-sm">
                                                                <i className="zmdi zmdi-delete" />
                                                            </Fab>
                                                        </Tooltip>
                                                    </Auth>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    }
                                </TableBody>
                                : null
                            }
                        </Table>
                        {this.props.nextPageLoading
                            ? <DialogLoaderComponent opacity />
                            : null
                        }
                    </section>
                    <TablePagination
                        count={meta ? meta.total : 0}
                        rowsPerPage={meta ? meta.per_page : 0}
                        page={meta ? meta.current_page - 1 : 0}
                        labelRowsPerPage={false}
                        component='div'
                        onChangePage={this.onChangePage.bind(this)}
                        onChangeRowsPerPage={this.props.changePerPage.bind(this)}
                    />
                </Paper>
            </TableLoaderComponent>
        </div>
    }

    authUserHasRole(roles) {
        if (roles.includes(this.props.user.auth.user.roles.slug)) {
            return true
        } else {
            return false
        }
    }

    toggleEditMode(key) {
        this.props.toggleEditMode(key)
    }

    onDelete(slug) {
        this.props.onDelete(slug)
    }

    changeSorting(name) {
        this.props.changeSorting(name)
    }

    onChangePage(e, dir) {

        if (dir < this.props.meta.current_page) {
            this.props.onPageChange(this.props.meta.links.previous)
        } else {
            this.props.onPageChange(this.props.meta.links.next)
        }
    }

}
