import React, { useCallback, useMemo } from 'react'
import CardBox from './CardBox';
import DayPicker, { DateUtils } from 'react-day-picker'
import moment from 'moment'
import 'moment/locale/pl'

const DayPickerComponent = ({ range = {}, setRange = () => {} }) => {
	const initialMonth = useMemo(() => new Date(new moment().subtract(1, 'month')), [])
	const handleDayClick = useCallback((day) => {
		const newRange = DateUtils.addDayToRange(day, range);
		setRange(newRange)
	}, [range])
	return (
		<CardBox styleName="col-12" heading="Filtry">
			{/* <div className="row"> */}
				{/* <div className="col-md-12"> */}
					<DayPicker
						selectedDays={[range.from, range]}
						className="Selectable"
						numberOfMonths={3}
						modifiers={{ start: range.from, end: range.to }}
						initialMonth={initialMonth}
						firstDayOfWeek={1}
						onDayClick={handleDayClick} />
				{/* </div> */}
			{/* // </div> */}
		</CardBox>
	)
}

export default DayPickerComponent;