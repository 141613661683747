import React from 'react';
import Table from '@material-ui/core/Table'; import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableHead from '@material-ui/core/TableHead'; import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
// import { Link } from 'react-router-dom'
import TablePagination from '@material-ui/core/TablePagination';
import Auth from '../../../../../util/Auth'
import Tooltip from '@material-ui/core/Tooltip';
import settings from '../../../../../config/settings.js'
import { Paper } from '@material-ui/core';
import DialogLoaderComponent from '../../../../../components/Loaders/DialogLoaderComponent';
import TableLoaderComponent from '../../../../../components/Loaders/TableLoaderComponent';

export default class FilesList extends React.Component {

    constructor(props) {
        super()
        this.state = {}
    }
    render() {
        return <div className="table-responsive-material">
            <TableLoaderComponent
                loading={this.props.loading}
            >
            <Paper>
                    <section className='position-relative' >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.data.map((n, key) => {
                          
                            return (
                                <TableRow key={key}>
                                    <TableCell>{n.title}</TableCell>
                                    <TableCell>
                                        {n.roles.map((item, key) => {
                                            let str = ''
                                            if (item === 3) {
                                                str += 'Przedstawiciel handlowy, '
                                            }
                                            if (item === 4) {
                                                str += 'Właściciel salonu, '
                                            }
                                            if (item === 5) {
                                                str += 'Pracownik salonu, '
                                            }
                                            if (item === 6) {
                                                str += 'Kadra szkoleniowa, '
                                            }
                                            return str
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <a download href={settings.api_endpoint + "/downloads/" + n.identifier + "/zipped"} target="_blank" rel="noopener noreferrer">
                                            <Tooltip className="mr-1" id="tooltip-icon" title="Pobierz" placement="top">
                                                <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm">
                                                    <i className="zmdi zmdi-download" />
                                                </Fab>
                                            </Tooltip>
                                        </a>
                                        <Auth roles={['super_admin', 'admin']}>
                                            <Tooltip className="mr-1" id="tooltip-icon" title="Edytuj" placement="top">
                                                <Fab onClick={this.toggleEditMode.bind(this, n.identifier)} className="jr-fab-btn bg-amber text-white jr-btn-fab-sm">
                                                    <i className="zmdi zmdi-edit" />
                                                </Fab>
                                            </Tooltip>
                                            <Tooltip id="tooltip-icon" title="Usuń" placement="top" onClick={this.onDelete.bind(this, n.identifier)}>
                                                <Fab className="jr-fab-btn bg-red text-white jr-btn-fab-sm">
                                                    <i className="zmdi zmdi-delete" />
                                                </Fab>
                                            </Tooltip>
                                        </Auth>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                        {this.props.nextPageLoading
                            ? <DialogLoaderComponent opacity />
                            : null
                        }
                    </section>
                <TablePagination
                    count={this.props.meta.total || 0}
                    rowsPerPage={this.props.meta.per_page || 0}
                    page={this.props.meta.current_page - 1 || 0}
                    labelRowsPerPage={false}
                    component='div'
                    onChangePage={this.onChangePage.bind(this)}
                    onChangeRowsPerPage={this.onChangePage.bind(this)}
                />
            </Paper>
              </TableLoaderComponent>
        </div>
    }

    toggleEditMode(id) {
        this.props.toggleAddMode(true, id)
    }

    onDelete(id) {
        this.props.onDelete(id)
    }

    onChangePage(e, dir) {

        if (dir < this.props.meta.current_page) {
            this.props.onPageChange(this.props.meta.links.previous)
        } else {
            this.props.onPageChange(this.props.meta.links.next)
        }
    }

}

// function FilesList(props) {

//     return (
//         <div className="table-responsive-material">
//             <Table>
//                 <TableHead>
//                     <TableRow>
//                         <TableCell>Imię i nazwisko</TableCell>
//                         <TableCell>POS</TableCell>
//                         <TableCell>E-mail</TableCell>
//                         <TableCell>Akcje</TableCell>
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {props.data.map((n, key) => {
//                         return (
//                             <TableRow key={key}>
//                                 <TableCell>{n.firstName+' '+n.lastName}</TableCell>
//                                 <TableCell>{n.pos}</TableCell>
//                                 <TableCell>{n.email}</TableCell>
//                                 <TableCell>
//                                     <Link to={'/app/users/' + n.identifier}>
//                                         <Button variant="fab" className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm mb-3">
//                                             <i className="zmdi zmdi-eye" />
//                                         </Button>
//                                     </Link>
//                                     <Link to="/edit">
//                                         <Button variant="fab" className="jr-fab-btn bg-amber text-white jr-btn-fab-sm mb-3">
//                                             <i className="zmdi zmdi-edit" />
//                                         </Button>
//                                     </Link>
//                                     <Link to="/delete">
//                                         <Button variant="fab" className="jr-fab-btn bg-red text-white jr-btn-fab-sm mb-3">
//                                             <i className="zmdi zmdi-delete" />
//                                         </Button>
//                                     </Link>
//                                 </TableCell>
//                             </TableRow>
//                         );
//                     })}
//                 </TableBody>
//                 <TablePagination
//                     count={32}
//                     rowsPerPage={5}
//                     page={0}
//                     onChangePage={changePage}
//                     onChangeRowsPerPage={changePage}
//                   />
//             </Table>
//         </div>
//     );
// }

// function changePage() {

// }
