const INIT_STATE = {
    list: [],
    loading: false,
    tableLoading: false,
    nextPageLoading: false,
    pagination: {},
};


export default (state = INIT_STATE, { payload, type}) => {
    switch (type) {


        case 'GET_ORDERS_APP_PENDING':
            return {
                ...state,
                error: false,
                loading: true,
                tableLoading: state.pagination && !state.pagination.current_page,
                nextPageLoading: state.pagination && (state.pagination.current_page >= 1),
            }

        case 'GET_ORDERS_APP_REJECTED':
            return {
                ...state,
                error: 'Nie udało się załadować nowe dane',
                loading: false,
                tableLoading: false,
                nextPageLoading: false,
            }

        case 'GET_ORDERS_APP_FULFILLED':
            return {
                ...state,
                list: payload.data.data ? payload.data.data : [],
                loading: false,
                tableLoading: false,
                nextPageLoading: false,
                pagination: payload.data.meta ? payload.data.meta.pagination : {}
            }
        case 'UPDATE_ORDER_APP_PENDING':
            return {
                ...state,
                error: false,
                loading: true
            }

        case 'UPDATE_ORDER_APP_REJECTED':
            return {
                ...state,
                error: 'Nie udało się załadować nowe dane',
                loading: false
            }

        case 'UPDATE_ORDER_APP_FULFILLED':
            return {
                ...state,
                list: state.list && state.list.length ? state.list.map((item) => item.identifier === payload.data.data.identifier ? payload.data.data : item) : [],
                loading: false,
            }
        default:
            return state;
    }
}
