import React, { useCallback, useState } from 'react'
import { useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { getOrderHistory } from '../../actions';
import TableComponent from '../../components/Table/TableComponent';
import { IconButton } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { INITIAL_PAGINATION } from '../../constants';


const OrdersHistoryPage = ({ ordersHistory, getOrderHistory }) => {
    const [filters, setFilters] = useState(INITIAL_PAGINATION)

    useEffect(() => {
        if (ordersHistory.error) {
            NotificationManager.error(ordersHistory.error, "", 2000);
        }
    }, [ordersHistory])

    useEffect(() => {
        getOrderHistory(filters);
    }, [filters])


    const onChangePage = useCallback((e, value) => {
        setFilters({
            ...filters,
            page: value + 1,
        })
    }, [filters])

    const changePerPage = useCallback((e, value) => {
        setFilters({
            ...filters,
            per_page: value.key,
        })
    }, [filters])

    return (
        <div className="app-wrapper animated slideInUpTiny animation-duration-3">
            <NotificationContainer />
            <div className="row mb-md-4">
                <TableComponent
                    headerOutside
                    hiddenShow
                    hiddenEdit
                    loading={ordersHistory.loading}
                    nextPageLoading={ordersHistory.nextPageLoading}
                    hiddenDelete
                    heading='Historia zamówień'
                    onChangePage={onChangePage}
                    changePerPage={changePerPage}
                    meta={ordersHistory.pagination}
                    titles={['Nazwa', 'Plik']}
                    hiddenPagination
                    data={ordersHistory && ordersHistory.list && ordersHistory.list.length
                        ? ordersHistory.list.map((order, key) => ({
                            id: key,
                            data: {
                                name: order.split('/').pop(),
                                link:
                                    <a href={order} target="_blank" rel="nofollow noopener noreferrer">
                                    <IconButton color='primary'>
                                        <InsertDriveFileIcon />
                                    </IconButton>
                                 </a>
                            }
                        }))
                        : []
                    }
                />
            </div>
        </div>
    )
}


export default connect((state) => ({
    ordersHistory: state.ordersHistory
}), {
    getOrderHistory,
})(OrdersHistoryPage);