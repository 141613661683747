import axios from 'axios'
import settings from '../config/settings.js'



export const getSlidersApp = (params, url = settings.api_endpoint + "/mobile_slide") => {
    return {
        type: 'GET_SLIDERS_APP',
        payload: axios.get(url, {
            params: params
        })
    }
}

export const addSlidersApp = (params, url = settings.api_endpoint + "/mobile_slide") => {
    return {
        type: 'ADD_SLIDERS_APP',
        payload: axios.post(url, params)
    }
}

export const updateSlidersApp = (data, url = settings.api_endpoint + "/mobile_slide") => {
    return {
        type: 'UPDATE_SLIDERS_APP',
        payload: axios.post(`${url}/${data.id}`, data.params)
    }
}
export const deleteSlidersApp = (id, url = settings.api_endpoint + "/mobile_slide") => {
    return {
        type: 'DELETE_SLIDERS_APP',
        payload: axios.delete(`${url}/${id}`)
    }
}
