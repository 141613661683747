import axios from 'axios'
import { STATISTICS_MOBILE_AGENTS, STATISTICS_MOBILE_CITIES, STATISTICS_MOBILE_POS, STATISTICS_MOBILE_AGENTS_POS, STATISTICS_MOBILE_ADDED, STATISTICS_MOBILE_BOUGHT, STATISTICS_MOBILE_SALONS_ADDED, STATISTICS_MOBILE_USERS_ADDED } from '../constants/api.js'
import settings from '../config/settings.js'

const formatRequest = (url, params) => axios.get(`${settings.api_endpoint}/${url}`, {
	params: params
})
export const getAppStatistic = (params = {}, roles) => {
	let requestAgents = {}
	let apiArr = [];
	const requestMostCities = formatRequest(STATISTICS_MOBILE_CITIES, params)
	const requestMostAdded = formatRequest(STATISTICS_MOBILE_ADDED, params)
	const requestMostPos = formatRequest(STATISTICS_MOBILE_POS, params)
	// const requestMostPromoCodes = formatRequest(STATISTICS_PROMO_CODES, params)
	const requestMostBought = formatRequest(STATISTICS_MOBILE_BOUGHT, params)
	const requestUsersAdded = formatRequest(STATISTICS_MOBILE_USERS_ADDED, params)
	apiArr.push(requestMostCities, requestMostAdded, requestMostPos, requestMostBought, requestUsersAdded)
	if (roles === 'super_admin' || roles ===  'admin'){
		const requestSalonsAdded = formatRequest(STATISTICS_MOBILE_SALONS_ADDED, params)
		if (params.agent)
			requestAgents = formatRequest(STATISTICS_MOBILE_AGENTS_POS, params)
		else
			requestAgents = formatRequest(STATISTICS_MOBILE_AGENTS, params)
		apiArr.push(requestSalonsAdded, requestAgents)
	}	
	return {
		type: 'GET_APP_STATISTIC',
		payload: axios.all(apiArr)
	}
}

export const getAppStatisticPagination = (key, params) => {
	return {
		type: 'GET_APP_STATISTIC_PAGINATION',
		payload: formatRequest(key, params)
	}
}


export const getAppStatisticAgent = (params) => {
	return {
		type: 'GET_APP_STATISTIC_AGENT_POS',
		payload: formatRequest(STATISTICS_MOBILE_AGENTS_POS, params)
	}
}
