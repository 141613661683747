import React from 'react';

export default class Condition extends React.Component {

	render() {
		
		if ( this.props.custom && this.props.component && this.props.condition ) {
			return this.props.component()
		}
		if ( this.props.custom && this.props.condition ) {
			return this.props.render()
			// return <div></div>
		} else {
			return this.props.condition ? this.props.children : null

		}

			    
	}

}

