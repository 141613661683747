const INIT_STATE = {
    loading: false,
    nextPageLoading: false,
    filesList: {
        loaded: false,
        list: [],
        meta: {},
    },
    fileDetails: {
        data: {},
        loading: false,
    },
    addFilesForm: {
        errors: {},
        added: false,
        loading: false,
        edited: false,
        status: ''
    },
    deletedFile: false,
    systemError: []
}


export default (state = INIT_STATE, action) => {



    if ( action.payload ) {
            if ( action.payload.response ) {
                if ( action.payload.response.status === 400 ) {
                    return {
                        ...state,
                        systemError: [...state.systemError, action.payload.response.data.error]
                    }
                }
        }

    }


    switch (action.type) {

        case 'GET_FILES_LIST_PENDING':
            return {
                ...state,
                loading: state.filesList && state.filesList.meta && !state.filesList.meta.current_page,
                nextPageLoading: state.filesList && state.filesList.meta && (state.filesList.meta.current_page >= 1),
                filesList: {
                    ...state.filesList,
                    loaded: false
                }
            }
        case 'GET_FILES_LIST_FULFILLED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
                filesList: {
                    loaded: true,
                    list: action.payload.data.data,
                    meta: action.payload && action.payload.data && action.payload.data.meta ? action.payload.data.meta.pagination : {},
                }
            }
        case 'GET_FILES_LIST_REJECTED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
                addFilesForm: {
                    ...state.addFilesForm,
                    errors: action.payload.response.data ? action.payload.response.data.error : {...state.addCourseForm.errors}
                }
            }


        case 'FETCH_FILE_PENDING':
            return {
                ...state,
                fileDetails: {
                    ...state.fileDetails,
                    loading: true,
                }
            }
        case 'FETCH_FILE_FULFILLED':
            return {
                ...state,
                fileDetails: {
                    data: action.payload.data.data,
                    loading: false,
                }
            }
        case 'FETCH_FILE_REJECTED':
            return {
                ...state,
                fileDetails: {
                    ...state.fileDetails,
                    loading: false,
                }
            }

        case 'CLEAR_FORM_DATA':
            return {
                ...state,
                addFilesForm: {
                    errors: {},
                    added: false
                }
            }

        case 'EDIT_FILE_PENDING':
            return {
                ...state,
                addFilesForm: {
                    ...state.addFilesForm,
                    loading: true,
                    edited: false
                }
            }
        case 'EDIT_FILE_FULFILLED':
            return {
                ...state,
                addFilesForm: {
                    ...state.addFilesForm,
                    loading: false,
                    edited: true
                }
            }
        case 'EDIT_FILE_REJECTED':
            return {
                ...state,
                addFilesForm: {
                    ...state.addFilesForm,
                    loading: false,
                    edited: false
                }
            }

        case 'DELETE_FILE_PENDING':
            return {
                ...state,
                deletedFile: false
            }
        case 'DELETE_FILE_FULFILLED':
            return {
                ...state,
                deletedFile: true,
                filesList: {
                    ...state.filesList,
                    list: state.filesList.list.filter((item, key) => action.payload.data.data.identifier !== item.identifier)
                }
            }
        case 'DELETE_FILE_REJECTED':
            return {
                ...state,
                deletedFile: false
            }

        case 'ADD_FILE_PENDING':
            return {
                ...state,
                addFilesForm: {
                    ...state.addFilesForm,
                    loading: true,
                    added: false,
                    status: ''
                }
            }
        case 'ADD_FILE_FULFILLED':
            return {
                ...state,
                filesList: {
                    ...state.filesList,
                    loaded: true,
                    list: [action.payload.data.data, ...state.filesList.list],

                },
                addFilesForm: {
                    ...state.addFilesForm,
                    added: true,
                    loading: false,
                    status: ''
                }
            }
        case 'ADD_FILE_REJECTED':
            return {
                ...state,
                addFilesForm: {
                    errors: action.payload&&action.payload.response ? action.payload.response.data.error : {...state.addFilesForm.errors},
                    added: false,
                    loading: false,
                    status: action.payload&&!action.payload.response ? 'error' : ''
                }
            }

        default:
            return state;
    }
}
