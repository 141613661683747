import React from 'react';
import Table from '@material-ui/core/Table'; import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableHead from '@material-ui/core/TableHead'; import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom'
import TablePagination from '@material-ui/core/TablePagination';
import Auth from '../../../../../../util/Auth'
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Paper } from '@material-ui/core';
import TableLoaderComponent from '../../../../../../components/Loaders/TableLoaderComponent';
import DialogLoaderComponent from '../../../../../../components/Loaders/DialogLoaderComponent';

export default class BasicTable extends React.Component {


    render() {
        const { meta } = this.props;
        return <div className="table-responsive-material">
            <TableLoaderComponent
                loading={this.props.loading}
            >
                <Paper>
                    <section className='position-relative' >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {/* Nazwa POS */}
                                        <TableSortLabel
                                            active={this.props.sort.name === "name" ? true : false}
                                            direction={!this.props.sort.direction ? "asc" : "desc"}
                                            onClick={this.changeSorting.bind(this, "name")} >
                                            Właściciel salonu
                                        </TableSortLabel>
                                    </TableCell>
                                    <Auth roles={['super_admin', 'admin']}>
                                        <TableCell className="d-none d-md-table-cell">
                                            Przedstawiciel
                                        </TableCell>
                                    </Auth>
                                    <Auth roles={['super_admin', 'admin']}>
                                        <TableCell className="d-none d-md-table-cell">
                                            Kwota do wypłaty
                                        </TableCell>
                                    </Auth>
                                    {/* <TableCell  className="d-none d-md-table-cell">Miejscowość</TableCell> */}
                                    {/* <TableCell>Przedstawiciel</TableCell> */}
                                    <TableCell>Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.data.map((n, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell>
                                                <Link to={'/app/saloons/' + n.identifier} className="keune-link">
                                                    {n.ownerName}
                                                </Link>
                                            </TableCell>
                                            <Auth roles={['super_admin', 'admin']}>
                                                <TableCell className="d-none d-md-table-cell">{n.agent && n.agent.data ? <Link to={'/app/users/' + n.agent.data.identifier} className="keune-link">{`${n.agent.data.firstName} ${n.agent.data.lastName}`}</Link> : ''}</TableCell>
                                            </Auth>
                                            <Auth roles={['super_admin', 'admin']}>
                                                <TableCell className="d-none d-md-table-cell">{n.should_pay || 0} zł</TableCell>
                                            </Auth>
                                            {/* <Auth roles={['super_admin', 'admin']}>
                                        <TableCell  className="d-none d-md-table-cell">{n.regionName.name}</TableCell>
                                    </Auth> */}
                                            {/* <TableCell  className="d-none d-md-table-cell">{n.cityName}</TableCell> */}
                                            <TableCell>
                                                <Tooltip className="mr-1" id="tooltip-icon" title="Podgląd salonu" placement="top">
                                                    <Link to={'/app/saloons/' + n.identifier}>
                                                        <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm">
                                                            <i className="zmdi zmdi-eye" />
                                                        </Fab>
                                                    </Link>
                                                </Tooltip>
                                                <Auth roles={['super_admin', 'admin']}>
                                                    <Tooltip className="mr-1" onClick={this.toggleEditMode.bind(this, key)} id="tooltip-icon" title="Edytuj salon" placement="top">
                                                        <Fab className="jr-fab-btn bg-amber text-white jr-btn-fab-sm">
                                                            <i className="zmdi zmdi-edit" />
                                                        </Fab>
                                                    </Tooltip>
                                                    <Tooltip id="tooltip-icon" title="Usuń salon" placement="top">
                                                        <Fab onClick={this.onDelete.bind(this, n.identifier)} className="jr-fab-btn bg-red text-white jr-btn-fab-sm">
                                                            <i className="zmdi zmdi-delete" />
                                                        </Fab>
                                                    </Tooltip>
                                                </Auth>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                        {this.props.nextPageLoading
                            ? <DialogLoaderComponent opacity />
                            : null
                        }
                    </section>
                    <TablePagination
                        component='div'
                        count={meta && meta.total ? meta.total : 0}
                        rowsPerPage={meta && meta.per_page ? meta.per_page : 0}
                        page={meta && meta.current_page ? meta.current_page - 1 : 0}
                        labelRowsPerPage={false}
                        onChangePage={this.onChangePage.bind(this)}
                        onChangeRowsPerPage={this.onChangePage.bind(this)}
                    />
                </Paper>
            </TableLoaderComponent >
        </div>
    }

    changeSorting(name) {
        this.props.changeSorting(name)
    }

    toggleEditMode(key) {
        this.props.toggleEditMode(key)
    }

    onDelete(slug) {
        this.props.onDelete(slug)
    }

    onChangePage(e, dir) {
        if (dir < this.props.meta.current_page) {
            this.props.onPageChange(this.props.meta.links.previous)
        } else {
            this.props.onPageChange(this.props.meta.links.next)
        }
    }

}
