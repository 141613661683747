import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Fab, Popover, TablePagination, Tooltip, Typography } from '@material-ui/core';
import Auth from '../../util/Auth';
import CardBox from '../CardBox';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import { useCallback } from 'react';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import TableLoaderComponent from '../Loaders/TableLoaderComponent';
import DialogLoaderComponent from '../Loaders/DialogLoaderComponent';
const useStyles = makeStyles((theme) => ({
    filterCont: {
        display: 'flex',
        paddingRight: 24,
        justifyContent: 'flex-end',
    },
    tableCont: {
        width: '100%',
    },
    table: {
        minWidth: 400,
    },
    cardBox: {
        overflow: 'auto'
    }
}))

const TableComponent = (
    {
        heading,
        cardBoxStyleName = 'col-12',
        headerOutside,
        editIcon = 'zmdi-edit',
        addTooltip,
        addBtn,
        addClick,
        titles = [],
        data = [],
        meta,
        loading,
        hiddenPagination,
        rowsPerPage = [10, 20, 30, 100],
        nextPageLoading,
        children,
        subtitle,
        badge,
        cardStyle = 'p-0',
        headingStyle,
        hiddenShow,
        hiddenEdit,
        hiddenDelete,
        handleEdit = () => { },
        handleDelete = () => { },
        handleShow = () => { },
        onChangePage = () => { },
        changePerPage = () => { }
    }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const matchesXs = useMediaQuery('(max-width:600px)');
    const handleShowFilter = useCallback((e) => {

        setAnchorEl(!anchorEl ? e.currentTarget : null);
    }, [anchorEl])
    const open = Boolean(anchorEl);
    return (
        <>
            {children ?
                <CardBox
                    styleName={cardBoxStyleName}
                    cardStyle="p-1 m-0"

                >
                    <Auth roles={['super_admin', 'admin', 'ph']}>
                        <div className={classes.filterCont}>
                            <IconButton
                                aria-label="Filter list"
                                onClick={handleShowFilter}
                                aria-owns={anchorEl ? 'filter-popper' : undefined}
                            >
                                <FilterListIcon />
                            </IconButton>
                            <Popover
                                id="filter-popper"
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleShowFilter}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <div className='m-2'>
                                    {children}
                                </div>
                            </Popover>
                        </div>
                    </Auth>
                </CardBox>
                : null
            }
            <CardBox
                styleName={cardBoxStyleName}
                cardStyle={cardStyle}
                childrenStyle={classes.cardBox}
                heading={heading}
                subtitle={subtitle}
                badge={badge}
                headerOutside={headerOutside}
                headingStyle={headingStyle}
                addTooltip={addTooltip}
                addBtn={addBtn}
                addClick={addClick}
            >
                <TableLoaderComponent
                    loading={loading}
                >
                    <section className='position-relative' >
                        <Table className={classes.table}>
                            {titles && titles.length
                                ? <TableHead>
                                    <TableRow>
                                        {titles.map((title, index) =>
                                            <TableCell className=" d-md-table-cell" key={index}>{title}</TableCell>
                                        )}
                                        {!hiddenShow || !hiddenEdit || !hiddenDelete ? <TableCell>Akcje</TableCell> : null}
                                    </TableRow>
                                </TableHead>
                                : null}
                            {data && data.length
                                ? <TableBody>
                                    {data.map((item, key) =>
                                        <TableRow key={key}>
                                            {Object.keys(item.data) && Object.keys(item.data).length
                                                ? Object.keys(item.data).map((cell, keyCell) =>
                                                    <TableCell className=" d-md-table-cell" key={keyCell}>{item.data[cell]}</TableCell>
                                                )
                                                : null}
                                            {
                                                !hiddenShow || !hiddenEdit || !hiddenDelete ? <TableCell>
                                                    {!hiddenShow ? <Tooltip id="tooltip-icon" title="Podgląd" placement="top">
                                                        <Fab onClick={() => handleShow(item.id)} className={`${matchesXs ? 'mb-1' : ''} jr-fab-btn bg-light-blue text-white jr-btn-fab-sm  mr-1`}>
                                                            <i className="zmdi zmdi-eye" />
                                                        </Fab>
                                                    </Tooltip>
                                                        : null}
                                                    {!hiddenEdit ? <Auth roles={['super_admin', 'admin']}>
                                                        <Tooltip id="tooltip-icon" title="Edytuj" placement="top">
                                                            <Fab onClick={() => handleEdit(item.id)} className={`${matchesXs ? 'mb-1' : ''} jr-fab-btn bg-amber text-white jr-btn-fab-sm mr-1`}>
                                                                <i className={`zmdi ${editIcon}`} />
                                                            </Fab>
                                                        </Tooltip>
                                                    </Auth>
                                                        : null}
                                                    {!hiddenDelete ? <Auth roles={['super_admin', 'admin']}>
                                                        <Tooltip id="tooltip-icon" title="Usuń" placement="top">
                                                            <Fab onClick={() => handleDelete(item.id)} className="jr-fab-btn bg-red text-white jr-btn-fab-sm">
                                                                <i className="zmdi zmdi-delete" />
                                                            </Fab>
                                                        </Tooltip>
                                                    </Auth>
                                                        : null}
                                                </TableCell>
                                                    : null
                                            }
                                        </TableRow>)}
                                </TableBody>
                                : <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={titles ? titles.length + 1 : 1}>
                                            <Typography align='center'>Dane są puste</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>}
                        </Table>
                        {nextPageLoading
                            ? <DialogLoaderComponent opacity />
                            : null
                        }
                    </section>
                    {!hiddenPagination
                        ? <TablePagination
                            count={meta && meta.total ? meta.total : 0}
                            rowsPerPage={meta && meta.per_page ? meta.per_page : 0}
                            page={meta && meta.current_page ? meta.current_page - 1 : 0}
                            labelRowsPerPage={false}
                           rowsPerPageOptions={rowsPerPage} 
                            component="div"
                            onChangePage={onChangePage}
                            onChangeRowsPerPage={changePerPage}
                        />
                        : null
                    }
                </TableLoaderComponent>
            </CardBox>
        </>
    )
}


export default TableComponent;
