import React from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios'
import settings from '../../config/settings.js'
import { RESEND_EMAIL } from '../../constants/api';
import DialogLoaderComponent from '../../components/Loaders/DialogLoaderComponent.js';
import { useEffect } from 'react';
import { LOGIN_ROUTE } from '../../constants/routes.js';

const ResendEmailPage = ({ history, location }) => {
	const [loader, setLoader] = useState(false);
	const [userEmail, setUserEmail] = useState('');
	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const email = params.get('email')
		if (email)
			setUserEmail(email)
		else
			history.push(LOGIN_ROUTE)
	}, [location])
	const handleSend = useCallback(() => {
		setLoader(true);
		axios.post(`${settings.api_endpoint}/${RESEND_EMAIL}`,
			{
				email: userEmail,
			})
			.then((success) => {
				setLoader(false);
				setShowMessage(true);
			})
			.catch((error) => {
				setLoader(false);
				NotificationManager.error('Nie udało się wysłać email do ciebie, spróbuj ponownie')
			})
	}, [userEmail])
	return (
		<div
			className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
			<div className="app-login-main-content">

				{/* <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img src="" alt="jambo" title="jambo"/>
                        </Link>
                    </div> */}

				<div className="app-login-content position-relative">
					{
						showMessage
							? <>
								<div className="app-login-header mb-4">
									<h1>Email został wysłany!</h1>
									<Typography>Na podany przez Ciebie adres e-mail został wysłany link aktywacyjny w celu potwierdzenia Twojego konta.</Typography>
								</div>
								<div className="app-login-form">
									<div className="mt-3 mb-3 d-flex align-items-center justify-content-between mt-3">
										<Link to={LOGIN_ROUTE} className="keune-link">
											<Button color="primary" className="jr-btn text-white keune-btn ">
												Zaloguj się
											</Button>
										</Link>
									</div>
								</div>
							</>
							: <>
								<div className="app-login-header mb-4">
									<h1>Konto nie zostało zweryfikowane</h1>
									<Typography>Twoje konto nie zostało jeszcze zweryfikowane. Sprawdź skrzynkę mailową lub wyślij link jeszcze raz</Typography>
								</div>
								<div className="app-login-form">
									<div className="mt-3 mb-3 d-flex align-items-center justify-content-between mt-3">
										<Button onClick={handleSend} color="primary" className="jr-btn text-white keune-btn ">
											Wyślij jeszcze raz
										</Button>
										<Link to={LOGIN_ROUTE} className="keune-link">
											Zaloguj się
										</Link>
									</div>
								</div>
							</>
					}
					<DialogLoaderComponent loaded={!loader} />
				</div>
			</div>
			<NotificationContainer />
		</div>
	);
};

export default React.memo(withRouter(ResendEmailPage));
