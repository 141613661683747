import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// import {
//     hideMessage,
//     showAuthLoader,
//     userFacebookSignIn,
//     userGithubSignIn,
//     userGoogleSignIn,
//     userSignIn,
//     userTwitterSignIn
// } from 'actions/Auth';
import * as UserActions from '../actions/User'
import FormLabel from '@material-ui/core/FormLabel';
import { Typography } from '@material-ui/core';
import { LOGIN_ROUTE } from '../constants/routes';
import DialogLoaderComponent from '../components/Loaders/DialogLoaderComponent';

class ResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            password: '',
            password_confirmation: ''
        }
    }


    componentDidUpdate(prevProps) {
        if (this.props.user && this.props.user.changePassword && this.props.user.changePassword.status === 'success') {
            this.props.history.push(LOGIN_ROUTE)
        }
    }
    handleInputChange(e) {
        let newState = { ...this.state }
        newState[e.target.name] = e.target.value
        this.setState(newState)
    }

    changePassword() {
        let token = this.props.match.params.token
        this.props.dispatch(UserActions.changePassword(this.state, token))
    }

    render() {
        const {
            password,
            password_confirmation
        } = this.state;
        const { showMessage, loader, alertMessage } = this.props;
        let message = this.props.user.changePassword.error.length ? this.props.user.changePassword.error : ''
        // let messageStatus = this.props.user.changePassword.status
        let passwordError = this.props.user.changePassword.error.password ? this.props.user.changePassword.error.password : ''
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    {/* <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img src="" alt="jambo" title="jambo"/>
                        </Link>
                    </div> */}

                    <div className="app-login-content position-relative">
                        <div className="app-login-header mb-4">
                            <h1>Ustaw nowe hasło!</h1>
                        </div>

                        <div className="app-login-form">
                            <form>
                                <fieldset>
                                    <TextField
                                        type="password"
                                        label={passwordError && passwordError.length ? passwordError[0] : 'Tu wpisz nowe hasło'}
                                        error={passwordError}
                                        fullWidth
                                        name="password"
                                        onChange={this.handleInputChange.bind(this)}
                                        defaultValue={password}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                    />
                                    <TextField
                                        type="password"
                                        label={"Powtórz nowe hasło"}
                                        fullWidth
                                        name="password_confirmation"
                                        onChange={this.handleInputChange.bind(this)}
                                        defaultValue={password_confirmation}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                    />
                                    {message ? <FormLabel component="legend">
                                        <Typography color='error'>{message}</Typography>
                                    </FormLabel> : null}
                                    <small>Hasło musi zawierać co najmniej 8 znaków, jedną liczbę, dużą i małe literę oraz znak specjalny.</small> <br /><br />
                                    <div className="mt-3 mb-3 d-flex align-items-center justify-content-between">
                                        <Button onClick={this.changePassword.bind(this)} variant="raised" color="primary" className="jr-btn text-white keune-btn">
                                            Zapisz hasło
                                        </Button>
                                        <Link to="/signin" className="keune-link">
                                            Logowanie
                                        </Link>
                                        <Link to="/recover-password" className="keune-link">
                                            Resetuj ponownie
                                        </Link>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <DialogLoaderComponent loaded={!loader} />
                    </div>
                </div>
                {showMessage && NotificationManager.error(alertMessage)}
                <NotificationContainer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // const {loader, alertMessage, showMessage, authUser} = auth;
    // return {loader, alertMessage, showMessage, authUser}
    return {
        user: state.user
    }
};

export default connect(mapStateToProps)(withRouter(ResetPassword));
