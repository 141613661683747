import React from 'react';
import Table from '@material-ui/core/Table';import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';import TableHead from '@material-ui/core/TableHead';import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip';
import {Badge} from 'reactstrap';



export default class WorkersList extends React.Component {
    constructor(props) {
        super()
    }

    render() {
        let badgeColor = ''
        let badgeText = ''
        return (
            <div className="table-responsive-material">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa szkolenia</TableCell>
                            <TableCell>Poziom</TableCell>
                            <TableCell>Miejsce</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.list.map(n => {
                            if ( n.statusName === 'accepted' ) {
                                badgeColor = 'success'
                                badgeText = 'Zaakceptowany'
                            }
                            if ( n.statusName === 'pending' ) {
                                badgeColor = 'warning'
                                badgeText = 'Do potwierdzenia'
                            }
                            if ( n.statusName === 'cancelled' ) {
                                badgeColor = 'danger'
                                badgeText = 'Odrzucony'
                            }
                            return (
                                <TableRow key={n.id}>
                                    <TableCell>{n.courseDate.data.title}</TableCell>
                                    <TableCell>{n.courseDate.data.level ? n.courseDate.data.level.name : null}</TableCell>
                                    <TableCell>{n.courseDate.data.placeName}</TableCell>
                                    <TableCell>{n.courseDate.data.dateStart}</TableCell>
                                    <TableCell>
                                        <Badge pill color={badgeColor}>{badgeText}</Badge>
                                    </TableCell>
                                    <TableCell>
                                        <Link to={'/app/course-dates/details/' + n.courseDate.data.identifier}>
                                            <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm mb-3">
                                                <i className="zmdi zmdi-eye" />
                                            </Fab>
                                        </Link> 
                                        {n.statusName !== 'accepted' ? this.renderAcceptBtn(n) : null}
                                        {n.statusName !== 'cancelled' ? this.renderCancelBtn(n) : null}
                                        {/* <Tooltip id="tooltip-icon" title="Usuń zapis" placement="top">
                                            <Button variant="fab" className="jr-fab-btn bg-danger text-white jr-btn-fab-sm mb-3">
                                                <i className="zmdi zmdi-delete" />
                                            </Button>
                                        </Tooltip> */}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        )
    }
    
    renderAcceptBtn(n) {
        return <Tooltip id="tooltip-icon" title="Zaakceptuj zapis" placement="top">
            <Fab onClick={this.changeSignupStatus.bind(this, n, 'accepted')} className="jr-fab-btn bg-success text-white jr-btn-fab-sm mb-3">
                        <i className="zmdi zmdi-badge-check" />
            </Fab>
                </Tooltip>
    }
    
    renderCancelBtn(n) {
        return <Tooltip id="tooltip-icon" title="Anuluj zapis" placement="top">
            <Fab onClick={this.changeSignupStatus.bind(this, n, 'cancelled')} className="jr-fab-btn bg-warning text-white jr-btn-fab-sm mb-3">
                        <i className="zmdi zmdi-stop" />
            </Fab>
                </Tooltip>
    }

    changeSignupStatus(item, status) {
        this.props.changeSignupStatus(item.courseDate.data.identifier, item.identifier, {
            statusName: status
        })
    }

}



