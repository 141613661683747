import axios from 'axios'
import settings from '../config/settings.js'



export const getOrders = (params, url = settings.api_endpoint + "/mobile_order") => {
    return {
        type: 'GET_ORDERS_APP',
        payload: axios.get(url, {
            params: params
        })
    }
}
export const setOrderDeliveryNumber = ({ id, deliveryNumber }, url = settings.api_endpoint + "/mobile_order/delivery_number") => {
    return {
        type: 'UPDATE_ORDER_APP',
        payload: axios.post(`${url}/${id}`, { deliveryNumber})
    }
}
export const updateOrderGiven = ({ id, given }, url = settings.api_endpoint + "/mobile_order/given") => {
    return {
        type: 'UPDATE_ORDER_APP',
        payload: axios.post(`${url}/${id}`, { given})
    }
}

export const updateOrderSend = ({ id, send }, url = settings.api_endpoint + "/mobile_order/send") => {
    return {
        type: 'UPDATE_ORDER_APP',
        payload: axios.post(`${url}/${id}`, { send})
    }
}
