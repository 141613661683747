import React, { useCallback, useEffect, useMemo } from 'react';
import DialogComponent from '../../../components/Dialog/DialogComponent';
import validations from '../../../functions/validations';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextFieldComponent from '../../../components/Form/TextFieldComponent';
import PublishCheckboxComponent from '../../../components/Form/PublishCheckboxComponent';
import InspirationRowFormComponent from './InspirationRowFormComponent';
import ProductAutocompleterComponent from './ProductAutocompleterComponent';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import {
	updateInspiration,
	addInspiration,
} from '../../../actions';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';

const InspirationSchema = () => Yup.object().shape({
	title: Yup.string()
		.required(validations('required'))
		.min(3, validations('min', 3))
		.max(80, validations('max', 80)),
	description: Yup.string()
		.required(validations('required'))
		.min(3, validations('min', 3)),
  slug: Yup.string()
    .required(validations('required'))
    .min(3, validations('min', 3))
    .max(80, validations('max', 80))
});

const InspirationFormComponent = ({
	loading,
	error,
	data, 
	show, 
	onClose = () => {},
	updateInspiration = () => {},
	addInspiration = () => {},
}) => {
  const initialValues = useMemo(() => data?.identifier  
	? data 
	: {
    title: '',
    slug: '',
    description: '',
    img: '',
    products: [],
    rows: [],
    published: 0,
  }, [data])

  const formik = useFormik({
		validationSchema: InspirationSchema(),
		enableReinitialize: true,
		initialValues,
		onSubmit: values => {
			let bodyFormData = new FormData();
			if(values?.img?.preview){
				bodyFormData.append('image', values.img || '')
			}
			bodyFormData.append('title', values.title || '')
			bodyFormData.append('slug', values.slug || '')
			bodyFormData.append('published', values.published ? 1 : 0)
			bodyFormData.append('description', values.description || '')
			if(values?.products?.length){
				values.products.forEach((product) => {
					bodyFormData.append('products[][value]', product.value)
					bodyFormData.append('products[][title]', product.title)
				})
			}
			if(values?.rows?.length){
				values.rows.forEach((row) => {
					if( row?.img?.preview){
						bodyFormData.append('rows[][img]', row.img || '')
					}
					bodyFormData.append('rows[][desc]', row.desc || '')
				})
			}
			if (data.identifier) {
				bodyFormData.append('_method', 'put')
				updateInspiration({ slug: values.slug, params: bodyFormData });
			}
			else
			addInspiration(bodyFormData);
		},
	});

	const handleAddSection = useCallback(() => {
		formik.setFieldValue(
			'rows', 
			[...formik.values.rows, {img: '', desc: ''}]
		);
	}, [formik]);

	useEffect(() => {
		if (!loading && !error) {
			formik.resetForm();
			onClose();
		} else if (error) {
				NotificationManager.error(error, "", 2000);
		}
}, [loading, error])

  return (
    <DialogComponent
			title={`${data?.id ? "Edytuj" : 'Dodaj post'} inspiracje`}
      open={show}
			loading={loading}
      handleSave={formik.handleSubmit}
			handleClose={onClose}
    >
      	<form 
        onSubmit={formik.handleSubmit} 
        className="col-md-12"
        >
          <TextFieldComponent
						fullWidth
						id='title'
						formikProps={formik}
						label='Tytuł'
					/>
          <TextFieldComponent
            fullWidth
            id='slug'
            formikProps={formik}
            label='Slug'
          />
					<PublishCheckboxComponent
						formik={formik}
					/>
					<ProductAutocompleterComponent 
						formikProps={formik} 
						id='products' 
					/>
					<InspirationRowFormComponent
						formik={formik}
					/>

					<Typography variant='h6' className='mt-4 mb-2'>Sekcje</Typography>
					{
						formik?.values?.rows?.length ? formik.values.rows.map((_, key) => 
							<InspirationRowFormComponent
								formik={formik}
								withIndex
								index={key}
								key={key}
								showLine
							/>
					) : null
					}
					<Button onClick={handleAddSection} color="default" variant='outlined'>
            Dodaj sekcje
          </Button>
        </form>
      {/* Your code here */}
    </DialogComponent>
  );
};


export default connect((state) => ({
	loading: state.inspirations.loading,
	error: state.inspirations.error
}), {
	updateInspiration,
	addInspiration,
})(InspirationFormComponent);
