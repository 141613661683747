import {
    // GET_POS_LIST,
    GET_POS_DETAILS,
    GET_POS_WORKERS
} from '../constants/ActionTypes';
import axios from 'axios'
import settings from '../config/settings.js'

// export const getPosList = ( filters, url = settings.api_endpoint + "/pos") => {
//     let params = {}
//     if ( filters ) {
//         params = filters
//     }
//     return {
//         type: 'GET_POS_LIST',
//         payload: axios.get(url, {
//             params
//         })
//     }
// }

export const getAgentPosList = ( params = {}, agentID) => {
    return {
        type: 'GET_AGENT_POS_LIST',
        payload: axios.get(settings.api_endpoint + "/agents/" + agentID + "/pos", {
            params: params
        })
    }
}

export const getPosList = ( params = {}, url = settings.api_endpoint + "/pos") => {
    return {
        type: 'GET_POS_LIST',
        payload: axios.get(url, {
            params: params
        })
    }
}

export const getNoOwnerPosList = ( params = {}, url = settings.api_endpoint + "/posNoOwner") => {
    return {
        type: 'GET_NO_OWNER_POS_LIST',
        payload: axios.get(url, {
            params: params
        })
    }
}

export function getPosDetails(id, parameters) {
    return {
        type: GET_POS_DETAILS,
        payload: axios.request({
            url: '/pos/' + id + '?' + parameters,
            method: 'GET',
            baseURL: settings.api_endpoint
        })
    }
}

export function getPosWorkers(id, filter = '') {
    return {
        type: GET_POS_WORKERS,
        payload: axios.request({
            url: '/pos/' + id + "/workers",
            method: 'GET',
            baseURL: settings.api_endpoint
        })
    }
}

export function getPosAgent(id) {
    return {
        type: 'GET_POS_AGENT',
        payload: axios.request({
            url: '/pos/' + id + "/agent",
            method: 'GET',
            baseURL: settings.api_endpoint
        })
    }
}

export function getPosOwner(id) {
    return {
        type: 'GET_POS_OWNER',
        payload: axios.request({
            url: '/pos/' + id + "/owner",
            method: 'GET',
            baseURL: settings.api_endpoint
        })
    }
}


export const updatePosAgent = (posId, agentId) => {
    return {
        type: 'UPDATE_POS_AGENT',
        payload: axios.request({
            url: '/pos/' + posId + '/agent/' + agentId,
            method: 'PUT',
            baseURL: settings.api_endpoint
        })
    }
}

export const setPosPayment = (posId, data, parameters, url = settings.api_endpoint + "/pos") => {
    return {
        type: 'SET_POS_PAYMENT',
        payload: axios.post(`${url}/${posId}/payment?${parameters}`, data)
    }
}

export const editPos = (id, data) => {
    return {
        type: 'EDIT_POS',
        payload: axios.request({
            url: '/pos/' + id,
            method: 'PUT',
            baseURL: settings.api_endpoint,
            data: data
        })
    }
}

export const addPos = (data) => {
   
    return {
        type: "ADD_POS",
        payload: axios.request({
            url: "/pos",
            method: "post",
            baseURL: settings.api_endpoint,
            data: data,
            params: {
                include: 'agent'
            }
        })
    }
}

export const deletePos = (slug) => {
    return {
        type: "DELETE_POS",
        payload: axios.request({
            url: "/pos/" + slug,
            method: "delete",
            baseURL: settings.api_endpoint
        })
    }
}

export const addWorkerToPos = (posId, userId) => {
    return {
        type: "ADD_WORKER_TO_POS",
        payload: axios.request({
            url: "/pos/" + posId + "/workers",
            method: "post",
            baseURL: settings.api_endpoint,
            data: {
                userID: userId
            }
        })
    }
}

export const updateWorkerPos = (posId, userId) => {
    let formData = new FormData()
    formData.append('_method', 'put')
    // formData.append('userID', userId)
    return {
        type: "UPDATE_WORKER_POS",
        payload: axios.request({
            url: "/pos/" + posId + "/workers/" + userId,
            method: "PUT",
            baseURL: settings.api_endpoint
            // data: {
                // userID: userId,
                // '_method': 'put'
            // }
            // data: formData
        })
    }
}


export const updateOwnerPos = (posId, userId) => {
    return {
        type: "UPDATE_OWNER_POS",
        payload: axios.request({
            url: "/pos/" + posId + "/owner/" + userId,
            method: "PUT",
            baseURL: settings.api_endpoint,
            params: {
                include: 'pos,pos.owner'
            }
        })
    }
}

export const clearPosForm = () => {
    return {
        type: 'CLEAR_POS_FORM'
    }
}

export const rewritePosToAgent = (userId, posList) => {
    return {
        type: "REWRITE_POS_TO_AGENT",
        payload: axios.request({
            url: "/agents/" + userId + "/changeAgent",
            method: "POST",
            baseURL: settings.api_endpoint,
            data: {
                posIDS: posList
            }
        })
    }
}
