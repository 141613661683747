export default (key, item) => {
    switch (true) {
        case key === 'required':
            return 'Pole jest wymagane';
        case key === 'min':
            return `Wypełnione pole jest za krótkie (min. ${item} znaków)`;
        case key === 'max':
            return `Wypełnione pole jest za długie (max. ${item} znaków)`;
        case key === 'phone':
            return `Numer telefonu jest nieprawidłowy`;
        case key === 'password':
            return `Hasło musi zawierać conajmniej 1 małą, 1 wielką literę, 1 liczbę oraz 1 znak specjlany`;
        case key === 'email':
            return `Wpisany tekst nie jest adresem email`;
        case key === 'repeat_password':
            return `Hasła się nie zgadzają się`;
        case key === 'city_zip_code':
            return `Pole wypełnione niepoprawnie `;
        case key === 'creditCard':
            return `Wpisany tekst nie jest kartą credytową `;
        case key === 'biggest':
            return `Wypełnione pole musi być większe niż ${item}`;
        case key === 'cvv':
            return `Wpisany tekst nie jest cvv lub cvc`;
        default:
            return 'Pole jest wymagane';

    }
}