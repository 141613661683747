import React, { Component } from 'react';
import {  withRouter } from 'react-router-dom';
import CustomScrollbars from '../../util/CustomScrollbars';
import Auth from '../../util/Auth'
import { NOTIFICATIONS_ROUTE, SLIDERS_ROUTE, ORDERS_ROUTE, ORDERS_HISTORY_ROUTE, BLOG_ROUTE, PROMO_CODES_ROUTE, APP_STATISTIC_ROUTE, INSPIRATIONS_ROUTE } from '../../constants/routes';
import { connect } from 'react-redux';
import SideNavItemComponent from '../../components/SideNavItemComponent';

class SidenavContent extends Component {

  render() {
    let { salonOwnerID } = this.props.user.auth.user

    return <CustomScrollbars className=" scrollbar">
      <ul className="nav-menu">
        <li className="nav-header">
          Szkolenia
        </li>
        <SideNavItemComponent
          to='/dashboard'
          title='Dashboard'
          iconClass='zmdi-view-dashboard'
        />

        <Auth roles={['super_admin', 'admin', 'ph']}>
          <Auth roles={['super_admin', 'admin']}>
            <SideNavItemComponent
              to='/users'
              title='Użytkownicy'
              iconClass='zmdi-accounts-alt'
            />
          </Auth>
          <Auth roles={['ph']}>
            <SideNavItemComponent
              to='/users'
              title=' Twoi Użytkownicy'
              iconClass='zmdi-accounts-alt'
            />
          </Auth>
          <Auth roles={['super_admin', 'admin']}>
            <SideNavItemComponent
              to='/saloons'
              title='Salony'
              iconClass='zmdi-store-24'
            />
          </Auth>
          <Auth roles={['ph']}>
            <SideNavItemComponent
              to='/saloons'
              title='Twoje Salony'
              iconClass=' zmdi-store-24'
            />
          </Auth>
        </Auth>
        {
          salonOwnerID
            ? <Auth roles={['pos', 'pos_admin']}>
              <SideNavItemComponent
                to={`/saloons/${salonOwnerID}`}
                title='Twój Salon'
                iconClass=' zmdi-store-24'
              />
            </Auth>
            : null
        }
        <SideNavItemComponent
          to={`/courses`}
          title='Szkolenia'
          iconClass='zmdi-local-library'
        />
        <Auth roles={['super_admin', 'admin', 'ph', 'training_staff']}>
          <SideNavItemComponent
            to={`/course-dates/calendar`}
            title='Terminarz szkoleń'
            iconClass='zmdi-calendar'
          />
        </Auth>
        <SideNavItemComponent
          to={`/files`}
          title='Materiały do pobrania'
          iconClass=' zmdi-file'
        />
        <SideNavItemComponent
          to={`/keune-news`}
          title='KEUNE News'
          iconClass='zmdi-collection-item'
        />
        <SideNavItemComponent
          to={BLOG_ROUTE}
          title='KEUNE Akademia Blog'
          iconClass='zmdi-collection-item'
        />

        <li className="nav-header">
          Aplikacja
        </li>
        <Auth roles={['super_admin', 'admin']}>
          <SideNavItemComponent
            to={PROMO_CODES_ROUTE}
            title='Kody promocyjne'
            iconClass='zmdi-favorite'
          />
        </Auth>
        <Auth roles={['super_admin', 'admin']}>
          <SideNavItemComponent
            to={NOTIFICATIONS_ROUTE}
            title='Powiadomienia'
            iconClass='zmdi-notifications-add'
          />
          <SideNavItemComponent
            to={SLIDERS_ROUTE}
            title='Slideshow'
            iconClass='zmdi-looks'
          />
          <SideNavItemComponent
            to={INSPIRATIONS_ROUTE}
            title='Inspiracje'
            iconClass='zmdi-collection-item'
          />
        </Auth>
        <Auth roles={['super_admin', 'admin', 'ph', 'pos', 'pos_admin']}>
          <SideNavItemComponent
            to={ORDERS_ROUTE}
            title='Zamówienia'
            iconClass='zmdi-shopping-cart'
          />
        </Auth>
        <Auth roles={['super_admin', 'admin']}>
          <SideNavItemComponent
            to={ORDERS_HISTORY_ROUTE}
            title='Historia zamówień'
            iconClass='zmdi-floppy'
          />
        </Auth>
      
        {/* <Auth roles={['super_admin', 'admin', 'pos_admin', 'ph']}>
          <li className="menu no-arrow">
            <NavLink to={`/app${ORDER_STATISTIC_ROUTE}`}>
              <i className="zmdi zmdi-chart" />
              <span className="nav-text">Statystyki Zakupów</span>
            </NavLink>
          </li>
        </Auth> */}
        <li className="nav-header">
          Dodatkowe
        </li>
        <Auth roles={['super_admin', 'admin']}>
          <SideNavItemComponent
            to={'/statistics'}
            title='Statystyki'
            iconClass='zmdi-chart'
          />
        </Auth>
        <Auth roles={['super_admin', 'admin', 'ph']}>
          <SideNavItemComponent
            to={APP_STATISTIC_ROUTE}
            title='Statystyki aplikacji'
            iconClass='zmdi-chart'
          />
        </Auth>
        <li className="menu no-arrow">
          <a href='https://form.asana.com/?hash=9e673a4c308e5eb20f9705e9c806e33056dfb9d928508eccb33c32729852758a&id=1196284115772129' target="_blank" rel="noopener noreferrer">
            <i className="zmdi zmdi-email" />
            <span className="nav-text">Zgłoś błąd</span>
          </a>
        </li>

      </ul>
    </CustomScrollbars>
  }
}

export default connect((state) => ({
  user: state.user,
}), {
})(withRouter(SidenavContent));
