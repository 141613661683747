import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import moment from 'moment';
import React, { useMemo } from 'react'
import { Badge } from 'reactstrap';
import VerticalComposedChart from '../app/routes/statistics/VerticalComposedChart';
import CardBox from './CardBox';
import DialogLoaderComponent from './Loaders/DialogLoaderComponent';

const useStyles = makeStyles(theme => ({
	emptySection: {
		height: 200,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}
}))
const BarChartComponent = ({ range, loading, heading, badge, fill, data = [], legend, headerOutside }) => {
	const classes = useStyles();
	const { from, to } = useMemo(() => range, [range])
	return (
		<CardBox
			styleName="col-12"
			heading={heading}
			headerOutside={headerOutside}
		>
			<div>

				{/* <h3>
								Zapisów łącznie: {signupsSum}
							</h3> */}
				{from && to ?
					<Badge pill color="primary" className='mt-2 mb-2'>
						{moment(from).format('DD-MM-YYYY')} - {moment(to).format('DD-MM-YYYY')}
					</Badge>
					: null
				}
				{badge
					? <Badge pill color="secondary" className='mt-2 mb-2'>
						{badge}
					</Badge>
					: null}
				{data && data.length
					? <VerticalComposedChart
						data={data}
						barLegend={legend}
						fill={fill}
					/>
					: <section className={classes.emptySection}>
						<Typography>Dane są puste</Typography>
					</section>
				}


			</div>
			{loading ? <DialogLoaderComponent /> : null}
		</CardBox>
	)
}

export default BarChartComponent