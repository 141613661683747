import React from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment'

const NewsBoxItem = ({data}) => {
  const {identifier, image, title, creationDate} = data;
  return <div className="col-sm-4 col-12 mb-1">
            <div className="row">
              <div className="col-md-5 col-6 mb-2 pr-0">
                <div className="grid-thumb-equal grid-art-thumb-equal rounded-sm">
                  <div className="grid-thumb-cover">
                    <Link to={"/app/keune-news/" + identifier}>
                      <img className="img-fluid" src={image ? image.data.urlFile : ''} alt="Obrazek"/>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-6">
                {/* <span className="badge bg-grey text-white">Health</span> */}
                <Link to={"/app/keune-news/" + identifier}>
                  <h5 className="mb-1">{title}</h5>
                </Link>
                <p className="meta-date mb-0">{moment(creationDate).format('Do MMMM YYYY')}</p>
              </div>
            </div>
          </div>

                    // <li className="media media-list post-list">
          //   <div className="size-90 post-image mr-3">
          //     <img className="img-fluid rounded" src={image ? image.data.urlFile : ''} alt="PostsPic"/>
          //   </div>
          //   <div className="media-body">
          //     <h4 className="mt-0 mb-1">{title}</h4>
          //     <p className="text-muted">{lead}</p>
          //   </div>
          // </li>
}

export default NewsBoxItem;