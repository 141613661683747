
import React from 'react'
import { FormLabel } from '@material-ui/core';
import Select from 'react-select';
import { useCallback } from 'react';

const FilterComponent = ({
    blockClass = '',
    placeholder,
    filterClass = '',
    label,
    value,
    options,
    filterId,
    maxMenuHeight = 200,
    setFilters,
    dispatch = () => {},
    filters,
    setOption,
    // onChange = () => { },
    // onInputChange = () => { },
}) => {

    const onChange = useCallback((newVal) => {
        setOption(newVal)
        setFilters({
            ...filters,
            page: 0,
            [`filter[${filterId}]`]: newVal ? newVal.value : ''
        })
    }, [ setFilters, setOption, filterId])

    const handleInputChange = useCallback((newValue) => {
        if (newValue.length > 2){
            window.stop();
            dispatch(newValue)
        }
    }, [dispatch]);

    return (
        <div className={blockClass}>
            <FormLabel className={filterClass}>{label}</FormLabel>
            <Select
                onInputChange={handleInputChange}
                value={value}
                isClearable
                placeholder={placeholder}
                maxMenuHeight={maxMenuHeight}
                onChange={onChange}
                options={options}
            />
        </div>
    )
}


export default FilterComponent;