const voids = [
    { value: 0, label: 'dolnośląskie' },
    { value: 1, label: 'kujawsko-pomorskie' },
    { value: 2, label: 'lubelskie' },
    { value: 3, label: 'lubuskie' },
    { value: 4, label: 'łódzkie' },
    { value: 5, label: 'małopolskie' },
    { value: 6, label: 'mazowieckie' },
    { value: 7, label: 'opolskie' },
    { value: 8, label: 'podkarpackie' },
    { value: 9, label: 'podlaskie' },
    { value: 10, label: 'pomorskie' },
    { value: 11, label: 'śląskie' },
    { value: 12, label: 'świętokrzyskie' },
    { value: 13, label: 'warmińsko-mazurskie' },
    { value: 14, label: 'wielkopolskie' },
    { value: 15, label: 'zachodniopomorskie' }
]

export default voids