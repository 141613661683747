import React from 'react';

import NotificationItem from './NotificationItem';
import CustomScrollbars from '../../util/CustomScrollbars';
import { Button } from '@material-ui/core';

const styles = {
  scrollBar: {
    height: 280,
  },
  moreBtn: {
    margin: 'auto'
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    justifiContent: 'center',
  }
}
const AppNotification = ({ list, onClick = () => { }, currentPage, totalPages, onLoadMore = () => { } }) => {
  return (
    <div style={styles.block}>
      <CustomScrollbars className="messages-list scrollbar" style={styles.scrollBar}>
        <ul className="list-unstyled">
          {
            list.map((item, key) => <NotificationItem key={key} onClick={onClick} notification={item} />)
          }
        </ul>
      </CustomScrollbars>
      {totalPages > currentPage 
        ? <Button style={styles.moreBtn} onClick={() => onLoadMore({page: currentPage + 1})}>
          Doładuj więcej
      </Button>
        : ''}
    </div>
  )
};

export default AppNotification;

