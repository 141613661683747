import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
// import Avatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
// import CardBox from 'components/CardBox';
import { connect } from 'react-redux'
// import ProfileBox from './ProfileBox'
// import ContactCard from './ContactCard'
// import CoursesList from './CoursesList'
// import EditForm from './EditForm'
// import * as UserActions from '../../../../../actions/User'
// import * as CoursesActions from '../../../../../actions/Courses'
import * as NewsActions from '../../../../../actions/News'
import Widget from "../../../../../components/Widget";
import Auxiliary from "../../../../../util/Auxiliary";
// import Dragndrop from '../../dragndrop'
// import Tooltip from '@material-ui/core/Tooltip';
// import {NotificationContainer, NotificationManager} from 'react-notifications';
import {NotificationContainer} from 'react-notifications';

class NewsDetails extends React.Component {

	constructor() {

    super()
    this.state = {

    }

	}

	componentWillMount() {

		let id = this.props.match.params.id
    this.props.dispatch(NewsActions.getNewsDetails(id))

	}


	render() {
		// let match = this.props.match
		let data = this.props.news.newsDetails.data
		let deviceWidth = window.innerWidth;

    return <Auxiliary>
       				<NotificationContainer/>
            <div className="jr-profile-banner" style={{
              backgroundImage: "url(" + data.image + ")",
              backgroundSize: 'cover'
              }}>
              <div className="jr-profile-container">
                <div className="jr-profile-banner-top">
                  <div className="jr-profile-banner-top-left">
                    <div className="jr-profile-banner-avatar-info">
                      <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">{data.title}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="jr-profile-content news-wrapper">
              <div className="row">
				  {deviceWidth > 768 ? <div className="col-xl-4 col-lg-4 col-md-4 col-12">
					<Widget styleName="jr-card-profile">
					  <div>
						<div className="mb-3">
                  <img className="newsImage" src={data.image ? data.image.data.urlFile : ''} alt={'File'} />
						</div>
					  </div>
					</Widget>
				  </div> : null}
                  <div className="col-xl-7 col-lg-7 col-md-7 col-12">
                    <Widget styleName="jr-card-profile">
                      <div>
                        <p>
                          <strong>
                            {data.lead}
                          </strong>
                        </p>
                {deviceWidth <= 768 ? <img className="newsImage" src={data.image ? data.image.data.urlFile : ''} alt={'News'} />: null}
                        <div dangerouslySetInnerHTML={{__html: data.description}} className='news-desc' />
                      </div>
                    </Widget>
                  </div>

              </div>
            </div>
          </Auxiliary>
  }
}

const mapStateToProps = (state) => {
	return {
    news: state.news
	}
}

export default connect(mapStateToProps)(NewsDetails)
