import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
import { Route, Switch} from 'react-router-dom';
// import asyncComponent from '../../../util/asyncComponent';
// import Button from '@material-ui/core/Button';
// import CardBox from 'components/CardBox';
import ListView from './routes/list/index'
// import PHListView from './routes/list/PHindex.js'
// import Auth from '../../../util/Auth'
import DetailsView from './routes/details/index'
// import { NotificationContainer } from 'react-notifications';

class POS extends React.Component {

	constructor() {
		super()
		this.state = {
			addMode: false
		}
	}

	render() {
		let match = this.props.match
		return <div className="app-wrapper">
			        <Switch>
				        <Route path={`${match.url}/`} exact component={ListView}/>
			            <Route path={`${match.url}/:slug`} component={DetailsView}/>
			        </Switch>
			    </div>
	}

	toggleAddMode() {
		this.setState({
			...this.state,
			addMode: !this.state.addMode
		})
	}

}

export default POS