import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    cont: {
        borderBottom: `1px solid #d5d5d5`
    }
}))
const DialogListComponent = ({ id, name, quantity, size, first, markAndSerie, price }) => {
    const classes = useStyles()
    return (
        <div className={`col-12  mb-2`}>
            <div className={`row pb-2 ${classes.cont}`}>
                <div className={`col-sm-2 col-6`}>{id}</div>
                <div className={`col-sm-2 col-6`}>{name}</div>
                <div className={`col-sm-2 col-6`}>{markAndSerie}</div>
                <div className={`col-sm-2 col-6`}>{size}</div>
                <div className={`col-sm-2 col-6`}>{quantity}</div>
                <div className={`col-sm-2 col-6`}>{price}</div>
            </div>
        </div>
    )
}


export default DialogListComponent;
