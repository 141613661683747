import React from "react";

class ChartCard extends React.Component {

  state = {
    isHide: false,
    children: ''
  }



  // componentDidMount() {
  //   this.setState((previousState) => ({
  //     chartProperties: this.props.chartProperties,
  //     children: this.props.children,
  //   }));
  // }

  handleToggle() {
    this.setState((previousState) => ({
      isHide: !previousState.isHide,
    }));
  }

  render() {
    // const {chartProperties, isHide, children} = this.state;
    const { isHide, children } = this.state;
    
    // const {prize, title, styleName, desc, bgColor, percent} = chartProperties;
    let { prize, title, styleName, desc, bgColor, percent } = this.props.data;

    return (
      <div className="jr-card jr-card-full m-0">
        <div
          className={isHide === true ? `jr-fillchart bg-${bgColor} jr-fillchart-nocontent` : `jr-fillchart bg-${bgColor} jr-overlay-fillchart`}>
          {/* <div className="card-title mb-3">{title}</div> */}
          {children}
          <div className="jr-fillchart-content">
            <div className="card-title mb-4">{title}</div>
            <h2 className="mb-2 jr-fs-xl jr-font-weight-medium">{prize}</h2>
            {percent > 0}
            <p className="mb-0 jr-fs-sm">
              {percent ?
                <span
                  className={`jr-font-weight-medium jr-fs-md jr-chart-${styleName}`}>{percent}
                  {percent > 0 ? <i className="zmdi zmdi-caret-down jr-fs-sm" /> : null}</span>
                : null
              }
              {desc}</p>
          </div>
          {/* <div className="jr-fillchart-btn-close" onClick={this.handleToggle.bind(this)}><i
            className="zmdi zmdi-close" /></div>
          <div className="jr-fillchart-btn" onClick={this.handleToggle.bind(this)}><i
            className={`zmdi zmdi-equalizer jr-fs-lg`} />
          </div> */}
        </div>
      </div>
    );
  }
};

export default ChartCard;
