import React from 'react';
import CoursesList from './CoursesList';
import CardBox from '../../../../../components/CardBox/index';
import AddCourse from '../../AddCourse'
import { connect } from 'react-redux'
import * as CoursesActions from '../../../../../actions/Courses'
import { NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert'
import Auth from '../../../../../util/Auth'

class Courses extends React.Component {

    constructor() {
        super()
        this.state = {
            addMode: false,
            editMode: false,
            editItem: null,
            deleteWarning: false,
            selectedDeletionSlug: '',
            editSlug: ''
        }

    }

    componentWillMount() {
        this.props.dispatch(CoursesActions.getCoursesList({
            per_page: 30
        }))
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.courses.addCourseForm.added && this.props.courses.addCourseForm.added) {
            NotificationManager.success("Pomyślnie dodano nowe szkolenie!", "", 4000);
            this.toggleAddMode()
        }
        if (!prevProps.courses.addCourseForm.edited && this.props.courses.addCourseForm.edited) {
            NotificationManager.success("Pomyślnie zedytowano szkolenie!", "", 4000);
            this.toggleAddMode()
            this.props.dispatch(CoursesActions.getCoursesList({
                per_page: 30
            }))
        }
    }

    
    render() {
        return (<div className="animated slideInUpTiny animation-duration-3">
            <Auth roles={['super_admin', 'admin']}>
                <AddCourse
                    coursesList={this.props.courses.list}
                    editMode={this.state.editMode}
                    levels={this.props.courses.levels}
                    categories={this.props.courses.categories}
                    addCourseForm={this.props.courses.addCourseForm}
                    addCourse={this.addCourse.bind(this)}
                    editCourse={this.editCourse.bind(this)}
                    open={this.state.addMode}
                    loaded={this.props.courses.courseDetails.loaded}
                    editSlug={this.state.editSlug}
                    togglePopup={this.toggleAddMode.bind(this)}
                    courseDetails={this.props.courses.courseDetails.data} />
            </Auth>
            {/* <div className="row mb-md-4">
                        <div className="col-lg-6">
                            <div className="search-bar right-side-icon bg-transparent d-none d-sm-block">
                                <div className="form-group">
                                    <input className="form-control border-0" type="search" placeholder="Wyszukaj szkolenia..." />
                                    <button className="search-icon"><i className="zmdi zmdi-search zmdi-hc-lg"/></button>
                                </div>
                            </div>
                        </div>
                    </div> */}
            <div className="row mb-md-4">
                <CardBox
                    styleName="col-12"
                    cardStyle="p-0"
                    heading="Lista szkoleń"
                    headerOutside
                    addClick={this.toggleAddMode.bind(this, false)}
                    addBtn='zmdi-plus'
                    addTooltip='Dodaj szkolenie'
                >
                    <CoursesList
                        toggleAddMode={this.toggleAddMode.bind(this)}
                        onDelete={this.onDelete.bind(this)}
                        onPageChange={this.getUsersPage.bind(this)}
                        meta={this.props.courses.coursesListMeta}
                        data={this.props.courses.list}
                        loading={this.props.courses.loading}
                        nextPageLoading={this.props.courses.nextPageLoading}
                    />
                </CardBox>
            </div>
            <Auth roles={['super_admin', 'admin']}>
                <SweetAlert show={this.state.deleteWarning}
                    warning
                    showCancel
                    confirmBtnText="Tak, usuń"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    cancelBtnText="Anuluj"
                    title="Jesteś pewien?"
                    onConfirm={this.onConfirmDelete.bind(this)}
                    onCancel={this.onCancelDelete.bind(this)} >
                    Szkolenie zostanie bezpowrotnie usunięte!
                </SweetAlert>
            </Auth>
        </div>);
    }

    getUsersPage(url) {
        this.props.dispatch(CoursesActions.getCoursesList({
            per_page: 15
        }, url))
    }

    toggleAddMode(editMode = false, slug) {
        if (editMode) {
            this.props.dispatch(CoursesActions.getCourseDetails(slug))
        }
        this.setState({
            ...this.state,
            addMode: !this.state.addMode,
            editMode: editMode,
            editSlug: slug
        })
    }

    // toggleAddMode() {
    //     this.setState({
    //         ...this.state,
    //         addMode: !this.state.addMode
    //     })
    // }   

    onConfirmDelete() {
        this.props.dispatch(CoursesActions.deleteCourse(this.state.selectedDeletionSlug))
        this.setState({
            ...this.state,
            deleteWarning: false
        })
    }

    onCancelDelete() {
        this.setState({
            ...this.state,
            deleteWarning: false,
            selectedDeletionSlug: ''
        })
    }

    onDelete(slug) {
        this.setState({
            ...this.state,
            deleteWarning: !this.state.deleteWarning,
            selectedDeletionSlug: slug
        })
    }

    addCourse(data) {
        this.props.dispatch(CoursesActions.addCourse(data))
    }

    editCourse(slug, data) {
        this.props.dispatch(CoursesActions.editCourse(slug, data))
    }
}


const mapStateToProps = state => ({
    courses: state.courses,
})


export default connect(mapStateToProps)(Courses)
