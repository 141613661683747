import React from 'react';
import Table from '@material-ui/core/Table';import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';import TableHead from '@material-ui/core/TableHead';import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
// import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom';
// import Tooltip from '@material-ui/core/Tooltip';



export default class AgentSaloons extends React.Component {

  
    render() {
        return <div className="table-responsive-material">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Właściciel salonu</TableCell>
                            {/* <TableCell>Akcje</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.data.map(n => {
                            return (
                                <TableRow key={n.identifier}>
                                    <TableCell>
                                        <Link to={"/app/saloons/"+n.identifier} className="keune-link">
                                            {n.ownerName}
                                        </Link>
                                    </TableCell>
                                    {/* <TableCell>
                                        <Link to={"/app/saloons/"+n.identifier}>
                                            <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm">
                                                <i className="zmdi zmdi-eye" />
                                            </Fab>
                                        </Link>
                                    </TableCell> */}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <Link to="/app/saloons">
                    {/* <Tooltip className="pull-right m-3" id="tooltip-icon" title="Wszystkie Twoje salony" placement="top">
                        <Fab color="primary" className="jr-fab-btn" aria-label="add">
                            <i className="zmdi zmdi-view-list-alt zmdi-hc-fw zmdi-hc-lg"/>
                        </Fab>
                    </Tooltip> */}
                    <Button variant="contained" color="primary" className=" text-white pull-right m-3">Wszystkie Twoje salony</Button>
                </Link>
            </div>
    }



}
