import React, { useEffect } from 'react'
import { withRouter } from 'react-router'

const PageViewCheckerComponent = ({ history }) => {

	useEffect(() => {
	
		window.gtag('event', 'page_view', {
			page_path: window.location.pathname
		});
	}, [history.location.pathname])
	return (
		<>

		</>
	)
}

export default withRouter(PageViewCheckerComponent);