import React from 'react';
import Table from '@material-ui/core/Table';import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';import TableHead from '@material-ui/core/TableHead';import TableRow from '@material-ui/core/TableRow';
// import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
// import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment'

// let id = 0;



export default class TrainingStaffCourse extends React.Component {

    render() {
        return <div className="table-responsive-material">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Szkolenie</TableCell>
                            <TableCell>Wolne miejsca</TableCell>
                            <TableCell>Data rozpoczęcia</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.data.map(n => {
                            return <TableRow key={n.identifier}>
                                    <TableCell>
                                        <Link to={"/app/course-dates/details/"+n.identifier} className="keune-link">
                                            {n.title}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{n.freeSpots} z ({n.capacity})</TableCell>
                                    <TableCell>{moment(n.dateStart).format('YYYY-MM-DD')}</TableCell>
                                </TableRow>
                        })}
                    </TableBody>
                </Table>
            </div>
    }



}
