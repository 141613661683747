import axios from 'axios'
import settings from '../config/settings.js'



export const getInspirations = (params, url = settings.api_endpoint + "/mobile_inspiration") => {
// export const getInspirations = (params, url = settings.api_endpoint + "/course") => {
	return {
		type: 'GET_INSPIRATIONS',
		payload: axios.get(url, {
			params: params
		})
	}
}


export const addInspiration = (params, url = settings.api_endpoint + "/mobile_inspiration") => {
	return {
		type: 'ADD_INSPIRATION',
		payload: axios.post(url, params)
	}
}

export const updateInspiration = (data, url = settings.api_endpoint + "/mobile_inspiration") => {
	return {
		type: 'UPDATE_INSPIRATION',
		payload: axios.post(`${url}/${data.slug}`, data.params)
	}
}
export const deleteInspiration = (id, url = settings.api_endpoint + "/mobile_inspiration") => {
	return {
		type: 'DELETE_INSPIRATION',
		payload: axios.delete(`${url}/${id}`)
	}
}
