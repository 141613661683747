import React from 'react';
import BigCalendar from 'react-big-calendar';
// import events from './events';
import moment from 'moment';
import 'moment/locale/pl'
import { connect } from 'react-redux'
import * as CoursesActions from '../../../../../actions/Courses'
import * as UserActions from '../../../../../actions/User'
import CardBox from '../../../../../components/CardBox/index';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AddForm from '../AddForm'
import EventBox from './EventBox'
import Auth from '../../../../../util/Auth'
import DialogLoaderComponent from '../../../../../components/Loaders/DialogLoaderComponent';




class Calendar extends React.Component {

  constructor(props) {
    super()
    this.state = {
      editMode: false,
      addFormMode: false,
      addStartDate: null,
      addEndDate: null
    }
  }

  componentDidMount() {
    this.props.dispatch(CoursesActions.getCourseDates({
      per_page: 0
    }))
    this.props.dispatch(CoursesActions.getCoursesList({
      per_page: 0
    }))
    this.props.dispatch(UserActions.getTeachers({
      per_page: 0,
      'filter[role]': 'training_staff'
    }))



  }

  componentDidUpdate(prevProps) {
    if (!prevProps.courses.addCourseDateForm.added && this.props.courses.addCourseDateForm.added) {
      NotificationManager.success("Dodano pomyślnie nowy termin szkolenia!", "", 4000);
      this.toggleAddPopup()
    }
    if (!prevProps.courses.addCourseDateForm.failed && this.props.courses.addCourseDateForm.failed) {
      NotificationManager.warning("Wystąpił błąd po stronie serwera. Sprobuj ponownie pozniej lub skontaktuj sie z administratorem serwisu.", "", 4000);
      this.toggleAddPopup()
    }
  }

  render() {

    moment.locale('pl')
    const localizer = BigCalendar.momentLocalizer(moment);
    let components = {
      event: EventBox
    }
    let propsDate = this.props.match.params.date
    let initialDate = propsDate ? new Date(propsDate.replace(/-/g, '/')) : new Date()
    const messages = {
      allDay: 'Wszystkie',
      previous: 'Poprzednie',
      next: 'Następne',
      today: 'Dzisiaj',
      month: 'Miesiąc',
      week: 'Tydzień',
      day: 'Dzień',
      agenda: 'Agenda',
      date: 'Data',
      time: 'Czas',
      event: 'Szkolenie'
    }
    let editModeIcon = this.state.editMode ? "zmdi-close" : "zmdi-edit"
    let editModeBg = this.state.editMode ? "bg-grey" : "bg-amber"

    let courseDates = this.props.courses.courseDates.list.map((item, key) => {
      item.start = new Date(item.dateStart.replace(/-/g, '/'))
      item.end = new Date(item.dateEnd.replace(/-/g, '/'))
      item.allDay = true
      item.selectable = true
      return item
    })
    return (
      <div className="app-calendar app-cul-calendar animated slideInUpTiny animation-duration-3">
        <NotificationContainer />
        {this.state.addFormMode ? <AddForm open={this.state.addFormMode}
          form={this.props.courses.addCourseDateForm}
          onSubmit={this.onAddFormSubmit.bind(this)}
          teachers={this.props.user.teachers}
          courses={this.props.courses.list}
          addStartDate={this.state.addStartDate}
          addEndDate={this.state.addEndDate}
          togglePopup={this.toggleAddPopup.bind(this)} /> : null}

        <Auth roles={['super_admin', 'admin']}>
          <CardBox>
            <div>
              <Tooltip id="tooltip-icon" title="Tryb edycji" placement="top">
                <Button onClick={this.toggleEditMode.bind(this)} variant="contained" className={"jr-btn " + editModeBg + " text-white"}>
                  <i className={"zmdi " + editModeIcon + " zmdi-hc-fw"} />
                  {this.state.editMode ? <span>Tryb edycji</span> : null}
                </Button>
              </Tooltip>
              <span>Włącz tryb edycji</span>
            </div>
          </CardBox>
        </Auth>
        {this.state.editMode ? <CardBox styleName="col-12">
          <h3 className="callout">
            Jesteś w trybie edycji. Możesz teraz tworzyć terminy szkoleń zaznaczając dany termin na kalendarzu, a poprzez kliknięcie w utworzony termin przejdziesz do edycji danych na temat tego szkolenia.
          </h3>
        </CardBox> : null}
        <section className="col-12 position-relative">
          <CardBox styleName="w-100">
            <BigCalendar
              selectable
              components={components}
              messages={messages}
              events={courseDates}
              views={['month']}
              // culture='pl-PL'
              showMultiDayTimes={true}
              defaultView='month'
              defaultDate={initialDate}
              localizer={localizer}
              // scrollToTime={new Date()}
              onNavigate={this.onNavigate.bind(this)}
              onSelectEvent={this.onSelectEvent.bind(this)}
              onSelectSlot={this.onSelectSlot.bind(this)}
            />
          </CardBox>
          {this.props.courses && this.props.courses.loading
            ? <DialogLoaderComponent />
            : null
          }

        </section>
      </div>
    )
  }

  onNavigate(item) {

    let newDate = new moment(item).format('YYYY-MM-01')
    this.props.history.push('/app/course-dates/calendar/' + newDate)
  }

  onSelectEvent(eventInfo) {



  }

  onSelectSlot(slotInfo) {
    if (this.state.editMode) {
      this.setState({
        ...this.state,
        addFormMode: true,
        addStartDate: moment(slotInfo.start).format('YYYY-MM-DD'),
        addEndDate: moment(slotInfo.end).format('YYYY-MM-DD')
      })
    }

  }

  toggleAddPopup(courseId) {
    this.setState({
      ...this.state,
      addFormMode: !this.state.addFormMode
    })
  }

  onAddFormSubmit(data) {
    this.props.dispatch(CoursesActions.addCourseDate(data))
  }

  toggleEditMode() {
    if (this.state.editMode) {
      NotificationManager.warning("Wyłączono tryb edycji", "", 2000);
    } else {
      NotificationManager.success("Włączono tryb edycji", "", 2000);
    }
    this.setState({
      ...this.state,
      editMode: !this.state.editMode
    })
  }

  createCourseDate(data) {
    this.props.dispatch(CoursesActions.createCourseDate(data))
  }

}

const mapStateToProps = (state) => {
  return {
    courses: state.courses,
    user: state.user
  }
}

export default connect(mapStateToProps)(Calendar)
