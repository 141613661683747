import React, { useCallback, useEffect, useState } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
	updateBlogPost,
	addBlogPost,
	deleteBlogPost,
	getBlogPosts
}
	from '../../actions';
import TableComponent from '../../components/Table/TableComponent';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import validations from '../../functions/validations'
import { useFormik } from 'formik';
import TextFieldComponent from '../../components/Form/TextFieldComponent';
import DialogComponent from '../../components/Dialog/DialogComponent';
import DialogImgComponent from '../../components/Dialog/DialogImgComponent';
import EditComponent from '../../components/Form/EditComponent';
import { DialogTitle, Typography } from '@material-ui/core';
import moment from 'moment';
import DropComponent from '../../components/Form/DropComponent';
import PublishCheckboxComponent from '../../components/Form/PublishCheckboxComponent';
import findItem from '../../functions/findItem';
import { INITIAL_PAGINATION } from '../../constants';
import RemoveDialogComponent from '../../components/RemoveDialog/RemoveDialogComponent';


const SignupSchema = (t) => Yup.object().shape({
	title: Yup.string()
		.required(validations('required'))
		.min(3, validations('min', 3))
		.max(80, validations('max', 80)),
	description: Yup.string()
		.required(validations('required'))
		.min(3, validations('min', 3))
});

const initialData = {
	title: '',
	slug: '',
	description: '',
	teaser: '',
	author: 'KEUNE',
	published: false,
	img: {},
	thumbnail: {},
};



const BlogPage = ({
	blogPosts,
	getBlogPosts = () => { },
	updateBlogPost = () => { },
	deleteBlogPost = () => { },
	addBlogPost = () => { },
}) => {
	const [data, setData] = useState({ ...initialData });
	const [openAdd, setOpenAdd] = useState(false);
	const [img, setImg] = useState(false);
	const [thumbnail, setThumbnail] = useState(false);
	const [showError, setShowError] = useState(false);
	const [editPost, setEditPost] = useState(false);
	const [activePost, setActivePost] = useState({});
	const [show, setShow] = useState(false);
	const [deletePost, setDeletePost] = useState(false);
	const [filters, setFilters] = useState(INITIAL_PAGINATION)

	const formik = useFormik({
		validationSchema: SignupSchema(),
		enableReinitialize: true,
		initialValues: data,
		onSubmit: values => {
			let bodyFormData = new FormData();
			if (img.preview)
				bodyFormData.append('image', img)
			if (thumbnail.preview)
				bodyFormData.append('thumbnail', thumbnail)
			bodyFormData.append('title', values.title)
			bodyFormData.append('author', values.author)
			bodyFormData.append('teaser', values.teaser)
			bodyFormData.append('publish', values.published ? 1 : 0)
			bodyFormData.append('description', values.description)
			if (editPost) {
				bodyFormData.append('_method', 'put')
				updateBlogPost({ slug: values.slug, params: bodyFormData });
			}
			else
				addBlogPost(bodyFormData);
		},
	});

	useEffect(() => {
		getBlogPosts(filters);
	}, [filters])
	useEffect(() => {
		if (!blogPosts.loading && !blogPosts.error) {
			setOpenAdd(false)
			setImg(false)
			setThumbnail(false)
			setEditPost(false);
			formik.resetForm();
			setData({ ...initialData });
			setDeletePost(false);
			setActivePost(false);

		} else if (blogPosts.error) {
			NotificationManager.error(blogPosts.error, "", 2000);
		}
	}, [blogPosts])

	const onChangePage = useCallback((e, value) => {
		setFilters({
			...filters,
			page: value + 1,
		})
	}, [filters])

	const changePerPage = useCallback((e, value) => {
		setFilters({
			...filters,
			per_page: value.key,
		})
	}, [filters])

	const handleCloseAdd = useCallback(() => {
		setData(initialData);
		setOpenAdd(false);
		setImg(false);
		setEditPost(false);
	}, [initialData])

	const handleShowDelete = useCallback((id) => {
		setActivePost(findItem(blogPosts, id))
		setDeletePost(true);
	}, [blogPosts])
	const handleCloseDelete = useCallback(() => {
		setDeletePost(false);
		setActivePost(false)
	}, [])
	const handleDelete = useCallback((e) => {
		if (activePost) {
			deleteBlogPost(activePost.slug)
		}
	}, [activePost])

	const handleShow = useCallback((id) => {
		if (blogPosts && blogPosts.list && blogPosts.list.length) {
			setActivePost(findItem(blogPosts, id))
			setShow(true)
		}
	}, [blogPosts])
	const handleClose = useCallback((id) => {
		setShow(false)
	}, [show, blogPosts])
	const handleAdd = useCallback(() => {
		setOpenAdd(true)
	}, [])
	const handleEdit = useCallback((id) => {
		const post = findItem(blogPosts, id);
		if (post) {
			setImg(post.image);
			setThumbnail(post.thumbnailImage);
			setData({
				id: post.identifier,
				slug: post.slug,
				title: post.title,
				description: post.description,
				teaser: post.teaser,
				author: post.author,
				published: post.published,
				img: post.img,
				thumbnail: post.thumbnail,
			})
			setEditPost(true);
			setOpenAdd(true)
		}
	}, [blogPosts])



	return (
		<section className="app-wrapper animated slideInUpTiny animation-duration-3">
			<NotificationContainer />
			<div className="row mb-md-4">
				<TableComponent
					headerOutside
					addTooltip='Dodaj post'
					addBtn='zmdi-plus'
					addClick={handleAdd}
					handleEdit={handleEdit}
					handleShow={handleShow}
					handleDelete={handleShowDelete}
					loading={blogPosts.loading}
					nextPageLoading={blogPosts.nextPageLoading}
					heading='KEUNE Akademia Blog'
					onChangePage={onChangePage}
					changePerPage={changePerPage}
					meta={blogPosts.pagination}
					titles={['Miniaturka', 'Tytuł', 'Author', 'Opublikowany']}
					data={blogPosts && blogPosts.list && blogPosts.list.length
						? blogPosts.list.map((post, key) => ({
							id: post.identifier,
							data: {
								img: <img width="50" src={post.thumbnailImage} alt={'Miniaturka'} />,
								title: post.title,
								author: post.author,
								published: post.published ? 'Tak' : 'Nie'
							}
						}))
						: []
					}
				/>
			</div>
			<DialogComponent
				open={openAdd}
				loading={blogPosts.loading}
				handleClose={handleCloseAdd}
				title={data && data.id ? "Edytuj post" : 'Dodaj post'}
				handleSave={formik.handleSubmit}
			>
				<form onSubmit={formik.handleSubmit} className="col-md-12">
					<TextFieldComponent
						fullWidth
						id='title'
						formikProps={formik}
						label='Tytuł'
					/>
					<PublishCheckboxComponent
						formik={formik}
					/>
					<TextFieldComponent
						fullWidth
						id='author'
						formikProps={formik}
						label='Author'
					/>
					<TextFieldComponent
						fullWidth
						id='teaser'
						formikProps={formik}
						label='Teaser'
					/>

					<div>
						<EditComponent
							value={formik.values.description}
							formikProps={formik}
							id='description'
						/>
					</div>
					<div>
						<DropComponent
							label='Miniaturka'
							showError={showError}
							setShowError={setShowError}
							img={thumbnail}
							setImg={setThumbnail}
						/>
					</div>
					<div>
						<DropComponent
							showError={showError}
							setShowError={setShowError}
							img={img}
							setImg={setImg}
						/>
					</div>
				</form>
			</DialogComponent>
			<DialogComponent
				open={show}
				hiddenClose
				btnSave="Zamknij"
				handleSave={handleClose}
			>
				{activePost
					? <>
						<div className={`col-md-6`}>
							<DialogTitle className='pl-0'>{activePost ? activePost.title : ''}</DialogTitle>
						</div>
						<div className={`col-md-6`}>
							<DialogTitle align='right'>{activePost.date ? moment(activePost.date).format('DD.MM.YYYY') : ''}</DialogTitle>
						</div>
						<div className={`col-sm-4 mt-2`}>
							Author:
						</div>
						<div className={`col-sm-8 mt-2`}>
							{activePost ? activePost.author : ''}
						</div>
						<div className="col-md-12 mt-2">
							Teaser
						</div>
						<div className="col-md-12">
							<Typography dangerouslySetInnerHTML={{ __html: activePost.teaser }} />
						</div>
						<div className="col-md-12 mt-2">
							Opis
						</div>
						<div className="col-md-12">
							<Typography dangerouslySetInnerHTML={{ __html: activePost.description }} />
						</div>
						<div className="col-md-12 mt-2">
							Zdjęcie
						</div>
						<DialogImgComponent
							alt={activePost ? activePost.title : ''}
							src={activePost ? activePost.image : ''}
						/>
						<div className="col-md-12 mt-2">
							Miniaturka
						</div>
						<DialogImgComponent
							alt={activePost ? activePost.title : ''}
							src={activePost ? activePost.thumbnail : ''}
						/>
					</>
					: null
				}
			</DialogComponent>
			<RemoveDialogComponent
				name='Post'
				show={deletePost}
				onDelete={handleDelete}
				onClose={handleCloseDelete}
			/>
		</section>
	)
}

export default connect((state) => ({
	blogPosts: state.blogPosts
}), {
	updateBlogPost,
	addBlogPost,
	deleteBlogPost,
	getBlogPosts,
})(BlogPage);
