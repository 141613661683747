import React from 'react'
// TablePagination
import { Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody } from '@material-ui/core';
import { useCallback } from 'react';

const HistoryTable = ({ sort }) => {
    const handleChangeSort = useCallback((column) => {

    }, [])
    return (
        <div className="table-responsive-material">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sort && sort.name === "course_name" ? true : false}
                                direction={sort && sort.direction ? "asc" : "desc"}
                                onClick={() => handleChangeSort('course_name')} >
                                Nazwa
                                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sort && sort.name === "course_date" ? true : false}
                                direction={sort && sort.direction ? "asc" : "desc"}
                                onClick={() => handleChangeSort('course_date')} >
                                Data
                                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sort && sort.name === "balance" ? true : false}
                                direction={sort && sort.direction ? "asc" : "desc"}
                                onClick={() => handleChangeSort('balance')} >
                                Billance
                                        </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* {this.props.data.map((n, key) => {
                        return (
                            <TableRow key={key}>
                                <TableCell>
                                    <Link to={'/app/users/' + n.identifier} className="keune-link">
                                        {n.firstName + ' ' + n.lastName}
                                    </Link>
                                </TableCell>
                                <TableCell className="d-none d-md-table-cell">{n.email}</TableCell>
                                <TableCell className="d-none d-md-table-cell">{n.roles.name}</TableCell>
                            </TableRow>
                        );
                    })} */}
                </TableBody>
                {/* <TablePagination
                    count={meta ? meta.total : 0}
                    rowsPerPage={meta ? meta.per_page : 0}
                    page={meta ? meta.current_page - 1 : 0}
                    labelRowsPerPage={false}
                    onChangePage={this.onChangePage.bind(this)}
                    onChangeRowsPerPage={this.props.changePerPage.bind(this)}
                /> */}
            </Table>
        </div>
    )
}


export default HistoryTable;