import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NotificationContainer } from 'react-notifications';
import TableComponent from '../../components/Table/TableComponent';
import { INITIAL_PAGINATION } from '../../constants';
import { connect } from 'react-redux';
import { deleteInspiration, getInspirations } from '../../actions';
import RemoveDialogComponent from '../../components/RemoveDialog/RemoveDialogComponent';
import findItem from '../../functions/findItem';
import InspirationFormComponent from './components/InspirationFormComponent';
import InspirationDialogComponent from './components/InspirationDialogComponent';

const InspirationsPage = ({ 
  inspirations = [],
	deleteInspiration = () => {},
	getInspirations = () => {},
}) => {
  const data = useMemo(() => inspirations?.list?.length
  ? inspirations.list.map((post) => ({
    id: post.identifier,
    data: {
      img: <img width="50" src={post.img} alt={'Miniaturka'} />,
      title: post.title,
      published: post.published ? 'Tak' : 'Nie'
    }
  }))
  : [], [inspirations])
  const [openForm, setOpenForm] = useState(false);
  const [openShow, setOpenShow] = useState(false);
  const [filters, setFilters] = useState(INITIAL_PAGINATION)
  const [deletePost, setDeletePost] = useState(false);
  const [activePost, setActivePost] = useState({});

  useEffect(() => {
		getInspirations(filters);
	}, [filters])

  const changePerPage = useCallback((_, value) => {
		setFilters({
			...filters,
			per_page: value.key,
		})
	}, [filters])

  const onChangePage = useCallback((_, value) => {
		setFilters({
			...filters,
			page: value + 1,
		})
	}, [filters])

  const handleActivePost = useCallback((id) => {
    setActivePost(findItem(inspirations, id))
  }, [inspirations]);

  const handleShowDelete = useCallback((id) => {
		handleActivePost(id);
		setDeletePost(true);
	}, [handleActivePost])

	const handleDelete = useCallback((e) => {
		if (activePost) {
			deleteInspiration(activePost.slug)
		}
	}, [activePost])

  const handleClose = useCallback(() => {
    setOpenForm(false);
    setDeletePost(false);
    setOpenShow(false)
    setActivePost(false);
	}, [])

  const handleAdd = useCallback(() => {
    setOpenForm(true)
  },[])

  const handleEdit = useCallback((id) => {
    handleActivePost(id);
		setOpenForm(true)
	}, [handleActivePost])

  const handleShow = useCallback((id) => {
    handleActivePost(id);
		setOpenShow(true)
	}, [handleActivePost])
  return (
    <section className="app-wrapper animated slideInUpTiny animation-duration-3">
      <NotificationContainer />
      <div className="row mb-md-4">
				<TableComponent
					headerOutside
					addTooltip='Dodaj inspirację'
					addBtn='zmdi-plus'
					addClick={handleAdd}
					handleEdit={handleEdit}
					handleShow={handleShow}
					handleDelete={handleShowDelete}
					heading='Inspiracje'
					onChangePage={onChangePage}
					changePerPage={changePerPage}
					nextPageLoading={inspirations.nextPageLoading}
					loading={inspirations.loading}
					meta={inspirations.pagination}
					titles={['Miniaturka', 'Tytuł', 'Opublikowany']}
          data={data}
				/>
			</div>
      <RemoveDialogComponent
				name='Inspirację'
				show={deletePost}
				onDelete={handleDelete}
				onClose={handleClose}
			/>
      <InspirationFormComponent 
        show={openForm} 
        onClose={handleClose}
        data={activePost} 
      />
      <InspirationDialogComponent
        open={openShow}
        onClose={handleClose}
        data={activePost} 
      />
    </section>
  );
};


export default connect((state) => ({
	inspirations: state.inspirations
}), {
	deleteInspiration,
	getInspirations,
})(InspirationsPage);
