// import {
//     GET_USER_LIST,
//     GET_USER_DETAILS,
//     SEARCH_USERS,
//     AUTH_USER,
// } from '../constants/ActionTypes';
import axios from 'axios'
import settings from '../config/settings.js'

export const getNews = ( params = {}, url = settings.api_endpoint + "/panelNews") => {
    return {
        type: 'GET_NEWS',
        payload: axios.get(url, {
            params: params
        })
    }
}

export const getNewsDetails = (id) => {
    return {
        type: "GET_NEWS_DETAILS",
        payload: id ? axios.request({
            url: "/panelNews/" + id,
            method: "GET",
            baseURL: settings.api_endpoint
        })  : {}
    }
}
export const getEmptyNewsDetails = (id) => {
    return {
        type: "GET_EMPTY_NEWS_DETAILS",
    }
}

export const deleteNews = (id) => {
    return {
        type: "DELETE_NEWS",
        payload: axios.request({
            url: "/panelNews/" + id,
            method: "delete",
            baseURL: settings.api_endpoint
        })
    }
}


export const addNews = (data) => {
    let formData = new FormData()
    Object.keys(data).forEach((item, key) => {
        formData.append(item, data[item])
    })
    return {
        type: "ADD_NEWS",
        payload: axios.request({
            url: "/panelNews",
            method: "post",
            baseURL: settings.api_endpoint,
            data: formData
        })
    }
}

export const editNews = (id, data) => {
    let formData = new FormData()
    Object.keys(data).forEach((item, key) => {
        formData.append(item, data[item])
    })

    formData.append('_method', 'put')

    return {
        type: 'EDIT_NEWS',
        payload: axios.request({
            url: '/panelNews/' + id,
            method: 'POST',
            baseURL: settings.api_endpoint,
            data: formData
        })
    }
}
