import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import DialogLoaderComponent from '../Loaders/DialogLoaderComponent';

const useStyles = makeStyles((theme) => ({
    paper: {
        '@media (max-width: 500px)': {
            margin: 10,

        },
    },
    content: {
        '@media (max-width: 500px)': {
            paddingLeft: 10,
            paddingRight: 10,

        },
    }
}))
const DialogComponent = ({ loading, open, title, children, btnSave = 'Zapisz', className = '', maxWidth = 'sm', handleClose = () => { }, handleSave = () => { }, hiddenClose }) => {
    const classes = useStyles();
    return (
        <Dialog
            disableEnforceFocus
            open={open}
            onClose={handleClose}
            fullWidth
            className={className}
            maxWidth={maxWidth}
            classes={{
                paper: classes.paper
            }}
        >

            {title ? <DialogTitle>{title}</DialogTitle> : null}
            {children
                ?
                <DialogContent 
                className={classes.content}
                >
                    <div className="container">
                        <div className="row">
                            {children}
                        </div>
                    </div>
                </DialogContent>
                : null}
            <DialogActions>
                {!hiddenClose ? <Button onClick={handleClose} color="secondary" >
                    Anuluj
                            </Button>
                    : null}
                <Button onClick={handleSave} className="bg-primary text-white">
                    {btnSave}
                </Button>

            </DialogActions>
            {loading ? <DialogLoaderComponent  /> : null }
        </Dialog>
    )
}


export default DialogComponent;