const INIT_STATE = {
    list: [],
    loading: false,
    // nextPageLoading: false,
    pagination: {},
    error: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'GET_ORDER_HISTORY_PENDING':
            return {
                ...state,
                error: false,
                loading: state.pagination && !state.pagination.current_page,
                // nextPageLoading: state.pagination && (state.pagination.current_page >= 1),
            }

        case 'GET_ORDER_HISTORY_REJECTED':
            return {
                ...state,
                error: 'Nie udało się załadować nowe dane',
                loading: false,
                nextPageLoading: false
            }

        case 'GET_ORDER_HISTORY_FULFILLED':
            return {
                ...state,
                list: action.payload.data.data ? action.payload.data.data : [],
                loading: false,
                // nextPageLoading: false,
                pagination: action.payload.data.meta ? action.payload.data.meta.pagination : {}
            }
        default:
            return state;
    }
}
