import React from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import TableComponent from '../../components/Table/TableComponent';
import DialogComponent from '../../components/Dialog/DialogComponent';
import { useCallback } from 'react';
import { useState } from 'react';
import { DialogTitle, IconButton, Tooltip, Typography } from '@material-ui/core';
import TextFieldComponent from '../../components/Form/TextFieldComponent';
import { useFormik } from 'formik';
import EditComponent from '../../components/Form/EditComponent';
import { makeStyles } from '@material-ui/styles';
import SweetAlert from 'react-bootstrap-sweetalert'
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { addNotificationsApp, deleteNotificationsApp, getNotificationsApp, updateNotificationsApp, sendPushNotificationApp } from '../../actions';
import moment from 'moment';
import * as Yup from 'yup';
import validations from '../../functions/validations'
import SendIcon from '@material-ui/icons/Send';
import DropComponent from '../../components/Form/DropComponent';
import DialogImgComponent from '../../components/Dialog/DialogImgComponent';
import findItem from '../../functions/findItem';
import { INITIAL_PAGINATION } from '../../constants';

const useStyles = makeStyles((theme) => ({
    showDialogTitle: {
        marginBottom: 20,
    },
    boldTitle: {
        fontWeight: 'bold',
    },
    mainPageInfoTitle: {
        marginTop: 40,
        marginBottom: 0,
    }
}))

const SignupSchema = (t) => Yup.object().shape({
    name: Yup.string()
        .required(validations('required'))
        .min(3, validations('min', 3))
        .max(80, validations('max', 80)),
    intro: Yup.string()
        .required(validations('required'))
        .min(3, validations('min', 3))
        .max(250, validations('max', 250)),
    description: Yup.string()
        .required(validations('required'))
        .min(3, validations('min', 3))
});

const initialData = {
    name: '',
    intro: '',
    description: '',
    slug: '',
};


const NotificationsPage = ({ getNotificationsApp, notificationsApp, updateNotificationsApp, deleteNotificationsApp, addNotificationsApp, sendPushNotificationApp }) => {
    const classes = useStyles();
    const [data, setData] = useState({ ...initialData });
    const [openAdd, setOpenAdd] = useState(false);
    const [img, setImg] = useState(false);
    const [showError, setShowError] = useState(false);
    const [editNotification, setEditNotification] = useState(false);
    const [activeNotification, setActiveNotification] = useState({});
    const [show, setShow] = useState(false);
    const [deleteNotification, setDeleteNotification] = useState(false);
    const [filters, setFilters] = useState(INITIAL_PAGINATION)
    useEffect(() => {
        getNotificationsApp(filters);
    }, [filters])

    useEffect(() => {
        if (!notificationsApp.loading && !notificationsApp.error) {
            setOpenAdd(false)
            setImg(false)
            setEditNotification(false);
            formik.resetForm();
            setData({ ...initialData });
            setDeleteNotification(false);
            setActiveNotification(false);

        } else if (notificationsApp.error) {
            NotificationManager.error(notificationsApp.error, "", 2000);
        }
    }, [notificationsApp])

    const handleShow = useCallback((id) => {
        if (notificationsApp && notificationsApp.list && notificationsApp.list.length) {
            const notification = findItem(notificationsApp, id);
            setActiveNotification(notification)
            setShow(true)
        }
    }, [notificationsApp])
    const handleClose = useCallback((id) => {
        setShow(false)
    }, [show, notificationsApp])
    const handleAdd = useCallback(() => {
        setOpenAdd(true)
    }, [])
    const handleEdit = useCallback((id) => {
        const notification = findItem(notificationsApp, id);
        if (notification) {
            setImg(notification.image);
            setData({
                id: notification.identifier,
                name: notification.title,
                intro: notification.intro,
                slug: notification.slug,
                description: notification.description,
            })
            setEditNotification(true);
            setOpenAdd(true)
        }
    }, [notificationsApp])
    const handleCloseAdd = useCallback(() => {
        setData(initialData);
        setOpenAdd(false);
        setImg(false);
        setEditNotification(false);
    }, [initialData])
    const handleShowDelete = useCallback((id) => {
        setActiveNotification(findItem(notificationsApp, id))
        setDeleteNotification(true);
    }, [notificationsApp])
    const handleCloseDelete = useCallback(() => {
        setDeleteNotification(false);
        setActiveNotification(false)
    }, [])
    const handleDelete = useCallback((e) => {
        if (activeNotification) {
            deleteNotificationsApp(activeNotification.identifier)
        }
    }, [activeNotification])

    const formik = useFormik({
        validationSchema: SignupSchema(),
        enableReinitialize: true,
        initialValues: data,
        onSubmit: values => {
            let bodyFormData = new FormData();

            bodyFormData.append('image', img && img.preview ? img : '')
            bodyFormData.append('name', values.name)
            bodyFormData.append('intro', values.intro)
            bodyFormData.append('description', values.description)
            bodyFormData.append('slug', values.slug || '')
            if (editNotification) {
                bodyFormData.append('_method', 'put')
                updateNotificationsApp({ id: values.id, params: bodyFormData });
            }
            else
                addNotificationsApp(bodyFormData);
        },
    });

    const onChangePage = useCallback((e, value) => {
        setFilters({
            ...filters,
            page: value + 1,
        })
    }, [filters])
    const changePerPage = useCallback((e, value) => {
        setFilters({
            ...filters,
            per_page: value.key,
        })
    }, [filters])

    const handleSendPush = useCallback((id) => {
        sendPushNotificationApp(id)

    }, [])
    return (
        <div className="app-wrapper animated notificationInUpTiny animation-duration-3">
            <NotificationContainer />
            <div className="row mb-md-4">
                <TableComponent
                    headerOutside
                    heading='Lista powiadomień'
                    addTooltip='Dodaj notyfikacje'
                    addBtn='zmdi-plus'
                    addClick={handleAdd}
                    handleEdit={handleEdit}
                    handleShow={handleShow}
                    onChangePage={onChangePage}
                    loading={notificationsApp.tableLoading}
                    nextPageLoading={notificationsApp.nextPageLoading}
                    meta={notificationsApp.pagination}
                    changePerPage={changePerPage}
                    handleDelete={handleShowDelete}
                    titles={['Nazwa', 'Data stworzenia', 'Na głównej', 'Wyślij']}
                    data={notificationsApp && notificationsApp.list && notificationsApp.list.length
                        ? notificationsApp.list.map((not) => ({
                            id: not.identifier,
                            data: {
                                name: not.title,
                                date: moment(not.date).format('DD.MM.YYYY'),
                                hasMainBlock: not.slug && not.image ? "Tak" : "Nie",
                                sendAction:
                                    <Tooltip title={not.is_send ? "Wyślij push pow. ponownie" : "Wyślij push powiadomienie"} placement="top">
                                        <IconButton color={not.is_send ? 'default' : 'primary'} onClick={() => handleSendPush(not.identifier)}>
                                            <SendIcon />
                                        </IconButton>
                                    </Tooltip>
                            }
                        }))
                        : []}
                />
            </div>
            <DialogComponent
                open={openAdd}
                handleClose={handleCloseAdd}
                title={'Dodaj notyfikacje'}
                handleSave={formik.handleSubmit}
                loading={notificationsApp.loading}
            >
                <form onSubmit={formik.handleSubmit} className="col-md-12">
                    <TextFieldComponent
                        fullWidth
                        id='name'
                        formikProps={formik}
                        label='Nazwa'
                    />
                    <TextFieldComponent
                        fullWidth
                        id='intro'
                        formikProps={formik}
                        label='Wstęp'
                    />

                    <TextFieldComponent
                        fullWidth
                        id='slug'
                        formikProps={formik}
                        label='Adres'
                    />
                    <div>
                        <EditComponent
                            value={formik.values.description}
                            formikProps={formik}
                            id='description'
                        />
                    </div>
                    {formik && formik.values && formik.values.slug
                        ? <div>
                            <DropComponent
                                showError={showError}
                                setShowError={setShowError}
                                img={img}
                                setImg={setImg}
                            />
                        </div>
                        : null
                    }
                </form>
            </DialogComponent>
            <DialogComponent open={show} handleClose={handleClose} handleSave={handleShow}>
                {activeNotification
                    ? <>
                        <div className={`col-md-6`}>
                            <DialogTitle>{activeNotification ? activeNotification.title : ''}</DialogTitle>
                        </div>
                        <div className={`col-md-6`}>
                            <DialogTitle align='right'>{activeNotification.date ? moment(activeNotification.date).format('DD.MM.YYYY') : ''}</DialogTitle>
                        </div>
                        <div className={`col-md-12 ${classes.showDialogTitle}`}>
                            <Typography className={classes.boldTitle}>{activeNotification.intro} </Typography>
                        </div>
                        {activeNotification.slug ?
                            <div className='col-md-12'>
                                <Typography >{activeNotification.slug} </Typography>
                            </div>
                            : null}
                        <div className="col-md-12">
                            <Typography dangerouslySetInnerHTML={{ __html: activeNotification.description }} />
                        </div>
                        <DialogImgComponent
                            alt={activeNotification ? activeNotification.title : ''}
                            src={activeNotification ? activeNotification.image : ''}
                        />
                    </>
                    : null
                }
            </DialogComponent>
            <SweetAlert show={deleteNotification}
                warning
                showCancel
                confirmBtnText="Tak, usuń"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                cancelBtnText="Anuluj"
                title="Jesteś pewien?"
                onConfirm={handleDelete}
                onCancel={handleCloseDelete} >
                Notyfikacja zostanie bezpowrotnie usunięta!
            </SweetAlert>
        </div>
    )
}


export default connect((state) => ({
    notificationsApp: state.notificationsApp
}), {
    getNotificationsApp,
    updateNotificationsApp,
    sendPushNotificationApp,
    addNotificationsApp,
    deleteNotificationsApp,
})(NotificationsPage);