import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CardBox from '../../../../../components/CardBox';
// import ContactCard from './ContactCard'
import WorkersList from './WorkersList'
import { connect } from 'react-redux'
import * as PosActions from '../../../../../actions/Pos'
import * as UserActions from '../../../../../actions/User'
import Widget from "../../../../../components/Widget";
import Auxiliary from "../../../../../util/Auxiliary";
import { Link } from 'react-router-dom'
import Auth from '../../../../../util/Auth'
import Tooltip from '@material-ui/core/Tooltip';
import ReactSelect from 'react-select'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Condition from '../../../../../util/Condition'
import OrderCardsComponent from '../../../../../components/orderStatisticComponents/OrderCardsComponent';
import ShouldPayComponent from './ShouldPayComponent';
import getRole from '../../../../../functions/getRole';
import DialogLoaderComponent from '../../../../../components/Loaders/DialogLoaderComponent';
import InfoRowComponent from './InfoRowComponent';

class Details extends React.Component {

  constructor(props) {

    super(props)
    this.state = {
      changeAgentMode: false,
      selectedAgent: null,
      selectedWorker: null
    }
  }

  componentWillMount() {
    let slug = this.props.match.params.slug
    const userType = getRole(this.props.user)
    this.props.dispatch(PosActions.getPosDetails(slug, ['ph', 'admin', 'super_admin'].includes(userType) ? 'include=statistics,payments' : 'include=statistics'))
    this.props.dispatch(PosActions.getPosWorkers(slug))
    this.props.dispatch(PosActions.getPosAgent(slug))
    this.props.dispatch(PosActions.getPosOwner(slug))

    this.props.dispatch(UserActions.getFreeAgents())
    this.props.dispatch(UserActions.getFreeWorkers())

  }

  componentDidUpdate(prevProps) {
    // let slug = this.props.match.params.slug
    if (!prevProps.pos.updatedPosAgent && this.props.pos.updatedPosAgent) {
      NotificationManager.success("Pomyślnie zaktualizowano listę obecności!", "", 4000)
      this.toggleChangeAgentMode()
    }
    if (!prevProps.pos.addWorkerToPosLoaded && this.props.pos.addWorkerToPosLoaded) {
      // NotificationManager.success("Pomyślnie zaktualizowano listę obecności!", "", 4000)
      // this.toggleChangeAgentMode()
      // this.props.dispatch(PosActions.getPosWorkers(slug))
      this.props.dispatch(UserActions.getFreeWorkers())
    }
  }

  handleCopy = (code = 'TEST') => {
    navigator.clipboard.writeText(code);
  };
  render() {
    // let match = this.props.match
    let posData = this.props.pos.posDetails.data
    const loading = this.props.pos.posDetails.loading
    let preparedAgentsList = this.props.user.freeAgents.list.map((item, key) => {
      return {
        label: item.firstName + ' ' + item.lastName,
        value: item.identifier
      }
    })
    preparedAgentsList = preparedAgentsList.filter((item, key) => {
      return item.value !== this.props.pos.posAgent.identifier
    })
    return <Auxiliary>
      <NotificationContainer />
      <div className="jr-profile-banner">
        <div className="jr-profile-container">
          <div className="jr-profile-banner-top">
            <div className="jr-profile-banner-top-left">
              <div className="jr-profile-banner-avatar-info">
                <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">{
                  loading
                    ? ''
                    : posData.title
                }</h2>
                <p className="mb-0 jr-fs-lg">{
                  loading
                    ? ''
                    : `${posData.regionName
                      ? posData.regionName.name
                      : ''}, ${posData.cityName}, ${posData.nameAddress}`
                }</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="jr-profile-content">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-12">
            <Widget title="Dane kontaktowe" styleName="jr-card-profile-sm">
              <InfoRowComponent
                iconName={'zmdi-my-location'}
                title={'Adres'}
              >
                <p className="mb-0">{`${posData.regionName ? posData.regionName.name : ''} ${posData.cityName} ${posData.nameAddress}`}</p>
              </InfoRowComponent>
              <InfoRowComponent
                iconName={'zmdi-pin-account'}
                title={'Przedstawiciel handlowy salonu'}
              >
                <Condition condition={this.props.pos.posAgent.firstName}>
                  <Link className="keune-link" to={"/app/users/" + this.props.pos.posAgent.identifier}>
                    {this.props.pos.posAgent.firstName + " " + this.props.pos.posAgent.lastName}
                  </Link>
                </Condition>
                <Condition condition={!this.props.pos.posAgent.firstName}>
                  Brak przedstawiciela handlowego
                </Condition>
                <Auth roles={['super_admin', 'admin']}>
                  <Tooltip onClick={this.toggleChangeAgentMode.bind(this)} id="tooltip-icon" className="ml-3" title="Zmień szkoleniowca" placement="top">
                    <Button color="primary" className="bg-orange text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                      <i className="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg" />
                    </Button>
                  </Tooltip>
                  {this.state.changeAgentMode ? <div className="row mt-2">
                    <ReactSelect
                      className="col-10"
                      options={preparedAgentsList}
                      onChange={this.onChangeAgent.bind(this)}
                      value={this.state.selectedAgent}
                      placeholder="Wyszukaj handlowca"
                    />
                    <div className="mt-1">
                      <Tooltip onClick={this.updatePosAgent.bind(this)} id="tooltip-icon" title="Zapisz" placement="top">
                        <Button color="primary" className="bg-green text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                          <i className="zmdi zmdi-save zmdi-hc-fw zmdi-hc-lg" />
                        </Button>
                      </Tooltip>
                    </div>
                  </div> : null}
                </Auth>
              </InfoRowComponent>
              <InfoRowComponent
                iconName={'zmdi-favorite'}
                title={'Promo kod -10% dla klienta na pierwszy zakup'}
              >
                {posData.promoCodeName
                  ? <>
                    {posData.promoCodeName}
                    <Tooltip onClick={() => this.handleCopy(posData.promoCodeName)} id="tooltip-copy-icon" className="ml-3" title="Skopiować" placement="top">
                      <Button color="primary" className="bg-orange text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                        <i className="zmdi zmdi-copy zmdi-hc-fw zmdi-hc-lg" />
                      </Button>
                    </Tooltip>
                  </>
                  : '-'}

              </InfoRowComponent>
              {loading ? <DialogLoaderComponent /> : null}
            </Widget>
          </div>
          <Condition condition={this.props.pos.posOwner.identifier}>
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <Widget title="Właściciel salonu" styleName="jr-card-profile-sm">
                <InfoRowComponent
                  iconName={'zmdi-account-box-o'}
                  title={'Imię i nazwisko'}
                >
                  <p className="mb-0">
                    <Link className="keune-link" to={"/app/users/" + this.props.pos.posOwner.identifier}>
                      {this.props.pos.posOwner.firstName + " " + this.props.pos.posOwner.lastName}
                    </Link>
                  </p>
                </InfoRowComponent>
                <InfoRowComponent
                  iconName={'zmdi-phone'}
                  title={'Numer telefonu'}
                >
                  <p className="mb-0">
                    <a className="keune-link" href={"tel:" + this.props.pos.posOwner.phoneNumber}>{this.props.pos.posOwner.phoneNumber}</a>
                  </p>
                </InfoRowComponent>
                <InfoRowComponent
                  iconName={'zmdi-email'}
                  title={'Adres e-mail'}
                >
                  <p className="mb-0">
                    <a className="keune-link" href={"mailto:" + this.props.pos.posOwner.email}>{this.props.pos.posOwner.email}</a>
                  </p>
                </InfoRowComponent>
                {loading ? <DialogLoaderComponent /> : null}
              </Widget>
            </div>
          </Condition>
          <ShouldPayComponent
            loading={loading}
          />
        </div>
        <div className="row">
          <Condition
            condition={this.props.pos.posWorkers.list.length > 0}
            custom={true}
            render={this.renderWorkersList.bind(this)}>
          </Condition>
        </div>
        <Auth roles={['super_admin', 'admin']}>
          {this.renderAddWorkers()}
        </Auth>
      </div>
      <OrderCardsComponent
        orderData={posData && posData.statistics ? posData.statistics.data : {}}
        styleName='w-100'
        loading={loading}
      />
      <Auth roles={['super_admin', 'admin']}>
        <OrderCardsComponent
          hideDesc
          heading="Statystyki wypłat Aplikacji Keune"
          orderData={posData && posData.payments ? posData.payments.data : {}}
          styleName='w-100'
          loading={loading}
        />
      </Auth>
    </Auxiliary>
  }

  renderWorkersList() {
    return <CardBox styleName="col-12" cardStyle="p-0" heading={"Pracownicy Salonu"}
      headerOutside>
      <WorkersList
        list={this.props.pos.posWorkers.list}
        loading={this.props.pos.posWorkers.loading}

      />
    </CardBox>
  }

  renderAddWorkers() {
    let selectList = this.props.user.freeWorkers.list.map((item, key) => {
      return {
        value: item.identifier,
        label: item.firstName + " " + item.lastName
      }
    })
    return <div className="col-xl-6 col-lg-6 col-md-6 col-12 pl-0">
      <Widget title="Dodaj pracownika do salonu" styleName="jr-card-profile-sm">
        <div className="media align-items-center flex-nowrap jr-pro-contact-list">
          <div className="media-body">
            <div className="row align-items-center">
              <div className="col-10">
                <ReactSelect
                  options={selectList}
                  value={this.state.selectedWorker}
                  onChange={this.setSelectedWorker.bind(this)}
                  placeholder="Wybierz pracownika..."
                />
              </div>
              <div>
                <Tooltip id="tooltip-icon" onClick={this.addWorkerToPos.bind(this)} title="Dodaj" placement="top">
                  <Fab color="primary" className="jr-fab-btn" aria-label="add">
                    <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg" />
                  </Fab>
                </Tooltip>
              </div>
            </div>


          </div>
        </div>
        {/* <DialogLoaderComponent /> */}
        {/* {this.props.pos.posDetails.loading ? <DialogLoaderComponent /> : null} */}
      </Widget>
    </div>
  }

  addWorkerToPos() {
    let posID = this.props.match.params.slug
    let userID = this.state.selectedWorker.value

    this.props.dispatch(PosActions.addWorkerToPos(posID, userID))
  }

  setSelectedWorker(item) {

    this.setState({
      ...this.state,
      selectedWorker: item
    })
  }

  updatePosAgent() {
    let posId = this.props.match.params.slug
    this.props.dispatch(PosActions.updatePosAgent(posId, this.state.selectedAgent.value))
  }

  onChangeAgent(value) {
    this.setState({
      ...this.state,
      selectedAgent: value
    })
  }

  toggleChangeAgentMode() {
    this.setState({
      ...this.state,
      changeAgentMode: !this.state.changeAgentMode
    })
  }

}

const mapStateToProps = (state) => {
  return {
    pos: state.pos,
    user: state.user
  }
}

export default connect(mapStateToProps)(Details)
