import React from 'react';
import Table from '@material-ui/core/Table'; import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableHead from '@material-ui/core/TableHead'; import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom'
import TablePagination from '@material-ui/core/TablePagination';
import Auth from '../../../../../util/Auth'
import TableLoaderComponent from '../../../../../components/Loaders/TableLoaderComponent';
import DialogLoaderComponent from '../../../../../components/Loaders/DialogLoaderComponent';

export default class CoursesList extends React.Component {

    constructor(props) {
        super()
        this.state = {}
    }

    render() {
        const { meta } = this.props;
        return <div className="table-responsive-material">
            <TableLoaderComponent
                loading={this.props.loading}
            >
                    <section className='position-relative' >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tytuł</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell className="d-none d-md-table-cell">Lead</TableCell>
                                    <TableCell>Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.data.map((n, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell>
                                                <Link to={"/app/courses/" + n.slug} className="keune-link">
                                                    {n.title}
                                                </Link>
                                            </TableCell>
                                            <TableCell style={{ backgroundColor: n.colorHEX }}>
                                                <img className="rounded float-left" src={n.thumbnail} style={{ maxWidth: 50 }} alt={'Miniaturka Kursu'} />
                                            </TableCell>
                                            <TableCell className="d-none d-md-table-cell">{n.lead}</TableCell>
                                            <TableCell>
                                                <Link to={'/app/courses/' + n.slug}>
                                                    <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm mr-1">
                                                        <i className="zmdi zmdi-eye" />
                                                    </Fab>
                                                </Link>
                                                <Auth roles={['super_admin', 'admin']}>
                                                    <Fab onClick={this.toggleEditMode.bind(this, n.slug)} className="jr-fab-btn bg-amber text-white jr-btn-fab-sm mr-1">
                                                        <i className="zmdi zmdi-edit" />
                                                    </Fab>
                                                    <Fab onClick={this.props.onDelete.bind(this, n.slug)} className="jr-fab-btn bg-red text-white jr-btn-fab-sm">
                                                        <i className="zmdi zmdi-delete" />
                                                    </Fab>
                                                </Auth>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {this.props.nextPageLoading
                            ? <DialogLoaderComponent opacity />
                            : null
                        }
                    </section>

                    <TablePagination
                        count={meta && meta.total ? meta.total : 0}
                        rowsPerPage={meta && meta.per_page ? meta.per_page : 0}
                        page={meta && meta.current_page ? meta.current_page - 1 : 0}
                        labelRowsPerPage={false}
                        component='div'
                        onChangePage={this.onChangePage.bind(this)}
                        onChangeRowsPerPage={this.onChangePage.bind(this)}
                    />
            </TableLoaderComponent>
        </div>
    }

    toggleEditMode(slug) {
        this.props.toggleAddMode(true, slug)
    }

    onChangePage(e, dir) {


        if (dir < this.props.meta.current_page) {
            this.props.onPageChange(this.props.meta.links.previous)
        } else {
            this.props.onPageChange(this.props.meta.links.next)
        }
    }

}

// function CoursesList(props) {

//     return (
//         <div className="table-responsive-material">
//             <Table>
//                 <TableHead>
//                     <TableRow>
//                         <TableCell>Imię i nazwisko</TableCell>
//                         <TableCell>POS</TableCell>
//                         <TableCell>E-mail</TableCell>
//                         <TableCell>Akcje</TableCell>
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {props.data.map((n, key) => {
//                         return (
//                             <TableRow key={key}>
//                                 <TableCell>{n.firstName+' '+n.lastName}</TableCell>
//                                 <TableCell>{n.pos}</TableCell>
//                                 <TableCell>{n.email}</TableCell>
//                                 <TableCell>
//                                     <Link to={'/app/users/' + n.identifier}>
//                                         <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm mb-3">
//                                             <i className="zmdi zmdi-eye" />
//                                         </Fab>
//                                     </Link>
//                                     <Link to="/edit">
//                                         <Fab className="jr-fab-btn bg-amber text-white jr-btn-fab-sm mb-3">
//                                             <i className="zmdi zmdi-edit" />
//                                         </Fab>
//                                     </Link>
//                                     <Link to="/delete">
//                                         <Fab className="jr-fab-btn bg-red text-white jr-btn-fab-sm mb-3">
//                                             <i className="zmdi zmdi-delete" />
//                                         </Fab>
//                                     </Link>
//                                 </TableCell>
//                             </TableRow>
//                         );
//                     })}
//                 </TableBody>
//                 <TablePagination
//                     count={32}
//                     rowsPerPage={5}
//                     page={0}
//                     onChangePage={changePage}
//                     onChangeRowsPerPage={changePage}
//                   />
//             </Table>
//         </div>
//     );
// }

// function changePage() {

// }
