const INIT_STATE = {
    error: false,
    loading: false,
    salons: [],
    sales: []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'GET_SALONS_FILTER_PENDING':
            return {
                ...state,
                error: false,
                loading: true
            }

        case 'GET_SALONS_FILTER_REJECTED':
            return {
                ...state,
                error: 'Nie udało się pobrać nowe dane',
                loading: false
            }

        case 'GET_SALONS_FILTER_FULFILLED':
            return {
                ...state,
                salons: action.payload.data.data ? action.payload.data.data : [],
                loading: false,
            }
        case 'GET_SALES_FILTER_PENDING':
            return {
                ...state,
                error: false,
                loading: true
            }

        case 'GET_SALES_FILTER_REJECTED':
            return {
                ...state,
                error: 'Nie udało się pobrać nowe dane',
                loading: false
            }

        case 'GET_SALES_FILTER_FULFILLED':
            return {
                ...state,
                sales: action.payload.data.data ? action.payload.data.data : [],
                loading: false,
            }
        default:
            return state;
    }
}
