import { Chip } from '@material-ui/core';
import React, { useMemo } from 'react'
import { STATUS_COMPLETED } from '../../../constants';

import Auth from '../../../util/Auth';

const GivenChipComponent = ({ handleGiveToClient = () => { }, order, activeText, nonActiveText, active}) => {
    const isActive = useMemo(() => order.status === STATUS_COMPLETED, [order])
    return (
        <>
            <Auth roles={['super_admin', 'admin']}>
                <Chip
                    label={active && isActive ? activeText : nonActiveText}
                    color={active || !isActive ? 'default' : 'primary'}
                    variant={!isActive ? 'default' : 'outlined'}
                    onClick={() => handleGiveToClient(order, isActive)}
                />
            </Auth>
            <Auth roles={['ph', 'pos', 'pos_admin']}>
                <Chip
                    label={active && isActive ? activeText : nonActiveText}
                    color={active || !isActive ? 'default' : 'primary'}
                    variant={!isActive ? 'default' : 'outlined'}
                    onClick={() => handleGiveToClient(order, isActive)}
                />
            </Auth>
        </>
    )
}


export default GivenChipComponent;
