import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { connect } from 'react-redux'
// import * as CoursesActions from '../../../actions/Courses'
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
// import Select from '@material-ui/core/Select';
// import Input from '@material-ui/core/Input';
import Editor from './editor'
import Dragndrop from './dragndrop'
// import { CirclePicker } from 'react-color'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogLoaderComponent from '../../../components/Loaders/DialogLoaderComponent';
// import FormLabel from '@material-ui/core/FormLabel';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import ReactSelect from 'react-select'
// import roles from '../../../util/Roles'

export default class AddCourse extends React.Component {

    constructor(props) {
        super()
        this.state = {
            selectedRoleGroup: null,
            news: {
                // role: '',
                title: '',
                lead: '',
                description: '',
                roles: [],
                image: null
            },
            errors: {
                title: '',
                lead: '',
                description: '',
                roleID: '',
                image: ''
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.newsDetails.title !== this.props.newsDetails.title) {
            let { title, lead, description, roles, image } = this.props.newsDetails
            // let currentRole = roles.filter((item, key) => {
            //     return item.value == roleID
            // })
          
            this.setState({
                ...this.state,
                news: {
                    ...this.state.news,
                    title: title,
                    lead: lead,
                    description: description,
                    roles: roles,
                    image: image ? image.data.urlFile : null
                }
            })
        }
    }


    render() {
       
        // let { title, lead, description, roleID } = this.props.addForm.errors
        let { lead } = this.props.addForm.errors
        let titleError = this.props.addForm.errors.title ? true : false
        let leadError = this.props.addForm.errors.lead ? true : false
        // let descriptionError = this.props.addForm.errors.description ? true : false
        // let roleIDError = this.props.addForm.errors.categoryID ? true : false

     

        return <form onSubmit={this.onSubmit.bind(this)}>
            <Dialog open={this.props.open} onClose={this.handleRequestClose}>
                <DialogLoaderComponent loaded={this.props.loaded} />
                <DialogTitle>{this.props.editMode ? 'Edytuj newsa' : 'Dodaj newsa'}</DialogTitle>
                <DialogContent>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <TextField
                                    error={titleError}
                                    autoFocus
                                    onChange={this.onChange.bind(this)}
                                    margin="dense"
                                    id="title"
                                    label={this.props.addForm.errors.title ? this.props.addForm.errors.title : 'Tytuł'}
                                    type="text"
                                    value={this.state.news.title}
                                    name="title"
                                    fullWidth
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <TextField
                                    error={leadError}
                                    onChange={this.onChange.bind(this)}
                                    margin="dense"
                                    id="lead"
                                    label={lead ? lead : 'Lead'}
                                    type="text"
                                    value={this.state.news.lead}
                                    name="lead"
                                    fullWidth
                                />
                            </div>

                            <div className="col-md-12 mb-3">
                                <Editor onEditorChange={this.onEditorChange.bind(this)}
                                    name="description"
                                    initialValue={this.state.news.description}
                                    value={this.state.news.description}
                                    onChange={this.onChange.bind(this)} />
                            </div>
                            <div className="col-md-12 mb-3">
                                {/* <ReactSelect
                                            className="col-10"
                                            options={roles}
                                            onChange={this.onRoleChange.bind(this)}
                                            value={this.state.selectedRoleGroup}
                                            placeholder="Wybierz grupę docelową"
                                        /> */}
                                <FormControlLabel
                                    control={
                                        <Checkbox color="primary"
                                            checked={this.state.news.roles.indexOf(3) > -1 ? true : false}
                                            onChange={this.onRoleChange.bind(this)}
                                            value={3}
                                            name={3}
                                        />
                                    }
                                    label="Przedstawiciel handlowy"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox color="primary"
                                            checked={this.state.news.roles.indexOf(4) > -1 ? true : false}
                                            onChange={this.onRoleChange.bind(this)}
                                            value={4}
                                            name={4}
                                        />
                                    }
                                    label="Szef salonu"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox color="primary"
                                            checked={this.state.news.roles.indexOf(5) > -1 ? true : false}
                                            onChange={this.onRoleChange.bind(this)}
                                            value={5}
                                            name={5}
                                        />
                                    }
                                    label="Pracownik salonu"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox color="primary"
                                            checked={this.state.news.roles.indexOf(6) > -1 ? true : false}
                                            onChange={this.onRoleChange.bind(this)}
                                            value={6}
                                            name={6}
                                        />
                                    }
                                    label="Kadra szkoleniowa"
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <h2>Zdjęcie</h2>
                                <Dragndrop existingImage={this.state.news.image}
                                    name="image"
                                    onFileChange={this.onFileChange.bind(this)} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.saveForm.bind(this)} color="primary">
                        {this.props.editMode ? 'Zapisz' : 'Dodaj'}
                    </Button>
                    <Button onClick={this.togglePopup.bind(this)} color="secondary">
                        Anuluj
                            </Button>
                </DialogActions>
            </Dialog>
        </form>
    }

    onRoleChange(e) {
      
        let roles = [...this.state.news.roles]
       
        // if ( e.target.checked ) {
        //     roles.push(e.target.name)
        // } else {
        //     roles.filter((item, key) => item == e.target.name)
        // }

        if (roles.indexOf(parseInt(e.target.name)) > -1) {
            // roles.push(e.target.name)
          
            roles = roles.filter((item, key) =>  item !== parseInt(e.target.name))
           

        } else {
            roles.push(parseInt(e.target.name))
            // roles.filter((item, key) => item == e.target.name)
        }

        this.setState({
            ...this.state,
            news: {
                ...this.state.news,
                roles: roles
            }
        })
    }

    // onRoleChange(item) {
    //     this.setState({
    //         ...this.state,
    //         selectedRoleGroup: item,
    //         news: {
    //             ...this.state.news,
    //             roleID: item.value
    //         }
    //     })
    // }

    togglePopup() {

        this.clearForm()
        this.props.togglePopup()
    }

    clearForm() {
        this.setState({
            ...this.state,
            news: {
                title: '',
                lead: '',
                description: '',
                roles: [],
                image: null
            }
        })
    }
    onFileChange(name, value) {
        let news = { ...this.state.news }
        news[name] = value[0]
        this.setState({
            ...this.state,
            news: news
        })
    }

    onEditorChange(name, value) {
        let news = { ...this.state.news }
        news[name] = value
        this.setState({
            ...this.state,
            news: news
        })
    }


    onChange(e) {
        let news = { ...this.state.news }
        news[e.target.name] = e.target.value
        this.setState({
            ...this.state,
            news: news
        })
    }

    onSubmit(e) {
        // let data = new FormData(e.target)
    }

    saveForm() {
        let slug = this.props.editSlug
        if (this.props.editMode) {
            this.props.editCourse(slug, this.state.news)
        } else {
            this.props.onSubmit(this.state.news)
        }
        this.clearForm()
    }

}
