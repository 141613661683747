import axios from 'axios'
import settings from '../config/settings.js'



export const getNotifications = (params, url = settings.api_endpoint + "/activity") => {
    return {
        type: 'GET_NOTIFICATIONS',
        payload: axios.get(url, {
            params: params
        })
    }
}

export const setNotificationAsRead = (id) => {
    return {
        type: 'SET_NOTIFICATION_AS_READ',
        payload: axios.request({
            url: settings.api_endpoint + '/activity/' + id,
            method: 'PUT',
            baseURL: settings.api_endpoint
        })
    }
}
