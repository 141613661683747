import React from 'react'
import Button from '@material-ui/core/Button';

const NotifContentComponent = ({ description, created}) => {

    return (
        <div className="media-body align-self-center">
            <p className="sub-heading mb-0">{description}</p>
            <Button size="small" className="jr-btn jr-btn-xs mb-0">
                <i className={`zmdi zmdi-calendar zmdi-hc-fw`} />
            </Button>
            <span className="meta-date"><small>{created}</small></span>
        </div>
    )
}


export default NotifContentComponent;