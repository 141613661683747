import React from 'react'
import JoditEditor from "jodit-react";
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const EditComponent = ({ formikProps, id, value }) => {
    const editor = useRef(null)
    const [newValue, setNewValue] = useState(value);
    // const value = formikProps.values[id];
    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }
   
    useEffect(() => { 
        formikProps.setFieldValue(id, newValue)
    }, [newValue])

    return (
        <div style={{ marginTop: '20px' }} className='editor'>
            <JoditEditor
                ref={editor}
                value={newValue}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(e) => {
                    setNewValue(e)
                }} 
                // onChange={(newContent) => formikProps.setFieldValue(id, newContent)}
            />
        </div>
    )
}


export default EditComponent;
