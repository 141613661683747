const INIT_STATE = {
    notifications: {
        list: [],
        loading: false,
        status: '',
        unread: false,
        pagination: {
            current_page: 0,
            total_pages: 0,
        }
    }
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'GET_NOTIFICATIONS_PENDING':
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    loading: true
                }
            }

        case 'GET_NOTIFICATIONS_REJECTED':
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    loading: false
                }
            }

        case 'GET_NOTIFICATIONS_FULFILLED':
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    list: [...state.notifications.list, ...action.payload.data.data],
                    loading: false,
                    unread: action.payload.data.unread,
                    pagination: action.payload.data && action.payload.data.meta && action.payload.data.meta.pagination ? action.payload.data.meta.pagination : {}
                }
            }

        case 'SET_NOTIFICATION_AS_READ_PENDING':
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    loading: true
                }
            }

        case 'SET_NOTIFICATION_AS_READ_REJECTED':
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    loading: false
                }
            }

        case 'SET_NOTIFICATION_AS_READ_FULFILLED':
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    list: state.notifications.list.map((item) => ({
                        ...item,
                        readed: item.identifier === action.payload.data.id ? true : item.readed
                    })),
                    loading: false,
                    unread: action.payload.data.unread
                }
            }

        default:
            return state;
    }
}
