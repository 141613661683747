import React from 'react';
import DialogComponent from '../../../components/Dialog/DialogComponent';
import { DialogTitle, Typography } from '@material-ui/core';
import moment from 'moment';
import InspirationDialogRowComponent from './InspirationDialogRowComponent';


const InspirationDialogComponent = ({
  onClose = () => {},
  data,
  open,
}) => {
  return (
  <DialogComponent
    open={open}
    hiddenClose
    btnSave="Zamknij"
    handleSave={onClose}
  >
   <>
    <div className={`col-md-6`}>
      <DialogTitle className='pl-0'>{data?.title}</DialogTitle>
    </div>
    <div className={`col-md-6`}>
      <DialogTitle align='right'>{moment(data?.date).format('DD.MM.YYYY')}</DialogTitle>
    </div>
    <InspirationDialogRowComponent
      description={data?.description}
      image={data?.img}
    />
    <div className="col-md-12">
      <Typography className='mt-4' variant='h6' >Sekcje</Typography>
    </div>
    {data?.rows?.length ? data?.rows?.map((row, index) => 
      <InspirationDialogRowComponent
       key={index}
       description={row?.desc}
       image={row?.img}
     />
    ) : null}
  </>
  </DialogComponent>
  );
};

export default React.memo(InspirationDialogComponent);
