import React, { useMemo } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CardBox from '../../components/CardBox/index';
import TableComponent from '../../components/Table/TableComponent';
import DialogComponent from '../../components/Dialog/DialogComponent';
import Auth from '../../util/Auth';
import { useCallback } from 'react';
import { useState } from 'react';
import { DialogTitle, Tab, Tabs, Typography, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DialogListComponent from '../../components/Dialog/DialogListComponent';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import FilterComponent from '../../components/Form/FilterComponent';
import { getSalonsFilterData, getOrders, getSalesFilterData, setOrderDeliveryNumber, updateOrderGiven, updateOrderSend } from '../../actions';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { INITIAL_PAGINATION, STATUSES, STATUS_COMPLETED } from '../../constants';
import RowListComponent from './components/RowListComponent';
import TextFieldComponent from '../../components/Form/TextFieldComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import validations from '../../functions/validations'
import GivenChipComponent from './components/GivenChipComponent';
import DeliveryNumbComponent from './components/DeliveryNumbComponent';
import OrderStatusComponent from './components/OrderStatusComponent';
import { Link } from 'react-router-dom';
import getRole from '../../functions/getRole';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        paddingLeft: 0,
        '@media (max-width: 573px)': {
            textAlign: 'center',
            padding: 0,
            '& *': {
                fontSize: 16,
            }
        },
    },
    dialogList: {
        paddingTop: 15,
        paddingBottom: 15,
        background: '#F2F2F2',
    },
    filter: {
        padding: 5,
        width: '100%',
    },
    filtersBlock: {
        display: 'flex',
        width: 500,
        height: 300,
    },
    mobileFilterBlock: {
        display: 'block',
        width: 300,
        height: 300,
    },
    filterTitle: {
        marginTop: 10,
        marginBottom: 10,
    },
    filterBlockLabel: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    giveClientCont: {
        padding: 15,
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 500px)': {
            display: 'block',
        },
    },
    giveClientTitle: {
        marginLeft: 5,
    },
    dialog: {
        // minWidth: 500,
        overflow: 'auto'
    },
    date: {
        '@media (max-width: 573px)': {
            textAlign: 'center',
            padding: 0,
            '& *': {
                fontSize: 16,
            }
        },
    }
}))

const SignupSchema = (t) => Yup.object().shape({
    deliveryNumber: Yup.string()
        .required(validations('required'))
        .min(11, validations('min', 11))
        .max(11, validations('max', 11)),
});


const initialData = {
    deliveryNumber: '',
    id: '',
};

const OrdersPage = ({
    user,
    ordersApp,
    getSalonsFilterData = () => { },
    getSalesFilterData = () => { },
    setOrderDeliveryNumber = () => { },
    updateOrderGiven = () => { },
    updateOrderSend = () => { },
    getOrders = () => { },
    filtersReducer
}) => {

    const classes = useStyles();
    const [data, setData] = useState({ ...initialData });
    const [openAdd, setOpenAdd] = useState(false);
    const [salon, setSalon] = useState(null);
    const [sales, setSales] = useState(null);
    const [activeOrder, setActiveOrder] = useState({});
    const [show, setShow] = useState(false);
    const matches = useMediaQuery('(max-width:1000px)');
    const matchesXs = useMediaQuery('(max-width:600px)');
    const [status, setStatus] = useState(STATUS_COMPLETED);
    const { titles, canEdit } = useMemo(() => {
        const role = getRole(user);
        const titles = matches ? ['Id', 'Salon'] : ['Id', 'Data', 'Salon', 'Przedstawiciel', 'Status'];
        const canEdit = ['super_admin', 'admin'].find((r) => r === role) ? true : false;
        if (canEdit) {
            titles.push('Wysłane')
        }
        titles.push('Odebrane', 'Akcje')
        return { titles, canEdit }
    }, [matches, user])
    const formik = useFormik({
        validationSchema: SignupSchema(),
        enableReinitialize: true,
        initialValues: data,
        onSubmit: values => {
            setOpenAdd(false)
            setOrderDeliveryNumber(values);
        },
    });

    const [filters, setFilters] = useState({
        ...INITIAL_PAGINATION,
        'filter[salon_id]': '',
        'filter[sales]': '',
        'filter[status]': STATUS_COMPLETED,
    })


    useEffect(() => {
        getOrders(filters);

    }, [filters])

    useEffect(() => {
        if (!ordersApp.loading && !ordersApp.error) {
            setOpenAdd(false)
            formik.resetForm();
            setData({ ...initialData });
        } else if (ordersApp.error) {
            NotificationManager.error(ordersApp.error, "", 2000);
        }
    }, [ordersApp])

    const findOrder = useCallback((id) => {
        return ordersApp.list.find((slide) => slide.identifier === id)
    }, [ordersApp])

    const handleShow = useCallback((id) => {
        if (ordersApp && ordersApp.list && ordersApp.list.length) {
            const order = findOrder(id);
            setActiveOrder(order)
            setShow(!show)
        }
    }, [ordersApp, show])

    const handleChangeStatus = useCallback((e, v) => {
        setStatus(v);
        setFilters({
            ...filters,
            'filter[status]': v
        })
    }, [filters])


    const onChangePage = useCallback((e, value) => {
        setFilters({
            ...filters,
            page: value + 1,
        })
    }, [filters])
    const changePerPage = useCallback((e, value) => {
        setFilters({
            ...filters,
            per_page: value.key,
        })
    }, [filters])

    const handleGiveToClient = useCallback((order, isActive) => {
        if (isActive) {
            const { given, identifier } = order
            if (!given || (given && canEdit)) {
                updateOrderGiven({ id: identifier, given: !given })
                setActiveOrder({
                    ...order,
                    given: !given
                })
            }
        }

    }, [])
    const handleSend = useCallback((order, isActive) => {
        if (isActive) {
            const { send, identifier } = order
            if (!send || (send && canEdit)) {
                updateOrderSend({ id: identifier, send: !send })
                setActiveOrder({
                    ...order,
                    send: !send
                })
            }
        }

    }, [])


    const handleEditDeliveryNumber = useCallback((id) => {
        const order = findOrder(id);
        if (order) {
            formik.setValues({
                id: order.identifier,
                deliveryNumber: order.deliveryNumber,
            })
            setOpenAdd(true);
            // setData({
            //     id: order.identifier,
            //     deliveryNumber: order.deliveryNumber,
            // })
        }
    }, [findOrder])

    const handleCloseAdd = useCallback(() => {
        formik.resetForm()
        setData(initialData);
        setOpenAdd(false);
    }, [initialData])
    return (
        <div className="app-wrapper animated slideInUpTiny animation-duration-3">
            <NotificationContainer />
            <div className="row mb-md-4">
                <Auth roles={['super_admin', 'admin', 'ph', 'pos_admin', 'pos']}>
                    <CardBox
                        styleName="col-12"
                        cardStyle="p-0"
                        headerOutside
                        heading='Lista zamówień'
                    >
                        <Tabs value={status} onChange={handleChangeStatus} variant="scrollable">
                            <Tab className="jr-tabs-label" value="" label="Wszyscy" />
                            {
                                Object.keys(STATUSES).map((status, key) =>
                                    <Tab
                                        className="jr-tabs-label"
                                        key={key}
                                        value={status}
                                        label={STATUSES[status]}
                                    />
                                )
                            }
                        </Tabs>
                    </CardBox>
                </Auth>
                <TableComponent
                    hiddenEdit
                    hiddenShow
                    hiddenDelete
                    loading={ordersApp.loading}
                    nextPageLoading={ordersApp.nextPageLoading}
                    onChangePage={onChangePage}
                    changePerPage={changePerPage}
                    meta={ordersApp.pagination}
                    titles={titles}
                    data={ordersApp && ordersApp.list && ordersApp.list.length
                        ? ordersApp.list.map((order) => ({
                            id: order.identifier,
                            data: {
                                identifier: order.identifier,
                                ...(matches
                                    ? {
                                        salon: order.salon ? <Link to={'/app/saloons/' + order.salon.identifier} className="keune-link">
                                            {order.salon.name}
                                        </Link> : '',
                                    }
                                    : {
                                        date: moment(order.date).format('DD.MM.YYYY'),
                                        salon: order.salon ? <Link to={'/app/saloons/' + order.salon.identifier} className="keune-link">
                                            {order.salon.name}
                                        </Link> : '',
                                        sales: order.sales,
                                        status: <OrderStatusComponent status={order.status} />,
                                    }
                                ),
                                ...(canEdit ? {
                                    send: <GivenChipComponent
                                        active={order.send}
                                        order={order}
                                        activeText='Wysłane'
                                        nonActiveText='Nie wysłane'
                                        handleGiveToClient={handleSend}
                                    />,
                                } : {}),

                                given: <GivenChipComponent
                                    active={order.given}
                                    order={order}
                                    activeText='Odebrane'
                                    nonActiveText='Nie odebrane'
                                    handleGiveToClient={handleGiveToClient}
                                />,
                                action: <DeliveryNumbComponent
                                    status={order.status}
                                    deliveryNumb={order.deliveryNumber}
                                    delivered={order.delivered}
                                    handleShow={() => handleShow(order.identifier)}
                                    onChangePage={() => handleEditDeliveryNumber(order.identifier)}
                                />,
                            }
                        }))
                        : []
                    }

                >
                    <div>
                        <Typography className={classes.filterBlockLabel}>Filtry</Typography>
                        <div className={matchesXs ? classes.mobileFilterBlock : classes.filtersBlock}>
                            <FilterComponent
                                label={'Salon'}
                                value={salon}
                                filters={filters}
                                setOption={setSalon}
                                setFilters={setFilters}
                                filterId={'salon_id'}
                                dispatch={getSalonsFilterData}
                                placeholder='u Agnie...'
                                filterClass={classes.filterTitle}
                                blockClass={classes.filter}
                                options={filtersReducer && filtersReducer.salons ? filtersReducer.salons : []}
                            />
                            <Auth roles={['super_admin', 'admin']}>
                                <FilterComponent
                                    label={'Przedstawiciel'}
                                    setOption={setSales}
                                    filters={filters}
                                    setFilters={setFilters}
                                    dispatch={getSalesFilterData}
                                    filterId={'sales'}
                                    placeholder='Ada...'
                                    value={sales}
                                    filterClass={classes.filterTitle}
                                    blockClass={classes.filter}
                                    options={filtersReducer && filtersReducer.sales ? filtersReducer.sales : []}
                                />
                            </Auth>
                        </div>
                    </div>
                </TableComponent>
            </div>
            {/* show */}
            {activeOrder ? <DialogComponent className={classes.dialog} hiddenClose open={show} handleClose={handleShow} handleSave={handleShow} maxWidth='md' btnSave='Zamknij'>
                <div className={`col-sm-6`}>
                    <DialogTitle className={classes.dialogTitle}>Zamówienie #{activeOrder.identifier}</DialogTitle>
                </div>
                <div className={`col-sm-6`}>
                    <DialogTitle align='right' className={classes.date}>{moment(activeOrder.date).format('DD.MM.YYYY HH:mm')}</DialogTitle>
                </div>
                <RowListComponent
                    label='Salon'
                    text={`${activeOrder.salon ? activeOrder.salon.name : ''}, ${activeOrder.address},  ${activeOrder.zipCode || ''},  ${activeOrder.city || ''}`}
                />
                <RowListComponent
                    label='Przedstawiciel'
                    text={activeOrder.sales || '-'}
                />
                <RowListComponent
                    label='Osoba odbierająca'
                    text={activeOrder.buyer ? activeOrder.buyer.fullName : '-'}
                />
                <RowListComponent
                    label='Status'
                    text={STATUSES[activeOrder.status]}
                />
                <RowListComponent
                    label='Numer telefonu'
                    text={activeOrder.buyer ? activeOrder.buyer.tel : '-'}
                />
                <RowListComponent
                    label='Email'
                    text={activeOrder.buyer ? activeOrder.buyer.email : '-'}
                />
                <RowListComponent
                    label='Numer przewozowy'
                    text={activeOrder.deliveryNumber || '-'}
                />
                {activeOrder.code
                    ? <RowListComponent
                        label='Kod promocyjny'
                        text={activeOrder.code}
                    />
                    : null
                }
                {activeOrder.withoutDiscountPrice
                    ? <RowListComponent
                        label='Suma bez zniżki'
                        text={`${activeOrder.withoutDiscountPrice} zł`}
                    />
                    : null
                }

                <RowListComponent
                    label='Uwagi'
                    text={activeOrder.attention || '-'}
                    textClass='col-sm-9'
                />
                <div className={`col-12 mt-4 `}>
                    <div className={`row ${classes.dialogList}`}>
                        <DialogListComponent
                            first
                            id='Id mag.'
                            name='Nazwa'
                            quantity='Ilość'
                            size='Rozmiar'
                            markAndSerie="Marka & Seria"
                            price="Łączna suma"
                        />
                        {activeOrder.products && activeOrder.products.data && activeOrder.products.data.length
                            ? activeOrder.products.data.map((product, key) =>
                                <DialogListComponent
                                    key={key}
                                    id={product.stockNumber}
                                    name={product.title}
                                    size={product.size}
                                    markAndSerie={`${product.mark} - ${product.serie}`}
                                    quantity={product.quantity}
                                    price={`${product.price} zł`}
                                />)
                            : null}
                    </div>
                </div>
                <div className={`col-sm-6`}></div>
                <div className={`col-sm-6`}>
                    <DialogTitle align='right' className={classes.date}>Łączna suma: {`${activeOrder.total_price || 0} zł`}</DialogTitle>
                </div>
            </DialogComponent> : null}

            <DialogComponent open={openAdd} handleClose={handleCloseAdd} title={'Dodaj numer przewozowy'} handleSave={formik.handleSubmit}>
                <form onSubmit={formik.handleSubmit} className="col-md-12">
                    <TextFieldComponent
                        fullWidth
                        type='number'
                        id='deliveryNumber'
                        formikProps={formik}
                        label='Numer'
                    />
                </form>
            </DialogComponent>
        </div>
    )
}


export default connect((state) => ({
    user: state.user,
    ordersApp: state.ordersApp,
    filtersReducer: state.filters,
}), {
    getOrders,
    getSalonsFilterData,
    getSalesFilterData,
    setOrderDeliveryNumber,
    updateOrderGiven,
    updateOrderSend,
})(OrdersPage);
