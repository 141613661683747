import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { connect } from 'react-redux'
// import * as CoursesActions from '../../../actions/Courses'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Editor from './editor'
import Dragndrop from './dragndrop'
import { CirclePicker } from 'react-color'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogComponent from '../../../components/Dialog/DialogComponent';

export default class AddCourse extends React.Component {

    constructor(props) {
        super()
        this.state = {
            selectedCourseTypeRadio: null,
            course: {
                // role: '',
                title: '',
                lead: '',
                description: '',
                levelID: '',
                categoryID: '',
                groupID: null,
                image: null,
                thumbnail: null,
                light: 1,
                colorHEX: '#f44336'
            },
            errors: {
                title: '',
                lead: '',
                description: '',
                levelID: '',
                categoryID: '',
                colorHEX: ''  
            }
        }
    }

    componentDidUpdate(prevProps) {
        if ( prevProps.courseDetails.title !== this.props.courseDetails.title ) {
            let { title, lead, description, level, category, thumbnail, image } = this.props.courseDetails
            this.setState({
                ...this.state,
                course: {
                    ...this.state.course,
                    title: title,
                    lead: lead,
                    description: description,
                    levelID: level?.id,
                    groupID: '',
                    categoryID: category?.id,
                    image: image,
                    thumbnail: thumbnail
                }
            })
        }
    }


    render() {
        // let { title, lead, description, password } = this.props.addCourseForm.errors
        let {  lead } = this.props.addCourseForm.errors
        let titleError = this.props.addCourseForm.errors.title ? true : false
        let leadError = this.props.addCourseForm.errors.lead ? true : false
        let groupIDError = this.props.addCourseForm.errors.groupID ? true : false
        // let descriptionError = this.props.addCourseForm.errors.description ? true : false
        let categoryIDError = this.props.addCourseForm.errors.categoryID ? true : false
        let levelIDError = this.props.addCourseForm.errors.levelID ? true : false
        let firstLevelCourses = this.props.coursesList.filter((item, key) => {
            return item.level.value === 1
        })
        return <form onSubmit={this.onSubmit.bind(this)}>
                         <Dialog open={this.props.open} onClose={this.handleRequestClose}>
                        <DialogTitle>{this.props.editMode ? 'Edytuj szkolenie' : 'Dodaj szkolenie'}</DialogTitle>
                        <DialogContent>
                
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.saveForm.bind(this)} color="primary">
                                {this.props.editMode ? 'Zapisz' : 'Dodaj'}
                            </Button>
                            <Button onClick={this.props.togglePopup} color="secondary">
                                Anuluj
                            </Button>
                        </DialogActions>
                    </Dialog>
            <DialogComponent
                open={this.props.open}
                btnSave={this.props.editMode ? 'Zapisz' : 'Dodaj'}
                handleClose={this.togglePopup}
                handleSave={this.saveForm.bind(this)}
                title={this.props.editMode ? 'Edytuj szkolenie' : 'Dodaj szkolenie'}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <TextField
                                error={titleError}
                                autoFocus
                                onChange={this.onChange.bind(this)}
                                margin="dense"
                                id="title"
                                label={this.props.addCourseForm.errors.title ? this.props.addCourseForm.errors.title : 'Tytuł'}
                                type="text"
                                value={this.state.course.title}
                                name="title"
                                fullWidth
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextField
                                error={leadError}
                                onChange={this.onChange.bind(this)}
                                margin="dense"
                                id="lead"
                                label={lead ? lead : 'Lead'}
                                type="text"
                                value={this.state.course.lead}
                                name="lead"
                                fullWidth
                            />
                        </div>
                        <div className="col-md-12">
                            <Editor onEditorChange={this.onEditorChange.bind(this)}
                                name="description"
                                value={this.state.course.description}
                                onChange={this.onChange.bind(this)} />
                        </div>
                        <div className="col-md-12 mb-3">
                            <FormControl component="fieldset" required>
                                <FormLabel component="legend">Określ poziom szkolenia</FormLabel>
                                <RadioGroup
                                    className="d-flex flex-row"
                                    aria-label="placeName"
                                    name="placeName"
                                    value={this.state.selectedCourseTypeRadio}
                                    onChange={this.onChangeSelectedCourseType.bind(this)} >
                                    <FormControlLabel value={"0"} control={<Radio color="primary" />} label="Poziom 1" />
                                    <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Kontynuacja innego szkolenia" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {this.state.selectedCourseTypeRadio === "1" ? <div className="col-md-6 mb-3">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="groupID">Wybierz szkolenie</InputLabel>
                                <Select
                                    error={groupIDError}
                                    name="groupID"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.course.groupID}
                                    input={<Input id="groupID" />} >
                                    <MenuItem value="">
                                        <em>Wybierz szkolenie...</em>
                                    </MenuItem>
                                    {firstLevelCourses.map((item, key) => {
                                        return <MenuItem key={key} value={item.identifier}>{item.title}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div> : null}
                        {this.state.selectedCourseTypeRadio === "1" ? <div className="col-md-6 mb-3">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="levelID">{levelIDError ? this.props.addCourseForm.errors.levelID : 'Wybierz poziom'}</InputLabel>
                                <Select
                                    error={levelIDError}
                                    name="levelID"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.course.levelID}
                                    input={<Input id="levelID" />} >
                                    <MenuItem value="">
                                        <em>Wybierz Poziom...</em>
                                    </MenuItem>
                                    {this.props.levels.map((item, key) => {
                                        return <MenuItem key={key} value={item.identifier}>{item.title}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div> : null}
                        <div className="col-md-12 mb-3">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="age-simple">{categoryIDError ? this.props.addCourseForm.errors.categoryID : 'Kategoria'}</InputLabel>
                                <Select
                                    error={categoryIDError}
                                    name="categoryID"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.course.categoryID}
                                    input={<Input id="age-simple" />} >
                                    <MenuItem value="">
                                        <em>Wybierz kategorię...</em>
                                    </MenuItem>
                                    {this.props.categories.map((item, key) => {
                                        return <MenuItem key={key} value={item.identifier}>{item.title}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h2>Miniatura</h2>
                            <Dragndrop existingImage={this.state.course.thumbnail}
                                name="thumbnail"
                                onFileChange={this.onFileChange.bind(this)} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <h2>Banner</h2>
                            <Dragndrop existingImage={this.state.course.image}
                                name="image"
                                onFileChange={this.onFileChange.bind(this)} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <h2>Kolor przewodni szkolenia</h2>
                            <CirclePicker color={this.state.course.colorHEX} onChange={this.onColorChange.bind(this)} />
                        </div>
                    </div>
                </div>
            </DialogComponent>
       
                </form>
    }

    onChangeSelectedCourseType(e, value) {
        if ( value === "0" ) {
            this.setState({
                ...this.state,
                selectedCourseTypeRadio: value,
                course: {
                    ...this.state.course,
                    groupID: '',
                    levelID: 1
                }
            })
        } else {
            this.setState({
                ...this.state,
                selectedCourseTypeRadio: value,
                course: {
                    ...this.state.course,
                    groupID: value,
                    levelID: ''
                }
            })
        }
    }

    onFileChange(name, value) {
        let course = {...this.state.course}
        course[name] = value[0]
        this.setState({
            ...this.state,
            course: course            
        })
    }

    onEditorChange(name, value) {
        let course = {...this.state.course}
        course[name] = value
        this.setState({
            ...this.state,
            course: course            
        })
    }

    onColorChange(e, e2) {
        this.setState({
            ...this.state,
            course: {
                ...this.state.course,
                colorHEX: e.hex
            }            
        })
    }

    onChange(e) {
        let course = {...this.state.course}
        course[e.target.name] = e.target.value
        this.setState({
            ...this.state,
            course: course            
        })
    }

    onSubmit(e) {
        // let data = new FormData(e.target)
    }

    saveForm() {
        let slug = this.props.editSlug
        if ( this.props.editMode ) {
            this.props.editCourse(slug, this.state.course)
        } else {
            this.props.addCourse(this.state.course)
        }
    }

}
