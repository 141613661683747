import axios from 'axios'
import settings from '../config/settings.js'



export const getPromoCodesApp = (params, url = settings.api_endpoint + "/mobile_code") => {
	// export const getPromoCodes = (params, url = settings.api_endpoint + "/course") => {
	return {
		type: 'GET_PROMO_CODES',
		payload: axios.get(url, {
			params: params
		})
	}
}


export const addPromoCodeApp = (params, url = settings.api_endpoint + "/mobile_code") => {
	return {
		type: 'ADD_PROMO_CODE',
		payload: axios.post(url, params)
	}
}

export const updatePromoCodeApp = (data, url = settings.api_endpoint + "/mobile_code") => {
	return {
		type: 'UPDATE_PROMO_CODE',
		payload: axios.post(`${url}/${data.id}`, data.params)
	}
}
export const deletePromoCodeApp = (id, url = settings.api_endpoint + "/mobile_code") => {
	return {
		type: 'DELETE_PROMO_CODE',
		payload: axios.delete(`${url}/${id}`)
	}
}
