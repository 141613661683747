// import {
//     GET_USER_LIST,
//     GET_USER_DETAILS,
//     SEARCH_USERS,
//     AUTH_USER,
// } from '../constants/ActionTypes';

const INIT_STATE = {
    loading: false,
    nextPageLoading: false,
    news: {
        loaded: false,
        list: [],
        meta: {},
    },
    addForm: {
        errors: {},
        added: false,
        edited: false
    },
    newsDetails: {
        loaded: false,
        data: {}
    }
}


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'GET_NEWS_PENDING':
            return {
                ...state,
                loading: state.news && state.news.meta && !state.news.meta.current_page,
                nextPageLoading: state.news && state.news.meta && (state.news.meta.current_page >= 1),
                news: {
                    ...state.news,
                    loaded: false,
                }
            }
        case 'GET_NEWS_FULFILLED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
                news: {
                    loaded: true,
                    list: action.payload&&action.payload.data ? action.payload.data.data : [],
                    meta: action.payload && action.payload.data && action.payload.data.meta ? action.payload.data.meta.pagination : {},
                }
            }
        case 'GET_NEWS_REJECTED':
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
                news: {
                    ...state.news,
                    loaded: false
                }
            }

        case 'GET_NEWS_DETAILS_PENDING':
            return {
                ...state,
                newsDetails: {
                    ...state.newsDetails,
                    loaded: false
                }
            }
        case 'GET_NEWS_DETAILS_FULFILLED':
            return {
                ...state,
                newsDetails: {
                    loaded: true,
                    data: action.payload.data.data
                }
            }
        case 'GET_NEWS_DETAILS_REJECTED':
            return {
                ...state,
                newsDetails: {
                    ...state.newsDetails,
                    loaded: false
                }
            }
            
        case 'GET_EMPTY_NEWS_DETAILS':
            return {
                ...state,
                newsDetails: {
                    data: {
                        identifier: '',
                        title: '',
                        description: '',
                        creationDate: '',
                        lastChange: '',
                        roles: [],
                    },
                    loaded: true
                }
            }
            
        case 'ADD_NEWS_PENDING':
            return {
                ...state,
                addForm: {
                    ...INIT_STATE.addForm
                }
            }
        case 'ADD_NEWS_FULFILLED':
            return {
                ...state,
                addForm: {
                    ...INIT_STATE.addForm,
                    added: true
                }
            }
        case 'ADD_NEWS_REJECTED':
            return {
                ...state,
                addForm: {
                    ...state.addForm,
                    added: false,
                    errors: action.payload.response.data.error
                }
            }
        
        case 'EDIT_NEWS_PENDING':
            return {
                ...state,
                addForm: {
                    ...state.addForm,
                    edited: false
                }
            }
        case 'EDIT_NEWS_FULFILLED':
            return {
                ...state,
                addForm: {
                    ...state.addForm,
                    edited: true
                }
            }
        case 'EDIT_NEWS_REJECTED':
            return {
                ...state,
                addForm: {
                    ...state.addForm,
                    errors: action.payload.response.data.error,
                    edited: false
                }
            }

        case 'DELETE_NEWS_PENDING':
            return {
                ...state
            }
        case 'DELETE_NEWS_FULFILLED':
            return {
                ...state,
                news: {
                    ...state.news,
                    list: state.news.list.filter((item, key) => item.identifier !== action.payload.data.data.identifier)
                }
            }
        case 'DELETE_NEWS_REJECTED':
            return {
                ...state
            }

        default:
            return state;
    }
}
