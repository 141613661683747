import React from 'react';
import Table from '@material-ui/core/Table'; import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableHead from '@material-ui/core/TableHead'; import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom'
import TablePagination from '@material-ui/core/TablePagination';
import Auth from '../../../../../util/Auth'
import Tooltip from '@material-ui/core/Tooltip';
import { Paper } from '@material-ui/core';
import DialogLoaderComponent from '../../../../../components/Loaders/DialogLoaderComponent';
import TableLoaderComponent from '../../../../../components/Loaders/TableLoaderComponent';

export default class CoursesList extends React.Component {

    constructor(props) {
        super()
        this.state = {}
    }

    render() {
        const { meta } = this.props;
        return <div className="table-responsive-material">
            <TableLoaderComponent
                loading={this.props.loading}
            >
                <Paper>
                    <section className='position-relative' >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Miniaturka</TableCell>
                                    <TableCell>Tytuł</TableCell>
                                    <TableCell>Lead</TableCell>
                                    <TableCell>Grupa docelowa</TableCell>
                                    <TableCell>Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.data.map((n, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell>
                                                <img width="50" src={n.image ? n.image.data.urlFile : ''} alt={'Miniaturka Kursu'} />
                                            </TableCell>
                                            <TableCell>{n.title}</TableCell>
                                            <TableCell>{n.lead}</TableCell>
                                            <TableCell>{n.roles.map((item, key) => {
                                                let str = ''
                                                if (item === 3) {
                                                    str += 'Przedstawiciel handlowy'
                                                }
                                                if (item === 4) {
                                                    str += ', Właściciel salonu'
                                                }
                                                if (item === 5) {
                                                    str += ', Pracownik salonu'
                                                }
                                                if (item === 6) {
                                                    str += ', Kadra szkoleniowa'
                                                }
                                                return str
                                            })}</TableCell>
                                            <TableCell>
                                                <Link to={'/app/keune-news/' + n.identifier}>
                                                    <Tooltip id="tooltip-icon" title="Podgląd użytkownika" placement="top">
                                                        <Fab className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm mr-1">
                                                            <i className="zmdi zmdi-eye" />
                                                        </Fab>
                                                    </Tooltip>
                                                </Link>
                                                <Auth roles={['super_admin', 'admin']}>
                                                    <Tooltip id="tooltip-icon" title="Edytuj użytkownika" placement="top">
                                                        <Fab onClick={this.toggleEditMode.bind(this, n.identifier)} className="jr-fab-btn bg-amber mr-1 text-white jr-btn-fab-sm">
                                                            <i className="zmdi zmdi-edit" />
                                                        </Fab>
                                                    </Tooltip>
                                                    <Tooltip id="tooltip-icon" title="Usuń użytkownika" placement="top">
                                                        <Fab onClick={this.props.onDelete.bind(this, n.identifier)} className="jr-fab-btn bg-red text-white jr-btn-fab-sm">
                                                            <i className="zmdi zmdi-delete" />
                                                        </Fab>
                                                    </Tooltip>
                                                </Auth>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {this.props.nextPageLoading
                            ? <DialogLoaderComponent opacity />
                            : null
                        }
                    </section>
                    <TablePagination
                        count={meta ? meta.total : 0}
                        rowsPerPage={meta ? meta.per_page : 0}
                        page={meta ? meta.current_page - 1 : 0}
                        labelRowsPerPage={false}
                        component='div'
                        onChangePage={this.onChangePage.bind(this)}
                        onChangeRowsPerPage={this.onChangePage.bind(this)}
                    />
                </Paper>
            </TableLoaderComponent>
        </div>
    }

    toggleEditMode(slug) {
        this.props.toggleAddMode(true, slug)
    }

    onChangePage(e, dir) {


        if (dir < this.props.meta.current_page) {
            this.props.onPageChange(this.props.meta.links.previous)
        } else {
            this.props.onPageChange(this.props.meta.links.next)
        }
    }

}

// function CoursesList(props) {

//     return (
//         <div className="table-responsive-material">
//             <Table>
//                 <TableHead>
//                     <TableRow>
//                         <TableCell>Imię i nazwisko</TableCell>
//                         <TableCell>POS</TableCell>
//                         <TableCell>E-mail</TableCell>
//                         <TableCell>Akcje</TableCell>
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {props.data.map((n, key) => {
//                         return (
//                             <TableRow key={key}>
//                                 <TableCell>{n.firstName+' '+n.lastName}</TableCell>
//                                 <TableCell>{n.pos}</TableCell>
//                                 <TableCell>{n.email}</TableCell>
//                                 <TableCell>
//                                     <Link to={'/app/users/' + n.identifier}>
//                                         <Button variant="fab" className="jr-fab-btn bg-light-blue text-white jr-btn-fab-sm mb-3">
//                                             <i className="zmdi zmdi-eye" />
//                                         </Button>
//                                     </Link>
//                                     <Link to="/edit">
//                                         <Button variant="fab" className="jr-fab-btn bg-amber text-white jr-btn-fab-sm mb-3">
//                                             <i className="zmdi zmdi-edit" />
//                                         </Button>
//                                     </Link>
//                                     <Link to="/delete">
//                                         <Button variant="fab" className="jr-fab-btn bg-red text-white jr-btn-fab-sm mb-3">
//                                             <i className="zmdi zmdi-delete" />
//                                         </Button>
//                                     </Link>
//                                 </TableCell>
//                             </TableRow>
//                         );
//                     })}
//                 </TableBody>
//                 <TablePagination
//                     count={32}
//                     rowsPerPage={5}
//                     page={0}
//                     onChangePage={changePage}
//                     onChangeRowsPerPage={changePage}
//                   />
//             </Table>
//         </div>
//     );
// }

// function changePage() {

// }
