import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Auth from '../../../../../util/Auth';
import Widget from "../../../../../components/Widget";
import { useFormik } from 'formik';
import TextFieldComponent from '../../../../../components/Form/TextFieldComponent';
import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { setPosPayment } from '../../../../../actions'
import * as Yup from 'yup';
import validations from '../../../../../functions/validations';
import { NotificationManager } from 'react-notifications';
import getRole from '../../../../../functions/getRole';
import DialogLoaderComponent from '../../../../../components/Loaders/DialogLoaderComponent';
const useStyles = makeStyles((theme) => ({
    input: {
        '& input': {
            padding: 10,
        }
    }
}))

const schema = (t) => Yup.object().shape({
    should_pay: Yup.string()
        .required(validations('required')),
});

const ShouldPayComponent = ({ loading, pos, user, setPosPayment = () => { } }) => {
    const classes = useStyles();
    const data = useMemo(() => pos && pos.posDetails && pos.posDetails.data ? pos.posDetails.data : {}, [pos])
    const [shouldPayMode, setShouldPayMode] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const formik = useFormik({
        validationSchema: schema(),
        enableReinitialize: true,
        initialValues: {
            should_pay: 0,
        },
        onSubmit: values => {
            if (data.identifier) {
                const userType = getRole(user)
                let sendNewNumber = true;
                setDisableBtn(true)
                setShouldPayMode(false)
                NotificationManager.warning("Kwota zostanie za 4 sec. Cofnij zmiane", "", 4000, () => {
                    sendNewNumber = false; 
                    setDisableBtn(false)
                    setShouldPayMode(true)
                })
                setTimeout(() => {
                    if (sendNewNumber){
                        setPosPayment(data.identifier, values, ['ph', 'admin', 'super_admin'].includes(userType) ? 'include=statistics,payments' : 'include=statistics')
                        setShouldPayMode(false)
                        formik.resetForm(); 
                        setDisableBtn(false)
                    }
                }, 4000)
            }
        },
    });

    useEffect(() => {
        if (pos && pos.posDetails && pos.posDetails.error)
            NotificationManager.error("Nie udało się zmienić kwotę", "", 4000)
    }, [pos])
    const toggleShouldPayMode = useCallback(() => {
        setShouldPayMode(!shouldPayMode)
    }, [shouldPayMode])

    return (
        <>
            <Auth roles={['super_admin', 'admin']}>
                <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                    <Widget title="Finanse" styleName="jr-card-profile-sm">
                        <div className="media align-items-start flex-nowrap jr-pro-contact-list">
                            <div className="mr-3">
                                <i className="zmdi zmdi-money jr-fs-xxl text-orange" />
                            </div>
                            <div className="media-body">
                                <span className="mb-0 text-grey jr-fs-sm">Kwota do wypłaty</span>
                                <div className="mb-0">
                                    {data && data.should_pay ? data.should_pay : 0} zł
                        <Tooltip onClick={toggleShouldPayMode} id="tooltip-icon" className="ml-3" title="Zapłać" placement="top">
                                        <Button color="primary" className="bg-orange text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                                            <i className="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg" />
                                        </Button>
                                    </Tooltip>
                                    {shouldPayMode ? <div className="row mt-2 align-items-center">
                                        <div className="col-10">
                                            <TextFieldComponent
                                                className={classes.input}
                                                fullWidth
                                                id='should_pay'
                                                variant='outlined'
                                                type='number'
                                                formikProps={formik}
                                                placeholder='Wpisz kwotę'
                                            />
                                        </div>
                                        <div >
                                            <Tooltip onClick={formik.handleSubmit} id="tooltip-icon" title="Zapisz" placement="top">
                                                <Button color="primary" className="bg-green text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit" disabled={disableBtn}>
                                                    <i className="zmdi zmdi-save zmdi-hc-fw zmdi-hc-lg" />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        {loading ?  <DialogLoaderComponent /> : null}
                    </Widget>
                </div>
            </Auth>
        </>
    )
}


export default connect((state) => ({
    pos: state.pos,
    user: state.user
}), {
    setPosPayment,
})(ShouldPayComponent);
