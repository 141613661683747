import React from 'react';
// import {Redirect, Route, Switch} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import CardBox from '../../../../../components/CardBox';
import { connect } from 'react-redux'
// import ProfileBox from './ProfileBox'
// import ContactCard from './ContactCard'
import CoursesList from './CoursesList'
import SaloonsList from './SaloonsList'
import TextField from '@material-ui/core/TextField';
// import EditForm from './EditForm'
import * as UserActions from '../../../../../actions/User'
import * as CoursesActions from '../../../../../actions/Courses'
import * as PosActions from '../../../../../actions/Pos'
import Widget from "../../../../../components/Widget";
import Auth from "../../../../../util/Auth";
import Auxiliary from "../../../../../util/Auxiliary";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ReactSelect from 'react-select'
import Condition from '../../../../../util/Condition'
import { Link } from 'react-router-dom'
import CoursesGrid from './CoursesGrid'
import Dragndrop from '../../dragndrop'
import HistoryTable from './HistoryTable';
import { Fab } from '@material-ui/core';
import DialogLoaderComponent from '../../../../../components/Loaders/DialogLoaderComponent';
// import settings from '../../../../../config/settings.js'

class UserDetails extends React.Component {

  constructor() {

    super()
    this.state = {
      signUpMode: false,
      courseID: null,
      saloonID: null,
      addPoints: {
        enabled: false,
        userPoints: null
      },
      userSaloonID: null,
      selectedSaloon: {},
      selectedUserSaloon: {},
      files: [],
      formFiles: [],
      changedPosMode: false,
      selectedPos: null,
      userFiles: [],
      changeAdminPosMode: false,
      selectedAdminPos: null,
      selectedAgent: null,
      selectedListItems: [],
      checkAll: false,
      listSelectMode: false
    }

  }

  componentDidUpdate(prevProps) {
    let userId = this.props.match.params.slug
    if (!prevProps.courses.signedUpForCourse && this.props.courses.signedUpForCourse) {
      NotificationManager.success("Użytkownik został zapisany na szkolenie!", "", 2000);
      this.resetSignUp()
      this.props.dispatch(UserActions.getUserCourses(userId))
    }
    if (!prevProps.courses.updatedCourseSignup && this.props.courses.updatedCourseSignup) {
      this.props.dispatch(UserActions.getUserCourses(userId))
      NotificationManager.success("Zapis na szkolenie został zaktualizowany!", "", 2000);
    }
    if (!prevProps.user.addedAgentSaloon && this.props.user.addedAgentSaloon) {
      this.props.dispatch(UserActions.getAgentSaloons(userId))
      this.props.dispatch(UserActions.getAvailableSaloons())
      NotificationManager.success("Salon został przypisany do agenta!", "", 2000);
    }
    if (!prevProps.pos.changedPosListForAgent && this.props.pos.changedPosListForAgent) {
      this.props.dispatch(UserActions.getAgentSaloons(userId))
      this.props.dispatch(UserActions.getAvailableSaloons())
      this.clearSelectList()
      NotificationManager.success("Przepisano wybrane salony!", "", 2000);
    }
    if (!prevProps.pos.updatedOwnerPos && this.props.pos.updatedOwnerPos) {
      this.props.dispatch(UserActions.getUserDetails({
        include: 'pos,pos.owner'
      }, userId))
      this.toggleChangePosMode()
      NotificationManager.success("Zmieniono salon użytkownika!", "", 2000);
    }
    if (!prevProps.pos.updatedWorkerPos && this.props.pos.updatedWorkerPos) {
      // this.props.dispatch(UserActions.getAgentSaloons(userId))
      this.props.dispatch(UserActions.getUserDetails({
        include: 'pos,pos.owner'
      }, userId))
      this.toggleChangePosMode()
      NotificationManager.success("Przypisano użytkownika do innego salonu!", "", 2000);
    }
    if (!prevProps.user.addPoints.added && this.props.user.addPoints.added) {
      this.toggleAddPointsMode()
      NotificationManager.success("Pomyślnie zmieniono bilans punktów!", "", 2000);
    }
  }

  componentDidMount() {

    let userId = this.props.match.params.slug
    this.props.dispatch(UserActions.getUserDetails({
      include: 'pos,pos.owner,logs'
    }, userId))
    this.props.dispatch(CoursesActions.getCourseDates({
      'filter[status]': 'pending',
      per_page: 0
    }))
    this.props.dispatch(CoursesActions.getCoursesList({
      per_page: 30
    }))
    // this.props.dispatch(CoursesActions.getUserCourses(userId))
    this.props.dispatch(UserActions.getAgentSaloons(userId, {
      per_page: 0
    }))
    this.props.dispatch(UserActions.getFreeAgents())
    this.props.dispatch(UserActions.getUserCourses(userId))
    this.props.dispatch(UserActions.getAvailableSaloons())
    this.props.dispatch(PosActions.getPosList({
      per_page: 0
    }))
    this.props.dispatch(PosActions.getNoOwnerPosList({
      per_page: 0
    }))

  }

  updateWorkerPos() {
    let posID = this.state.selectedPos.value
    let userID = this.props.match.params.slug
    this.props.dispatch(PosActions.updateWorkerPos(posID, userID))
  }

  updateOwnerPos() {
    let posID = this.state.selectedAdminPos.value
    let userID = this.props.match.params.slug
    this.props.dispatch(PosActions.updateOwnerPos(posID, userID))
  }

  deleteCourseStaffFile(fileId) {
    let courseId = this.props.match.params.slug
    this.props.dispatch(CoursesActions.deleteCourseStaffFiles(courseId, fileId))
  }

  deleteCourseStaffFormFile(id) {
    if (this.state.courseFormFiles.length === 1) {
      this.props.dispatch(CoursesActions.clearCourseStaffFilesValidation())
    }
    this.setState({
      ...this.state,
      courseFormFiles: this.state.courseFormFiles.filter((item, key) => key !== id)
    })
  }

  onFileChange(e, files) {
    this.setState({
      ...this.state,
      courseFormFiles: files
    }, () => {
    })
  }

  addCourseStaffFiles() {
    let slug = this.props.match.params.slug
    this.props.dispatch(CoursesActions.addCourseStaffFiles(slug, this.state.courseFormFiles))
  }


  clearCourseFormFiles() {
    this.setState({
      ...this.state,
      courseFormFiles: []
    })
  }

  render() {
    // let match = this.props.match
    let userData = this.props.user.userDetails.data

    return <Auxiliary>
      <NotificationContainer />
      <DialogLoaderComponent loaded={!this.props.user.userDetails.loading}  />
      <div className="jr-profile-banner">
        <div className="jr-profile-container">
          <div className="jr-profile-banner-top">
            <div className="jr-profile-banner-top-left">
              <div className="jr-profile-banner-avatar">
                <Avatar className="size-90" alt="..." src={userData.avatar} />
              </div>
              <div className="jr-profile-banner-avatar-info">
                <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                  {userData.firstName + " " + userData.lastName}
                  <Condition condition={this.props.user.auth.user.identifier === userData.identifier}>
                    {" (Ty)"}
                  </Condition>
                </h2>
                <p className="mb-0 jr-fs-lg">{this.renderRoles()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="jr-profile-content">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-12">
            <Widget title="Dane kontaktowe" styleName="jr-card-profile-sm">
              <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                  <i className="zmdi zmdi-email jr-fs-xxl text-orange" />
                </div>
                <div className="media-body">
                  <span className="mb-0 text-grey jr-fs-sm">Email</span>
                  <p className="mb-0">{userData.email}</p>
                </div>
              </div>
              <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                  <i className="zmdi zmdi-phone jr-fs-xxl text-orange" />
                </div>
                <div className="media-body">
                  <span className="mb-0 text-grey jr-fs-sm">Numer telefonu</span>
                  <p className="mb-0">{userData.phoneNumber}</p>
                </div>
              </div>
              <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                  <i className="zmdi zmdi-cake jr-fs-xxl text-orange" />
                </div>
                <div className="media-body">
                  <span className="mb-0 text-grey jr-fs-sm">Data urodzenia</span>
                  <p className="mb-0">{userData.birthdayDate}</p>
                </div>
              </div>
              <Condition condition={userData.roles.slug === 'worker' || userData.roles.slug === 'pos_admin'}>
                <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                  <div className="mr-3">
                    <i className="zmdi zmdi-reader jr-fs-xxl text-orange" />
                  </div>
                  <div className="media-body">
                    <span className="mb-0 text-grey jr-fs-sm">Bilans punktów</span>
                    <p className="mb-0">{userData.pointBalance}</p>
                  </div>
                </div>
              </Condition>
              <Auth roles={['super_admin', 'admin']}>
                {userData.logs && userData.logs.data && userData.logs.data.date
                  ? <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                    <div className="mr-3">
                      <i className="zmdi zmdi-power jr-fs-xxl text-orange" />
                    </div>
                    <div className="media-body">
                      <span className="mb-0 text-grey jr-fs-sm">Data ostatniego logowania</span>
                      <p className="mb-0">{
                        userData.logs.data.date
                      }</p>
                    </div>
                  </div>
                  : null}
                
              </Auth>
              {/* <Auth roles={['super_admin', 'admin']}>
                      <Condition condition={userData.roles.slug == 'worker' || userData.roles.slug == 'pos_admin'}
                                 custom={true}
                                 component={this.renderAddPoints.bind(this)}>
                      </Condition>
                    </Auth> */}
            </Widget>
          </div>
          <Condition condition={userData.roles.slug === 'worker'}
            custom={true}
            render={this.renderPosInfo.bind(this)}>
          </Condition>
          <Condition condition={userData.roles.slug === 'pos_admin'}
            custom={true}
            component={this.renderAdminPosInfo.bind(this)}>
          </Condition>
          {/* <Condition condition={userData.roles.slug === 'pos_admin'}
            custom={true}
            component={this.renderHistoryInfo.bind(this)}>
          </Condition> */}
          {/* <Condition condition={userData.roles.slug == 'worker' || userData.roles.slug == 'pos_admin' }>
                  {this.renderCourseSignup()}
                </Condition> */}
          {/* <Auth roles={['super_admin', 'admin']}>
                  {this.renderUserFiles()}
                </Auth> */}
          {/* <Condition condition={userData.roles.slug == 'worker' || userData.roles.slug == 'pos_admin' }>
                  {this.renderUserFiles()}
                </Condition>                 */}
        </div>
      </div>
      <Condition condition={userData.roles.slug === 'ph'}>
        {this.renderAgentSaloons()}
      </Condition>
      <Condition condition={userData.roles.slug === 'ph'}>
        {this.renderAddSaloonsToAgent()}
      </Condition>
      <Condition condition={userData.roles.slug === 'worker' || userData.roles.slug === 'pos_admin'}>
        {this.renderUserCourses()}
      </Condition>
      <Condition condition={userData.roles.slug === 'worker' || userData.roles.slug === 'pos_admin'}>
        <CoursesGrid courseDates={this.props.courses.courseDates} data={this.props.user.userCourses.list} />
      </Condition>
    </Auxiliary>
  }

  renderAddPoints() {

    return <div>
      <Tooltip onClick={this.toggleAddPointsMode.bind(this)} id="tooltip-icon" title="Dodaj punkty" placement="left">
        <Fab color="primary" className="mr-2 bg-orange text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
          <i className="zmdi zmdi-money zmdi-hc-fw zmdi-hc-lg" />
        </Fab>
      </Tooltip>
      Dodaj punkty
      {this.state.addPoints.enabled ?
        <div className="row">
          <div className="col-4">
            <TextField
              error={false}
              autoFocus
              onChange={this.onPointsChange.bind(this)}
              margin="dense"
              id="points"
              type="number"
              value={this.state.addPoints.userPoints}
              name="userPoints"
              fullWidth />
          </div>
          <div className="mt-1">
            <Tooltip onClick={this.addPoints.bind(this)} id="tooltip-icon" title="Zapisz" placement="top">
              <Fab color="primary" className="bg-green text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                <i className="zmdi zmdi-save zmdi-hc-fw zmdi-hc-lg" />
              </Fab>
            </Tooltip>
          </div>
        </div>
        : null}
    </div>

  }

  renderAdminPosInfo() {
    let userData = this.props.user.userDetails.data
    let preparedPosList = this.props.pos.noOwnerPosList.list.map((item, key) => {
      return {
        value: item.identifier,
        label: item.title
      }
    })
    // if ( userData.pos ) {
    return <div className="col-xl-4 col-lg-4 col-md-4 col-12">
      <Widget title="Salon użytkownika" styleName="jr-card-profile-sm">
        {!userData.pos ?
          <div className="media align-items-center flex-nowrap jr-pro-contact-list">
            Brak salonu
          </div>
          : <div>
            <div className="media align-items-center flex-nowrap jr-pro-contact-list">
              <div className="mr-3">
                <i className="zmdi zmdi-local-library jr-fs-xxl text-orange" />
              </div>
              <div className="media-body">
                <span className="mb-0 text-grey jr-fs-sm">Nazwa</span>
                <p className="mb-0">
                  <Link to={userData.pos ? "/app/saloons/" + userData.pos.data.identifier : '#'}>{userData.pos ? userData.pos.data.title : ''}</Link>
                </p>
              </div>
            </div>
            <div className="media align-items-center flex-nowrap jr-pro-contact-list">
              <div className="mr-3">
                <i className="zmdi zmdi-pin jr-fs-xxl text-orange" />
              </div>
              <div className="media-body">
                <span className="mb-0 text-grey jr-fs-sm">Adres</span>
                <p className="mb-0">
                  {userData.pos.data.nameAddress ? userData.pos.data.cityName + ", " + userData.pos.data.nameAddress : '[brak adresu]'}
                </p>
              </div>
            </div>
          </div>}
        <Auth roles={['super_admin', 'admin']}>
          <Tooltip onClick={this.toggleChangeAdminPosMode.bind(this)} id="tooltip-icon" title="Zmień salon" placement="left">
            <div>
              <Fab color="primary" className="mr-1 bg-orange text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                <i className="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg" />
              </Fab>
              Zmień salon
            </div>
          </Tooltip>
          {this.state.changeAdminPosMode ? <div className="row mt-2">
            <ReactSelect
              className="col-10"
              options={preparedPosList}
              onChange={this.onAdminPosChange.bind(this)}
              value={this.state.selectedAdminPos}
              placeholder="Wyszukaj salonu" />
            <div className="mt-1">
              <Tooltip onClick={this.updateOwnerPos.bind(this)} id="tooltip-icon" title="Zapisz" placement="top">
                <Fab color="primary" className="bg-green text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                  <i className="zmdi zmdi-save zmdi-hc-fw zmdi-hc-lg" />
                </Fab>
              </Tooltip>
            </div>
          </div> : null}
        </Auth>
      </Widget>
    </div>
    // } else {
    //   return <div className="col-xl-4 col-lg-4 col-md-4 col-12">
    //           <Widget title="Salon użytkownika" styleName="jr-card-profile-sm">
    //             <div  className="media align-items-center flex-nowrap jr-pro-contact-list">
    //               Brak salonu
    //             </div>
    //           </Widget>
    //         </div>
    // }

  }
  renderHistoryInfo() {

    return <div className="col-xl-4 col-lg-4 col-md-4 col-12">
      <Widget title="Historia bilansu" styleName="jr-card-profile-sm">
        <HistoryTable />
      </Widget>
    </div>
  }



  addPoints() {

    let userId = this.props.match.params.slug
    this.props.dispatch(UserActions.addPoints(userId, {
      userPoints: this.state.addPoints.userPoints
    }))

  }

  toggleAddPointsMode() {
    this.setState({
      ...this.state,
      addPoints: {
        ...this.state.addPoints,
        enabled: !this.state.addPoints.enabled,
        points: null
      }
    })
  }

  toggleChangeAdminPosMode() {
    this.setState({
      ...this.state,
      changeAdminPosMode: !this.state.changeAdminPosMode,
      selectedAdminPos: null
    })
  }

  toggleChangePosMode() {
    this.setState({
      ...this.state,
      changePosMode: !this.state.changePosMode,
      selectedPos: null
    })
  }

  onWorkerPosChange(item) {
    this.setState({
      ...this.state,
      selectedPos: item
    })
  }

  onAdminPosChange(item) {
    this.setState({
      ...this.state,
      selectedAdminPos: item
    })
  }

  renderPosInfo() {
    let userData = this.props.user.userDetails.data
    let preparedPosList = this.props.pos.list.map((item, key) => {
      return {
        value: item.identifier,
        label: item.title
      }
    })

    // let owner = {}
    // if (userData.pos) {
    //   owner = userData.pos.length <= 0 ? userData.pos.data.owner : {}
    // }
    if (userData.pos) {
      return <div className="col-xl-4 col-lg-4 col-md-4 col-12">
        <Widget title="Salon użytkownika" styleName="jr-card-profile-sm">
          <div className="media align-items-center flex-nowrap jr-pro-contact-list">
            <div className="mr-3">
              <i className="zmdi zmdi-local-library jr-fs-xxl text-orange" />
            </div>
            <div className="media-body">
              <span className="mb-0 text-grey jr-fs-sm">Nazwa</span>
              <p className="mb-0">
                <Link to={"/app/saloons/" + userData.pos.data.identifier}>{userData.pos.data.title}</Link>
              </p>
            </div>
          </div>
          <div className="media align-items-center flex-nowrap jr-pro-contact-list">
            <div className="mr-3">
              <i className="zmdi zmdi-pin jr-fs-xxl text-orange" />
            </div>
            <div className="media-body">
              <span className="mb-0 text-grey jr-fs-sm">Adres</span>
              <p className="mb-0">
                {userData.pos.data.nameAddress ? userData.pos.data.cityName + ", " + userData.pos.data.nameAddress : '[brak adresu]'}
              </p>
            </div>
          </div>
          {/* <div  className="media align-items-center flex-nowrap jr-pro-contact-list">
                  <div className="mr-3">
                    <i className="zmdi zmdi-account jr-fs-xxl text-orange"/>
                  </div>
                  <div className="media-body">
                    <span className="mb-0 text-grey jr-fs-sm">Właściciel salonu</span>
                    <p className="mb-0">
                      <Link to={"/app/users/"+owner && owner.data ? owner.data.identifier : '/'}>
                        {owner && owner.data ? owner.data.firstName : ''+" "+owner && owner.data ? owner.data.lastName : ''}
                      </Link>
                    </p>
                  </div>
                </div> */}
          <Auth roles={['super_admin', 'admin']}>
            <Tooltip onClick={this.toggleChangePosMode.bind(this)} id="tooltip-icon" title="Zmień salon" placement="left">
              <div>
                <Fab color="primary" className="mr-1 bg-orange text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                  <i className="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg" />
                </Fab>
                Zmień salon
              </div>
            </Tooltip>
          </Auth>
          {this.state.changePosMode ? <div className="row mt-2">
            <ReactSelect
              className="col-10"
              options={preparedPosList}
              onChange={this.onWorkerPosChange.bind(this)}
              value={this.state.selectedPos}
              placeholder="Wyszukaj salonu" />
            <div className="mt-1">
              <Tooltip onClick={this.updateWorkerPos.bind(this)} id="tooltip-icon" title="Zapisz" placement="top">
                <Fab color="primary" className="bg-green text-white jr-fab-btn jr-btn-fab-xs" aria-label="edit">
                  <i className="zmdi zmdi-save zmdi-hc-fw zmdi-hc-lg" />
                </Fab>
              </Tooltip>
            </div>
          </div> : null}
        </Widget>
      </div>
    } else {
      let selectList = this.props.pos.list.map((item, key) => {
        return {
          value: item.identifier,
          label: item.title
        }
      })
      return <div className="col-xl-4 col-lg-4 col-md-4 col-12">
        <Widget title="Salon użytkownika" styleName="jr-card-profile-sm">
          <div className="media align-items-center flex-nowrap jr-pro-contact-list">
            <div className="media-body">
              <span className="mb-0 text-grey jr-fs-sm">Nazwa</span>
              <p className="mb-0">Użytkownik nie jest przypisany do żadnego salonu</p>
              <ReactSelect
                options={selectList}
                value={this.state.selectedUserSaloon}
                onChange={this.setSelectedUserSaloon.bind(this)}
                placeholder="Wybierz salon"
              />
              <Tooltip id="tooltip-icon" onClick={this.addSaloonToWorker.bind(this)} title="Przypisz salon" placement="top">
                <Fab color="primary" className="jr-fab-btn" aria-label="add">
                  <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg" />
                </Fab>
              </Tooltip>
            </div>
          </div>
        </Widget>
      </div>
    }

  }

  renderCourseSignup() {
    return <div className="col-xl-4 col-lg-4 col-md-4 col-12">
      <Widget title="Zapisz na szkolenie" styleName="jr-card-profile-sm">
        <div className="row">
          {this.renderCoursesSelect()}
          {this.state.courseID ? this.renderSubmitBtn() : null}
        </div>
      </Widget>
    </div>
  }

  renderAddSaloonsToAgent() {
    let selectList = this.props.user.allSaloons.list.map((item, key) => {
      return {
        value: item.identifier,
        label: item.title
      }
    })
    return <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <Widget title="Przypisz salon do przedstawiciela" styleName="jr-card-profile-sm">
        <div className="row">
          <div className="col-md-7">
            <ReactSelect
              options={selectList}
              value={this.state.selectedSaloon}
              onChange={this.setSelectedSaloon.bind(this)}
              placeholder="Search a country (start with a)"
            />
            {/* <FormControl className="w-100">
                    <InputLabel shrink htmlFor="courseID">Wybierz salon</InputLabel>
                    <Select
                        name="saloonID"
                        onChange={this.setSelectedSaloon.bind(this)}
                        value={this.state.saloonID} >
                        <MenuItem value="">
                            <em>Wybierz salon...</em>
                        </MenuItem>
                        {this.props.user.allSaloons.list.map((item, key) => {
                          return <MenuItem key={key} value={item.identifier}>{item.title+", "+item.nameAddress+", "+item.regionName}</MenuItem>
                        })}
                    </Select>
                </FormControl> */}
          </div>
          <div className="col-md-2">
            <Tooltip id="tooltip-icon" onClick={this.addAgentSaloon.bind(this)} title="Przypisz salon" placement="top">
              <Fab color="primary" className="jr-fab-btn" aria-label="add">
                <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg" />
              </Fab>
            </Tooltip>
          </div>
        </div>
      </Widget>
    </div>
  }

  handleChangeSingle(e) {
  }

  renderUserCourses() {
    return <div className="row">
      <div className="col-lg-12 col-sm-12">
        {/* <CardBox styleName="col-12" cardStyle="p-0" heading={"Szkolenia"}
              headerOutside>
                {this.renderCoursesList()}
              </CardBox> */}
      </div>
    </div>
  }

  addAgentSaloon() {
    let userId = this.props.match.params.slug
    let posId = this.state.saloonID
    this.props.dispatch(UserActions.addAgentSaloon(posId, userId))
    this.clearSelectedSaloon()
  }

  addSaloonToWorker() {
    let userId = this.props.match.params.slug
    let posId = this.state.userSaloonID
    this.props.dispatch(UserActions.addSaloonToWorker(posId, userId))
    // this.clearSelectedSaloon()
  }

  deleteAgentSaloon(posId) {
    let userId = this.props.match.params.slug
    this.props.dispatch(UserActions.deleteAgentSaloon(posId, userId))
  }

  onPointsChange(e) {
    this.setState({
      ...this.state,
      addPoints: {
        ...this.state.addPoints,
        userPoints: e.target.value
      }
    })
  }

  clearSelectedSaloon() {
    this.setState({
      ...this.state,
      saloonID: null,
      selectedSaloon: {}
    })
  }

  setSelectedSaloon(item, test) {
    this.setState({
      ...this.state,
      saloonID: item.value,
      selectedSaloon: item
    })
  }

  setSelectedUserSaloon(item, test) {
    this.setState({
      ...this.state,
      userSaloonID: item.value,
      selectedUserSaloon: item
    })
  }

  hasRole(role) {
    if (role === this.props.user.userDetails.data.roles.slug) {
      return true
    }
    return false
  }

  getRole() {
    return this.props.user.userDetails.data.roles.slug
  }

  renderCoursesList() {
    if (this.props.courses.userCourses.length > 0) {
      return <CoursesList changeSignupStatus={this.changeSignupStatus.bind(this)}
        list={this.props.courses.userCourses} />

    } else {
      return <Widget styleName="jr-card-profile">
        <div>Ten użytkownik nie ma historii szkoleń.</div>
      </Widget>
    }
  }

  renderRoles() {
    return this.props.user.userDetails.data.roles.name
  }

  renderAgentSaloons() {
    let userId = this.props.match.params.slug
    let preparedAgentsList = this.props.user.freeAgents.list.map((item, key) => {
      return {
        value: item.identifier,
        label: item.firstName + " " + item.lastName
      }
    })
    preparedAgentsList = preparedAgentsList.filter((item, key) => {
      return item.value !== userId
    })
    return <div className="row">
      <div className="col-lg-12 col-sm-12">
        <CardBox styleName="col-12" cardStyle="p-0" heading={"Salony należące do przedstawiciela"}
          headerOutside>
          <SaloonsList onDelete={this.deleteAgentSaloon.bind(this)}
            agentsList={preparedAgentsList}
            onSelectChange={this.onAgentChange.bind(this)}
            selectedAgent={this.state.selectedAgent}
            list={this.props.user.agentSaloons.list}
            onCheckAll={this.onCheckAll.bind(this)}
            checkAll={this.state.checkAll}
            selectedListItems={this.state.selectedListItems}
            listSelectMode={this.state.listSelectMode}
            onSave={this.onSelectListSave.bind(this)}
            onCheck={this.addSelectedListItem.bind(this)}
            onUncheck={this.deleteSelectedListItem.bind(this)} />
        </CardBox>
      </div>
    </div>
  }

  renderSubmitBtn() {
    return <div className="col-md-2">
      <Tooltip id="tooltip-icon" onClick={this.onSubmit.bind(this)} title="Zapisz" placement="top">
        <Fab color="primary" className="jr-fab-btn" aria-label="add">
          <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg" />
        </Fab>
      </Tooltip>
    </div>
  }

  renderCoursesSelect() {
    // let availableCourses = this.props.courses.courseDates.filter((item, key) => {
    //   let filtered = true
    //   this.props.courses.courseDates.map((i, k) => {
    //     if ( i.courseDate.identifier == item.identifier ) {
    //       filtered = false
    //     }
    //   })
    //   return filtered

    // })
    let availableCourses = this.props.courses.courseDates
    if (availableCourses.length > 0) {
      return <div className="col-md-8">
        <FormControl className="w-100">
          <InputLabel shrink htmlFor="courseID">Wybierz termin szkolenia</InputLabel>
          <Select
            name="courseID"
            onChange={this.onChange.bind(this)}
            value={this.state.courseID}
            input={<Input name="courseID" id="courseID" />} >
            <MenuItem value="">
              <em>Wybierz termin szkolenia...</em>
            </MenuItem>
            {availableCourses.map((item, key) => {
              return <MenuItem key={key} value={item.identifier}>{item.title}, {item.dateStart}, {item.placeName}</MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
    } else {
      return <div>Brak nadchodzących szkoleń</div>
    }

  }

  resetSignUp() {
    this.setState({
      ...this.state,
      signUpMode: false,
      courseID: null
    })
  }

  onSelectListSave() {
    if (this.state.selectedAgent) {
      this.props.dispatch(PosActions.rewritePosToAgent(this.state.selectedAgent.value, this.state.selectedListItems))
    } else {

    }

  }

  onSubmit() {
    let courseID = this.state.courseID
    let creatorID = this.props.user.auth.user.identifier
    let participantID = this.props.user.userDetails.data.identifier
    this.props.dispatch(CoursesActions.signUpForCourse(courseID, {
      creatorID,
      participantID
    }))
  }

  onChange(e) {
    let newState = { ...this.state }
    newState[e.target.name] = e.target.value
    this.setState(newState, () => {

    })
  }

  renderUserFiles() {

    return <div className="col-xl-4">
      <Widget styleName="jr-card-profile">
        <div className="mb-3">
          <h3 className="card-title mb-2 mb-md-3">Karta szkoleniowa</h3>
          <div className="row">
            <div className="col-xl-8">
              <Dragndrop text="Dodaj plik" onFileChange={this.onFileChange.bind(this)} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-10">
              {Object.keys(this.props.user.addUserFiles.errors).length > 0 ? <div className="text-red mb-2">Przesyłane pliki są zbyt duże.</div> : null}
              {this.state.userFiles.length > 0 ? <h3 className="card-title mb-2 mb-md-3">Pliki do przesłania:</h3> : null}
              {this.state.userFiles.map((item, key) => {
                return <div key={key} className="mb-1 row" style={{ clear: 'both' }} >
                  <div className="file-list-item col-10">
                    {item.name} - {Math.round((item.size / (1024 * 1024)) * 100) / 100}MB
                  </div>
                  <Tooltip className="pull-right col-2" onClick={this.deleteCourseStaffFormFile.bind(this, key)} id="tooltip-icon" title="Usuń plik" placement="top">
                    <Fab color="primary" className="bg-red text-white jr-fab-btn jr-btn-fab-xs" aria-label="delete">
                      <i className="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg" />
                    </Fab>
                  </Tooltip>
                </div>
              })}
            </div>
            {/* {this.state.courseFormFiles.length > 0 ? <div className="col-xl-2">
                      <Tooltip id="tooltip-icon" title="Wyślij" placement="top">
                          <Button onClick={this.addCourseStaffFiles.bind(this)} variant="fab" color="primary" className="jr-fab-btn" aria-label="add">
                            <i className="zmdi zmdi-mail-send zmdi-hc-fw zmdi-hc-lg"/>
                          </Button>
                      </Tooltip>
                    </div> : null} */}
          </div>
          {/* {this.props.courses.courseDetails.data.staffFiles.data.length > 0 ? this.renderExistingCourseFiles() : <p className="text-grey jr-fs-sm mb-0">Brak materiałow do tego szkolenia</p>} */}
          {/* {this.props.courses.courseDetails.data.staffFiles.data.length > 0 ? <a href={settings.api_endpoint+'/course/'+this.props.courses.courseDetails.data.slug+'/staffFiles/zipped'} download>
                      <Tooltip id="tooltip-icon" title="Pobierz materiały" placement="top">
                        <Button variant="fab" color="primary" className="jr-fab-btn" aria-label="add">
                          <i className="zmdi zmdi-download zmdi-hc-fw zmdi-hc-lg"/>
                        </Button>
                      </Tooltip>
                    </a> : null} */}
        </div>
      </Widget>
    </div>
  }

  checkForListSelectMode() {

    if (this.state.selectedListItems.length > 0) {
      this.setState = {
        ...this.state,
        listSelectMode: true
      }
    } else {
      this.setState = {
        ...this.state,
        listSelectMode: false
      }
    }

  }

  onAgentChange(item) {
    this.setState({
      ...this.state,
      selectedAgent: item
    })
  }

  addSelectedListItem(item) {
    let currentList = [...this.state.selectedListItems]
    if (currentList.indexOf(item) > -1) {
      let index = currentList.indexOf(item)
      currentList.splice(index, 1)
    } else {
      currentList.push(item)
    }
    this.setState({
      ...this.state,
      selectedListItems: currentList,
      listSelectMode: currentList.length > 0 ? true : false
    })
  }

  deleteSelectedListItem(item) {
    let currentList = [...this.state.selectedListItems]
    let index = currentList.indexOf(item)
    currentList.splice(index, 1)
    this.setState({
      ...this.state,
      selectedListItems: currentList
    })
  }

  toggleSignUpMode() {
    this.setState({
      ...this.state,
      signUpMode: !this.state.signUpMode
    })
  }

  changeSignupStatus(courseId, signupId, data) {

    this.props.dispatch(CoursesActions.changeSignupStatus(courseId, signupId, data))

  }

  clearSelectList() {

    this.setState({
      ...this.state,
      selectedListItems: [],
      selectedAgent: null,
      listSelectMode: false,
      checkAll: false
    })

  }

  onCheckAll() {
    let checkedList = this.props.user.agentSaloons.list.map((item, key) => {
      return item.identifier
    })
    let checkAll = true
    if (this.state.selectedListItems.length > 0) {
      checkedList = []
      checkAll = false
    }
    this.setState({
      ...this.state,
      selectedListItems: checkedList,
      checkAll: checkAll,
      listSelectMode: checkedList.length > 0 ? true : false
    })
  }

}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    courses: state.courses,
    pos: state.pos
  }
}

export default connect(mapStateToProps)(UserDetails)
