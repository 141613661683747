const INIT_STATE = {
    loading: false,
    error: '',
    dashboardData: {
        users: {},
        data: {},
        statistic: {},
        courseDates: {},
        saloons: {},
        agentPosList: {
            data: []
        },
        upcomingCourseDate: {
            data: []
        },
        upcomingExpertCourses: {
            data: []
        }
    }
}


export default (state = INIT_STATE, {type, payload}) => {
    switch (type) {

        case 'GET_DASHBOARD_DATA_PENDING':
            return {
                ...state
            }
        case 'GET_DASHBOARD_DATA_REJECTED':
            return {
                ...state
            }
        case 'GET_DASHBOARD_DATA_FULFILLED':
            if (payload && payload.data) {
                return {
                    ...state,
                    dashboardData: {
                        ...state.dashboardData,
                        ...payload.data
                    }
                }
            } else {
                return { ...state }
            }

        case 'GET_ORDER_STATISTIC_PENDING':
            return {
                ...state,
                error: false,
                loading: true
            }

        case 'GET_ORDER_STATISTIC_REJECTED':
            return {
                ...state,
                error: 'Nie udało się załadować nowe dane',
                loading: false
            }

        case 'GET_ORDER_STATISTIC_FULFILLED':
            const newData = payload.data.data ? payload.data.data : {};
            return {
                ...state,
                dashboardData: {
                    ...state.dashboardData,
                    statistic: {
                        ...(state.dashboardData.statistic ? state.dashboardData.statistic : {}),
                        ...newData
                    },
                },
                loading: false,
            }
        default:
            return state;
    }
}
