import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CardBox from '../../components/CardBox';
import moment from 'moment'
import 'moment/locale/pl'
import ReactSelect from 'react-select'
import DayPickerComponent from '../../components/DayPickerComponent';
import BarChartComponent from '../../components/BarChartComponent';
import StatisticTableComponent from './components/StatisticTableComponent';
import { getAppStatistic, getAppStatisticPagination, getAppStatisticAgent } from '../../actions';
import { connect } from 'react-redux';
import { STATISTICS_MOBILE_ADDED, STATISTICS_MOBILE_BOUGHT, STATISTICS_MOBILE_CITIES, STATISTICS_MOBILE_POS, STATISTICS_MOBILE_SALONS_ADDED, STATISTICS_MOBILE_USERS_ADDED } from '../../constants/api';
import Auth from '../../util/Auth';
// import { makeStyles } from '@material-ui/core';

// const useStyles = makeStyles((theme) => ({
// }))

const allAgent = {
	label: 'Wybierz przedstawiciela',
	value: 0
}


const AppStatisticPage = ({
	appStatistic,
	userData,
	getAppStatistic = () => { },
	getAppStatisticPagination = () => { },
	getAppStatisticAgent = () => { },
}) => {
	//  const classes = useStyles();
	const { data, loading, barChartLoading } = useMemo(() => appStatistic, [appStatistic])
	const role = useMemo(() => userData && userData.auth && userData.auth.user && userData.auth.user.roles ? userData.auth.user.roles.slug : false, [userData])
	const [rendered, setRendered] = useState(false);
	const [range, setRange] = useState({
		from: new Date(new moment().subtract(30, 'days')),
		to: new Date()
	})
	const [currentAgent, setCurrentAgent] = useState(allAgent);
	const barChartData = useMemo(() => data && data.agentSalons && data.agentSalons.length
		? data.agentSalons.map((item) => ({
			// eslint-disable-next-line
			['Sprzędaż w aplikacji (PLN)']: item.value,
			name: item.name,
		}))
		: [], [data])
	const selectOptions = useMemo(() => [
		allAgent,
		...(data && data.agents && data.agents.length
			? data.agents.map(({ name, id }) => ({
				label: name,
				value: id,
			}))
			: [])
	], [data])
	const getParams = useCallback(() => {
		const { from, to } = range
		const formatFrom = moment(from).format('DD-MM-YYYY');
		const formatTo = moment(to).format('DD-MM-YYYY');
		return { from: formatFrom, to: formatTo, agent: currentAgent ? currentAgent.value : 0 }
	}, [range, currentAgent])

	useEffect(() => {
		if (role)
			getAppStatistic(getParams(), role)
	}, [range, role])

	useEffect(() => {
		if (rendered) {
			if (role)
				getAppStatisticAgent(getParams())
		}
		else
			setRendered(true)
	}, [currentAgent, role])
	const handleChangeAgent = useCallback((ag) => {
		setCurrentAgent(ag)
	}, [])



	const handleChange = useCallback((key) => (e, page) => {
		const { from, to } = getParams();
		getAppStatisticPagination(key, { page: page + 1, from, to})
	}, [getParams])
	return (
		<section className="app-wrapper-mobile-response animated slideInUpTiny animation-duration-3 row m-0">
			<DayPickerComponent
				range={range}
				setRange={setRange}
			/>
			<Auth roles={['super_admin', 'admin']}>
				<CardBox styleName={`col-12`} heading="Statystyki przedstawiciela" headerOutside>
					<div className="row ">
						<div className="col-md-4 p-1">
							<ReactSelect
								className="w-100"
								onChange={handleChangeAgent}
								value={currentAgent}
								options={selectOptions}
								placeholder="Wpisz nazwę salonu..."
							/>
						</div>
					</div>
				</CardBox>
				<BarChartComponent
					range={range}
					loading={loading || barChartLoading}
					heading={'Salony przedstawiciela w Keune App'}
					badge={`Salonów dodaną do aplikacji: ${data && data.agentSalons ? data.agentSalons.length : 0}`}
					data={barChartData}
					legend='Sprzędaż w aplikacji (PLN)'
				/>
			</Auth>
			<StatisticTableComponent	
				heading='Liczba dodanych salonów do aplikacji'
				titles={['Przedstawiciel', 'Liczba salonów']}
				badge
				data={data}
				hiddenSubtitle
				range={range}
				loading={loading}
				onChange={handleChange(STATISTICS_MOBILE_SALONS_ADDED)}
				keyArr='salonsAdded'
			/>
			<StatisticTableComponent	
				heading='Zarejestrowane Użytkownicy'
				badgeTitle='użytkowników które dokonali zakupów'
				titles={['Imię i Nazwisko', 'Liczba zakupów']}
				data={data}
				badge
				roles={['ph']}
				range={range}
				loading={loading}
				onChange={handleChange(STATISTICS_MOBILE_USERS_ADDED)}
				keyArr='usersAdded'
			/>
			<div className='col-12 jr-entry-header'>
				<h3 className="entry-heading">Statystyki produktów</h3>
			</div>
			<StatisticTableComponent	
				heading='Najlepiej sprzedające się produkty'
				titles={['Nazwa', 'Liczba kupionych']}
				badge
				roles={['ph']}
				data={data}
				range={range}
				loading={loading}
				onChange={handleChange(STATISTICS_MOBILE_BOUGHT)}
				keyArr='mostBought'
			/>
			<StatisticTableComponent	
				heading='Produkty najczęściej dodawane do koszyka (bez zakupu)'
				titles={['Nazwa', 'Liczba dodanych']}
				data={data}
				badge
				roles={['ph']}
				range={range}
				loading={loading}
				onChange={handleChange(STATISTICS_MOBILE_ADDED)}
				keyArr='mostAdded'
			/>
			<div className='col-12 jr-entry-header'>
				<h3 className="entry-heading">Statystyki salonów</h3>
			</div>
			<StatisticTableComponent	
				heading='Salony z największą liczbą zakupów w aplikacji'
				titles={['Nazwa salonu', 'Liczba udanych transakcji', 'Liczba odebranych zakupów']}
				data={data}
				badge
				roles={['ph']}
				range={range}
				loading={loading}
				onChange={handleChange(STATISTICS_MOBILE_POS)}
				keyArr='mostPos'
			/>
			<StatisticTableComponent	
				heading='Miasta z największą liczbą zakupów w aplikacji'
				titles={['Nazwa miasta', 'Liczba udanych transakcji']}
				data={data}
				badge
				roles={['ph']}
				range={range}
				loading={loading}
				onChange={handleChange(STATISTICS_MOBILE_CITIES)}
				keyArr='mostCities'
			/>
			{/* <StatisticTableComponent	
				heading='Kody promocyjne z największą liczbą zakupów w aplikacji'
				titles={['Kod', 'Nazwa salon', 'Liczba udanych transakcji']}
				data={data}
				badge
				roles={['ph']}
				range={range}
				loading={loading}
				onChange={handleChange(STATISTICS_PROMO_CODES)}
				keyArr='mostPromoCodes'
			/> */}
		</section>
	)
}

export default connect((state) => ({
	appStatistic: state.appStatistic,
	userData: state.user
}), {
	getAppStatisticPagination,
	getAppStatistic,
	getAppStatisticAgent,
})(AppStatisticPage);
