import React from 'react'
import { makeStyles } from '@material-ui/styles';
import CircularLoader from '../../app/routes/components/routes/progressbar/circular/CircularLoader';

const useStyles = (opacity) => makeStyles((theme) => ({
	cont: {
		position: 'absolute',
		zIndex: 10000,
		background: opacity ? '#ffffff90' : '#ffffff',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	}
}))

const DialogLoaderComponent = ({ opacity, loaded }) => {
	const classes = useStyles(opacity)();

	return (
		<>
			{!loaded
				? <section className={classes.cont}>
					<CircularLoader />
				</section>
				: ''
			}
		</>
	)
}

export default React.memo(DialogLoaderComponent);