import React from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import TableComponent from '../../components/Table/TableComponent';
import DialogComponent from '../../components/Dialog/DialogComponent';
import { useCallback } from 'react';
import { useState } from 'react';
import { DialogTitle } from '@material-ui/core';
import TextFieldComponent from '../../components/Form/TextFieldComponent';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/styles';
import SweetAlert from 'react-bootstrap-sweetalert'
import DropComponent from '../../components/Form/DropComponent';
import { connect } from 'react-redux';
import { getSlidersApp, addSlidersApp, updateSlidersApp, deleteSlidersApp } from '../../actions';
import { useEffect } from 'react';
import * as Yup from 'yup';
import validations from '../../functions/validations';
import DialogImgComponent from '../../components/Dialog/DialogImgComponent';
import PublishCheckboxComponent from '../../components/Form/PublishCheckboxComponent';
import findItem from '../../functions/findItem';
import { INITIAL_PAGINATION } from '../../constants';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        paddingLeft: 0,
    },

    boldTitle: {
        fontWeight: 'bold',
    },

}))

const SignupSchema = (t) => Yup.object().shape({
    name: Yup.string()
        .required(validations('required'))
        .min(3, validations('min', 3))
        .max(80, validations('max', 80)),
    slug: Yup.string()
        .required(validations('required'))
        .min(3, validations('min', 3))
        .max(80, validations('max', 80))
});

const initialData = {
    name: '',
    slug: '',
    published: false,
    img: {},

};



const SlidersPage = ({ slidersApp, getSlidersApp, updateSlidersApp, deleteSlidersApp, addSlidersApp }) => {
    const classes = useStyles();
    const [data, setData] = useState({ ...initialData });
    const [openAdd, setOpenAdd] = useState(false);
    const [editSlide, setEditSlide] = useState(false);
    const [activeSlide, setActiveSlide] = useState({});
    const [showError, setShowError] = useState(false);
    const [show, setShow] = useState(false);
    const [deleteSlide, setDeleteSlide] = useState(false);
    const [img, setImg] = useState(false);
    const [filters, setFilters] = useState(INITIAL_PAGINATION)
    useEffect(() => {
        getSlidersApp(filters);
    }, [filters])



    const handleShow = useCallback((id) => {
        if (slidersApp && slidersApp.list && slidersApp.list.length) {
            setActiveSlide(findItem(slidersApp, id))
            setShow(true)
        }
    }, [slidersApp])
    const handleClose = useCallback((id) => {
        setShow(false)
    }, [show, slidersApp])
    const handleAdd = useCallback(() => {
        setOpenAdd(true)
    }, [])
    const handleEdit = useCallback((id) => {
        const slide = findItem(slidersApp, id)
        if (slide) {
            setImg(slide.img);
            setData({
                id: slide.identifier,
                name: slide.title,
                slug: slide.slug,
                img: slide.img,
                published: slide.published,

            })
            setEditSlide(true);
            setOpenAdd(true)
        }
    }, [slidersApp])
    const handleCloseAdd = useCallback(() => {
        setOpenAdd(false);
        setEditSlide(false);
        setData(initialData);
        setImg(false);
        // eslint-disable-next-line
    }, [])
    const handleShowDelete = useCallback((id) => {
        setActiveSlide(findItem(slidersApp, id))
        setDeleteSlide(true);
    }, [slidersApp])
    const handleCloseDelete = useCallback(() => {
        setDeleteSlide(false);
        setActiveSlide(false)
    }, [])
    const handleDelete = useCallback((e) => {
        if (activeSlide) {
            deleteSlidersApp(activeSlide.identifier)
        }
    }, [activeSlide])

    const formik = useFormik({
        validationSchema: SignupSchema(),
        enableReinitialize: true,
        initialValues: data,
        onSubmit: values => {
            if (img) {
                setShowError(false);
                let bodyFormData = new FormData();
                if (img.preview) {
                    bodyFormData.append('image', img)
                }
                bodyFormData.append('title', values.name)
                bodyFormData.append('published', values.published ? 1 : 0)
                bodyFormData.append('slug', values.slug)

                if (editSlide) {
                    bodyFormData.append('_method', 'put')
                    updateSlidersApp({ id: values.id, params: bodyFormData });
                }
                else
                    addSlidersApp(bodyFormData);
            } else {
                setShowError(true);
            }
        },
    });

    const onChangePage = useCallback((e, value) => {
        setFilters({
            ...filters,
            page: value + 1,
        })
    }, [filters])
    const changePerPage = useCallback((e, value) => {
        setFilters({
            ...filters,
            per_page: value.key,
        })
    }, [filters])

    useEffect(() => {
        if (!slidersApp.loading && !slidersApp.error) {
            setEditSlide(false);
            setOpenAdd(false)
            setImg(false);
            formik.resetForm();
            setData({ ...initialData });
            setDeleteSlide(false);
            setActiveSlide(false);
        } else if (slidersApp.error) {
            NotificationManager.error(slidersApp.error, "", 2000);
        }
    }, [slidersApp])

    return (
        <div className="app-wrapper animated slideInUpTiny animation-duration-3">
            <NotificationContainer />
            <div className="row mb-md-4">
                <TableComponent
                    headerOutside
                    heading='Lista sliderów'
                    addTooltip='Dodaj slider'
                    addBtn='zmdi-plus'
                    addClick={handleAdd}
                    handleEdit={handleEdit}
                    onChangePage={onChangePage}
                    changePerPage={changePerPage}
                    handleShow={handleShow}
                    loading={slidersApp.tableLoading}
                    nextPageLoading={slidersApp.nextPageLoading}
                    meta={slidersApp.pagination}
                    handleDelete={handleShowDelete}
                    titles={['Miniaturka', 'Nazwa', 'Opublikowany']}
                    data={slidersApp && slidersApp.list && slidersApp.list.length
                        ? slidersApp.list.map((slide) => ({
                            id: slide.identifier,
                            data: {
                                img: <img width="50" src={slide.img} alt={slide.title} />,
                                title: slide.title,
                                published: slide.published ? 'Tak' : 'Nie'
                            }
                        }))
                        : []
                    }
                />
            </div>
            <DialogComponent
                open={openAdd}
                handleClose={handleCloseAdd}
                title={'Dodaj slide'}
                handleSave={formik.handleSubmit}
                loading={slidersApp.loading}
            >
                <form onSubmit={formik.handleSubmit} className="col-md-12">
                    <TextFieldComponent
                        fullWidth
                        id='name'
                        formikProps={formik}
                        label='Nazwa'
                    />
                    <TextFieldComponent
                        fullWidth
                        id='slug'
                        formikProps={formik}
                        label='Slug'
                    />
                    <PublishCheckboxComponent
                        formik={formik}
                    />
                    <div>
                        <DropComponent
                            showError={showError}
                            setShowError={setShowError}
                            img={img}
                            setImg={setImg}
                            error='Dodania obrazku jest wymagane'
                        />
                    </div>
                </form>
            </DialogComponent>
            <DialogComponent open={show} handleClose={handleClose} handleSave={handleClose} hiddenClose btnSave='Zamknij'>
                <div className={`col-md-12`}>
                    <DialogTitle className={classes.dialogTitle}>{activeSlide ? activeSlide.title : ''}</DialogTitle>
                </div>
                <div className={`col-sm-4 mt-2`}>
                    Slug:
                </div>
                <div className={`col-sm-8 mt-2`}>
                    {activeSlide ? activeSlide.slug : ''}
                </div>
                <div className={`col-sm-12 mt-2 mb-2`}>
                    Obrazek:
                </div>
                <DialogImgComponent
                    src={activeSlide ? activeSlide.img : ''}
                    alt={activeSlide ? activeSlide.title : ''}
                />
            </DialogComponent>
            <SweetAlert
                show={deleteSlide}
                warning
                showCancel
                confirmBtnText="Tak, usuń"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                cancelBtnText="Anuluj"
                title="Jesteś pewien?"
                onConfirm={handleDelete}
                onCancel={handleCloseDelete} >
                Slider zostanie bezpowrotnie usunięty!
            </SweetAlert>
        </div>
    )
}


export default connect((state) => ({
    slidersApp: state.slidersApp
}), {
    getSlidersApp,
    deleteSlidersApp,
    addSlidersApp,
    updateSlidersApp,
})(SlidersPage);
