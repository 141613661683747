import React from 'react';
import TextField from '@material-ui/core/TextField';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import { connect } from 'react-redux'
// import * as CoursesActions from '../../../actions/Courses'
import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
// import Editor from './editor'
import Dragndrop from './dragndrop'
// import { CirclePicker } from 'react-color'
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import CircularLoader from '../components/routes/progressbar/circular/CircularLoader'
import { Line } from 'rc-progress';
import DialogComponent from '../../../components/Dialog/DialogComponent';

export default class AddFile extends React.Component {

    constructor(props) {
        super()
        this.state = {
            selectedCourseTypeRadio: null,
            file: {
                title: '',
                files: null,
                roles: []
            },
            item: {
                // role: '',
                title: '',
                lead: '',
                description: '',
                levelID: '',
                categoryID: '',
                groupID: null,
                image: null,
                thumbnail: null,
                light: 1,
                colorHEX: '#f44336'
            },
            errors: {
                title: '',
                lead: '',
                description: '',
                levelID: '',
                categoryID: '',
                colorHEX: ''
            }
        }
    }


    componentDidUpdate(prevProps) {

        if (prevProps.open && !this.props.open) {
            this.clearForm()
        }
        if (prevProps.editItem.title !== this.props.editItem.title) {
            let { title, image, roles } = this.props.editItem
            this.setState({
                ...this.state,
                file: {
                    ...this.state.file,
                    title: title,
                    files: image.data,
                    roles: roles
                }
            }, () => {

            })
        }
    }




    render() {
        // let { title, roles, files } = this.props.form.errors
        let { roles, files } = this.props.form.errors
        let titleError = this.props.form.errors.title ? true : false
        let rolesError = this.props.form.errors.roles ? true : false
        let filesError = this.props.form.errors.files ? true : false



        return <form onSubmit={this.onSubmit.bind(this)}>
            <DialogComponent
                loading={this.props.loading}
                open={this.props.open}
                btnSave={this.props.editMode ? 'Zapisz' : 'Dodaj'}
                handleClose={this.togglePopup.bind(this)}
                handleSave={this.saveForm.bind(this)}
                title={this.props.editMode ? 'Edytuj pliki' : 'Dodaj pliki'}
            >
                <div className="col-md-12 mb-3">
                    <TextField
                        error={titleError}
                        autoFocus
                        onChange={this.onChange.bind(this)}
                        margin="dense"
                        id="title"
                        label={this.props.form.errors.title ? this.props.form.errors.title : 'Tytuł'}
                        type="text"
                        value={this.state.file.title}
                        name="title"
                        fullWidth
                    />
                </div>
                <div className="col-md-12 mb-3">
                    {/* <ReactSelect
                                            className="col-10"
                                            options={roles}
                                            onChange={this.onRoleChange.bind(this)}
                                            value={this.state.selectedRoleGroup}
                                            placeholder="Wybierz grupę docelową"
                                        /> */}
                    <div>
                        <InputLabel error={rolesError}
                            shrink>
                            {rolesError ? roles : 'Role użytkownikow'}
                        </InputLabel>
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox color="primary"
                                checked={this.state.file.roles.indexOf(3) > -1 ? true : false}
                                onChange={this.onRoleChange.bind(this)}
                                value={'3'}
                                name={'3'}
                            />
                        }
                        label="Przedstawiciel handlowy"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary"
                                checked={this.state.file.roles.indexOf(4) > -1 ? true : false}
                                onChange={this.onRoleChange.bind(this)}
                                value={'4'}
                                name={'4'}
                            />
                        }
                        label="Szef salonu"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary"
                                checked={this.state.file.roles.worker}
                                onChange={this.onRoleChange.bind(this)}
                                value={this.state.file.roles.worker}
                                name='5'
                            />
                        }
                        label="Pracownik salonu"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary"
                                checked={this.state.file.roles.training_staff}
                                onChange={this.onRoleChange.bind(this)}
                                value={this.state.file.roles.training_staff}
                                name='6'
                            />
                        }
                        label="Kadra szkoleniowa"
                    />
                </div>
                <div className="row col-md-12 mb-3 align-items-center">
                    <div className="col-md-9">
                        <InputLabel error={filesError}
                            shrink>
                            {filesError ? files : ' Przesyłane pliki'}
                        </InputLabel>

                        {this.props.editMode ? this.renderExistingFiles() : <Dragndrop existingFiles={this.state.file.files}
                            name="files"
                            onFileChange={this.onFileChange.bind(this)} />}
                    </div>
                    <div className="col-md-3">
                        {this.props.progress !== 0 ? <Line percent={this.props.progress} strokeWidth='3' strokeColor='#3f51b5' strokeLinecap='square' /> : null}
                    </div>
                </div>


            </DialogComponent>
        </form>
    }

    renderExistingFiles() {
        if (this.state.file.files) {
            return this.state.file.files.map((item, key) => {
                return <div key={key}>
                    {item.fileName}
                </div>
            })
        }

    }

    onRoleChange(e) {
        let roles = [...this.state.file.roles]
        if (roles.indexOf(parseInt(e.target.name)) > -1) {
            roles = roles.filter((item, key) => {
                return item !== parseInt(e.target.name)
            })
        } else {
            roles.push(parseInt(e.target.name))
        }
        this.setState({
            ...this.state,
            file: {
                ...this.state.file,
                roles: roles
            }
        })
    }

    onChangeSelectedCourseType(e, value) {
        if (value === "0") {
            this.setState({
                ...this.state,
                selectedCourseTypeRadio: value,
                course: {
                    ...this.state.course,
                    groupID: '',
                    levelID: 1
                }
            })
        } else {
            this.setState({
                ...this.state,
                selectedCourseTypeRadio: value,
                course: {
                    ...this.state.course,
                    groupID: value,
                    levelID: ''
                }
            })
        }
    }

    onFileChange(name, value) {
        let files = { ...this.state.file.files }
        files = value
        this.setState({
            ...this.state,
            file: {
                ...this.state.file,
                files: files
            }
        })

    }

    onEditorChange(name, value) {
        let course = { ...this.state.course }
        course[name] = value
        this.setState({
            ...this.state,
            course: course
        })
    }

    onColorChange(e, e2) {
        this.setState({
            ...this.state,
            course: {
                ...this.state.course,
                colorHEX: e.hex
            }
        })
    }

    onChange(e) {
        let file = { ...this.state.file }
        file[e.target.name] = e.target.value
        this.setState({
            ...this.state,
            file: file
        })
    }

    onSubmit(e) {
        // let data = new FormData(e.target)
    }

    togglePopup() {

        this.clearForm()
        this.props.togglePopup()
    }

    clearForm() {
        this.setState({
            ...this.state,
            file: {
                title: '',
                files: null,
                roles: []
            }
        })
    }

    saveForm() {
        // let slug = this.props.editSlug
        if (this.props.editMode) {
            let data = this.state.file
            delete data.files
            this.props.editCourse(this.props.editItem.identifier, this.state.file)
        } else {
            this.props.addCourse(this.state.file)
        }
    }

}
